import { gql } from "@apollo/client";
export const InsertCustomerOne = gql`
  mutation InsertCustomerOne($object: employees_insert_input!) {
    insert_customers(object: $object) {
      id
    }
  }
`;

export const UpdateNurses =
  gql`mutation UpdateNurses(\$where:customers_bool_exp!, \$object: customers_set_input) {
  update_customers(where: \$where, _set: \$object) {
    affected_rows
  }
}`

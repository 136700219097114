import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Badge } from "reactstrap";
import { FiArrowLeft, FiBell, FiEdit, FiXCircle } from "react-icons/fi";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Placeholder, SelectPicker } from "rsuite";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { showAlert } from "helpers/index";

import { getImage } from "helpers";

//constants
import nurseStatusList from "constants/nurseStatus";
import {
  availabilityDropdownData,
  terminationDropdownData,
  locationPendingDropdownData,
} from "constants/documentCategories";

//components
import MarketPlaceProfileTabs from "./tabs/MarketPlaceProfileTabs";
import InvitationModal from "components/invitation/InvitationModal";
import CreateInterviewModal from "components/marketplace/CreateInterviewModal";
import NotificationModal from "components/notification/NotificationModal";

//graphql
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  FetchCustomerDetails,
  FetchCustomerDetailsAdmin,
} from "gql/customers/query";
import { FetchSSN } from "gql/documents/query";
import { UpdateCustomer } from "gql/profile/mutation";

//functions
import {
  FormatData,
  formatCustomerStatus,
} from "pages/Marketplace/marketplace.functions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import {
  notifyNurseDocSubmission,
  notInterestedNurseEmail,
} from "toolkit/admin/admin.api";

const MarketPlaceProfile: React.FC = (props) => {
  const history = useHistory();
  const client = useApolloClient();
  const params: any = useParams();
  const dispatch = useDispatch();
  const {
    auth: { token, user },
  } = useSelector(authState);
  const { Paragraph } = Placeholder;
  // const [activeTab, setActiveTab] = useState("professional_exp");
  const [UpdateCustomerMutation] = useMutation(UpdateCustomer);

  const [avatar, setAvatar] = useState<string>("");
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [interviewModalOpen, setInterviewModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isEditStatus, setEditStatus] = useState(false);
  const [isEditAvailable, setEditAvailable] = useState(false);
  const [isEditTerminated, setEditTerminated] = useState(false);
  const [isEditLocationPending, setEditLocationPending] = useState(false);

  const handleGetImage = async () => {
    const image: string = (await getImage(
      nurseProfile.profile_picture,
      token,
      "image"
    )) as string;
    setAvatar(image);
  };

  const [nurseProfile, setNurseProfile] = useState<any>(null);
  const { loading, data, refetch, error, called } = useQuery(
    user.role === "provider" ? FetchCustomerDetails : FetchCustomerDetailsAdmin,
    {
      variables: {
        id: params?.id,
      },
    }
  );

  const { data: docInfo } = useQuery(FetchSSN, {
    variables: {
      practioner_id: params?.id,
    },
  });

  const handleUpdateInterviewStatus = async () => {
    Swal.fire({
      title: "Update Interview Status",
      text: "You can select/reject candidate based on the recent interview or reschedule another interview",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Select Candidate",
      confirmButtonColor: "#50cd89",
      denyButtonText: `Reject Candidate`,
    }).then(async (result) => {
      if (!result.isDismissed) {
        let status = "";
        if (result.isConfirmed) {
          status = "selected";
        } else if (result.isDenied) {
          status = "rejected";
        }
        await UpdateCustomerMutation({
          variables: {
            id: nurseProfile.id,
            object: {
              onboarding_interview_status: status,
              onboarding_interview_comments: null,
              onboarding_application_form_submitted: true,
            },
          },
        });

        //send email to nurse for document submission
        if (result.isConfirmed) {
          const formData = {
            name: nurseProfile.name,
            email: nurseProfile.email,
          };
          await dispatch(notifyNurseDocSubmission({ formData, token }));
        }

        showAlert({
          title: "Updated!",
          message: `Interview status updated successfully!`,
          type: "success",
        });
        client.refetchQueries({
          include: [
            "FetchCustomerDetailsAdmin",
            "FetchCustomersAdmin",
            "DashboardAnalytics",
            "CustomerAggregateByNurseTypes",
            "CustomerAggregateByStatus",
            "CustomerAggregateByNurseTypesForMassachusetts",
            "CustomerAggregateByStatusForMassachusetts",
          ],
        });
      }
    });
  };
  const handleVerifyCandidate = (status: boolean) => {
    Swal.fire({
      title: `${status ? "Verify" : "Unverify"} Candidate`,
      text: `Are you sure want to ${
        status ? "verify" : "unverify"
      } this candidate?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50cd89",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UpdateCustomerMutation({
          variables: {
            id: nurseProfile.id,
            object: {
              verified: status,
              onboarding_application_form_submitted: true,
            },
          },
        });
        showAlert({
          title: "Updated!",
          message: `Candidate verified successfully!`,
          type: "success",
        });
        client.refetchQueries({
          include: [
            "FetchCustomerDetailsAdmin",
            "FetchCustomersAdmin",
            "DashboardAnalytics",
            "CustomerAggregateByNurseTypes",
            "CustomerAggregateByStatus",
            "CustomerAggregateByNurseTypesForMassachusetts",
            "CustomerAggregateByStatusForMassachusetts",
          ],
        });
      }
    });
  };
  const handleEditStatus = async (status: string) => {
    Swal.fire({
      title: `Update Status Manually`,
      text: `Are you sure want to update the status manually?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50cd89",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let object = {};
          if (status === "verified") {
            object = {
              verified: true,
              onboarding_documents_verified: true,
              onboarding_documents_submitted: true,
              onboarding_interview_scheduled: true,
              onboarding_interview_status: "selected",
              onboarding_application_form_submitted: true,
            };
          } else if (status === "documents_submitted") {
            object = {
              onboarding_documents_submitted: true,
              onboarding_application_form_submitted: true,
            };
          } else if (status === "selected") {
            object = {
              onboarding_interview_status: "selected",
              onboarding_interview_scheduled: true,
              onboarding_application_form_submitted: true,
            };
          } else if (status === "to_be_interviewed") {
            object = { onboarding_application_form_submitted: true };
          } else if (status === "rejected") {
            object = { onboarding_interview_status: "rejected" };
          }
          await UpdateCustomerMutation({
            variables: {
              id: nurseProfile.id,
              object,
            },
          });
          //send email to nurse for document submission
          if (status === "selected") {
            const formData = {
              name: nurseProfile.name,
              email: nurseProfile.email,
            };
            await dispatch(notifyNurseDocSubmission({ formData, token }));
          }

          showAlert({
            title: "Updated!",
            message: `Status updated successfully!`,
            type: "success",
          });
          client.refetchQueries({
            include: [
              "FetchCustomerDetailsAdmin",
              "FetchCustomersAdmin",
              "DashboardAnalytics",
              "CustomerAggregateByNurseTypes",
              "CustomerAggregateByStatus",
              "CustomerAggregateByNurseTypesForMassachusetts",
              "CustomerAggregateByStatusForMassachusetts",
            ],
          });
          setEditStatus(false);
        } catch (err) {
          showAlert({
            title: "Error!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      }
    });
  };

  const handleEditAvailable = async (status: string) => {
    Swal.fire({
      title: `Mark the nurse  ${status === 'interested' ? "interested" : "not interested"}`,
      text: `Are you sure want to update?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50cd89",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let object = {};
          if (status === "interested") {
            object = {
              is_interested: true,
              is_archived: false,
            };
          } else if (status === "not interested") {
            object = {
              is_interested: false,
              is_archived: true,
            };
            const formData = {
              name: nurseProfile.name,
              email: nurseProfile.email,
            };
            await dispatch(notInterestedNurseEmail({ formData, token }));
          }
          await UpdateCustomerMutation({
            variables: {
              id: nurseProfile.id,
              object,
            },
          });

          //send email to nurse

          showAlert({
            title: "Updated!",
            message: `Updated successfully!`,
            type: "success",
          });
          client.refetchQueries({
            include: ["FetchCustomerDetailsAdmin", "FetchCustomersAdmin"],
          });
          setEditAvailable(false);
        } catch (err) {
          showAlert({
            title: "Error!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      }
    });
  };

  const handleEditTermination = async (status: string) => {
    Swal.fire({
      title: `Mark the nurse ${status === 'terminate' ? "terminate" : "not terminate"}`,
      text: `Are you sure want to update?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50cd89",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let object = {};
          if (status === "terminate") {
            object = {
              is_terminated: true,
              is_archived: true,
            };
          } else if (status === "not terminate") {
            object = {
              is_terminated: false,
              is_archived: false,
            };
          }
          await UpdateCustomerMutation({
            variables: {
              id: nurseProfile.id,
              object,
            },
          });

          showAlert({
            title: "Updated!",
            message: `Updated successfully!`,
            type: "success",
          });
          client.refetchQueries({
            include: ["FetchCustomerDetailsAdmin", "FetchCustomersAdmin"],
          });
          setEditTerminated(false);
        } catch (err) {
          showAlert({
            title: "Error!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      }
    });
  };
  const handleEditLocationPending = async (status: string) => {
    Swal.fire({
      title: `Mark the nurse location ${status === 'location pending' ? "pending" : "confirmed"}`,
      text: `Are you sure want to update?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50cd89",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let object = {};
          if (status === "location pending") {
            object = {
              is_location_pending: true,
              is_archived: true,
            };
          } else if (status === "location confirmed") {
            object = {
              is_location_pending: false,
              is_archived: false,
            };
          }
          await UpdateCustomerMutation({
            variables: {
              id: nurseProfile.id,
              object,
            },
          });

          showAlert({
            title: "Updated!",
            message: `Updated successfully!`,
            type: "success",
          });
          client.refetchQueries({
            include: ["FetchCustomerDetailsAdmin", "FetchCustomersAdmin"],
          });
          setEditLocationPending(false);
        } catch (err) {
          showAlert({
            title: "Error!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (data?.customers_by_pk) {
      const customerData = FormatData([data?.customers_by_pk]);
      if (customerData?.length) {
        setNurseProfile(customerData[0]);
      }
    }
  }, [data]);
  useEffect(() => {
    if (nurseProfile?.profile_picture) {
      handleGetImage();
    }
  }, [nurseProfile]);

  function toggle() {
    setNotificationModalOpen(!notificationModalOpen);
  }

  return (
    <React.Fragment>
      <div className="page-content AppBody">
        <MetaTags>
          <title>
            {nurseProfile !== null && !loading
              ? nurseProfile.firstname && nurseProfile.lastname
                ? `${nurseProfile.firstname} ${nurseProfile.lastname}`
                : "Nurse Profile"
              : "Marketplace Profile"}{" "}
            | Caregigs - On Demand Healthcare Professionals
          </title>
        </MetaTags>
        <Container fluid className="MarketPlaceProfile">
          {nurseProfile !== null && !loading ? (
            <div className="MarketPlaceProfile-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="MarketPlaceProfile_top">
                    <div
                      className="back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <FiArrowLeft />
                    </div>
                    <Breadcrumbs
                      title={
                        user.role === "provider"
                          ? "Marketplace"
                          : "Nurse Profile"
                      }
                      links={
                        user.role === "provider"
                          ? [
                              { title: "Home", link: "/" },
                              { title: "Marketplace", link: "/marketplace" },
                              {
                                title: `${nurseProfile.firstname} ${nurseProfile.lastname}`,
                                link: "#",
                              },
                            ]
                          : [
                              { title: "Nurses", link: "/nurses" },
                              {
                                title:
                                  nurseProfile.firstname &&
                                  nurseProfile.lastname
                                    ? `${nurseProfile.firstname} ${nurseProfile.lastname}`
                                    : "Nurse Profile",
                                link: "#",
                              },
                            ]
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="MarketPlaceProfile_card left">
                    {/* profile */}
                    <div className="profile">
                      {!nurseProfile.profile_picture || !avatar ? (
                        <img
                          src={"/avatar.jpg"}
                          alt=""
                          className="profile_img"
                        />
                      ) : (
                        <img className="profile_img" src={avatar} alt="" />
                      )}
                      <div className="profile_details">
                        <h4
                          className="profile_details-name"
                          style={{ width: "100%", position: "relative" }}
                        >
                          {nurseProfile.firstname} {nurseProfile.lastname}{" "}
                          {nurseProfile.verified && (
                            <i className="bx bx-badge-check text-success"></i>
                          )}
                          {user.role === "moderator" && (
                            <FiBell
                              style={{
                                position: "absolute",
                                top: 5,
                                right: -5,
                              }}
                              onClick={() => setNotificationModalOpen(true)}
                            />
                          )}
                        </h4>
                        <h5 className="profile_details-position">
                          {nurseProfile.questionnaire?.qualificatioType ||
                            "Not Specified"}
                        </h5>
                        <div className="profile_details-row">
                          <div className="profile_details-card">
                            <div className="profile_details-row">
                              <img
                                src={"/icons/shift.png"}
                                alt=""
                                className="sft_img"
                              />
                              <h5 className="sft-number">
                                {nurseProfile.completedShifts || 0}
                              </h5>
                            </div>
                            <h5 className="sft-tag">Shift</h5>
                          </div>
                          <div className="profile_details-card">
                            <div className="profile_details-row">
                              <img
                                src={"/icons/rateperhr.png"}
                                alt=""
                                className="sft_img"
                              />
                              {nurseProfile.preferred_rate ? (
                                <h5 className="sft-number">
                                  ${nurseProfile.preferred_rate}
                                </h5>
                              ) : (
                                <h5 className="sft-number">N/A</h5>
                              )}
                            </div>
                            <h5 className="sft-tag">Rate/hr</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* invite buttons */}
                    {user.role === "provider" ? (
                      <button
                        className="btn btn-cus"
                        onClick={() => setInviteModalOpen(true)}
                      >
                        + Invite
                      </button>
                    ) : !nurseProfile?.onboarding_interview_scheduled ? (
                      <button
                        className="btn btn-cus"
                        onClick={() => setInterviewModalOpen(true)}
                        type="button"
                      >
                        Schedule Interview
                      </button>
                    ) : !nurseProfile.verified ? (
                      <>
                        {nurseProfile?.onboarding_interview_status ===
                        "scheduled" ? (
                          <p className="mb-3">
                            Interview Scheduled on{" "}
                            <i>
                              {moment(
                                nurseProfile?.onboarding_interview_date
                              ).format("MM/DD/YYYY hh:mm A")}
                            </i>
                          </p>
                        ) : (
                          <p className="mb-3">
                            Candidate{" "}
                            {nurseProfile?.onboarding_interview_status} in
                            interview
                          </p>
                        )}

                        <div className="d-flex justify-content-start align-items-center">
                          {nurseProfile?.onboarding_interview_status ===
                            "scheduled" && (
                            <a
                              href={nurseProfile?.onboarding_interview_link}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary"
                            >
                              Join Meeting
                            </a>
                          )}
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginLeft: 5 }}
                            onClick={() => setInterviewModalOpen(true)}
                          >
                            Reschedule
                          </button>
                          {nurseProfile?.onboarding_interview_status ===
                            "scheduled" && (
                            <button
                              type="button"
                              className="btn btn-warning"
                              style={{ marginLeft: 5 }}
                              onClick={handleUpdateInterviewStatus}
                            >
                              Update Interview Status
                            </button>
                          )}
                          {nurseProfile?.onboarding_documents_verified &&
                            nurseProfile?.onboarding_interview_status ===
                              "selected" && (
                              <button
                                type="button"
                                className="btn btn-success"
                                style={{ marginLeft: 5 }}
                                onClick={() => handleVerifyCandidate(true)}
                              >
                                Verify Candidate
                              </button>
                            )}
                        </div>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: 5 }}
                        onClick={() => handleVerifyCandidate(false)}
                      >
                        Unverify Candidate
                      </button>
                    )}
                    {/* Personal Information */}
                    <div className="PersonalInformation">
                      <h4 className="PersonalInformation_header">
                        {" "}
                        Personal Information{" "}
                      </h4>
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Full Name</div>
                        <div className="information-info">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {nurseProfile.firstname && nurseProfile.lastname
                                ? `${nurseProfile.firstname} ${nurseProfile.lastname}`
                                : "N/A"}
                            </span>
                            {!isEditStatus ? (
                              <Badge
                                to="#"
                                className={`badge ${
                                  formatCustomerStatus(nurseProfile).color
                                } font-size-11 mx-2 p-2`}
                                style={{
                                  cursor:
                                    formatCustomerStatus(nurseProfile).value !==
                                    "verified"
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={() =>
                                  formatCustomerStatus(nurseProfile).value !==
                                    "verified" && nurseProfile.is_phone_verified
                                    ? setEditStatus(true)
                                    : null
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <span>
                                    {formatCustomerStatus(nurseProfile).status}
                                  </span>
                                  {formatCustomerStatus(nurseProfile).value !==
                                    "verified" &&
                                    nurseProfile.is_phone_verified && (
                                      <FiEdit style={{ marginLeft: 5 }} />
                                    )}
                                </div>
                              </Badge>
                            ) : (
                              <div className="d-flex align-items-center">
                                <SelectPicker
                                  data={nurseStatusList.filter(
                                    (status) => status.value !== "signed_up"
                                  )}
                                  cleanable={false}
                                  searchable={false}
                                  value={
                                    formatCustomerStatus(nurseProfile).value
                                  }
                                  onChange={handleEditStatus}
                                />
                                <FiXCircle
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setEditStatus(false)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Mobile</div>
                        <div className="information-info">
                          {nurseProfile.contactno1}
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Email</div>
                        <div className="information-info">
                          {nurseProfile.email}
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Location</div>
                        <div className="information-info">
                          {nurseProfile.address || "N/A"}
                        </div>
                      </div>
                      {/* info */}
                      <div className="information">
                        <div className="information-header">
                          Social Security Number
                        </div>
                        <div className="information-info">
                          {docInfo?.documents[0]?.document_info
                            ?.documentNumber || "N/A"}
                        </div>
                      </div>
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Other</div>
                        <div className="information-info">
                          Covid Vaccinated:{" "}
                          {nurseProfile.questionnaire?.covidVaccinated}
                        </div>
                        <div className="information-info">
                          Preferred Time to Call:{" "}
                          {nurseProfile.questionnaire?.preferredTimeToCall}
                        </div>
                        <div className="information-info">
                          Interested in Travel Assignments:{" "}
                          {nurseProfile.questionnaire?.travelAssignments}
                        </div>
                        <div className="information-info">
                          Negative TB test within last year:{" "}
                          {nurseProfile.questionnaire?.TBNegative}
                        </div>
                      </div>
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Avaliablity</div>
                        <div className="information-info">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {nurseProfile.is_interested === true
                                ? "Interested"
                                : "Not-Interested"}
                            </span>
                            {!isEditAvailable ? (
                              <Badge
                                to="#"
                                className={`badge ${
                                  nurseProfile.is_interested == true
                                    ? "bg-success"
                                    : "bg-danger"
                                } font-size-11 m-2 p-2`}
                                onClick={() => setEditAvailable(true)}
                              >
                                <div className="d-flex align-items-center">
                                  <span>
                                    {nurseProfile.is_interested === true
                                      ? "Interested"
                                      : "Not-Interested"}
                                  </span>

                                  <FiEdit style={{ marginLeft: 5 }} />
                                </div>
                              </Badge>
                            ) : (
                              <div className="d-flex align-items-center">
                                <SelectPicker
                                  data={availabilityDropdownData}
                                  cleanable={false}
                                  searchable={false}
                                  value={
                                    nurseProfile.is_interested === true
                                      ? "interested"
                                      : "not interested"
                                  }
                                  onChange={handleEditAvailable}
                                />
                                <FiXCircle
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setEditAvailable(false)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="information">
                        <div className="information-header">Termination</div>
                        <div className="information-info">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {nurseProfile.is_terminated === true
                                ? "Terminated"
                                : "Not-Terminated"}
                            </span>
                            {!isEditTerminated ? (
                              <Badge
                                to="#"
                                className={`badge ${
                                  nurseProfile.is_terminated == true
                                    ? "bg-danger"
                                    : "bg-success"
                                } font-size-11 m-2 p-2`}
                                onClick={() => setEditTerminated(true)}
                              >
                                <div className="d-flex align-items-center">
                                  <span>
                                    {nurseProfile.is_terminated === true
                                      ? "Terminated"
                                      : "Not-Terminated"}
                                  </span>

                                  <FiEdit style={{ marginLeft: 5 }} />
                                </div>
                              </Badge>
                            ) : (
                              <div className="d-flex align-items-center">
                                <SelectPicker
                                  data={terminationDropdownData}
                                  cleanable={false}
                                  searchable={false}
                                  value={
                                    nurseProfile.is_terminated === true
                                      ? "terminate"
                                      : "not terminate"
                                  }
                                  onChange={handleEditTermination}
                                />
                                <FiXCircle
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setEditTerminated(false)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="information">
                        <div className="information-header">
                          Location Pending
                        </div>
                        <div className="information-info">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {nurseProfile.is_location_pending === true
                                ? "Location Pending"
                                : "Location Confirmed"}
                            </span>
                            {!isEditLocationPending ? (
                              <Badge
                                to="#"
                                className={`badge ${
                                  nurseProfile.is_location_pending == true
                                    ? "bg-danger"
                                    : "bg-success"
                                } font-size-11 m-2 p-2`}
                                onClick={() => setEditLocationPending(true)}
                              >
                                <div className="d-flex align-items-center">
                                  <span>
                                    {nurseProfile.is_location_pending === true
                                      ? "Location Pending"
                                      : "Location Confirmed"}
                                  </span>

                                  <FiEdit style={{ marginLeft: 5 }} />
                                </div>
                              </Badge>
                            ) : (
                              <div className="d-flex align-items-center">
                                <SelectPicker
                                  data={locationPendingDropdownData}
                                  cleanable={false}
                                  searchable={false}
                                  value={
                                    nurseProfile.is_location_pending == true
                                      ? "location pending"
                                      : "location confirmed"
                                  }
                                  onChange={handleEditLocationPending}
                                />
                                <FiXCircle
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setEditLocationPending(false)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <MarketPlaceProfileTabs
                    user={nurseProfile}
                    // activeTab={activeTab}
                    // setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="p-2">
              <Paragraph rows={25} graph="image" active />
            </div>
          )}
        </Container>
      </div>
      <InvitationModal
        isEdit={false}
        setIsEdit={() => {
          //
        }}
        toggle={() => setInviteModalOpen(false)}
        modal={inviteModalOpen}
        refetch={() => {
          //
        }}
        customer={nurseProfile}
        selectedPosition={null}
        selectedDate={new Date()}
      />
      <CreateInterviewModal
        isOpen={interviewModalOpen}
        toggle={() => setInterviewModalOpen(false)}
        customer={nurseProfile}
      />
      <NotificationModal
        toggle={toggle}
        modal={notificationModalOpen}
        notificationTokens={[nurseProfile?.notification_token]}
      />
    </React.Fragment>
  );
};

export default MarketPlaceProfile;

import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { Placeholder } from "rsuite";
import Profile from "../../pages/Marketplace/ContactsProfile/contacts-profile";

//functions
import { FormatData } from "pages/Marketplace/marketplace.functions";
//GraphQL
import { useQuery } from "@apollo/client";
import { FetchCustomerDetails } from "gql/customers/query";

const ProfileModal: React.FC<{
  user: any;
  isOpen: boolean;
  toggle: () => void;
}> = ({ user, isOpen, toggle }) => {
  const { Paragraph } = Placeholder;
  const [customer, setCustomer] = useState<any>(null);
  const { loading, data, refetch, error, called } = useQuery(
    FetchCustomerDetails,
    {
      variables: {
        id: user?.customer.id,
      },
    }
  );
  useEffect(() => {
    if (data?.customers_by_pk) {
      const customerData = FormatData([data?.customers_by_pk]);
      if (customerData?.length) {
        setCustomer(customerData[0]);
      }
    }
  }, [data]);
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={() => {
        toggle();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Profile</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {customer !== null && !loading ? (
          <Profile user={customer} />
        ) : (
          <div className="p-2">
            <Paragraph rows={15} graph="image" active />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ProfileModal;

import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  InputGroup,
} from "reactstrap";
import { TagGroup, Tag } from "rsuite";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { differenceBy } from "lodash";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";

// icons
import { FiCheck, FiX } from "react-icons/fi";

//types
import locationType, {
  locationInputType,
  areaType,
} from "pages/Settings/locations/locations.types";

//helpers
import { getAddressComponents } from "helpers";

//components
import AutoComplete from "components/Common/AutoComplete";
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate";

//graphql
import {
  InsertLocationOne,
  UpdateLocation,
  InsertAreaInLocation,
  DeleteLocation,
} from "gql/locations/mutation";
import { FetchAreas } from "gql/areas/query";
import { InsertArea } from "gql/areas/mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { FetchLocationsForFilter } from "gql/locations/query";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { profileState } from "toolkit/profile/profile.slice";

const initialState: locationInputType = {
  organisation_id: "",
  name: "",
  address: "",
  geopoint: {},
  isactive: true,
  email: "",
  contactno: "",
  opening_hours: {
    timer: [{ start: "" }, { end: "" }],
  },
  buildings: [],
  type: "",
  lat: 0,
  lng: 0,
  facility_details: "",
};
const LocationModal: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  refetch: () => void;
  selectedLocation: locationType | undefined;
}> = ({ isEdit, setIsEdit, toggle, modal, refetch, selectedLocation }) => {
  const client = useApolloClient();
  type SelectPickerPaginateHandle = React.ElementRef<
    typeof SelectPickerPaginate
  >;
  const areaRef = React.useRef<SelectPickerPaginateHandle>(null);
  const { profile } = useSelector(profileState);
  const {
    auth: { user },
  } = useSelector(authState);
  const [CreateLocationMutation, { loading }] = useMutation(InsertLocationOne);
  const [UpdateLocationMutation, { loading: loadingUpdateLocation }] =
    useMutation(UpdateLocation);
  const [CreateAreaMutation, { loading: loadingCreateArea }] =
    useMutation(InsertArea);
  const [
    InsertAreaInLocationMutation,
    { loading: loadingInsertAreaInLocation },
  ] = useMutation(InsertAreaInLocation);
  const [DeleteLocationMutation, { loading: loadingDeleteLocation }] =
    useMutation(DeleteLocation);
  const [location, setLocation] = useState<locationInputType>(initialState);
  const [customState, setCustomState] = useState<any>({
    address: "",
    lat: 0,
    lng: 0,
    city: "",
    state: "",
    buildings: [],
    opening_hours: {
      timer: [{ start: "" }, { end: "" }],
    },
  });
  const [isCreateAreaOpen, setCreateAreaOpen] = useState<boolean>(false);
  const [areaName, setAreaName] = useState<string>("");
  const [selectedAreas, setSelectedAreas] = useState<areaType[]>([]);
  const [originalAreas, setOriginalAreas] = useState<areaType[]>([]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (location && location.name) || "",
      contactno: (location && location.contactno) || "",
      email: (location && location.email) || "",
      address: (location && location.address) || "",
      facility_details: (location && location.facility_details) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Location Name"),
      // contactno: Yup.string().length(10, "Contact No. Should be 10 Digits Long").required("Please Enter Contact No."),
      contactno: Yup.string()
        .length(10, "Contact No. Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Please Enter Contact No."),
      email: Yup.string().required("Please Enter Email"),
      address: Yup.string().required("Please Enter Address"),
      facility_details: Yup.string().required("Please Enter Facility Details"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        await UpdateLocationMutation({
          variables: {
            id: selectedLocation?.id,
            object: {
              name: values.name,
              organisation_id: profile.organisation_id,
              address: customState.address,
              city: customState.city,
              state: customState.state,
              geopoint: {
                type: "Point",
                crs: {
                  type: "name",
                  properties: { name: "urn:ogc:def:crs:EPSG::4326" },
                },
                coordinates: [customState.lat, customState.lng],
              },
              email: values.email,
              contactno: String(values.contactno),
              lat: customState.lat,
              lng: customState.lng,
              facility_details: values.facility_details,
            },
          },
          // refetchQueries: [{
          // 	query: FetchLocationsForFilter,
          // 	variables: {
          // 		isactive: true,
          // 		createdby: user?.id
          // 	}
          // }]
        });
        const deletedAreas = differenceBy(originalAreas, selectedAreas, "id");
        if (deletedAreas.length) {
          //delete areas from location
          await DeleteLocationMutation({
            variables: {
              where: { area_id: { _in: deletedAreas.map((area) => area.id) } },
            },
          });
        }
        const newAreas = differenceBy(selectedAreas, originalAreas, "id");
        if (newAreas.length) {
          //map new areas in location
          const objects: any[] = [];
          newAreas.map((_area) => {
            objects.push({
              location_id: selectedLocation?.id,
              area_id: _area.id,
            });
          });
          await InsertAreaInLocationMutation({
            variables: {
              objects,
            },
          });
        }
        setIsEdit(false);
        // Swal.fire("Location edited successfully", "", "success")
        showAlert({
          title: "Updated!",
          message: "Location edited successfully",
          type: "success",
        });
      } else {
        const locationResp = await CreateLocationMutation({
          variables: {
            object: {
              organisation_id: profile.organisation_id,
              name: values.name,
              address: customState.address,
              city: customState.city,
              state: customState.state,
              geopoint: {
                type: "Point",
                crs: {
                  type: "name",
                  properties: { name: "urn:ogc:def:crs:EPSG::4326" },
                },
                coordinates: [customState.lat, customState.lng],
              },
              isactive: true,
              email: values.email,
              contactno: String(values.contactno),
              opening_hours: customState.opening_hours,
              buildings: customState.buildings,
              type: "",
              // type: values.type,
              lat: customState.lat,
              lng: customState.lng,
              facility_details: values.facility_details,
            },
          },
          // refetchQueries: [{
          // 	query: FetchLocationsForFilter,
          // 	variables: {
          // 		isactive: true,
          // 		createdby: user?.id
          // 	}
          // }]
        });
        if (locationResp?.data && selectedAreas.length) {
          const locationId = locationResp?.data?.insert_locations_one?.id;
          const objects: any[] = [];
          selectedAreas.map((_area) => {
            objects.push({
              location_id: locationId,
              area_id: _area.id,
            });
          });
          await InsertAreaInLocationMutation({
            variables: {
              objects,
            },
          });
        }
        Swal.fire("Location added successfully", "", "success");
      }
      handleClose();
      refetch();
    },
  });
  const handleClose = () => {
    toggle();
    validation.resetForm();
    setLocation(initialState);
    setIsEdit(false);
    setCreateAreaOpen(false);
    setAreaName("");
    setSelectedAreas([]);
    setOriginalAreas([]);
  };
  const handleSetAddress = (address: any) => {
    let lat = 0,
      lng = 0,
      city = "",
      state = "";
    if (address.geometry) {
      lat = address.geometry.location.lat();
      lng = address.geometry.location.lng();
    }
    if (
      Array.isArray(address?.address_components) &&
      address?.address_components?.length
    ) {
      const address_components = getAddressComponents(
        address.address_components
      );
      city = address_components.city;
      state = address_components.state;
    }
    validation.setFieldValue("address", address.formatted_address);
    setCustomState({
      ...customState,
      address: address.formatted_address,
      lat,
      lng,
      city,
      state,
    });
  };
  const handleSetOpeningHour = (type: string, time: Date) => {
    const _time = moment(time).format("H:mm A");
    if (type === "start") {
      setCustomState({
        ...customState,
        opening_hours: {
          timer: [
            { start: _time },
            { end: customState.opening_hours.timer[1].end },
          ],
        },
      });
    } else if (type === "end") {
      setCustomState({
        ...customState,
        opening_hours: {
          timer: [
            { start: customState.opening_hours.timer[0].start },
            { end: _time },
          ],
        },
      });
    }
  };
  const handleCreateArea = async () => {
    try {
      if (areaName.length > 0) {
        await CreateAreaMutation({
          variables: {
            objects: [
              {
                name: areaName,
              },
            ],
          },
        });
        areaRef.current?.handleRefetch();
        setAreaName("");
        setCreateAreaOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleRemoveArea = (id: string) => {
    const _filteredAreas = selectedAreas.filter(
      (_area: areaType) => _area.id !== id
    );
    setSelectedAreas(_filteredAreas);
  };
  const handleAddArea = (_area: string) => {
    const _areaJson = JSON.parse(_area);
    const _selectedAreas = [...selectedAreas];
    _selectedAreas.push(_areaJson);
    setSelectedAreas(_selectedAreas);
  };
  useEffect(() => {
    if (isEdit && selectedLocation?.id) {
      setLocation({
        ...location,
        name: selectedLocation.name,
        address: selectedLocation.address,
        email: selectedLocation.email,
        contactno: selectedLocation.contactno,
        facility_details: selectedLocation.facility_details,
      });
      setCustomState({
        ...customState,
        address: selectedLocation.address,
        lat: selectedLocation.lat,
        lng: selectedLocation.lng,
      });
      const _selectedAreas: areaType[] = [];
      selectedLocation?.areas?.map((area) => {
        _selectedAreas.push({
          id: area.area.id,
          name: area.area.name,
          user_id: area.area.user_id,
          active: area.area.active,
        });
      });
      setSelectedAreas(_selectedAreas);
      setOriginalAreas(_selectedAreas);
    }
  }, [selectedLocation, isEdit]);
  return (
    <Modal
      className="customModal"
      isOpen={modal}
      toggle={handleClose}
      backdrop="static"
    >
      <ModalHeader toggle={handleClose} tag="h4">
        {!!isEdit ? "Edit Location" : "Add Location"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Location Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Address</Label>
                <AutoComplete
                  onSelect={handleSetAddress}
                  name="address"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="contactno"
                  label="Phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contactno || ""}
                  invalid={
                    validation.touched.contactno && validation.errors.contactno
                      ? true
                      : false
                  }
                />
                {validation.touched.contactno && validation.errors.contactno ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contactno}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Areas</Label>
                <Row>
                  <Col md={8}>
                    {/* {isCreateAreaOpen ? ( */}
                    <Input
                      name="area"
                      placeholder="Area Name"
                      type="text"
                      onChange={(e) => setAreaName(e.target.value)}
                      required={isCreateAreaOpen ? true : false}
                      value={areaName}
                      className={isCreateAreaOpen ? "d-block" : "d-none"}
                    />
                    {/* ) : ( */}
                    <SelectPickerPaginate
                      ref={areaRef}
                      query={FetchAreas}
                      placeholder="Select Area"
                      value={""}
                      onChange={(area: string) => handleAddArea(area)}
                      arrKey="areas"
                      objectLabel="name"
                      objectValue="id"
                      style={{ width: "100%" }}
                      paginate={true}
                      filterCondition={{
                        id: { _nin: selectedAreas.map((area) => area.id) },
                        user_id: { _eq: user?.id },
                      }}
                      getFullObj={true}
                      searchValue=""
                      menuStyle={{ zIndex: 9999 }}
                      className={isCreateAreaOpen ? "d-none" : "d-block"}
                    />
                    {/* )} */}
                  </Col>
                  <Col md={4}>
                    {isCreateAreaOpen ? (
                      <Row style={{ justifyContent: "center" }}>
                        <Col md={6}>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => handleCreateArea()}
                            type="button"
                            disabled={loadingCreateArea}
                          >
                            <FiCheck />
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => setCreateAreaOpen(false)}
                            type="button"
                          >
                            <FiX />
                          </button>
                        </Col>
                      </Row>
                    ) : (
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => setCreateAreaOpen(true)}
                        type="button"
                      >
                        Create Area
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <TagGroup>
                  {selectedAreas.map((_area: areaType, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={() => handleRemoveArea(_area.id)}
                    >
                      {_area.name}
                    </Tag>
                  ))}
                </TagGroup>
              </div>
              <div className="mb-3">
                <Label className="form-label">Facility Details</Label>
                <Input
                  name="facility_details"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.facility_details || ""}
                  invalid={
                    validation.touched.facility_details &&
                    validation.errors.facility_details
                      ? true
                      : false
                  }
                />
                {validation.touched.facility_details &&
                validation.errors.facility_details ? (
                  <FormFeedback type="invalid">
                    {validation.errors.facility_details}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
					<Label className="form-label">Buildings</Label>
					<div style={{ width: "100%" }}>
						<TagInput
							onChange={(value) => setCustomState({ ...customState, buildings: value })}
							trigger={["Enter", "Comma"]}
							value={customState.buildings}
							data={[]}
							style={{ width: "100%" }}
						/>
					</div>
				</div>
				<div className="mb-3">
					<Row>
						<Col md={6}>
							<div className="mb-3">
								<Label htmlFor="formrow-email-Input">Start Time</Label>
								<InputGroup>
									<Flatpickr
										className="form-control d-block"
										placeholder="Select time"
										options={{
											enableTime: true,
											noCalendar: true,
											dateFormat: "H:i"
										}}
										onChange={(value)=>handleSetOpeningHour("start", value[0])}
									/>
									<div className="input-group-append">
										<span className="input-group-text">
											<i className="mdi mdi-clock-outline" />
										</span>
									</div>
								</InputGroup>
							</div>
						</Col>
						<Col md={6}>
							<div className="mb-3">
								<Label htmlFor="formrow-email-Input">End Time</Label>
								<InputGroup>
									<Flatpickr
										className="form-control d-block"
										placeholder="Select time"
										options={{
											enableTime: true,
											noCalendar: true,
											dateFormat: "H:i"
										}}
										onChange={(value)=>handleSetOpeningHour("end", value[0])}
									/>
									<div className="input-group-append">
										<span className="input-group-text">
											<i className="mdi mdi-clock-outline" />
										</span>
									</div>
								</InputGroup>
							</div>
						</Col>
					</Row>
				</div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                  disabled={loading || loadingUpdateLocation}
                >
                  <FiCheck />
                  &nbsp; Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default LocationModal;

import React, { useEffect, useState, useRef } from "react";
import {
	Col,
	Form,
	FormGroup,
	Input,
	Row,
	Spinner
} from "reactstrap"
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import { Pagination } from 'rsuite';
import Swal from "sweetalert2";
import "../../../assets/scss/custom.scss"
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import "../datatables.scss"

//components
import LocationModal from "components/locations/LocationModal";
import QRViewer from "components/locations/QRViewer";

// icons
import { FiPlus, FiDownloadCloud } from "react-icons/fi";

//Functions
import { LocationColumns, FormatData, BuildLocationSearchQuery, BuildLocationFilterQuery } from "./locations.functions"

//filters
import LocationFilter from "components/Filters/locations/LocationFilter.jsx";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client"
import { FetchLocations } from "../../../gql/locations/query"
import { UpdateLocation } from "../../../gql/locations/mutation"

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//types
import locationType from "./locations.types";

const Locations = () => {
	const { ExportCSVButton } = CSVExport
	const { auth: { user } } = useSelector(authState)
	const initialCondition = {
		createdby: { _eq: user.id },
		isactive: { _eq: true }
	}
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [filterValue, setFilterValue] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>(initialCondition)
	const [selectedLocation, setSelectedLocation] = useState<locationType | undefined>()
	const [showQRModal, setShowQRModal] = useState<boolean>(false)

	const [getLocationList, { loading, data, refetch, error, called }] = useLazyQuery(FetchLocations, {
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const [UpdateLocationMutation] = useMutation(UpdateLocation)
	const defaultSorted: any = [];

	function toggle() {
		setModal(!modal);
	}
	const onEditClick = (_location: locationType) => {
		setSelectedLocation(_location)
		setIsEdit(true)
		toggle()
	}
	const onQRClick = (_location: locationType) => {
		setSelectedLocation(_location)
		setShowQRModal(true)
	}
	const handleDelete = (id: string) => {
		Swal.fire({
			title: 'Are you sure want to delete this location?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await UpdateLocationMutation({
					variables: {
						id,
						object: {
							isactive: false
						}
					}
				})
				refetch()
				Swal.fire('Location Deleted Successfully!', '', 'success')
			}
		})
	}
	const handleSearch = (value: string) => {
		const conditionTemp = BuildLocationSearchQuery(value, null)
		setQueryCondition({ ...initialCondition, ...conditionTemp })
	}

	const handleRefetch = () => {
		if (called) {
			refetch()
		} else {
			getLocationList()
		}
	}
	useEffect(() => {
		if (user?.id) {
			getLocationList()
		}
	}, [user])
	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [searchKey])
	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildLocationFilterQuery(filterValue, null)
		setQueryCondition({ ...initialCondition, ...conditionTemp })
	}, [filterValue])
	return (
		<React.Fragment>
			<div>
				<Row className="mb-3">
					<Col xl={3} sm={6}>
						<div className="mt-2">
							<h5>Locations</h5>
						</div>
					</Col>
					<Col xl={9} sm={6}>
						<Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
							<div className="search-box mb-2 me-2">
								<div className="position-relative">
									<button
										type="button"
										className="btn btn-primary btn-sm"
										onClick={() => setModal(true)}
									>
										<FiPlus  /> Add Location
									</button>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			</div>
			<div>
				<ToolkitProvider
					keyField='id'
					columns={LocationColumns(searchKey, filterValue, history, handleDelete, onEditClick, onQRClick)}
					data={FormatData(Array.isArray(data?.locations) ? data.locations : [])}
					bootstrap4
					exportCSV={{ onlyExportSelection: true, exportAll: true }}
				>
					{toolkitProps => (
						<React.Fragment>
							<Row>
								<>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box me-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<FormGroup>
															<Input
																id="exampleEmail"
																name="email"
																placeholder="Search locations"
																type="text"
																onChange={(e) => setSearchKey(e.target.value)}
																value={searchKey}
															/>
															<i className="bx bx-search-alt search-icon" />
														</FormGroup>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<ExportCSVButton
													{...toolkitProps.csvProps}
													style={{ border: "1px solid #74788d", marginRight: 10 }}
												> <FiDownloadCloud  />&nbsp;&nbsp; Export</ExportCSVButton>
												<LocationFilter
													onFilterChange={setFilterValue}
													activeFilters={["status", "created_at"]}
													filterQueryConditions={null}
												/>
											</div>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col sm="8">
											Total Locations: {data?.locations_aggregate?.aggregate?.count || 0}
										</Col>
									</Row>
									{loading && (
										<Col xl="12" className="text-center">
											<Spinner color="primary" />
										</Col>
									)}
									<Row>
										<Col xl="12">
											<div className="table-responsive">
												<BootstrapTable
													key={`${searchKey}_${JSON.stringify(filterValue)}`}
													bordered={false}
													striped={false}
													defaultSorted={defaultSorted}
													selectRow={{
														mode: 'checkbox',
														onSelectAll: (isSelected) => { console.log(isSelected) }
													}}
													classes={
														"table align-middle table-nowrap table-check"
													}
													headerWrapperClasses={"thead-light"}
													{...toolkitProps.baseProps}
													noDataIndication={() => "No locations found!"}
												/>
											</div>
										</Col>
									</Row>
								</>
							</Row>
							<Row className="align-items-md-center mt-30">
								<Col className="inner-custom-pagination d-flex">
									<div className="d-inline mt-3 w-100">
										<Pagination
											total={data?.locations_aggregate?.aggregate?.count || 0}
											limit={PageData.limit}
											activePage={PageData.pageNo}
											onChangePage={(pageNo: number) => setPageData({ ...PageData, pageNo })}
											limitOptions={[30, 50, 100]}
											layout={['limit', 'pager']}
											onChangeLimit={(limit: number) => setPageData({ ...PageData, limit })}
											className="d-flex justify-content-between"
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
			</div>
			<LocationModal
				isEdit={isEdit}
				setIsEdit={setIsEdit}
				toggle={toggle}
				modal={modal}
				refetch={handleRefetch}
				selectedLocation={selectedLocation}
			/>
			<QRViewer 
				isOpen={showQRModal}
				toggle={()=>setShowQRModal(!showQRModal)}
				location={selectedLocation}
			/>
		</React.Fragment>
	)
}

export default Locations

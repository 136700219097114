import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Button, ButtonToolbar } from "rsuite";
import { FaGooglePlay } from "react-icons/fa";
import { AiFillApple } from "react-icons/ai";
import OtpInput from "react-otp-input";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Swal from "sweetalert2";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  registerApi,
  verifyOTPApi,
  resendOTPApi,
} from "../../toolkit/auth/auth.api";
import { authState } from "../../toolkit/auth/auth.slice";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";

const Register = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { isLoading },
  } = useSelector(authState);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(1);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      hospitalName: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string()
        .length(10, "Phone Number Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Please Enter Your Phone"),
      password: Yup.string().required("Please Enter Your Password"),
      hospitalName: Yup.string().required("Please Enter Facility Name"),
    }),
    onSubmit: async (values) => {
      try {
        const resp = await dispatch(
          registerApi({
            ...values,
            phone: `+1${values.phone}`,
            email: values.email?.toLowerCase()?.trim(),
          })
        );
        if (resp.type === "auth/register/fulfilled") {
          setStep(4);
        } else {
          let message = "Something went wrong!";
          if (resp?.payload?.error?.message) {
            message = resp?.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
        }
      } catch (err) {
        console.log(err);
        Swal.fire("Something went wrong!", "", "warning");
      }
    },
  });

  const handleSubmitOTP = async () => {
    try {
      const resp = await dispatch(
        verifyOTPApi({ email: validation.values.email, otp })
      );
      if (resp.type === "auth/verify-otp/rejected") {
        let message = "Something went wrong!";
        if (resp?.payload?.error?.message) {
          message = resp?.payload?.error?.message;
        }
        Swal.fire(message, "", "warning");
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong!", "", "warning");
    }
  };
  const handleResendOTP = async () => {
    try {
      await dispatch(resendOTPApi({ phone: `+1${validation.values.phone}` }));
      Swal.fire("OTP Sent Successfully", "", "success");
    } catch (err) {}
  };
  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Caregigs - Partner Portal</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages authPage register">
        {/* <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}> */}
        <div className="authPage_card register">
          <div className="authPage_card-left register">
            <div className="content">
              <img
                src={
                  "/cheerful-african-american-medical-worker-in-hospit-2.jpg"
                }
                className="content_img"
              />
              <h2 className="">
                {" "}
                <em>On-Demand</em> Healthcare <em>Staffing Marketplace</em> and{" "}
                <em>Intelligent Scheduling</em> Software{" "}
              </h2>
              <h4 className="mt-4">
                {" "}
                Caregigs Scheduling enables healthcare organizations to build &
                manage optimized nursing schedules faster.{" "}
              </h4>
              <ul>
                {/* <li>
                  <i className="fas fa-check-circle"></i> Hire Freelance Nurses from Our Market Place
                </li> 
                <li>
                  <i className="fas fa-check-circle"></i> Skilled Nursing Facilities
                </li>
                <li>
                  <i className="fas fa-check-circle"></i> Unlimited Opportunities and Flexibility
                </li>
                <li>
                  <i className="fas fa-check-circle"></i> Rapid Deployment between 24 to 48 hours
                </li>
                <li>
                  <i className="fas fa-check-circle"></i> Credentialed Healthcare Professionals
                </li>
                <li>
                  <i className="fas fa-check-circle"></i> Cost Effective Healthcare Facilities
                </li>*/}
              </ul>
            </div>
          </div>

          <div className="authPage_card-right register">
            {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={"/cc_icon.png"}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
            <div className="authPage_card-right-form">
              <div className="p-2">
                {/* <h4>Login to</h4> */}
                {/* <h1>CAREGIGS</h1> */}
                {step === 1 ? (
                  <>
                    <img
                      src={"/caregigs_logo.png"}
                      alt=""
                      className="authPage_card-right-logo regs reg-1-logo"
                    />
                    <ButtonToolbar style={{ marginTop: 70, marginBottom: 300 }}>
                      <Button
                        appearance="primary"
                        size="lg"
                        style={{ height: 50, marginBottom: 40 }}
                        block
                        onClick={() => setStep(2)}
                      >
                        Nurse Signup
                      </Button>
                      <Button
                        appearance="primary"
                        size="lg"
                        style={{ height: 50 }}
                        block
                        onClick={() => setStep(3)}
                      >
                        Facility Signup
                      </Button>
                    </ButtonToolbar>
                  </>
                ) : step === 2 ? (
                  <div style={{ marginTop: -200 }}>
                    <img
                      src={"/caregigs_logo.png"}
                      alt=""
                      className="authPage_card-right-logo regs reg-1-logo"
                    />
                    <div className="text-center mb-2" style={{ marginTop: 30 }}>
                      <p className="mb-2">
                        Nurses registration is only on the Mobile App. <br />
                        Please download the app from the below Links.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.caregigs.co&pli=1"
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            src={"/playstore_badge.png"}
                            alt=""
                            style={{ width: 210 }}
                          />
                        </a>
                        <a
                          href="https://apps.apple.com/in/app/caregigs-nursing-jobs/id1637022291"
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            src={"/appstore_badge.png"}
                            alt=""
                            style={{ width: 180, height: 56 }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : step === 3 ? (
                  <>
                    <img
                      src={"/caregigs_logo.png"}
                      alt=""
                      className="authPage_card-right-logo regs reg-1-logo"
                    />
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Enter name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Phone</Label>
                        <Input
                          name="phone"
                          type="text"
                          placeholder="Enter phone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Facility Name</Label>
                        <Input
                          id="hospitalName"
                          name="hospitalName"
                          className="form-control"
                          placeholder="Enter facility name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.hospitalName || ""}
                          invalid={
                            validation.touched.hospitalName &&
                            validation.errors.hospitalName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.hospitalName &&
                        validation.errors.hospitalName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.hospitalName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <InputGroup>
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <InputGroupText
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                          </InputGroupText>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block cus-btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Caregigs{" "}
                          <a
                            href="https://www.caregigs.co/terms-of-use/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                          >
                            Terms of Use
                          </a>
                        </p>
                      </div>
                      <div className="text-center mt-2">
                        <p>
                          Already have an account ?{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </>
                ) : (
                  step === 4 && (
                    <>
                      <img
                        src={"/caregigs_logo.png"}
                        alt=""
                        className="authPage_card-right-logo regs"
                        style={{ marginBottom: 35 }}
                      />
                      <Form
                        className="form-horizontal"
                        onSubmit={async (e) => {
                          e.preventDefault();
                          handleSubmitOTP();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Verify OTP</Label>
                          <OtpInput
                            value={otp}
                            onChange={setOTP}
                            numInputs={4}
                            separator={<span>&nbsp;</span>}
                            inputStyle={{
                              width: "100%",
                              height: "3.5rem",
                              marginRight: 10,
                              marginBottom: "34px",
                            }}
                          />
                          {/* {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null} */}
                        </div>
                        <Row className="mb-3">
                          <Col className="">
                            <Link
                              to="#"
                              onClick={handleResendOTP}
                              className="text-center w-100 d-block"
                            >
                              Resend OTP
                            </Link>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="">
                            <button
                              className="btn btn-primary btn-block cus-btn "
                              type="submit"
                              disabled={otp.length === 0 || isLoading}
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        {/* </Col>
          </Row>
        </Container> */}
      </div>
    </React.Fragment>
  );
};

export default Register;

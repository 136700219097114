import React, {useState, useEffect} from "react";
import {
	Col,
	Row,
	Form,
	FormGroup,
	Input
} from "reactstrap";
import { Pagination } from 'rsuite';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//Functions
import { OTPColumns, FormatData, BuildOTPSearchQuery } from "./latestOtps.functions"

//GraphQL
import { useQuery } from "@apollo/client"
import { FetchLatestOTPs } from "gql/otps/query"

const LatestOTPTable: React.FC = props => {
	const defaultSorted: any = [];
	const keyField = "id";
	const initialCondition = {
		used: {_eq: false}
	}
	const [searchKey, setSearchKey] = useState("");
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>(initialCondition)
	const { loading, data, refetch, error, called } = useQuery(FetchLatestOTPs, {
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (value: string) => {
		const conditionTemp = BuildOTPSearchQuery(value, null)
		setQueryCondition({ ...initialCondition, ...conditionTemp })
	}
	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [searchKey])

	return (
		<Row>
			<Col lg="12">
				<ToolkitProvider
					keyField={keyField}
					data={FormatData(Array.isArray(data?.otps) ? data.otps : [])}
					columns={OTPColumns(searchKey)}
					bootstrap4
					search
				>
					{toolkitProps => (
						<React.Fragment>
							<Row className="mb-2">
                    <Col sm="12">
                      <div className="search-box me-2 mb-2 d-inline-block" style={{width: 250}}>
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                id="exampleEmail"
                                name="email"
                                placeholder="Search by phone/email"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										<BootstrapTable
											key={searchKey}
											bordered={false}
											striped={false}
											defaultSorted={defaultSorted}
											classes={
												"table align-middle table-nowrap table-hover"
											}
											headerWrapperClasses={"thead-light"}
											{...toolkitProps.baseProps}
											noDataIndication={() => "No latest OTPs found!"}
										/>
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-30">
									<Col className="inner-custom-pagination d-flex">
										<div className="d-inline mt-3 w-100">
											<Pagination
												total={data?.otps_aggregate?.aggregate?.count || 0}
												limit={PageData.limit}
												activePage={PageData.pageNo}
												onChangePage={(pageNo) => setPageData({ ...PageData, pageNo })}
												limitOptions={[10, 30, 50]}
												layout={['limit', 'pager']}
												onChangeLimit={(limit) => setPageData({ ...PageData, limit })}
												className="d-flex justify-content-between"
											/>
										</div>
									</Col>
								</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
			</Col>
		</Row>
	);
};

export default LatestOTPTable;
import { gql } from "@apollo/client"
export const FetchLocations =
  gql`query FetchLocations (\$limit:Int,\$offset:Int,\$where:locations_bool_exp){
    locations (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      address
      isactive
      email
      contactno
      opening_hours
      created_at
      buildings
      type
      lat
      lng
      facility_details
      areas{
        id
        area{
          id
          name
          user_id
          active
        }
      }
      organisation {
        id
        name
      }
      employees_aggregate (where: {isactive: {_eq: true}}) {
        aggregate {
          count
        }
      }
      positions_aggregate(where: {active: {_eq: true}}) {
        aggregate {
          count
        }
      }
    }
    locations_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
export const FetchLocationsForFilter =
  gql`query LocationFilter(\$limit:Int,\$offset:Int,\$where:locations_bool_exp) {
  locations(limit: \$limit, offset: \$offset, where: \$where, distinct_on: name) {
    name
    id
  }
}`
export const FetchLocationsForScheduler =
  gql`query FetchLocationsForScheduler (\$where:locations_bool_exp){
    locations (where: \$where, order_by: {name: asc}){
      id
      name
      isactive
      type
      positions(where: {active: {_eq: true}}) {
        name
        id
        abbreviation
        text_color
        background_color
        start_time
        end_time
        type
      }
    }
  }
  `
import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Form,
    Col,
    Row,
} from "reactstrap";
import { showAlert } from "helpers/index";
import { useParams } from "react-router-dom";
import CheckPickerPaginate from "components/Filters/CheckPickerPaginate";

// icons
import { FiCheck } from "react-icons/fi";
//graphql
import { useMutation } from "@apollo/client";
import { FetchFacilities } from "gql/facilities/query";
import { UpdateCustomer } from "gql/profile/mutation";

const BlockedFacilityModal: React.FC<{
    isOpen: boolean;
    toggle: () => void;
    refetch: () => void;
    facilities: string[]
}> = ({ isOpen, toggle, refetch, facilities }) => {
    const params: { id: string } = useParams()
    const [UpdateCustomerMutation] = useMutation(UpdateCustomer)
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [selectedFacilities, setSelectedFacilities] = useState<string[]>(facilities)
    const handleClose = () => {
        toggle()
    }
    const handleSubmit = async () => {
        try {
            setBtnLoading(true)
            await UpdateCustomerMutation({
                variables: {
                    id: params?.id,
                    object: {
                        blocked_facilities: selectedFacilities
                    }
                }
            })
            showAlert({
                title: "Updated!",
                message: "Blocked facility list updated successfully!",
                type: "success",
            });
            refetch()
            handleClose()
        } catch (err) {
            showAlert({
                title: "Error!",
                message: "Something went wrong!",
                type: "danger",
            });
        } finally {
            setBtnLoading(false)
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            size="md"
            backdrop="static"
            className="customModal"
            toggle={handleClose}
        >
            <ModalHeader toggle={handleClose} tag="h4">
                Update Blocked Facility List
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit()
                        return false;
                    }}
                >
                    <Row form>
                        <Col xs={12}>
                            <div className="mb-3">
                                <CheckPickerPaginate
                                    query={FetchFacilities}
                                    placeholder="Choose Facilities"
                                    value={selectedFacilities}
                                    onChange={setSelectedFacilities}
                                    arrKey="users"
                                    objectLabel="name"
                                    objectValue="id"
                                    style={{ width: "100%" }}
                                    paginate={true}
                                    filterCondition={{
                                        role: { _eq: "provider" }
                                    }}
                                    menuStyle={{ zIndex: 9999 }}
                                    getFullObj={false}
                                />
                            </div>
                            <div className="text-end">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={btnLoading}
                                >
                                    <FiCheck /> &nbsp;
                                    Submit
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}
export default BlockedFacilityModal
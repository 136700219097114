import { gql } from "@apollo/client";
export const FetchCustomers = gql`
  query FetchCustomers($limit: Int, $offset: Int, $where: customers_bool_exp) {
    customers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      firstname
      lastname
      profession
      verified
      address
      email
      contactno1
      contactno2
      isactive
      profile_picture
      tags
      preferred_rate
      gender
      created_at
      lat
      lng
      questionnaire
      notification_token
      rating
      notes {
        title
        note
      }
    }
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const FetchCustomersAdmin = gql`
  query FetchCustomersAdmin(
    $limit: Int
    $offset: Int
    $where: customers_bool_exp
  ) {
    customers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      firstname
      lastname
      profession
      verified
      address
      email
      contactno1
      contactno2
      isactive
      profile_picture
      tags
      preferred_rate
      gender
      created_at
      lat
      lng
      questionnaire
      onboarding_application_form_submitted
      onboarding_documents_verified
      onboarding_documents_submitted
      onboarding_interview_scheduled
      onboarding_interview_date
      onboarding_interview_status
      onboarding_interview_comments
      onboarding_interview_link
      is_interested
      is_terminated
      is_location_pending
      notification_token
      notes {
        title
        note
      }
    }
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const FetchCustomerDetails = gql`
  query FetchCustomerDetails($id: uuid!) {
    customers_by_pk(id: $id) {
      id
      firstname
      lastname
      profession
      verified
      address
      email
      contactno1
      contactno2
      isactive
      questionnaire
      profile_picture
      tags
      preferred_rate
      dob
      gender
      created_at
      lat
      lng
      education_info
      nurse_rate
      notes {
        title
        note
      }
      completedShifts: job_requests_aggregate(
        where: { status: { _eq: "completed" } }
      ) {
        aggregate {
          count
        }
      }
      futureShifts: job_requests_aggregate(
        where: { status: { _eq: "approved" } }
      ) {
        aggregate {
          count
        }
      }
      pendingInvitations: invitations_aggregate(
        where: { status: { _eq: "requested" } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
export const FetchCustomerDetailsAdmin = gql`
  query FetchCustomerDetailsAdmin($id: uuid!) {
    customers_by_pk(id: $id) {
      id
      firstname
      lastname
      profession
      verified
      address
      email
      contactno1
      contactno2
      isactive
      questionnaire
      profile_picture
      tags
      preferred_rate
      dob
      gender
      created_at
      lat
      lng
      ssn
      representative_info
      education_info
      onboarding_application_form_submitted
      onboarding_documents_verified
      onboarding_documents_submitted
      onboarding_interview_scheduled
      onboarding_interview_date
      onboarding_interview_status
      onboarding_interview_comments
      onboarding_interview_link
      notification_token
      is_phone_verified
      reference
      blocked_facilities
      nurse_rate
      is_interested
      is_terminated
      is_location_pending
      nurse_overtime_rate
      notes {
        title
        note
      }
      completedShifts: job_requests_aggregate(
        where: { status: { _eq: "completed" } }
      ) {
        aggregate {
          count
        }
      }
      futureShifts: job_requests_aggregate(
        where: { status: { _eq: "approved" } }
      ) {
        aggregate {
          count
        }
      }
      pendingInvitations: invitations_aggregate(
        where: { status: { _eq: "requested" } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
export const FetchCustomersForFilter = gql`
  query FetchCustomers($limit: Int, $offset: Int, $where: customers_bool_exp) {
    customers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      firstname
      lastname
      verified
      email
      contactno1
      isactive
    }
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const FetchCustomersViewForFilter = gql`
  query FetchCustomersView(
    $limit: Int
    $offset: Int
    $where: customers_view_bool_exp
  ) {
    customers_view(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      firstname
      lastname
      verified
      email
      contactno1
      isactive
      nurse_type
    }
    customers_view_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// Redux
import { useDispatch } from "react-redux";
import { resetProfile } from "toolkit/profile/profile.slice";
import { loginRemoveToken } from "toolkit/auth/auth.slice";
import { resetLayout } from "toolkit/layout/layout.slice";

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();

  const handleLogout = () => {
    localStorage.clear()
    dispatch(resetProfile());
    dispatch(loginRemoveToken());
    dispatch(resetLayout());
  };

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  function tToggle() {
    var body = document.body;
    if (body.classList.contains("sidebar-enable")) {
      body.classList.remove("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink exact to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="menu-title">{props.t("Apps")}</li> */}
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/nurses" className=" ">
                <i className="bx bx-user"></i>
                <span>Nurses</span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/facilities" className=" ">
                <i className="bx bx-building"></i>
                <span>Facilities</span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/all-shifts" className=" ">
                <i className="bx bx-task"></i>
                <span>Shifts</span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/maple-shifts" className=" ">
                <i className="bx bx-calendar"></i>
                <span>Maple Shifts</span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink exact to="/stages" className="">
                <i className="bx bx-filter"></i>
                <span>Stages</span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/calendar" className=" ">
                <i className="bx bx-calendar-event"></i>
                <span>Calendar</span>
              </NavLink>
            </li>
            {/* <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/map" className=" ">
                <i className="bx bx-map-pin"></i>
                <span>Map</span>
              </NavLink>
            </li> */}
            {/* <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/reports" className=" ">
                <i className="bx bx-receipt"></i>
                <span>Reports</span>
              </NavLink>
            </li> */}
            <li
              onClick={() => {
                tToggle();
              }}
            >
              <NavLink to="/faqs" className=" ">
                <i className="bx bx-comment"></i>
                <span>FAQs</span>
              </NavLink>
            </li>
            <li>
              <a href="#" onClick={handleLogout}>
                <i className="bx bx-log-out"></i>
                <span>Logout</span>
              </a>
            </li>
            {/* <li>
              <NavLink to="#" className="">
                <i className="bx bx-chat"></i>
                <span>{props.t("Chat")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="#" className="">
                <i className="bx bx-file"></i>
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <span>{props.t("File Manager")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Ecommerce")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Products")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Product Detail")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Orders")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Customers")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Cart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Checkout")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Shops")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Add Product")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>{props.t("Crypto")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Wallet")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Buy/Sell")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Exchange")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Lending")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Orders")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("KYC Application")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("ICO Landing")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Email")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Inbox")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Read Email")} </NavLink>
                </li>
                <li>
                  <NavLink to="/#">
                    <span
                      className="badge rounded-pill badge-soft-success float-end"
                      key="t-new"
                    >
                      {props.t("New")}
                    </span>
                    <span key="t-email-templates">{props.t("Templates")}</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="#">
                        {props.t("Basic Action")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        {props.t("Alert Email")}{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        {props.t("Billing Email")}{" "}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Invoices")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Invoice List")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Invoice Detail")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Projects")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Projects Grid")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Projects List")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Project Overview")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Create New")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>{props.t("Tasks")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Task List")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Kanban Board")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Create Task")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contacts")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("User Grid")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("User List")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Profile")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="">
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <i className="bx bxs-detail" />

                <span>{props.t("Blog")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Blog List")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Blog Grid")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Blog Details")}</NavLink>
                </li>
              </ul>
            </li>
            <li className="menu-title">Pages</li>
            <li>
              <NavLink to="/#" className="">
                <i className="bx bx-user-circle"></i>
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <span>{props.t("Authentication")}</span>
              </NavLink>
              <ul className="sub-menu">
                <li>
                  <NavLink to="#">{props.t("Login")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Login 2")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Register")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Register 2")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Recover Password")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Recover Password 2")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Lock Screen")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Lock Screen 2")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Confirm Mail")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Confirm Mail 2")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Email Verification")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Email Verification 2")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Two Step Verification")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Two Step Verification 2")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>{props.t("Utility")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Starter Page")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Maintenance")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Coming Soon")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Timeline")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("FAQs")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Pricing")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Error 404")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Error 500")}</NavLink>
                </li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Components")}</li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-tone"></i>
                <span>{props.t("UI Elements")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Alerts")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Buttons")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Cards")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Carousel")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Dropdowns")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Grid")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Images")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Lightbox")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Modals")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Range Slider")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Session Timeout")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Progress Bars")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Placeholders")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Sweet-Alert")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Tabs & Accordions")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Typography")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Toasts")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Video")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("General")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Colors")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Rating")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Notifications")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Offcanvas")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Breadcrumb")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="">
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>{props.t("Forms")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Form Elements")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Layouts")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Form Validation")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Advanced")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Editors")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form File Upload")} </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Xeditable")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Repeater")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Wizard")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Form Mask")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Transfer List")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Tables")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Basic Tables")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Data Tables")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Responsive Table")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Editable Table")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Drag & Drop Table")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>{props.t("Charts")}</span>
              </NavLink>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Apex charts")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Chartist Chart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Chartjs Chart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("E Chart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Toast UI Chart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Sparkline Chart")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Knob Chart")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Re Chart")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-aperture"></i>
                <span>{props.t("Icons")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Boxicons")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    {props.t("Material Design")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Dripicons")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Font awesome")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>{props.t("Maps")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="#">{props.t("Google Maps")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Vector Maps")}</NavLink>
                </li>
                <li>
                  <NavLink to="#">{props.t("Leaflet Maps")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <NavLink to="/#">{props.t("Level 1.1")}</NavLink>
                </li>
                <li>
                  <NavLink to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <NavLink to="/#">{props.t("Level 2.1")}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/#">{props.t("Level 2.2")}</NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import React, { useState, useEffect } from "react"
import {
	Modal,
	ModalFooter,
	Button
} from "reactstrap"
import Zoom from 'react-img-zoom'
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { FiChevronLeft, FiChevronRight, FiDownload } from "react-icons/fi";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//types
import DocumentType from "../../pages/MarketPlaceProfile/tabs/documents/documents.types"
//helpers
import { getImage, downloadFile } from "helpers"

//data
import { EmploymentTypeEnums } from "constants/documentCategories";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ImageViewer: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	document: DocumentType | null
}> = ({ isOpen, toggle, document }) => {
	const { auth: { token } } = useSelector(authState);
	const [image, setImage] = useState<string>("/placeholder.jpg")
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [renderNavButtons, setRenderNavButtons] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const isPDF = ["CORI", "BACKGROUND_AUTHORIZATION", ...EmploymentTypeEnums].includes(document?.category as string) || document?.document_url.includes("_pdf_")

	const handleGetImage = async (link: string) => {
		try {
			setLoading(true)
			const _image = await getImage(link, token, "image")
			setImage(_image as string)
		} catch (err) {

		} finally {
			setLoading(false)
		}
	}
	const handleGetPDF = async (link: string) => {
		try {
			setLoading(true)
			const _image = await getImage(link, token, "pdf")
			setImage(_image as string)
		} catch (err) {

		} finally {
			setLoading(false)
		}
	}
	const onDocumentLoadSuccess = (numPages: number) => {
		setNumPages(numPages);
		setRenderNavButtons(true)
	}
	const changePage = (offset: number) => {
		setPageNumber(prevPageNumber => prevPageNumber + offset);
	}
	const previousPage = () => { changePage(-1); }
	const nextPage = () => { changePage(+1); }

	useEffect(() => {
		if (document?.document_url) {
			if (isPDF) {
				handleGetPDF(document?.document_url)
			} else {
				handleGetImage(document?.document_url)
			}
		}
	}, [document])
	return (
		<Modal
			size="lg"
			isOpen={isOpen}
			toggle={() => {
				toggle();
			}}
			centered
		>
			<div className="modal-header">
				{/* <h5 className="modal-title mt-0">Profile</h5> */}
				<button
					type="button"
					onClick={() => {
						toggle();
					}}
					className="close"
					data-dismiss="modal"
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body" style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: isPDF ? "auto" : "zoom-in" }}>
				{isPDF ? (
					<div>
						<Document
							file={image}
							onLoadSuccess={(value: PDFDocumentProxy) => onDocumentLoadSuccess(value.numPages)}
							onLoadError={(value) => console.log(value)}
							error="Fetching PDF file..."
						>
							<Page pageNumber={pageNumber} />
						</Document>
						{renderNavButtons && (
							<div className="d-flex justify-content-between align-items-center shadow pd-3 mb-5 rounded m-auto" style={{ width: 130, height: 50 }}>
								<button
									disabled={pageNumber <= 1}
									onClick={previousPage}
									className="btn"
								>
									<FiChevronLeft />
								</button>
								<p style={{ padding: 0 }}>
									{pageNumber} of {numPages}
								</p>
								<button
									disabled={pageNumber === numPages}
									onClick={nextPage}
									className="btn"
								>
									<FiChevronRight />
								</button>
							</div>
						)}
					</div>
				) : (
					<Zoom
						img={image}
						zoomScale={2}
						width={750}
						height={600}
						key={image}
						style={{ cursor: "pointer" }}
					/>
				)}
			</div>
			<ModalFooter>
				<Button
					color="primary"
					className="m-auto"
					onClick={() => downloadFile(image, isPDF ? `${document?.category}.pdf` : `${document?.category}.png`)}
					disabled={loading}
				>
					Download <FiDownload />
				</Button>
			</ModalFooter>
		</Modal>
	)
}
export default ImageViewer
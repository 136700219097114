import React, { useState, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Input,
	Form,
	Col,
	Row,
	FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert, uploadImage, getFilename } from "helpers/index";
import moment from "moment";

//components
import AutoCompleteStates from "components/Common/AutoCompleteStates";

// icons
import { FiCheck } from "react-icons/fi";

//graphql
import { useMutation, useApolloClient } from "@apollo/client";
import { InsertDocumentOne } from "gql/documents/mutation";

const UploadLicenseModal: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	user: any
}> = ({ isOpen, toggle, user }) => {
	const client = useApolloClient()
	const [state, setState] = useState({
		issusingAuthority: "",
		documentNumber: "",
		expireDate: new Date(),
		licenseImage: ""
	})
	const [btnLoading, setBtnLoading] = useState<boolean>(false)
	const [file, setFile] = useState<FileList | null>(null)

	const [InsertDocumentMutation] = useMutation(InsertDocumentOne)

	const handleClose = () => {
		toggle()
		validation.resetForm()
	}
	// validation
	const validation: any = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			issusingAuthority: state.issusingAuthority || "",
			documentNumber: state.documentNumber || "",
			expireDate: state.expireDate || new Date(),
			licenseImage: state.licenseImage || ""
		},
		validationSchema: Yup.object({
			issusingAuthority: Yup.string().required("Please Enter Issusing Authority"),
			documentNumber: Yup.string().required("Please Enter Document Number"),
			expireDate: Yup.date().required("Please Enter Expire Date"),
			licenseImage: Yup.mixed().required("Please Select License Image"),
		}),
		onSubmit: async (values) => {
			try {
				if(file){
					setBtnLoading(true)
					const formData = new FormData()
						formData.append("uploads", file[0], getFilename(file))
						const documentURL = await uploadImage(formData)
						await InsertDocumentMutation({
							variables: {
								object: {
									name: "License",
									practioner_id: user.id,
									createdby: user.id,
									updatedby: user.id,
									document_url: documentURL,
									status: "verified",
									category: "LICENSE",
									verified_at: new Date(),
									document_info: {
										listType: "",
										expireDate: moment(values.expireDate).format("YYYY-MM-DD"),
										documentName: "",
										documentNumber: values.documentNumber,
										issusingAuthority: values.issusingAuthority
									}
								}
							}
						})
						client.refetchQueries({
							include: ["FetchDocuments"],
						});
					showAlert({
						title: "Updated!",
						message: "License uploaded successfully!",
						type: "success",
					});
					handleClose()
				}
			} catch (err) {
				showAlert({
					title: "Error!",
					message: "Something went wrong!",
					type: "danger",
				});
			} finally {
				setBtnLoading(false)
			}
		},
	});
	const handleSetIssuingAuthority = (value: string) => {
		validation.setFieldValue("issusingAuthority", value);
	}
	return (
		<Modal
			isOpen={isOpen}
			size="md"
			backdrop="static"
			className="customModal"
			toggle={handleClose}
		>
			<ModalHeader toggle={handleClose} tag="h4">
				Upload License
			</ModalHeader>
			<ModalBody>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
				>
					<Row form>
						<Col xs={12}>
							<div className="mb-3">
								<Label className="form-label">Issuing Authority</Label>
								<AutoCompleteStates
                  onSelect={handleSetIssuingAuthority}
                  name="issusingAuthority"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.issusingAuthority || ""}
                  invalid={
                    validation.touched.issusingAuthority && validation.errors.issusingAuthority
                      ? true
                      : false
                  }
                />
                {validation.touched.issusingAuthority && validation.errors.issusingAuthority ? (
                  <FormFeedback type="invalid">
                    {validation.errors.issusingAuthority}
                  </FormFeedback>
                ) : null}
							</div>
							<div className="mb-3">
								<Label className="form-label">Document Number</Label>
								<Input
									name="documentNumber"
									label="Document Number"
									type="text"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.documentNumber || ""}
									invalid={
										validation.touched.documentNumber &&
											validation.errors.documentNumber
											? true
											: false
									}
								/>
								{validation.touched.documentNumber &&
									validation.errors.documentNumber ? (
									<FormFeedback type="invalid">
										{validation.errors.documentNumber}
									</FormFeedback>
								) : null}
							</div>
							<div className="mb-3">
								<Label className="form-label">Expiration Date</Label>
								<Input
									name="expireDate"
									type="date"
									label="Expiration Date"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={
										moment(validation.values.expireDate).format("YYYY-MM-DD") || ""
									}
									invalid={
										validation.touched.expireDate &&
											validation.errors.expireDate
											? true
											: false
									}
								/>
								{validation.touched.expireDate &&
									validation.errors.expireDate ? (
									<FormFeedback type="invalid">
										{validation.errors.expireDate}
									</FormFeedback>
								) : null}
							</div>
							<div className="mb-3">
								<Label className="form-label">Front Image</Label>
								<br />
								<br />
								<input 
									type="file" 
									onChange={(e:any) => {
										validation.setFieldValue("licenseImage", e.target.files[0].name);
										setFile(e.target.files)
									}}
									accept="image/*, application/pdf"
								/>
								{validation.touched.licenseImage &&
									validation.errors.licenseImage ? (
									<p className="mt-2" style={{fontSize: "80%", color: "#f46a6a"}}>
										{validation.errors.licenseImage}
									</p>
								) : null}
							</div>
							<div className="text-end">
								<button
									className="btn btn-primary"
									type="submit"
									disabled={btnLoading}
								>
									<FiCheck /> &nbsp;
									Submit
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</Modal>
	)
}
export default UploadLicenseModal
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

//redux
import { useDispatch } from "react-redux"
import {loginRemoveToken} from "toolkit/auth/auth.slice"
import {resetProfile} from "toolkit/profile/profile.slice"
import {resetLayout} from "toolkit/layout/layout.slice"
const Logout:React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetProfile())
    dispatch(loginRemoveToken())
    dispatch(resetLayout())
  }, [])
  return <></>
}
export default withRouter(Logout)

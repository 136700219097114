import React, { useEffect, useState } from "react";
import {
	Card,
	Button,
	FormGroup,
	Input,
	Label,
	Form,
	FormFeedback
} from "reactstrap";
import { FiCamera, FiEdit2 } from "react-icons/fi";
import Swal from "sweetalert2";
import { Placeholder } from "rsuite";

import OtpModal from "./OtpModal";

import { getImage, uploadImage } from "helpers";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { profileState } from "toolkit/profile/profile.slice";
import { authState } from "toolkit/auth/auth.slice";
import { fetchProfileApi, updateEmailApi } from "toolkit/profile/profile.api";

//graphql
import { useMutation, useApolloClient } from "@apollo/client";
import { FetchUserByEmail, FetchUserByPhone } from "gql/profile/query"
import { UpdateOrganisation, UpdateProfile } from "gql/profile/mutation";

const EditProfile = () => {
	const dispatch = useDispatch()
	const client = useApolloClient()
	const { profile } = useSelector(profileState)
	const { auth: { token } } = useSelector(authState);
	const { Paragraph } = Placeholder

	const [state, setState] = useState({
		name: "",
		email: "",
		phone: "",
		hospitalName: "",
		avatar: "/avatar.jpg"
	})
	const [file, setFile] = useState(null)
	const [avatar, setAvatar] = useState<string|null>(null);
	const [btnLoading, setBtnLoading] = useState<boolean>(false)
	const [UpdateOrganisationMutation] = useMutation(UpdateOrganisation)
	const [UpdateProfileMutation] = useMutation(UpdateProfile)
	const [isOtpModalOpen, setOtpModalOpen] = useState<boolean>(false)

	const handleEditProfile = async () => {
		const values = validation.values
		const promises: any[] = []
		promises.push(UpdateProfileMutation({
			variables: {
				id: profile.id,
				object: {
					name: values.name,
					// email: values.email,
					phonenumber: `+1${values.phone}`
				}
			}
		}))
		let avatar = profile.organisation?.avatar
		if (file) {
			const formData = new FormData()
			formData.append("uploads", file)
			avatar = await uploadImage(formData)
		}
		if (profile?.role !== "moderator") {
			promises.push(UpdateOrganisationMutation({
				variables: {
					id: profile.organisation?.id,
					object: {
						name: values.hospitalName,
						email: values.email,
						contactno: `+1${values.phone}`,
						avatar
					}
				}
			}))
		}
		await Promise.all(promises)
		dispatch(fetchProfileApi(token))
		Swal.fire("Profile updated successfully", "", "success")
	}
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			name: (state && state.name) || '',
			email: (state && state.email) || '',
			phone: (state && state.phone) || '',
			hospitalName: (state && state.hospitalName) || '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Please Enter Your Name"),
			email: Yup.string().required("Please Enter Your Email"),
			phone: Yup.string()
				.length(10, "Phone Number Should be 10 Digits Long")
				.matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Phone number is not valid")
				.required("Please Enter Your Phone"),
			// hospitalName: Yup.string().required("Please Enter Facility Name"),
			hospitalName: Yup.string().test(
				"hospitalName_test",
				"Please Enter Facility Name",
				function (value) {
					if (profile?.role !== "moderator" && !value) {
						return false
					} else {
						return true
					}
				}
			)
		}),
		onSubmit: async (values) => {
			try {
				setBtnLoading(true)
				//check for duplicate email
				if (values.email !== profile.email) {
					const { data: userByEmail } = await client.query({
						query: FetchUserByEmail,
						variables: {
							email: values.email
						},
						fetchPolicy: "no-cache"
					})
					if (userByEmail?.users?.length > 0 && Array.isArray(userByEmail?.users)) {
						Swal.fire("Email already exists. Please input a new email", "", "warning")
						return;
					}
				}
				//check for duplicate phone
				if (values.phone !== profile.phonenumber) {
					const { data: userByPhone } = await client.query({
						query: FetchUserByPhone,
						variables: {
							phonenumber: values.phone
						},
						fetchPolicy: "no-cache"
					})
					if (userByPhone?.users?.length > 0 && Array.isArray(userByPhone?.users)) {
						Swal.fire("Phone already exists. Please input a new phone", "", "warning")
						return;
					}
				}
				if (values.email !== profile.email) {
					dispatch(updateEmailApi({
						formData: { new_email: values.email },
						token
					}))
					setOtpModalOpen(true)
					return
				}
				await handleEditProfile()
			} catch (err) {
				Swal.fire("Something went wrong!", "", "warning")
			} finally {
				setBtnLoading(false)
			}
		}
	});
	const handleSetFile = (e: any) => {
		const file = e.target.files[0]
		setFile(file)
		setAvatar(URL.createObjectURL(file))
	}
	const handleGetImage = async () => {
		let image = ""
		if (profile.organisation?.avatar) {
			image = await getImage(profile.organisation?.avatar, token, "image") as string;
		} else {
			image = "/avatar.jpg"
		}
		return image
	};
	useEffect(() => {
		(async () => {
			if (profile?.id) {
				const phoneArr = profile.phonenumber?.split("+1")
				let _phone = profile.phonenumber
				if (phoneArr.length === 2) {
					_phone = phoneArr[1]
				}
				setState({
					...state,
					name: profile.name,
					email: profile.email,
					phone: _phone,
					hospitalName: profile.organisation?.name
				})
				setAvatar(await handleGetImage())
			}
		})()
	}, [profile])
	return (
		<>
			<div className="ProfilePage-card">
				<div className="row w-100 mb-4">
					<div className="col">
						<div className="ProfilePage-header d-flex" >
							<FiEdit2 style={{ marginTop: 4 }} /> <div style={{ paddingLeft: 10 }} >Edit Profile</div>
						</div>
					</div>
				</div>
				{profile?.id ? (
					<div className="row" >
						{profile && profile?.role !== "moderator" && (
							<div className="col-12 col-lg-6" >
								<div className="ProfilePage-pic_wrapper">
									<img
										src={avatar === null ? "/avatar.jpg" : avatar}
										className="ProfilePage-pic_img"
									/>
									<input
										type="file"
										className="ProfilePage-pic_input"
										id="profileImgInput"
										onChange={handleSetFile}
										accept="image/*"
									/>
									<label
										className="ProfilePage-pic_button"
										htmlFor="profileImgInput"
									>
										<FiCamera />&nbsp; Edit Picture
									</label>
								</div>
							</div>
						)}
						<div className={`col-12 col-lg-${profile?.role === "moderator" ? "12" : "6"}`} >
							<Form
								className="form-horizontal ProfilePage-form "
								onSubmit={(e) => {
									e.preventDefault();
									validation.handleSubmit();
									return false;
								}}
							>
								<div className="row w-100">
									<div className="col-md-12 mb-3">
										<FormGroup>
											<Label for="fName">Name</Label>
											<Input
												id="name"
												name="name"
												className="form-control"
												placeholder="Enter name"
												type="text"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.name || ""}
												invalid={
													validation.touched.name && validation.errors.name ? true : false
												}
											/>
											{validation.touched.name && validation.errors.name ? (
												<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
											) : null}
										</FormGroup>
									</div>
									{profile?.role !== "moderator" && (
										<div className="col-md-12 mb-3">
											<FormGroup>
												<Label for="lName">Facility Name</Label>
												<Input
													id="hospitalName"
													name="hospitalName"
													className="form-control"
													placeholder="Enter facility name"
													type="text"
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.hospitalName || ""}
													invalid={
														validation.touched.hospitalName && validation.errors.hospitalName ? true : false
													}
												/>
												{validation.touched.hospitalName && validation.errors.hospitalName ? (
													<FormFeedback type="invalid">{validation.errors.hospitalName}</FormFeedback>
												) : null}
											</FormGroup>
										</div>
									)}
									<div className="col-md-12 mb-3">
										<FormGroup>
											<Label for="email">Email</Label>
											<Input
												id="email"
												name="email"
												className="form-control"
												placeholder="Enter email"
												type="email"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.email || ""}
												invalid={
													validation.touched.email && validation.errors.email ? true : false
												}
											/>
											{validation.touched.email && validation.errors.email ? (
												<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
											) : null}
										</FormGroup>
									</div>
									<div className="col-md-12 mb-3">
										<FormGroup>
											<Label for="Phone">Phone</Label>
											<Input
												name="phone"
												type="text"
												placeholder="Enter phone"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.phone || ""}
												invalid={
													validation.touched.phone && validation.errors.phone ? true : false
												}
											/>
											{validation.touched.phone && validation.errors.phone ? (
												<FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
											) : null}
										</FormGroup>
									</div>
								</div>
								<div className="row w-100">
									<div className="col">
										<div className="profileImgBtnWrapper">
											<Button
												className="cus-btn "
												color="primary"
												type="submit"
												disabled={btnLoading}
											>
												Save Changes
											</Button>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				) : (
					<div className="p-2">
						<Paragraph rows={10} graph="square" active />
					</div>
				)}
			</div>
			<OtpModal
				isOpen={isOtpModalOpen}
				toggle={() => setOtpModalOpen(false)}
				email={validation.values.email}
				onVerify={handleEditProfile}
			/>
		</>
	);
};

export default EditProfile;

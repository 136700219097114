import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import AutoSizer from "react-virtualized-auto-sizer";

import { VariableSizeGrid as Grid } from 'react-window';
//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import '../../../pages/Schedule/grid.scss';

//helpers
import { getImage } from "helpers";

//parts
import NextPrevButtons from "./parts/NextPrevButtons";
import CalendarTop from "./parts/CalendarTop";
import StaffCalendarLeft from "./parts/StaffCalendarLeft";

//redux
import { useSelector, useDispatch } from "react-redux";
import { schedulerState, handleSetShiftModal } from "toolkit/scheduler/scheduler.slice";
import { authState } from "toolkit/auth/auth.slice";

const moment = extendMoment(Moment);

const Cell = props => {
	const dispatch = useDispatch()
	const { auth: { token } } = useSelector(authState);

	const formatTime = (time) => {
		return moment(time, "HH:mm").format("hh:mm A")
	}
	const getCellValue = (jobOpenings, jobRequests, invitations, _employee, rangedate) => {
		let cellValue = null

		let _jobRequest = jobRequests.filter((job) => job.job_opening?.job_date === rangedate.format("YYYY-MM-DD") && _employee?.id === job.employee?.id)
		if (_jobRequest.length) {
			_jobRequest = _jobRequest.slice(-1).pop()
			let positionName = ""
			let __position = _jobRequest?.job_opening?.position
			if (__position?.abbreviation) {
				positionName = __position.abbreviation
			} else {
				positionName = __position?.name
			}
			positionName += ` - ${_jobRequest?.job_opening?.location?.name}`
			let label = positionName
			cellValue = {
				label,
				count: 0,
				start_time: formatTime(_jobRequest?.job_opening?.position?.start_time),
				end_time: formatTime(_jobRequest?.job_opening?.position?.end_time),
				background: __position?.background_color,
				color: __position?.text_color,
				isNurseConnect: false,
				type: "jobRequest",
				data: _jobRequest
			}
		}
		return cellValue
	}

	function getCell(props) {
		const { jobOpenings, jobRequests, invitations, daterange, employees } = useSelector(schedulerState)
		const [avatar, setAvatar] = useState("/avatar.jpg")
		const rangedate = daterange[props.columnIndex]
		let _employee = employees[props.rowIndex]

		// useEffect(()=>{
		// 	if(_employee?.avatar && props.columnIndex === 0){
		// 		(async()=>{
		// 			setAvatar(await getImage(_employee?.avatar, token))
		// 		})()
		// 	}
		// }, [_employee])
		// if (props.rowIndex === 0 && props.columnIndex === 0) {
		// 	return (<div
		// 		// onClick={() => tog_center()}
		// 		className="GridLeftHeader"
		// 		style={{ ...props.style }}
		// 	>
		// 		Staffs
		// 	</div>)
		// } 
		// else if (props.rowIndex === 0 && props.columnIndex > 0) {
		// 	return (<div
		// 		// onClick={() => tog_center()}
		// 		className="GridTopHeader"
		// 		style={{ ...props.style }}
		// 	>
		// 		<div className="d-flex">
		// 			<div
		// 				style={{
		// 					background: rangedate.format('dddd') === 'Saturday' || rangedate.format('dddd') === 'Sunday' ? '#000000' : '#009EF7',
		// 					color: "#FFFFFF",
		// 					width: '30px',
		// 					paddingTop: '5px',
		// 					textAlign: 'center',
		// 					verticalAlign: 'middle',
		// 					paddingBottom: '5px'
		// 				}}>
		// 				<span style={{ fontSize: '15px', color: "#FFFFFF" }}> {rangedate.format('DD')}</span>
		// 			</div>
		// 			<div style={{ padding: '5px', fontSize: '10px' }}>
		// 				{rangedate.format('MMM')}<br />
		// 				{rangedate.format('dddd')}
		// 			</div>
		// 		</div>
		// 	</div>)
		// } 
		// else if (props.rowIndex > 0 && props.columnIndex === 0) {
		// 	let background = "#f8f8fb";
		// 	let color = "#0009";
		// 	if (_employee?.background_color) {
		// 		background = _employee?.background_color;
		// 		color = _employee?.text_color;
		// 	}
		// 	var divstyle = JSON.parse(JSON.stringify(props.style))
		// 	divstyle.background = background
		// 	divstyle.color = color
		// 	return (
		// 		<div
		// 			className="GridLeftHeader"
		// 			style={divstyle}
		// 		>
		// 			<img src={avatar} style={{width: 30, height: 30, borderRadius: "50%", margin: 10}}/>
		// 			<span style={{ marginLeft: '2px', fontSize: 10 }}>{employeeName}</span>
		// 		</div>)
		// } else {
		let background = "#fff";
		let color = "#0009";
		let cellValue = getCellValue(jobOpenings, jobRequests, invitations, _employee, rangedate);
		if (cellValue !== null) {
			background = cellValue.background;
			color = cellValue.color;
		}
		var divstyle = JSON.parse(JSON.stringify(props.style))
		divstyle.background = background
		divstyle.color = color
		divstyle.cursor = "pointer"
		return (
			<>
				{cellValue === null ? (
					<div
						onClick={() => {
							dispatch(handleSetShiftModal({
								modal_open: true,
								selectedPosition: null,
								selectedDate: rangedate.toDate(),
								selectedCellData: null,
								shiftModalDisplay: "staff",
								selectedEmployee: _employee
							}))
						}}
						className="GridItemValue"
						style={divstyle} >
						<span className="p-1 ps-3 pe-3" style={{ fontSize: '10px' }}>
							{/* Open Shift */}
						</span>
					</div>
				) : (
					<div
						onClick={() => {
							dispatch(handleSetShiftModal({
								modal_open: true,
								selectedPosition: cellValue.data?.job_opening?.position,
								selectedDate: rangedate.toDate(),
								selectedCellData: cellValue,
								shiftModalDisplay: "staff",
								selectedEmployee: null
							}))
						}}
						className="GridItemValue"
						style={divstyle} >
						{cellValue.isNurseConnect && (
							<img
								width="20px"
								src={"/cc_icon.png"}
								alt=""
								className="img-fluid mx-1"
							/>
						)}
						<span className="p-1" style={{ fontSize: '10px' }}>
							{cellValue.label} {cellValue.count > 0 && (
								`(${cellValue.count})`
							)}
							<br />{cellValue.start_time} - {cellValue.end_time}
						</span>
					</div>
				)}
			</>
		)
		// }
	}
	return (
		<React.Fragment>
			{getCell(props)}
		</React.Fragment>
	)
};

const StaffViewCalendar = props => {
	const ref = useRef(null)
	const { daterange } = useSelector(schedulerState)
	const [leftOffset, setLeftOffset] = useState(0)
	const [isLeftReached, setLeftReached] = useState(false)
	const handleNextPrev = (action) => {
		const { width, columnCount } = ref.current.props
		let _totalOffset = (((columnCount - 1) * 150) + 230) - width
		let newOffset = leftOffset + ref.current.props.width
		if (action === "next" && !isLeftReached) {
			ref.current?.scrollTo({ scrollLeft: leftOffset + ref.current.props.width })
			if (_totalOffset < newOffset) {
				setLeftReached(true)
			}
		} else if (action === "prev" && leftOffset > 0) {
			setLeftReached(false)
			ref.current?.scrollTo({ scrollLeft: leftOffset - ref.current.props.width })
		}
	}
	const handleOnScroll = ({ scrollLeft, scrollTop }) => {
		setLeftOffset(scrollLeft);
		document.getElementById(
			"main_calendar-top-right"
		).scrollLeft = scrollLeft;
		document.getElementById(
			"main_calendar-bottom-left"
		).scrollTop = scrollTop;
	}
	return (
		<React.Fragment>
			<NextPrevButtons
				handleNextPrev={(action) => handleNextPrev(action)}
			/>
			<div className="main_calendar">
				<CalendarTop
					onScroll={(leftOffset) => ref.current?.scrollTo({ scrollLeft: leftOffset })}
					calendarType="staffCalendar"
				/>
				<div className="main_calendar-bottom">
					<StaffCalendarLeft
						onScroll={(topOffset) => ref.current?.scrollTo({ scrollTop: topOffset })}
						employees={props.employees}
					/>
					<div className="main_calendar-bottom-right">
						<AutoSizer>
							{({ height, width }) => (
								<Grid
									className="Grid"
									columnCount={daterange.length}
									columnWidth={index => 150}
									height={height}
									rowCount={props.employees.length}
									rowHeight={index => 55}
									width={width}
									key={String(daterange[0])}
									ref={ref}
									onScroll={handleOnScroll}
								>
									{/* {(_props) => Cell({..._props, positions: props.positions})} */}
									{Cell}
								</Grid>
							)}
						</AutoSizer>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

StaffViewCalendar.propTypes = {
	positions: PropTypes.any,
	employees: PropTypes.any
}

Cell.propTypes = {
	columnIndex: PropTypes.any,
	rowIndex: PropTypes.any,
	style: PropTypes.any,
	// positions: PropTypes.any
}

export default StaffViewCalendar;

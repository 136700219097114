import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Row,
	Spinner,
	Form,
	FormGroup,
	Input
} from "reactstrap";
import { Pagination, Loader } from 'rsuite';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";

//components
import ProfileModal from "components/profile/ProfileModal"

// icons'
import { FiRefreshCcw } from "react-icons/fi";

//GraphQL
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { FetchJobRequests } from "../../gql/jobRequests/query"
import { UpdateJobRequest } from "../../gql/jobRequests/mutation"
import { UpdateJobOpening } from "gql/jobOpenings/mutation";

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";

//Functions
import { JobRequestColumns, FormatData, BuildJobRequestSearchQuery, BuildJobRequestFilterQuery } from "./requests.functions"

//filters
import JobRequestFilter from "components/Filters/jobRequests/JobRequestFilter.jsx";

const RequestTable: React.FC<{
	queryCondition: any
}> = props => {
	const { auth: { user } } = useSelector(authState);
	const client = useApolloClient();
	const initialCondition = {
		job_opening: { createdby: { _eq: user?.id } },
		active: { _eq: true },
		status: { _nin: ["completed", "active"] },
		practitioner_id: { _is_null: false }
	}
	const [filterValue, setFilterValue] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>({})
	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false)
	const [selectedCustomer, setSelectedCustomer] = useState<any>({})

	const [getJobRequests, { loading, data, refetch, error, called }] = useLazyQuery(FetchJobRequests, {
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest)
	const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening)
	const [isRefreshing, setRefreshing] = useState(false)

	const defaultSorted: any = [];

	const handleRefetch = () => {
		if (called) {
			refetch()
		} else {
			getJobRequests()
		}
	}

	const handleSearch = (value: string) => {
		const conditionTemp = BuildJobRequestSearchQuery(value, null)
		let _cond = {}
		if (props.queryCondition !== null) {
			_cond = props.queryCondition
		} else {
			_cond = initialCondition
		}
		setQueryCondition({ ..._cond, ...conditionTemp })
	}

	const onDeleteClick = (id: string) => {
		Swal.fire({
			title: 'Do you want to delete this request?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await UpdateJobRequestMutation({
					variables: {
						id,
						object: {
							active: false
						}
					}
				})
				handleRefetch()
				Swal.fire('Deleted Successfully!', '', 'success')
			}
		})
	}
	const handleChangeStatus = async (id: string, jobOpeningId: string, status: string) => {
		Swal.fire({
			title: `Are you sure want to ${status === "approved" ? "approve" : "reject"} this shift?`,
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await UpdateJobRequestMutation({
					variables: {
						id,
						object: {
							status
						}
					}
				})
				if (status === "approved") {
					await UpdateJobOpeningMutation({
						variables: {
							id: jobOpeningId,
							object: {
								filled: true
							}
						}
					})
					Swal.fire('Request Accepted!', '', 'success')
				} else {
					Swal.fire('Request Rejected!', '', 'success')
				}
				handleRefetch()
			}
		})
	}
	const handleRevokeStatus = async (id: string, jobOpeningId: string) => {
		Swal.fire({
			title: `Are you sure want to revoke the status?`,
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				const promises: any[] = []
				promises.push(UpdateJobRequestMutation({ variables: { id, object: { status: "rejected" } } }))
				promises.push(UpdateJobOpeningMutation({ variables: { id: jobOpeningId, object: { filled: false } } }))
				await Promise.all(promises)
				handleRefetch()
				Swal.fire('Status Revoked Successfully!', '', 'success')
			}
		})
	}
	const onSelectUser = (requestData: any) => {
		setSelectedCustomer({ id: requestData.customer_id })
		setProfileModalOpen(true)
	}
	const handleRefresh = async () => {
		try {
			setRefreshing(true)
			await client.refetchQueries({
				include: ["FetchJobRequests"]
			})
		} catch (err) {

		} finally {
			setRefreshing(false)
		}
	}
	// useEffect(() => {
	// 	if (user?.id) {
	// 		getJobRequests()
	// 	}
	// }, [user])
	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			let _cond = {}
			if (props.queryCondition !== null) {
				_cond = props.queryCondition
			} else {
				_cond = initialCondition
			}
			setQueryCondition(_cond)
		}
	}, [searchKey])
	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildJobRequestFilterQuery(filterValue, null)
		let _cond = {}
		if (props.queryCondition !== null) {
			_cond = props.queryCondition
		} else {
			_cond = initialCondition
		}
		setQueryCondition({ ..._cond, ...conditionTemp })
	}, [filterValue])

	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [props.queryCondition])

	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getJobRequests()
		}
	}, [queryCondition])

	const keyField = "id";
	return (
		<Row>
			<Col lg="12">
				<div className="GigRequestPage-card" >
					<div className="mb-2 d-flex justify-content-end">
						
					</div>
					<CardBody>
						<ToolkitProvider
							keyField={keyField}
							data={FormatData(Array.isArray(data?.job_requests) ? data.job_requests : [])}
							columns={JobRequestColumns(searchKey, filterValue, history, handleChangeStatus, handleRevokeStatus, onDeleteClick, onSelectUser)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box d-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<FormGroup>
															<Input
																name="email"
																placeholder="Search requests"
																type="text"
																onChange={(e) => setSearchKey(e.target.value)}
																value={searchKey}
															/>
															<i className="bx bx-search-alt search-icon" />
														</FormGroup>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<button
													className="btn btn-primary mr-2"
													style={{marginRight: 10}}
													onClick={handleRefresh}
													disabled={isRefreshing}
												>
													{isRefreshing ? <Loader inverse /> : <div> <FiRefreshCcw  /></div>}
												</button>
												{/* <ExportCSVButton
													{...toolkitProps.csvProps}
													style={{ border: "1px solid #74788d", marginRight: 10 }}
												>Export</ExportCSVButton> */}
												<JobRequestFilter
													onFilterChange={setFilterValue}
													activeFilters={["status", "job_date", "position", "location"]}
													filterQueryConditions={null}
												/>
											</div>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col sm="8">
											Total Gig Requests: {data?.job_requests_aggregate?.aggregate?.count || 0}
										</Col>
									</Row>
									{loading && (
										<Col xl="12" className="text-center">
											<Spinner color="primary" />
										</Col>
									)}
									<Row>
										<Col xl="12">
											<div className="table-responsive">
												<BootstrapTable
													key={`${searchKey}_${JSON.stringify(filterValue)}`}
													bordered={false}
													striped={false}
													defaultSorted={defaultSorted}
													selectRow={{
														mode: 'checkbox',
														onSelectAll: (isSelected) => { console.log(isSelected) }
													}}
													classes={
														"table align-middle table-nowrap table-hover justify-content-center"
													}
													headerWrapperClasses={"thead-light"}
													{...toolkitProps.baseProps}
													noDataIndication={() => "No requests found!"}
												/>
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-30">
										<Col className="inner-custom-pagination d-flex">
											<div className="d-inline mt-3 w-100">
												<Pagination
													total={data?.job_requests_aggregate?.aggregate?.count || 0}
													limit={PageData.limit}
													activePage={PageData.pageNo}
													onChangePage={(pageNo: number) => setPageData({ ...PageData, pageNo })}
													limitOptions={[30, 50, 100]}
													layout={['limit', 'pager']}
													onChangeLimit={(limit: number) => setPageData({ ...PageData, limit })}
													className="d-flex justify-content-between"
												/>
											</div>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</div>
			</Col>
			{selectedCustomer?.id && (
				<ProfileModal
					user={selectedCustomer}
					isOpen={isProfileModalOpen}
					toggle={() => setProfileModalOpen(false)}
				/>
			)}
		</Row>
	);
};

export default RequestTable;
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Badge } from "reactstrap";
import { FiArrowLeft, FiBell, FiXCircle, FiEdit } from "react-icons/fi";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Placeholder, SelectPicker } from "rsuite";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { getImage } from "helpers";

import { showAlert } from "helpers/index";

//components
import FacilityProfileTabs from "./tabs/FacilityProfileTabs";

import { ssnDropdownData } from "constants/documentCategories";

//graphql
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { FetchFacilityDetails } from "gql/facilities/query";
import { UpdateCustomer } from "gql/profile/mutation";
import { UpdateSSNPermission } from "gql/facilities/mutation";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const FacilitiesProfile: React.FC = (props) => {
  const history = useHistory();
  const client = useApolloClient();
  const params: any = useParams();
  const {
    auth: { token, user },
  } = useSelector(authState);
  const { Paragraph } = Placeholder;
  // const [activeTab, setActiveTab] = useState("professional_exp");
  const [UpdateCustomerMutation] = useMutation(UpdateCustomer);
  const [UpdateSSNMutation] = useMutation(UpdateSSNPermission);

  const [avatar, setAvatar] = useState<string>("");
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isEditStatus, setEditStatus] = useState(false);

  const handleGetImage = async () => {
    const image: string = (await getImage(
      facilityProfile?.organisation?.avatar,
      token,
      "image"
    )) as string;
    setAvatar(image);
  };

  const [facilityProfile, setFacilityProfile] = useState<any>(null);
  const { loading, data, refetch, error, called } = useQuery(
    FetchFacilityDetails,
    {
      variables: {
        id: params?.id,
      },
    }
  );

  useEffect(() => {
    if (data?.users_by_pk) {
      const customerData = [data?.users_by_pk];
      if (customerData?.length) {
        setFacilityProfile(customerData[0]);
      }
    }
  }, [data]);
  useEffect(() => {
    if (facilityProfile?.organisation?.avatar) {
      handleGetImage();
    }
  }, [facilityProfile]);

  function toggle() {
    setNotificationModalOpen(!notificationModalOpen);
  }

  const handleEditStatus = async (action: string) => {
    try {
      Swal.fire({
        title: ``,
        text: `Do you want to ${action} access to view the Document?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UpdateSSNMutation({
            variables: {
              id: facilityProfile.organisation.id,
              object: {
                view_ssn: action,
              },
            },
          });
          refetch();
          showAlert({
            title: "Requested!",
            message: "Request sent successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content AppBody">
        <MetaTags>
          <title>
            {facilityProfile !== null && !loading
              ? `${facilityProfile.name}`
              : "Facilities Profile"}{" "}
            | Caregigs - On Demand Healthcare Professionals
          </title>
        </MetaTags>
        <Container fluid className="MarketPlaceProfile">
          {facilityProfile !== null && !loading ? (
            <div className="MarketPlaceProfile-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="MarketPlaceProfile_top">
                    <div
                      className="back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <FiArrowLeft />
                    </div>
                    <Breadcrumbs
                      title={"Facility Profile"}
                      links={[
                        { title: "Facilities", link: "/facilities" },
                        {
                          title: `${facilityProfile.name}`,
                          link: "#",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="MarketPlaceProfile_card left">
                    {/* profile */}
                    <div className="profile">
                      {!facilityProfile?.organisation?.avatar || !avatar ? (
                        <img
                          src={"/avatar.jpg"}
                          alt=""
                          className="profile_img"
                        />
                      ) : (
                        <img className="profile_img" src={avatar} alt="" />
                      )}
                      <div className="profile_details">
                        <h4
                          className="profile_details-name"
                          style={{ width: "100%", position: "relative" }}
                        >
                          {facilityProfile?.name}{" "}
                          {facilityProfile.verified && (
                            <i className="bx bx-badge-check text-success"></i>
                          )}
                          {/* <FiBell
                            style={{ position: "absolute", top: 5, right: -5 }}
                            onClick={() => setNotificationModalOpen(true)}
                          /> */}
                        </h4>
                      </div>
                    </div>

                    {/* Personal Information */}
                    <div className="PersonalInformation">
                      <h4 className="PersonalInformation_header">
                        {" "}
                        Personal Information{" "}
                      </h4>
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Full Name</div>
                        <div className="information-info">
                          {facilityProfile.name}{" "}
                          {facilityProfile?.is_maple_user ? (
                            <Badge pill color="secondary" className="font-size-11 m-2 p-2">
                              Maple Partner
                            </Badge>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Mobile</div>
                        <div className="information-info">
                          {facilityProfile.phonenumber}
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Facility Name</div>
                        <div className="information-info">
                          {facilityProfile?.organisation?.name}
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                      <div className="information">
                        <div className="information-header">Email</div>
                        <div className="information-info">
                          {facilityProfile.email}
                        </div>
                      </div>
                      <div className="information">
                        <div className="information-header">SSN Status</div>
                        {/* <div className="information-info">
                          {facilityProfile.organisation.view_ssn}
                        </div> */}
                        <div className="information-info">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {facilityProfile.organisation.view_ssn
                                ? facilityProfile.organisation.view_ssn
                                : "N/A"}
                            </span>
                            {!isEditStatus ? (
                              <Badge
                                pill
                                to="#"
                                className={`badge ${
                                  facilityProfile.organisation.view_ssn ==
                                  "Requested"
                                    ? "bg-warning"
                                    : facilityProfile.organisation.view_ssn ==
                                      "Grant"
                                    ? "bg-success"
                                    : facilityProfile.organisation.view_ssn ==
                                      "Deny"
                                    ? "bg-danger"
                                    : "bg-light"
                                } font-size-11 m-2 p-2`}
                                onClick={() => setEditStatus(true)}
                              >
                                <div className="d-flex align-items-center">
                                  <span>
                                    {facilityProfile.organisation.view_ssn}
                                  </span>
                                  <FiEdit style={{ marginLeft: 5 }} />
                                </div>
                              </Badge>
                            ) : (
                              <div className="d-flex align-items-center">
                                <SelectPicker
                                  data={ssnDropdownData}
                                  cleanable={false}
                                  searchable={false}
                                  value={facilityProfile.organisation.view_ssn}
                                  onChange={(value) => handleEditStatus(value)}
                                />
                                <FiXCircle
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setEditStatus(false)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* info */}
                      {/* info */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <FacilityProfileTabs
                    user={facilityProfile}
                    // activeTab={activeTab}
                    // setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="p-2">
              <Paragraph rows={25} graph="image" active />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FacilitiesProfile;

import { createAsyncThunk } from "@reduxjs/toolkit";

import apiURL from "../../config/index";
import { changeLayout } from "../layout/layout.slice";

export const registerApi: any = createAsyncThunk(
  "auth/register",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("user/register", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const loginApi: any = createAsyncThunk(
  "auth/login",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("user/login", reqParam);
      if(response.data?.data?.user_info?.role === "moderator"){
        thunkAPI.dispatch(changeLayout("vertical"))
      }else{
        thunkAPI.dispatch(changeLayout("horizontal"))
      }
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const socialLoginApi: any = createAsyncThunk(
  "auth/social-login",
  async (reqParam) => {
    const response = await apiURL.post("user/social-login", reqParam);
    return response.data;
  }
);

export const forgotPasswordApi: any = createAsyncThunk(
  "auth/forgot-password",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("user/forgotpassword", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const verifyForgotPasswordApi: any = createAsyncThunk(
  "auth/verify-forgot-password",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("user/verifyforgotpassword", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const resetPasswordApi: any = createAsyncThunk(
  "auth/reset-password",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/resetpassword", reqParam, {
        headers: {
          Authorization: `Bearer ${reqParam.accessToken}`
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const verifyOTPApi: any = createAsyncThunk(
  "auth/verify-otp",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("user/verifyotp", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const resendOTPApi: any = createAsyncThunk(
  "auth/resendotp",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.post("customer/resendotp", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
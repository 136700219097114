import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


//redux
import { useSelector, useDispatch } from "react-redux"
import {layoutState, resetLayout} from "../../toolkit/layout/layout.slice"
import {fetchProfileApi} from "toolkit/profile/profile.api"
import { loginRemoveToken, authState } from "toolkit/auth/auth.slice";

const Header = () => {
  const dispatch = useDispatch()
  const {layout: {showRightSidebar}} = useSelector(layoutState)
  const { auth: { token } } = useSelector(authState)

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const handleFetchProfile = async() => {
    try{
      const resp = await dispatch(fetchProfileApi(token))
      if(resp.type === "user/me/rejected"){
        dispatch(loginRemoveToken())
        dispatch(resetLayout())
      }
    }catch(err){
      dispatch(loginRemoveToken())
      dispatch(resetLayout())
    }
  }
  useEffect(()=>{
    if(token){
      handleFetchProfile()
    }
  }, [token])
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={"/icon.png"} alt="" height="30" />
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={"/icon.png"} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

export default Header;

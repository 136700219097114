import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import Profile from "../pages/Profile/index.tsx";

// MarketPlace Profile Page
import MarketPlaceProfile from "../pages/MarketPlaceProfile/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout.tsx";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Help
import Help from "pages/Authentication/Help";
import NurseHelpSection from "pages/Authentication/NurseHelpSection";
import FacilityHelpSection from "pages/Authentication/FacilityHelpSection";
import HelpSearch from "pages/Authentication/HelpSearch";
import ContactUs from "pages/Authentication/ContactUs";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Schedule from "../pages/Schedule/index";
import Settings from "../pages/Settings/index";
import Marketplace from "../pages/Marketplace/index";
import Timecards from "../pages/Timecards/index.tsx";
import Requests from "../pages/Requests/index.tsx";
import JobOpenings from "../pages/JobOpenings/index.tsx";
import Invitations from "pages/Invitations";

//Admin
import Nurses from "../pages/Admin/Nurses/index.tsx";
import Facilities from "../pages/Admin/Facilities/index.tsx";
import FacilitiesProfile from "pages/Admin/FacilitiesProfile";
import Calendar from "../pages/Admin/calendar/index.tsx";
import AddLeads from "pages/Admin/AddLeads";
import Stages from "pages/Admin/Stages";
import Shifts from "pages/Admin/Shifts";
import FAQs from "pages/Admin/FAQs";
import Map from "pages/Admin/Map";
import Reports from "pages/Admin/Reports";
import MapleShifts from "pages/Admin/MapleShifts";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, role: "moderator" },
  { path: "/schedule", component: Schedule, role: "provider" },
  { path: "/timecards", component: Timecards, role: "provider" },
  { path: "/requests", component: Requests, role: "provider" },
  { path: "/job-openings", component: JobOpenings, role: "provider" },
  { path: "/invitations", component: Invitations, role: "provider" },

  { path: "/settings/locations", component: Settings, role: "provider" },
  { path: "/settings/staff", component: Settings, role: "provider" },
  { path: "/settings/general", component: Settings, role: "provider" },
  { path: "/settings/positions", component: Settings, role: "provider" },
  { path: "/settings/timecards", component: Settings, role: "provider" },
  {
    path: "/settings/email-notification",
    component: Settings,
    role: "provider",
  },
  {
    path: "/settings/text-notification",
    component: Settings,
    role: "provider",
  },

  { path: "/marketplace", component: Marketplace, role: "provider" },

  // //profile
  { path: "/profile", component: Profile, role: "common" },

  // MarketPlaceProfile
  {
    path: "/marketplace-profile/:id",
    component: MarketPlaceProfile,
    role: "common",
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/schedule" /> },

  { path: "/logout", component: Logout, role: "common" },

  //Admin
  { path: "/nurses", component: Nurses, role: "moderator" },
  { path: "/facilities", component: Facilities, role: "moderator" },
  {
    path: "/facilities-profile/:id",
    component: FacilitiesProfile,
    role: "moderator",
  },
  { path: "/calendar", component: Calendar, role: "moderator" },
  { path: "/create-nurse", component: AddLeads, role: "moderator" },
  { path: "/stages", component: Stages, role: "moderator" },
  { path: "/all-shifts/:tab?", component: Shifts, role: "moderator" },
  { path: "/faqs", component: FAQs, role: "moderator" },
  { path: "/map", component: Map, role: "moderator" },
  { path: "/reports", component: Reports, role: "moderator" },
  { path: "/maple-shifts", component: MapleShifts, role: "moderator" },
];

const publicRoutes = [
  { path: "/login", component: Login, restricted: true },
  { path: "/forgot-password", component: ForgetPwd, restricted: true },
  { path: "/register", component: Register, restricted: true },
  { path: "/help", component: Help, restricted: false },
  { path: "/help/nurses", component: NurseHelpSection, restricted: false },
  {
    path: "/help/facilities",
    component: FacilityHelpSection,
    restricted: false,
  },
  { path: "/help/search", component: HelpSearch, restricted: false },
  { path: "/help/contact-us", component: ContactUs, restricted: false },
];

export { publicRoutes, authProtectedRoutes };

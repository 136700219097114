import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Form, FormGroup, Input } from "reactstrap";
import { Pagination, Nav } from "rsuite";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDebounce } from "react-use";
import moment from "moment";
import { Link, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

// icons
import { FiDownloadCloud, FiPlus } from "react-icons/fi";

//helpers
import { showAlert } from "helpers/index";

//GraphQL
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
// import { FetchJobRequests } from "gql/jobRequests/query";
import { FetchFaqs } from "gql/faq/query";

import { DeleteFAQ } from "gql/faq/mutation";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//Functions
import {
  JobRequestColumns,
  FormatData,
  BuildJobRequestSearchQuery,
} from "./faqs.functions";

//components
import CreateShiftAdmin from "components/faqs/CreateFAQs";

import faqsType from "./faqs.types";

const FaqTable: React.FC<{
  queryCondition: any;
  facility: string | null;
}> = (props) => {
  const params: any = useParams();
  const search = useLocation().search;

  const { ExportCSVButton } = CSVExport;
  const {
    auth: { user },
  } = useSelector(authState);
  const initialCondition = {};
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>({});
  const [modal, setModal] = useState(false);
  const [DeleteFAQMutation] = useMutation(DeleteFAQ);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState<faqsType | undefined>();

  const [getFaqs, { loading, data, refetch, error, called }] = useLazyQuery(
    FetchFaqs,
    {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    }
  );

  const defaultSorted: any = [];

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobRequestSearchQuery(value, null);
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };
  useDebounce(
    () => {
      if (searchKey.length > 0) {
        handleSearch(searchKey);
      } else {
        let _cond = {};
        if (props.queryCondition !== null) {
          _cond = props.queryCondition;
        } else {
          _cond = initialCondition;
        }
        setQueryCondition(_cond);
      }
    },
    1500,
    [searchKey]
  );

  useEffect(() => {
    // if (Object.keys(queryCondition).length) {
    getFaqs();
    // }
  }, []);

  const handleDeleteFAQ = (id: string) => {
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to delete this FAQ?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await DeleteFAQMutation({
            variables: {
              id: id,
            },
          });
          refetch();
          showAlert({
            title: "Deleted!",
            message: "FAQ deleted successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

  const onEditClick = (_employee: faqsType) => {
    setSelectedFaq(_employee);
    setIsEdit(true);
    toggle();
  };
  const keyField = "id";

  function toggle() {
    setModal(!modal);
  }
  return (
    <Row>
      <Col lg="12">
        <div className="GigShiftPage-card">
          <ToolkitProvider
            keyField={keyField}
            data={FormatData(Array.isArray(data?.faqs) ? data.faqs : [])}
            columns={JobRequestColumns(searchKey, onEditClick, handleDeleteFAQ)}
            bootstrap4
            exportCSV={{ onlyExportSelection: true, exportAll: true }}
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col sm="4">
                    <div className="search-box d-block">
                      <div className="position-relative">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <FormGroup>
                            <Input
                              name="email"
                              placeholder="Search FAQs"
                              type="text"
                              onChange={(e) => setSearchKey(e.target.value)}
                              value={searchKey}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col sm="8">
                    <div className="text-sm-end d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary shadow mx-2"
                        onClick={() => setModal(true)}
                      >
                        <FiPlus /> Create FAQ
                      </button>
                      <ExportCSVButton
                        {...toolkitProps.csvProps}
                        style={{ border: "1px solid #74788d", marginRight: 10 }}
                      >
                        {" "}
                        <FiDownloadCloud />
                        &nbsp;&nbsp; Export
                      </ExportCSVButton>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="8" style={{ textTransform: "capitalize" }}>
                    Total FAQs: {data?.faqs_aggregate?.aggregate?.count || 0}
                  </Col>
                </Row>
                {loading && (
                  <Col xl="12" className="text-center">
                    <Spinner color="primary" />
                  </Col>
                )}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        key={`${searchKey}_${JSON.stringify(filterValue)}`}
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={{
                          mode: "checkbox",
                          onSelectAll: (isSelected) => {
                            console.log(isSelected);
                          },
                        }}
                        classes={"table align-middle table-nowrap table-hover"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        noDataIndication={() => "No FAQs found!"}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline mt-3 w-100">
                      <Pagination
                        total={data?.faqs_aggregate?.aggregate?.count || 0}
                        limit={PageData.limit}
                        activePage={PageData.pageNo}
                        onChangePage={(pageNo) =>
                          setPageData({ ...PageData, pageNo })
                        }
                        limitOptions={[30, 50, 100]}
                        layout={["limit", "pager"]}
                        onChangeLimit={(limit) =>
                          setPageData({ ...PageData, limit })
                        }
                        className="d-flex justify-content-between"
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </div>
      </Col>
      <CreateShiftAdmin
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        toggle={toggle}
        modal={modal}
        selectedFaq={selectedFaq}
      />
    </Row>
  );
};

export default FaqTable;

import React from "react";
import moment from "moment";
import axios from "axios";
import { Store } from "react-notifications-component";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DocumentType } from "../pages/MarketPlaceProfile/tabs/Licenses";

//constants
import {
  IDEnums,
  TBScreeningEnums,
  EmploymentTypeEnums,
  I9Enums,
} from "constants/documentCategories";
//types
import { breakTimeType } from "pages/Admin/Shifts/shifts.types";

export const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const time = moment().format("HH:mm:ss");
  const _startDate =
    typeof startDate === "string"
      ? moment(`${startDate} ${time}`, "YYYY-MM-DD HH:mm:ss")
      : moment(startDate);
  const _endDate =
    typeof endDate === "string"
      ? moment(`${endDate} ${time}`, "YYYY-MM-DD HH:mm:ss")
      : moment(endDate);
  const now = _startDate.clone(),
    dates = [];
  while (now.isSameOrBefore(_endDate)) {
    dates.push(now.toDate());
    now.add(1, "days");
  }
  return dates;
};
export const isSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
export const getImage = async (link: string, token: string, type: string) => {
  return new Promise((resolve, reject) => {
    fetch(link, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const base64EncodedStr = Buffer.from(buffer).toString("base64");
        const base64Pre =
          type === "image"
            ? "data:image/png;base64,"
            : "data:application/pdf;base64,";
        const src = base64Pre + base64EncodedStr;
        resolve(src);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const uploadImage = async (formData: any) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}customer/userimage/private`,
      formData
    );
    if (resp.data?.status === 200) {
      return resp.data?.data?.file_url;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const getAddressComponents = (address_components: any[]) => {
  let state = "",
    city = "",
    postalCode = "",
    country = "";
  for (let i = 0; i < address_components.length; i++) {
    if (address_components[i].types.includes("administrative_area_level_1")) {
      state = address_components[i].long_name;
    } else if (address_components[i].types.includes("locality")) {
      city = address_components[i].long_name;
    } else if (address_components[i].types.includes("postal_code")) {
      postalCode = address_components[i].long_name;
    } else if (address_components[i].types.includes("country")) {
      country = address_components[i].long_name;
    }
  }
  return { state, city, postalCode, country };
};
export const getShiftStatus = (selectedCellData: any) => {
  if (selectedCellData?.type === "jobRequest") {
    if (selectedCellData.data?.status === "completed") {
      return (
        <div style={{ background: "#78A451" }} className="shift-status-tag">
          Nurse Clocks Out
        </div>
      );
    } else if (selectedCellData.data?.status === "active") {
      return (
        <div style={{ background: "#78A451" }} className="shift-status-tag">
          Nurse Clocks In
        </div>
      );
    } else if (
      selectedCellData.data?.status === "approved" &&
      selectedCellData.data?.is_confirmed
    ) {
      return (
        <div style={{ background: "#78A451" }} className="shift-status-tag">
          Nurse Confirms Shift
        </div>
      );
    } else if (
      selectedCellData.data?.status === "approved" &&
      !selectedCellData.data?.is_confirmed &&
      selectedCellData.data?.customer
    ) {
      return (
        <div
          style={{ background: "rgba(245, 202, 98, 1)" }}
          className="shift-status-tag"
        >
          Waiting Nurse Acceptance
        </div>
      );
    } else {
      return <></>;
    }
  } else if (selectedCellData?.type === "jobOpening") {
    if (selectedCellData.count === 0) {
      return (
        <div
          style={{ background: "#D9D9D9", color: "#222" }}
          className="shift-status-tag"
        >
          Facility puts Shift in Marketplace
        </div>
      );
    } else if (selectedCellData.count > 0) {
      return (
        <div
          style={{ background: "rgba(123, 164, 225, 1)" }}
          className="shift-status-tag"
        >
          Nurse Applies for Shift
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const showAlert = (params: any) => {
  Store.addNotification({
    title: params.title,
    message: params.message,
    type: params.type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
  });
};
export const printCalendar = (id: string) => {
  const input = document.getElementById(id) as HTMLElement;
  window.scrollTo(0, 0);
  html2canvas(input, {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: document.documentElement.offsetWidth,
    windowHeight: input.scrollHeight,
  }).then((canvas) => {
    const imgData = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    const doc = new jsPDF("p", "mm");
    let position = 0;
    doc.addImage(
      imgData,
      "PNG",
      0,
      position,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;
    }
    doc.save("caregigs.pdf");
  });
};
export const getDocumentStatus = (document: DocumentType) => {
  let status: { class: string; value: string } | null = null;
  switch (document.status) {
    case "not_verified":
      status = { class: "text-danger", value: "Not Approved" };
      break;
    case "verified":
      status = { class: "text-success", value: "Approved" };
      break;
    default:
      status = { class: "text-warning", value: "Pending" };
  }
  return status;
};
export const getNurseStatus = (type: any) => {
  let NurseStatus: any;

  switch (type) {
    case "Verified":
      NurseStatus = "verified";
      break;
    case "Conditionally Verified":
      NurseStatus = "conditionally_verified";
      break;
    case "Documents Submitted":
      NurseStatus = "documents_submitted";
      break;
    case "Selected":
      NurseStatus = "selected";
      break;
    case "To be Interviewed":
      NurseStatus = "to_be_interviewed";
      break;
    case "Signed Up":
      NurseStatus = "signed_up";
      break;
    case "Rejected":
      NurseStatus = "rejected";
      break;
  }
  return NurseStatus;
};
export const downloadFile = (link: string, name: string) => {
  const a = document.createElement("a");
  a.href = link;
  a.download = name;
  a.click(); //Downloaded file
};
export const getFilename = (files: FileList) => {
  const type = files[0].type;
  if (type === "application/pdf") {
    const _split = files[0].name.split(".");
    const _filename = _split[0].replace(/ /g, "_");
    console.log(_filename, `${_filename}_pdf_${Date.now()}.pdf`);
    return `${_filename}_pdf_${Date.now()}.pdf`;
  } else {
    return files[0].name;
  }
};
export const getDocumentName = (document_enum: string) => {
  if (document_enum === "ANNUAL_PHYSICAL") {
    return "Annual Physical";
  } else if (IDEnums.includes(document_enum)) {
    return "ID";
  } else if (TBScreeningEnums.includes(document_enum)) {
    return "TB Screening";
  } else if (document_enum === "COVID_VACCINE") {
    return "Vaccine";
  } else if (I9Enums.includes(document_enum)) {
    return "I-9";
  } else if (document_enum === "CORI") {
    return "CORI Form";
  } else if (document_enum === "BACKGROUND_AUTHORIZATION") {
    return "Background Authorization";
  } else if (EmploymentTypeEnums.includes(document_enum)) {
    return "Employment Type";
  } else if (document_enum === "LICENSE") {
    return "License";
  } else if (document_enum === "COVID_TEST_RESULTS") {
    return "Covid Result";
  } else if (document_enum === "FLU_SHOT") {
    return "Flu Shot";
  } else if (document_enum === "CPR") {
    return "CPR";
  } else if (document_enum === "DEMENTIA_TRAINING") {
    return "Dementia Training Certificate";
  } else if (document_enum === "US_SSN_CARD") {
    return "Social Security Card";
  } else if (document_enum === "RESUME") {
    return "Resume";
  } else if (document_enum === "WORK_AUTHORIZATION") {
    return "Work Authorization";
  } else {
    return "N/A";
  }
};
export const formatTotalHours = (totalMinutes: number) => {
  if (totalMinutes < 60) {
    return `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""}`;
  } else {
    const timeInHour = totalMinutes / 60;
    return `${timeInHour.toFixed(2)} hour${timeInHour > 1 ? "s" : ""}`;
  }
};
export const getTotalBreakTime = (
  breakTimes: breakTimeType[],
  breakTimeBySupervisor: number
) => {
  let breakTimeInMinutes = 0;
  if (breakTimeBySupervisor) {
    breakTimeInMinutes = breakTimeBySupervisor;
  } else if (breakTimes.length) {
    breakTimes.map((bt) => {
      const bt_start = moment(bt.start_time, "ddd, MMM D, YYYY,HH:mm A");
      const bt_end = moment(bt.end_time, "ddd, MMM D, YYYY,HH:mm A");
      const bt_duration = moment.duration(bt_end.diff(bt_start)).asMinutes();
      breakTimeInMinutes += bt_duration;
    });
  }
  return breakTimeInMinutes;
};
export const getTotalShiftHour = (
  clock_in_time: string,
  clock_out_time: string,
  breakTimes: breakTimeType[],
  breakTimeBySupervisor: number
) => {
  const _clock_in_time = moment(clock_in_time, "ddd, MMM D, YYYY,HH:mm A");
  const _clock_out_time = moment(clock_out_time, "ddd, MMM D, YYYY,HH:mm A");
  let total_minutes_without_break = moment
    .duration(_clock_out_time.diff(_clock_in_time))
    .asMinutes();
  const total_break_time = getTotalBreakTime(breakTimes, breakTimeBySupervisor);
  total_minutes_without_break -= total_break_time;
  return total_minutes_without_break;
};

import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Swal from "sweetalert2";

//redux
import { useSelector, useDispatch } from "react-redux";
import { loginApi } from "../../toolkit/auth/auth.api";
import { authState } from "../../toolkit/auth/auth.slice";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";

//Import config
import { facebook, google } from "../../config";

const Login = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { isLoading },
  } = useSelector(authState);
  const [showPassword, setShowPassword] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      try {
        const resp = await dispatch(
          loginApi({
            ...values,
            email: values.email?.toLowerCase()?.trim(),
          })
        );
        if (resp.type === "auth/login/rejected") {
          let message = "Something went wrong!";
          if (resp.payload?.error?.message) {
            message = resp.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Caregigs - Partner Portal</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages authPage">
        {/* <Container> */}
        {/* <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}> */}
        <div className="authPage_card">
          <div className="authPage_card-left">
            {/* <img
              src={"/caregigs_logo.png"}
              alt=""
              className="authPage_card-left-logo"
            /> */}
          </div>
          <div className="authPage_card-right">
            <div className="authPage_card-right-form">
              <div className="p-2">
                {/* <h4>Login to</h4> */}
                {/* <h1>CAREGIGS</h1> */}
                <img
                  src={"/caregigs_logo.png"}
                  alt=""
                  className="authPage_card-right-logo"
                />
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <InputGroup>
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      <InputGroupText
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </InputGroupText>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block cus-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      Log In
                    </button>
                  </div>
                  {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                            // onFailure={() => { }}
                            />
                          </li>
                        </ul>
                      </div> */}
                  <div className="mt-4 text-center">
                    <Link to="/forgot-password" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Forgot your password?
                    </Link>
                  </div>

                  <div className="text-center mt-2">
                    <p>
                      Don&#39;t have an account ?{" "}
                      <Link to="/register" className="fw-medium text-primary">
                        {" "}
                        Signup now{" "}
                      </Link>{" "}
                    </p>
                    {/* <p>
                  © {new Date().getFullYear()} Caregigs. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Ampcome
                </p> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* </Col>
          </Row> */}
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

import React from 'react'
import { List } from "rsuite";

const Education:React.FC<{user: any}> = ( {user}) => {
  return (
    <div>
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Qualification Type:
        </div>
        <div className="information-info" >
          {user.questionnaire?.qualificatioType}
        </div>
      </div>
      {/* info */}
      {Array.isArray(user.education_info) && (
        <List style={{ marginTop: 20 }}>
        {user.education_info.map((edu: any, i: number) => (
          <List.Item key={i}>
            <p className="profileMoodalPara">
              <strong>Institution </strong>{" "} &nbsp;&nbsp;
              {edu?.institution || "N/A"}
            </p>
            <p className="profileMoodalPara">
              <strong>Specialisation </strong>{" "} &nbsp;&nbsp;
              {edu?.specialisation || "N/A"}
            </p>
            <p className="profileMoodalPara">
              <strong>Completion Year </strong>{" "} &nbsp;&nbsp;
              {edu?.completion_year || "N/A"}
            </p>
          </List.Item>
        ))}
      </List>)}
    </div>
  )
}

export default Education

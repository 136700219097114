import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
  fetchProfileApi,
  changePasswordApi,
  updateEmailApi,
  verifyEmailUpdateApi
} from "./profile.api";

interface UserType {
  role: string;
  is_walkthrough_done: boolean;
  name: string;
  email: string;
  id: string;
  is_email_verified: boolean;
  isactive: boolean;
  avatar: string | undefined | null;
  google_id: string | undefined | null;
  facebook_id: null;
  apple_id: string | undefined | null;
  updated_at: string;
  created_at: string;
  notification_token: string | undefined | null;
  position: string | null;
  short_name: string | null;
  organisation_id: string | null;
  phonenumber: string | null;
  user_type: string;
}

interface StateType {
  message: string;
  isLoading: boolean;
  errorMsg: string;
  profile: UserType | null;
}

const initialState: StateType = {
  message: "",
  isLoading: false,
  errorMsg: "",
  profile: null
};

export const profileSlice: any = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile(state, action){
      return { ...state, profile: null };
    },
    resetMessage(state){
      return { ...state, message: "", errorMsg: "" };
    }
  },
  extraReducers: {
    /** --------------fetchProfileApi Reducer--------------- */
    [fetchProfileApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [fetchProfileApi.fulfilled]: (state, { payload }) => {
      // localStorage.setItem("TOKEN", payload.data.token);
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        profile: payload
      };
    },
    [fetchProfileApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },
    /** --------------changePasswordApi Reducer--------------- */
    [changePasswordApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [changePasswordApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [changePasswordApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },
    /** --------------updateEmailApi Reducer--------------- */
    [updateEmailApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [updateEmailApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [updateEmailApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },
    /** --------------verifyEmailUpdateApi Reducer--------------- */
    [verifyEmailUpdateApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [verifyEmailUpdateApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [verifyEmailUpdateApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },
  },
});

export const profileState = (state: any) => state.profile;
export const { resetProfile } = profileSlice.actions

export default profileSlice.reducer;

import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker, Checkbox } from 'rsuite'
import { useState } from "react"
import PropTypes from "prop-types"
import CheckPickerPaginate from "../CheckPickerPaginate"
import { FetchPositionsForFilter } from "gql/positions/query"
import { FetchLocationsForFilter } from "gql/locations/query"

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const JobRequestFilter = (props) => {
    const { auth: { user } } = useSelector(authState)
    const [filterData, setFilterData] = useState([])
    const [filters, setFilters] = useState([])
    const [filterValues, setFilterValues] = useState({})

    const statusData = [{ label: "Requested", value: "requested" }, { label: "Approved", value: "approved" }, { label: "Cancelled", value: "cancelled" }, { label: "Rejected", value: "rejected" }]

    const resetFilter = (value) => {
        const filtersTemp = filters.filter(item => item !== value)
        setFilters(filtersTemp)
    }
    useEffect(() => {
        props.onFilterChange(filterValues)
    }, [filterValues])
    useEffect(() => {
        if (filters.length) {
            const filterValuesTemp = { ...filterValues }
            if (!filters.includes("status")) {
                delete filterValuesTemp["status"]
            }
            if (!filters.includes("job_date")) {
                delete filterValuesTemp["job_date"]
            }
            if (!filters.includes("position")) {
                delete filterValuesTemp["position"]
            }
            if (!filters.includes("location")) {
                delete filterValuesTemp["location"]
            }
            setFilterValues(filterValuesTemp)
        } else {
            setFilterValues({})
        }
    }, [filters])
    useEffect(() => {
        const filterDataTemp = []
        props.activeFilters.map((filter) => {
            if (filter === "status") {
                filterDataTemp.push({ label: "Status", value: "status" })
            }
            if (filter === "job_date") {
                filterDataTemp.push({ label: "Job Date", value: "job_date" })
            }
            if (filter === "position") {
                filterDataTemp.push({ label: "Position", value: "position" })
            }
            if (filter === "location") {
                filterDataTemp.push({ label: "Location", value: "location" })
            }
        })
        setFilterData(filterDataTemp)
    }, [props.activeFilters])
    return (
        <div className="auto-horizontal-scroll">
            <CheckPicker
                data={filterData}
                placeholder={"Filter"}
                style={{ width: 130 }}
                value={filters}
                onChange={setFilters}
                searchable={false}
                menuStyle={{ zIndex: 9999 }}
            />
            {filters.map((filter, i) => {
                if (filter === "status") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={statusData}
                                placeholder={"Select Status"}
                                style={{ marginLeft: 10 }}
                                onChange={(status) => setFilterValues({ ...filterValues, status })}
                                searchable={false}
                                placement="bottomEnd"
                                menuStyle={{ zIndex: 9999 }}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "job_date") {
                    return (
                        <React.Fragment key={i}>
                            <DateRangePicker
                                placement="auto"
                                onChange={(job_date) => setFilterValues({ ...filterValues, job_date })}
                                style={{ marginLeft: 10 }}
                                placeholder="Select Job Date"
                                // value={Array.isArray(filterValues.job_date) ? filterValues.job_date : []}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("job_date")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "position") {
                    return (
                        <React.Fragment key={i}>
                            <CheckPickerPaginate
                                query={FetchPositionsForFilter}
                                placeholder="Select Position"
                                value={Array.isArray(filterValues.position) ? filterValues.position : []}
                                onChange={(position) => setFilterValues({ ...filterValues, position })}
                                arrKey="positions"
                                objectLabel="name"
                                objectValue="id"
                                style={{ marginLeft: 10 }}
                                paginate={true}
                                filterCondition={{
                                    createdby: { _eq: user?.id },
                                    active: { _eq: true }
                                }}
                                menuStyle={{ zIndex: 9999 }}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("position")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "location") {
                    return (
                        <React.Fragment key={i}>
                            <CheckPickerPaginate
                                query={FetchLocationsForFilter}
                                placeholder="Select Location"
                                value={Array.isArray(filterValues.location) ? filterValues.location : []}
                                onChange={(location) => setFilterValues({ ...filterValues, location })}
                                arrKey="locations"
                                objectLabel="name"
                                objectValue="id"
                                style={{ marginLeft: 10 }}
                                paginate={true}
                                filterCondition={{
                                    createdby: { _eq: user?.id },
                                    isactive: { _eq: true }
                                }}
                                menuStyle={{ zIndex: 9999 }}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("location")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
            })}
        </div>
    )
}
JobRequestFilter.propTypes = {
    onFilterChange: PropTypes.func,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any
}
export default JobRequestFilter
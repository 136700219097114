import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge, Button, Row, Input, Label } from "reactstrap";

//types
import timecardsType from "./timecards.types";

//utils
import { getTotalShiftHour, getTotalBreakTime, formatTotalHours } from "helpers";

export const FormatData = (employees: timecardsType[]) => {
  const _employees = employees.map((elem: timecardsType) => {
    // let total_minutes = 0;
    // let facility_cost = 0;
    // const clock_in_time = moment(elem.clock_in_time);
    // const clock_out_time = moment(elem.clock_out_time);
    // const _elem = elem.job_opening || elem.invitation;
    // const duration = moment.duration(clock_out_time.diff(clock_in_time));
    // total_minutes = duration.asMinutes();
    // facility_cost = (_elem?.fee_amount / 60) * total_minutes;
    let total_minutes = 0;
    let facility_cost = 0;
    const _elem = elem.job_opening || elem.invitation;
    total_minutes = getTotalShiftHour(elem.clock_in_time, elem.clock_out_time, elem.break_times, elem.actual_break_time)
    facility_cost = (_elem?.fee_amount / 60) * total_minutes;
    const break_time = getTotalBreakTime(elem.break_times, elem.actual_break_time)
    return {
      id: elem.id,
      status: elem.status,
      active: elem.active,
      created_at: elem.created_at,
      job_date: _elem?.job_date,
      name:
        elem.customer?.firstname?.length && elem.customer?.lastname?.length
          ? `${elem.customer?.firstname} ${elem.customer?.lastname}`
          : "Guest User",
      customer_id: elem.customer?.id,
      position_name: _elem?.position?.name || "N/A",
      position_id: _elem?.position?.id,
      position_start_time: _elem?.position?.start_time,
      position_end_time: _elem?.position?.end_time,
      location_name: _elem?.location?.name || "N/A",
      location_id: _elem?.location?.id,
      fee_amount: `$${_elem?.fee_amount}`,
      currency: _elem?.currency,
      fee_period: _elem?.fee_period,
      clock_in: elem.clock_in_time || "N/A",
      clock_out: elem.clock_out_time || "N/A",
      total_hours:
        isNaN(total_minutes) || total_minutes < 0
          ? "Invalid Time"
          : (total_minutes / 60).toFixed(2),
      facility_cost:
        isNaN(total_minutes) || total_minutes < 0
          ? "N/A"
          : `$${(facility_cost.toFixed(2))}`,
      rating: elem.rating,
      review: elem.review,
      total_break_time: break_time > 0 ? formatTotalHours(break_time) : "N/A"
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const TimecardsColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  handleChangeStatus: (id: string, status: string) => void,
  toggleRatingModal: (param: timecardsType) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "img",
    text: "#",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        {!user.customer_avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.name.charAt(0)}
            </span>
          </div>
        ) : (
          <div>
            {/* <img
                  className="rounded-circle avatar-xs"
                  src={images[user.img]}
                  alt=""
                /> */}
          </div>
        )}
      </>
    ),
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link
            to={`/marketplace-profile/${user.customer_id}`}
            className="text-dark"
            // onClick={()=>onSelectUser(user)}
          >
            {formatHighlighter(user.name, searchKey, filterValue)}
          </Link>
        </h5>
        <p className="text-muted mb-0">{user.designation}</p>
      </>
    ),
  },
  {
    dataField: "position_name",
    text: "Position",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "rating",
    text: "Rating",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Label className="form-check-label" htmlFor="productratingCheck1">
            {row.rating > 0 ? (
              <>
                <i className="bx bxs-star text-warning" />{" "}
                {row.rating.toFixed(1)}
              </>
            ) : (
              <>N/A</>
            )}
          </Label>
        </h5>
      </>
    ),
  },
  {
    dataField: "job_date",
    text: "Date",
    sort: true,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "position_start_time",
    text: "Shift Start",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "position_end_time",
    text: "Shift End",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "clock_in",
    text: "Clock In",
    sort: true,
  },
  {
    dataField: "clock_out",
    text: "Clock Out",
    sort: true,
  },
  {
    dataField: "fee_amount",
    text: "Rate",
    sort: true,
    hidden: true
  },
  {
    dataField: "total_break_time",
    text: "Total Break Time",
    sort: true,
  },
  {
    dataField: "total_hours",
    text: "Total Hours",
    sort: true,
  },
  {
    dataField: "facility_cost",
    text: "Cost",
    sort: true,
    hidden: true
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>
        {" "}
        <Badge
          pill
          to="#"
          className={`badge ${
            cellContent === "requested"
              ? "bg-warning"
              : cellContent === "completed"
              ? "bg-success"
              : "bg-danger"
          } font-size-11 m-2 p-2`}
        >
          {formatHighlighter(
            cellContent?.toUpperCase(),
            searchKey,
            filterValue
          )}
        </Badge>
      </span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    align: "center",
    headerAlign: "center",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <div className="d-flex gap-3 justify-content-center">
        {/* <Link className="text-danger" to="#">
					<i className="bx bxs-star text-warning"></i>
				</Link> */}
        <Button
          color="warning"
          outline
          size="sm"
          style={{ height: 30, marginLeft: 5, minWidth: 100 }}
          to="#"
          onClick={() => toggleRatingModal(row)}
        >
          {row.rating == null ? "Rate Nurse" : "Update Rating"}
        </Button>
      </div>
    ),
  },
];
export const BuildJobRequestSearchQuery = (
  value: string,
  queryCondition: any,
  userId: string
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _and: [
        {
          _or: [
            {
              job_opening: { createdby: { _eq: userId } },
            },
            {
              invitation: { createdby: { _eq: userId } },
            },
          ],
        },
        {
          _or: [
            {
              customer: {
                _or: [
                  { firstname: { _ilike: `%${value}%` } },
                  { lastname: { _ilike: `%${value}%` } },
                ],
              },
            },
            {
              job_opening: {
                position: { name: { _ilike: `%${value}%` } },
              },
            },
            {
              job_opening: {
                location: { name: { _ilike: `%${value}%` } },
              },
            },
            {
              invitation: {
                position: { name: { _ilike: `%${value}%` } },
              },
            },
            {
              invitation: {
                location: { name: { _ilike: `%${value}%` } },
              },
            },
            { status: { _ilike: `%${value}%` } },
          ],
        },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildJobRequestFilterQuery = (
  filterValue: any,
  queryCondition: any,
  userId: string
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        status: { _eq: filterValue.status },
      };
    }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            {
              _or: [
                {
                  job_opening: { createdby: { _eq: userId } },
                },
                {
                  invitation: { createdby: { _eq: userId } },
                },
              ],
            },
            {
              _or: [
                {
                  _and: [
                    { job_opening: { job_date: { _gte: job_date_from } } },
                    { job_opening: { job_date: { _lte: job_date_till } } },
                  ],
                },
                {
                  _and: [
                    { invitation: { job_date: { _gte: job_date_from } } },
                    { invitation: { job_date: { _lte: job_date_till } } },
                  ],
                },
              ],
            },
          ],
        };
      }
    }
    if (filterValue?.hasOwnProperty("position")) {
      conditionTemp = {
        ...conditionTemp,
        _and: [
          {
            _or: [
              {
                job_opening: { createdby: { _eq: userId } },
              },
              {
                invitation: { createdby: { _eq: userId } },
              },
            ],
          },
          {
            _or: [
              {
                job_opening: { position_id: { _in: filterValue.position } },
              },
              {
                invitation: { position_id: { _in: filterValue.position } },
              },
            ],
          },
        ],
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

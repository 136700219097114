import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  FormFeedback,
} from "reactstrap";
import { Pagination, InputGroup } from "rsuite";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { showAlert } from "helpers/index";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
// icons
import { FiPlus } from "react-icons/fi";

//components
import CreateNoteModal from "components/notes/CreateNoteModal";

//GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { FetchRates } from "gql/rates/query";
import { InsertRateOne } from "gql/rates/mutation";

//Functions
import { NotesColumns, FormatData } from "./rates.functions";

//type
import NoteType from "./rates.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const RatesTable: React.FC<{
  queryCondition: any;
  user: any;
}> = (props) => {
  const {
    auth: { user },
  } = useSelector(authState);

  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });

  const { loading, data, refetch, error, called } = useQuery(FetchRates, {
    variables: {
      where: {
        position: { user: { id: { _eq: props?.user?.id } } },
      },
    },
  });
  const defaultSorted: any = [];

  useEffect(() => {
    if (called) {
      refetch();
    }
  }, [called]);

  const keyField = "id";
  return (
    <Row>
      <Col lg="12">
        <ToolkitProvider
          keyField={keyField}
          data={FormatData(
            Array.isArray(data?.rate_history) ? data.rate_history : []
          )}
          columns={NotesColumns()}
          bootstrap4
          search
        >
          {(toolkitProps) => (
            <React.Fragment>
              {loading && (
                <Col xl="12" className="text-center">
                  <Spinner color="primary" />
                </Col>
              )}
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      // selectRow={{
                      // 	mode: 'checkbox',
                      // 	onSelectAll: (isSelected) => { console.log(isSelected) }
                      // }}
                      classes={"table align-middle table-nowrap table-hover"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      noDataIndication={() => "No rate history found!"}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline mt-3 w-100">
                    <Pagination
                      total={data?.notes_aggregate?.aggregate?.count || 0}
                      limit={PageData.limit}
                      activePage={PageData.pageNo}
                      onChangePage={(pageNo) =>
                        setPageData({ ...PageData, pageNo })
                      }
                      limitOptions={[30, 50, 100]}
                      layout={["limit", "pager"]}
                      onChangeLimit={(limit) =>
                        setPageData({ ...PageData, limit })
                      }
                      className="d-flex justify-content-between"
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
        {/* </CardBody>
				</Card> */}
      </Col>
    </Row>
  );
};

export default RatesTable;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Pagination } from "rsuite";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";

//components
import InvitationModal from "components/invitation/InvitationModal";

//filters
import InvitationFilter from "components/Filters/invitations/InvitationFilter";

// icons
import { FiPlus } from "react-icons/fi";

//GraphQL
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { FetchInvitations } from "../../gql/invitations/query";
import { UpdateInvitation } from "../../gql/invitations/mutation";
import { UpdateJobRequestByField } from "../../gql/jobRequests/mutation";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";
import { notifySubscribers } from "toolkit/admin/admin.api";

//Functions
import {
  InvitationColumns,
  FormatData,
  BuildInvitationSearchQuery,
  BuildInvitationFilterQuery,
} from "./invitations.functions";
import invitationType from "./invitations.types";
import moment from "moment";

const InvitationTable: React.FC<{
  queryCondition: any;
}> = (props) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const {
    auth: { user, token },
  } = useSelector(authState);
  const initialCondition = {
    isactive: { _eq: true },
    createdby: { _eq: user.id },
  };
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>(initialCondition);
  const [modal, setModal] = useState<boolean>(false);

  const [getInvitations, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchInvitations, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
      fetchPolicy: "no-cache",
    });
  const [UpdateInvitationMutation] = useMutation(UpdateInvitation);
  const [UpdateJobRequestByFieldMutation] = useMutation(
    UpdateJobRequestByField
  );

  const defaultSorted: any = [];

  const handleRefetch = () => {
    if (called) {
      refetch();
    } else {
      getInvitations();
    }
    client.refetchQueries({
      include: [
        "FetchJobOpeningsForScheduler",
        "FetchJobRequestsForScheduler",
        "FetchInvitationsForScheduler",
      ],
    });
  };
  const handleChangeStatus = async (item: any) => {
    Swal.fire({
      title: `Are you sure want to cancel this invitation?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (item.status === "accepted") {
          //if the invite is accepted then we need to check the shift status. Only before 6 hours of the shift we can cancel a confirmed invite
          const _job_request = item.job_requests[0];
          if (_job_request) {
            const shiftDate = moment(
              `${item.job_date} ${item.position_start_time}`,
              "YYYY-MM-DD hh:mm"
            );
            const duration = moment.duration(moment().diff(shiftDate));
            const hours = duration.asHours();

            if (_job_request.status === "completed") {
              //check if shift is already completed
              showAlert({
                title: "Not Permitted!",
                message:
                  "The shift associated with this invitation is already completed",
                type: "warning",
              });
              return;
            } else if (_job_request.clock_in_time !== null) {
              //check if shift is already clocked in
              showAlert({
                title: "Not Permitted!",
                message:
                  "The shift associated with this invitation is already clocked in",
                type: "warning",
              });
              return;
            } else if (hours > 6) {
              showAlert({
                title: "Not Permitted!",
                message:
                  "You can cancel a confirmed invite only before 6 hours of the shift time",
                type: "warning",
              });
              return;
            }
          }
        }
        const formData = {
          event: "FACILITY_SHIFT_INVITE_CANCELLED",
          organisation_id: user.organisation_id,
          nurse_id: item.customer_id,
          position_id: item.position_id,
          date: moment(item.job_date).format("LL"),
        };
        await dispatch(notifySubscribers({ formData, token }));
        await UpdateInvitationMutation({
          variables: {
            id: item.id,
            object: {
              status: "cancelled",
            },
          },
        });
        await UpdateJobRequestByFieldMutation({
          variables: {
            object: {
              status: "cancelled",
            },
            where: {
              invitation_id: { _eq: item.id },
            },
          },
        });
        handleRefetch();
        Swal.fire("Invitation Cancelled Successfully!", "", "success");
      }
    });
  };
  const handleDeleteInvitation = async (item: any) => {
    Swal.fire({
      title: `Are you sure want to delete this invitation?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = {
          event: "FACILITY_SHIFT_INVITE_DELETED",
          organisation_id: user.organisation_id,
          nurse_id: item.customer_id,
          position_id: item.position_id,
          date: moment(item.job_date).format("LL"),
        };
        await dispatch(notifySubscribers({ formData, token }));
        await UpdateInvitationMutation({
          variables: {
            id: item.id,
            object: {
              isactive: false,
              status: "cancelled",
            },
          },
        });
        handleRefetch();
        Swal.fire("Invitation Deleted Successfully!", "", "success");
      }
    });
  };
  const handleSearch = (value: string) => {
    const conditionTemp = BuildInvitationSearchQuery(value, null);
    if (props.queryCondition) {
      setQueryCondition({ ...props.queryCondition, ...conditionTemp });
    } else {
      setQueryCondition({ ...initialCondition, ...conditionTemp });
    }
  };

  useEffect(() => {
    let _condition = {};
    if (props.queryCondition !== null) {
      _condition = { ...props.queryCondition };
    } else {
      _condition = { ...initialCondition };
    }
    if (Object.keys(filterValue).length) {
      const conditionTemp = BuildInvitationFilterQuery(filterValue, null);
      _condition = { ..._condition, ...conditionTemp };
    }
    setQueryCondition(_condition);
  }, [props.queryCondition, filterValue]);

  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getInvitations();
    }
  }, [queryCondition]);
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      if (props.queryCondition) {
        setQueryCondition(props.queryCondition);
      } else {
        setQueryCondition(initialCondition);
      }
    }
  }, [searchKey]);
  const keyField = "id";
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <ToolkitProvider
              keyField={keyField}
              data={FormatData(
                Array.isArray(data?.invitations) ? data.invitations : []
              )}
              columns={InvitationColumns(
                searchKey,
                filterValue,
                history,
                handleChangeStatus,
                handleDeleteInvitation
              )}
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row
                    className="mb-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col sm="4">
                      <div className="search-box d-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                name="invite"
                                placeholder="Search invitations"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-end ">
                        {user.role === "provider" ? (
                          <div className="search-box me-2">
                            <div className="position-relative">
                              <button
                                type="button"
                                className="btn btn-primary shadow"
                                onClick={() => setModal(true)}
                              >
                                <FiPlus /> New Invite
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div>
                          <InvitationFilter
                            onFilterChange={setFilterValue}
                            activeFilters={["job_date", "position", "status"]}
                            filterQueryConditions={null}
                          />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm="8">
                      Total Invitations:{" "}
                      {data?.invitations_aggregate?.aggregate?.count || 0}
                    </Col>
                  </Row>
                  {loading && (
                    <Col xl="12" className="text-center">
                      <Spinner color="primary" />
                    </Col>
                  )}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={{
                            mode: "checkbox",
                            onSelectAll: (isSelected) => {
                              console.log(isSelected);
                            },
                          }}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          noDataIndication={() => "No invitations found!"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline mt-3 w-100">
                        <Pagination
                          total={
                            data?.invitations_aggregate?.aggregate?.count || 0
                          }
                          limit={PageData.limit}
                          activePage={PageData.pageNo}
                          onChangePage={(pageNo) =>
                            setPageData({ ...PageData, pageNo })
                          }
                          limitOptions={[30, 50, 100]}
                          layout={["limit", "pager"]}
                          onChangeLimit={(limit) =>
                            setPageData({ ...PageData, limit })
                          }
                          className="d-flex justify-content-between"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
      <InvitationModal
        isEdit={false}
        setIsEdit={() => {
          //
        }}
        toggle={() => setModal(false)}
        modal={modal}
        refetch={handleRefetch}
        customer={null}
        selectedPosition={null}
        selectedDate={new Date()}
      />
    </Row>
  );
};

export default InvitationTable;

import React, { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import {
	Input
} from "reactstrap";

const AutoComplete: React.FC<{
	onSelect: (address: any) => void;
	value: string;
	onChange: (value: any) => void;
	onBlur: (value: any) => void;
	invalid: boolean;
	name: string;
	placeholder?: string
}> = ({ onSelect, onChange, value, onBlur, invalid, name, placeholder }) => {
	const [showList, setShowList] = useState<boolean>(false)
	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	const getLocationDetails = async (place_id: string) => {
		return new Promise((resolve, reject) => {
			placesService?.getDetails(
				{
					placeId: place_id,
				},
				(placeDetails: any) => resolve(placeDetails)
			);
		})
	}
	const renderPredictionList = () => {
		return (
			<ul style={styles.listWrapper} className="listWrapper">
				{placePredictions.map((address, i) => (
					<li
						key={i}
						onClick={async() => {
							const addressDetails = await getLocationDetails(address.place_id)
							onSelect(addressDetails)
							setShowList(false)
						}}
						style={styles.list}
					>
						{address.description}
					</li>
				))}
			</ul>
		)
	}
	return (
		<>
			<Input
				name={name}
				label="Address"
				type="text"
				onChange={(e) => {
					getPlacePredictions({ input: e.target.value });
					onChange(e)
					setShowList(true)
				}}
				autoComplete="caregigs"
				value={value}
				onBlur={onBlur}
				invalid={invalid}
				placeholder={placeholder || ""}
			/>
			{(placePredictions.length > 0 && showList) && renderPredictionList()}
		</>
	)
}
const styles = {
	listWrapper: {
		listStyle: "none",
		paddingLeft: 0,
		height: 150
	},
	list: {
		padding: "5px 0",
		borderBottom: "1px solid #eee",
		cursor: "pointer"
	}
}
export default AutoComplete
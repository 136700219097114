import React, { useEffect, useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";

//GraphQL
import { useApolloClient, useQuery } from "@apollo/client";
import { FetchCustomersAdmin } from "gql/customers/query";

//constants
import nurseStages from "constants/nurseStages";

const Stages: React.FC = (props) => {
  const client = useApolloClient();

  const verifiedCondition = {
    is_deleted: { _eq: false },
    verified: { _eq: true },
    onboarding_documents_verified: { _eq: true },
    is_archived: { _eq: false },
  };

  const conditionallyVerifiedCondition = {
    is_deleted: { _eq: false },
    verified: { _eq: true },
    is_archived: { _eq: false },

    _or: [
      { onboarding_documents_verified: { _eq: false } },
      { onboarding_documents_submitted: { _eq: false } },
    ],
  };

  const docSubmittedCondition = {
    is_deleted: { _eq: false },
    onboarding_documents_submitted: { _eq: true },
    verified: { _eq: false },
    is_archived: { _eq: false },
  };

  const selectedCondition = {
    is_deleted: { _eq: false },
    verified: { _eq: false },
    onboarding_documents_submitted: { _eq: false },
    onboarding_interview_status: { _eq: "selected" },
    is_archived: { _eq: false },
  };

  const interviewedCondition = {
    is_deleted: { _eq: false },
    is_archived: { _eq: false },
    _and: [
      { onboarding_application_form_submitted: { _eq: true } },
      { onboarding_interview_status: { _neq: "selected" } },
      { onboarding_documents_submitted: { _eq: false } },
      { verified: { _eq: false } },
    ],
  };
  const signedUpCondition = {
    is_deleted: { _eq: false },
    is_archived: { _eq: false },
    onboarding_application_form_submitted: { _eq: false },
  };
  const archivedCondition = {
    is_deleted: { _eq: false },
    is_archived: { _eq: true },
  };
  const limit = 50;
  const [offset, setOffset] = useState({
    verified: 0,
    condVerified: 0,
    docSubmitted: 0,
    selected: 0,
    interviewed: 0,
    signedUp: 0,
    rejected: 0,
    archived: 0,
  });
  const [aggregate, setAggregate] = useState({
    verified: 0,
    condVerified: 0,
    docSubmitted: 0,
    selected: 0,
    interviewed: 0,
    signedUp: 0,
    rejected: 0,
    archived: 0,
  });
  const [list, setList] = useState({
    verified: [],
    condVerified: [],
    docSubmitted: [],
    selected: [],
    interviewed: [],
    signedUp: [],
    rejected: [],
    archived: [],
  });

  const formatStatus = (tab: string) => {
    if (tab === "Verified") {
      return "verified";
    } else if (tab === "Conditionally Verified") {
      return "condVerified";
    } else if (tab === "Documents Submit") {
      return "docSubmitted";
    } else if (tab === "Selected") {
      return "selected";
    } else if (tab === "To be Interviewed") {
      return "interviewed";
    } else if (tab === "Signed Up") {
      return "signedUp";
    } else if (tab === "Archived") {
      return "archived";
    } else {
      return "rejected";
    }
  };

  const generateQueryResponse = async (conditions: any, offset: number) => {
    try {
      const verifiedQuery = await client.query({
        query: FetchCustomersAdmin,
        variables: {
          where: conditions,
          limit,
          offset,
        },
      });
      return verifiedQuery?.data?.customers || [];
    } catch (err) {
      return null;
    }
  };
  const showMoreItems = async (tab: string) => {
    if (tab === "Verified") {
      const newVerifiedOffset = offset.verified + limit;
      const _verifiedResp = await generateQueryResponse(
        verifiedCondition,
        newVerifiedOffset
      );
      if (_verifiedResp) {
        const newVerifiedList = list.verified.concat(_verifiedResp);
        setList({
          ...list,
          verified: newVerifiedList,
        });
        setOffset({
          ...offset,
          verified: newVerifiedOffset,
        });
      }
    } else if (tab === "Conditionally Verified") {
      const newConVerifiedOffset = offset.condVerified + limit;
      const _condVerifiedResp = await generateQueryResponse(
        conditionallyVerifiedCondition,
        newConVerifiedOffset
      );
      if (_condVerifiedResp) {
        console.log(_condVerifiedResp);
        if (_condVerifiedResp?.length) {
          const condVerifiedList = list.condVerified.concat(_condVerifiedResp);
          setList({
            ...list,
            condVerified: condVerifiedList,
          });
          setOffset({
            ...offset,
            condVerified: newConVerifiedOffset,
          });
        }
      }
    } else if (tab === "Documents Submit") {
      const newDocsSubmittedOffset = offset.docSubmitted + limit;
      const _docsSubmittedResp = await generateQueryResponse(
        docSubmittedCondition,
        newDocsSubmittedOffset
      );
      if (_docsSubmittedResp) {
        console.log(_docsSubmittedResp);
        if (_docsSubmittedResp?.length) {
          const newDocsSubmittedList =
            list.docSubmitted.concat(_docsSubmittedResp);
          setList({
            ...list,
            docSubmitted: newDocsSubmittedList,
          });
          setOffset({
            ...offset,
            docSubmitted: newDocsSubmittedOffset,
          });
        }
      }
    } else if (tab === "Selected") {
      const newSelectedOffset = offset.selected + limit;
      const _selectedResp = await generateQueryResponse(
        selectedCondition,
        newSelectedOffset
      );
      if (_selectedResp) {
        const newSelectedList = list.selected.concat(_selectedResp);
        setList({
          ...list,
          selected: newSelectedList,
        });
        setOffset({
          ...offset,
          selected: newSelectedOffset,
        });
      }
    } else if (tab === "To be Interviewed") {
      const newInterviewedOffset = offset.interviewed + limit;
      const _interviewedResp = await generateQueryResponse(
        interviewedCondition,
        newInterviewedOffset
      );
      if (_interviewedResp) {
        const newInterviewedList = list.interviewed.concat(_interviewedResp);
        setList({
          ...list,
          interviewed: newInterviewedList,
        });
        setOffset({
          ...offset,
          interviewed: newInterviewedOffset,
        });
      }
    } else if (tab === "Signed Up") {
      const newSignedUpOffset = offset.signedUp + limit;
      const _signedUpResp = await generateQueryResponse(
        signedUpCondition,
        newSignedUpOffset
      );
      if (_signedUpResp) {
        const newSignedUpList = list.signedUp.concat(_signedUpResp);
        setList({
          ...list,
          signedUp: newSignedUpList,
        });
        setOffset({
          ...offset,
          signedUp: newSignedUpOffset,
        });
      }
    } else if (tab === "Archived") {
      const newArchivedOffset = offset.archived + limit;
      const _archivedResp = await generateQueryResponse(
        archivedCondition,
        newArchivedOffset
      );
      if (_archivedResp) {
        const newArchivedList = list.archived.concat(_archivedResp);
        setList({
          ...list,
          archived: newArchivedList,
        });
        setOffset({
          ...offset,
          archived: newArchivedOffset,
        });
      }
    }
  };
  const handleFetchAllData = async () => {
    const promises: any[] = [];
    const verifiedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: verifiedCondition,
        limit: limit,
        offset: offset.verified,
      },
    });
    promises.push(verifiedQuery);
    const condVerifiedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: conditionallyVerifiedCondition,
        limit: limit,
        offset: offset.condVerified,
      },
    });
    promises.push(condVerifiedQuery);
    const submittedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: docSubmittedCondition,
        limit: limit,
        offset: offset.docSubmitted,
      },
    });
    promises.push(submittedQuery);
    const selectedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: selectedCondition,
        limit: limit,
        offset: offset.selected,
      },
    });
    promises.push(selectedQuery);
    const interviewedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: interviewedCondition,
        limit: limit,
        offset: offset.interviewed,
      },
    });
    promises.push(interviewedQuery);
    const signedUpQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: signedUpCondition,
        limit: limit,
        offset: offset.signedUp,
      },
    });
    promises.push(signedUpQuery);
    const archivedQuery = client.query({
      query: FetchCustomersAdmin,
      variables: {
        where: archivedCondition,
        limit: limit,
        offset: offset.archived,
      },
    });
    promises.push(archivedQuery);
    const resp = await Promise.all(promises);
    setAggregate({
      ...aggregate,
      verified: resp[0]?.data?.customers_aggregate?.aggregate?.count || 0,
      condVerified: resp[1]?.data?.customers_aggregate?.aggregate?.count || 0,
      docSubmitted: resp[2]?.data?.customers_aggregate?.aggregate?.count || 0,
      selected: resp[3]?.data?.customers_aggregate?.aggregate?.count || 0,
      interviewed: resp[4]?.data?.customers_aggregate?.aggregate?.count || 0,
      signedUp: resp[5]?.data?.customers_aggregate?.aggregate?.count || 0,
      archived: resp[6]?.data?.customers_aggregate?.aggregate?.count || 0,
    });
    setList({
      ...list,
      verified: resp[0]?.data?.customers || [],
      condVerified: resp[1]?.data?.customers || [],
      docSubmitted: resp[2]?.data?.customers || [],
      selected: resp[3]?.data?.customers || [],
      interviewed: resp[4]?.data?.customers || [],
      signedUp: resp[5]?.data?.customers || [],
      archived: resp[6]?.data?.customers || [],
    });
  };
  useEffect(() => {
    handleFetchAllData();
  }, []);

  const renderCards = useCallback(
    (tab: string) => {
      const customerList: any[] = list[formatStatus(tab)];
      const jsx: any[] = [];
      customerList.map((item: any) => {
        jsx.push(
          <div className="StagesPage-Card p-2" key={item.id}>
            <div className="d-flex flex-row">
              {/* <img src={"/avatar.jpg"} style={{ height: 70, width: 70 }} /> */}
              <div className="d-flex flex-column">
                <Link to={`/marketplace-profile/${item.id}`} target="_blank">
                  <h6>
                    {item.firstname
                      ? `${item.firstname} ${item.lastname}`
                      : "Guest User"}
                  </h6>
                </Link>
                <p className="m-0">
                  Type: {item.questionnaire.qualificatioType || "N/A"}
                </p>
                <p className="m-0">
                  Preferred Rate: ${item.preferred_rate || 0}
                </p>
              </div>
            </div>
            <div>
              <p className="m-0">Email: {item.email}</p>
            </div>
            <div>
              <p className="m-0">Contact: {item.contactno1}</p>
            </div>
          </div>
        );
      });
      return jsx;
    },
    [offset, list]
  );

  const renderCardsLength = useCallback(
    (tab: string) => aggregate[formatStatus(tab)],
    [aggregate]
  );

  const renderLoadMoreButton = useCallback(
    (tab: string) => {
      const total: number = aggregate[formatStatus(tab)];
      const currentLength: number = list[formatStatus(tab)].length;
      if (currentLength < total) {
        return (
          <Button
            outline
            color="primary mt-2 mb-2"
            onClick={() => showMoreItems(tab)}
            block
          >
            Load more
          </Button>
        );
      } else {
        return null;
      }
    },
    [list, offset]
  );
  return (
    <React.Fragment>
      <div className="page-content StagesPage AppBody">
        <MetaTags>
          <title>Stages | Caregigs</title>
        </MetaTags>
        <div className="StagesPage-Row">
          {nurseStages.map((item: any) => (
            <div className="StagesPage-Col" key={item.id}>
              <div
                style={{
                  backgroundColor: item.color,
                  height: 60,
                  padding: 10,
                  width: "100%",
                }}
                className="StagesPage-CardHeader"
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <h5>{item.position}</h5>
                  </div>
                  <div>
                    <h6>{renderCardsLength(item.position)}</h6>
                  </div>
                </div>
              </div>
              <div className="StagesPage-CardBody">
                {renderCards(item.position)}
                {renderLoadMoreButton(item.position)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Stages);

import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { Dropdown } from "rsuite";
import { GoKebabVertical } from "react-icons/go";

// icons
import { FiClock } from "react-icons/fi";

//types
import faqsType from "./faqs.types";

//utils
import {
  getTotalShiftHour,
  getTotalBreakTime,
  formatTotalHours,
} from "helpers";

export const FormatData = (employees: faqsType[]) => {
  const _employees = employees.map((elem: faqsType) => {
    return {
      id: elem.id,
      title: elem.title,
      description: elem.description,
      category: elem.category,
      active: elem.active,
    };
  });
  return _employees;
};
const formatHighlighter = (text: string, searchKey: string) => {
  const searchWords = [searchKey];
  // if (Array.isArray(filterValue?.role)) {
  //   searchWords = [...searchWords];
  // }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const JobRequestColumns = (
  searchKey: string,
  onEditClick: (emp: faqsType) => void,
  handleDeleteFAQ: (id: string) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
    csvExport: false,
  },
  {
    text: "Title",
    dataField: "title",
    sort: true,
    formatter: (cellContent: string) => (
      <span>{formatHighlighter(cellContent, searchKey)}</span>
    ),
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <span>
        {/* cellContent.slice(0, 50) + "...", */}
        {formatHighlighter(cellContent.slice(0, 50) + "...", searchKey)}
      </span>
    ),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
  },
  {
    dataField: "active",
    text: "Status",
    sort: true,
    formatter: (cellContent: any, row: any) => (
      <span>
        <Badge
          pill
          className={`badge ${
            cellContent ? "bg-success" : "bg-danger"
          } font-size-11 m-2 p-2`}
        >
          {cellContent ? "Active" : "Blocked"}
        </Badge>
      </span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    align: "center",
    headerAlign: "center",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <div className="faq-breadcrumb-dropdown">
        <Dropdown
          renderToggle={(props, ref) => <GoKebabVertical {...props} />}
          placement="leftStart"
        >
          <Dropdown.Item onClick={() => onEditClick(row)}>
            Edit FAQ
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDeleteFAQ(row.id)}>
            Delete FAQ
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  },
];

export const BuildJobRequestSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { title: { _ilike: `%${value}%` } },
        { description: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

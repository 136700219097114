import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import FaqTable from "./FaqsTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

const ShiftList: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="page-content Timecardpage AppBody">
        <MetaTags>
          <title>FAQs | Caregigs</title>
        </MetaTags>
        <div className="Timecardpage-container admin">
          {/* breadcrumb */}
          <Breadcrumbs
            title="FAQs"
            links={[
              { title: "Home", link: "/" },
              { title: "FAQs", link: "#" },
            ]}
          />
          <FaqTable queryCondition={null} facility={null} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ShiftList);

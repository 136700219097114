import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Link, NavLink, useLocation  } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//redux
import { useSelector, useDispatch } from "react-redux"
import {layoutState, toggleLeftmenu} from "toolkit/layout/layout.slice"
import {fetchProfileApi} from "toolkit/profile/profile.api"
import { authState, loginRemoveToken } from "toolkit/auth/auth.slice";
import {resetLayout} from "toolkit/layout/layout.slice"

const Header = props => {
  const {layout: {leftMenu}} = useSelector(layoutState)
  const dispatch = useDispatch()
  const { auth: { token } } = useSelector(authState)
  const location = useLocation();

  const handleFetchProfile = async() => {
    try{
      const resp = await dispatch(fetchProfileApi(token))
      if(resp.type === "user/me/rejected"){
        dispatch(loginRemoveToken())
        dispatch(resetLayout())
      }
    }catch(err){
      dispatch(loginRemoveToken())
      dispatch(resetLayout())
    }
  }
  useEffect(()=>{
    if(token){
      handleFetchProfile()
    }
  }, [token])

  const isSettingsNavActive = (location.pathname === "/settings/locations" || location.pathname === "/settings/staff" || location.pathname === "/settings/positions")
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={"/cc_icon.png"} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={"/caregigs_logo.png"} alt="" height="60" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={"/caregigs_logo.png"} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={"/caregigs_logo.png"} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                dispatch(toggleLeftmenu(!leftMenu));
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>

          <div className="d-flex">
           
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/schedule">
              <button
                type="button"
                className="btn header-item  ">
                Schedule
              </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/marketplace">
              <button
                type="button"
                className="btn header-item  ">
                Marketplace
              </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/Timecards">
              <button
                type="button"
                className="btn header-item  ">
                Timecards
              </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/job-openings">
              <button
                type="button"
                className="btn header-item">
                Gig Shifts
              </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/requests">
              <button
                type="button"
                className="btn header-item">
                Gig Requests
              </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <NavLink to="/invitations">
                <button
                  type="button"
                  className="btn header-item">
                  Invitations
                </button>
              </NavLink>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
            <NavLink to="/settings/locations" className={isSettingsNavActive ? "active" : ""}>
              <button
                type="button"
                className="btn header-item  ">
                Settings
              </button>
              </NavLink>
            </div>
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
};

export default Header;

import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useLocation, NavLink } from "react-router-dom";
import { Col, Row, Input, Form, FormGroup } from "reactstrap";
import { v4 as uuid } from "uuid";
import { Loader } from "rsuite";
import { ValueType } from "rsuite/esm/Checkbox";
import Swal from "sweetalert2";

// icons
import { FiSend, FiArrowLeft, FiPlus, FiArchive } from "react-icons/fi";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import MarketplaceListView from "components/marketplace/MarketplaceListView";
import NotificationModal from "components/notification/NotificationModal";
import NurseFilter from "components/Filters/nurses/NurseFilter";

//GraphQL
import { useMutation, useQuery } from "@apollo/client";
import { FetchCustomersAdmin } from "gql/customers/query";
import { UpdateNurses } from "gql/customers/mutation";

//Functions
import {
  BuildCustomerFilterQueryForAdmin,
  BuildCustomerSearchQuery,
} from "../../Marketplace/marketplace.functions";
import { getNurseStatus } from "helpers";
import { filter } from "lodash";

interface QueryConditionType {
  is_deleted: {
    _eq: boolean;
  };
  is_archived: {
    _eq: boolean;
  };
}
const Nurses: React.FC = (props) => {
  const search = useLocation().search;
  const tabs = [
    {
      name: "Nurses",
      value: "nurses",
    },
    {
      name: "Archived Nurses",
      value: "archived",
    },
  ];

  const initialCondition: QueryConditionType = {
    is_deleted: { _eq: false },
    is_archived: { _eq: false },
  };
  const archiveCondition: QueryConditionType = {
    is_deleted: { _eq: false },
    is_archived: { _eq: true },
  };

  const initialFilter = {
    status: [],
    speciality: [],
    licenseState: [],
    addressMA: [],
    revoked: [],
  };
  const [customerList, setCustomerList] = useState([]);
  const [filters, setFilters] = useState<{
    status: ValueType[];
    speciality: ValueType[];
    licenseState: ValueType[];
    addressMA: ValueType[];
    revoked: ValueType[];
  }>(initialFilter);
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState(initialCondition);
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [filterKey, setFilterKey] = useState(uuid());
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [UpdateNursesMutation] = useMutation(UpdateNurses);
  const [activeTab, setActiveTab] = useState("nurses");
  const resetRef = useRef<any>(null);

  const { loading, data, refetch, error, called } = useQuery(
    FetchCustomersAdmin,
    {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const onClickInvite = (user: any) => {
    setInviteModalOpen(true);
  };
  const handleSearch = (value: string) => {
    if (activeTab == "nurses") {
      const conditionTemp = BuildCustomerSearchQuery(value, null);
      setQueryCondition({ ...initialCondition, ...conditionTemp });
    } else if (activeTab == "archived") {
      const conditionTemp = BuildCustomerSearchQuery(value, null);
      setQueryCondition({ ...archiveCondition, ...conditionTemp });
    }
  };
  useEffect(() => {
    if (Array.isArray(data?.customers)) {
      setCustomerList(data?.customers);
    }
  }, [data]);
  // setFilters({...filters, price: value})

  const getFilterQuery = () => {
    const _conditions = BuildCustomerFilterQueryForAdmin(filters);
    if (activeTab == "nurses") {
      if (Object.keys(_conditions).length) {
        return { ...initialCondition, ..._conditions };
      } else {
        return initialCondition;
      }
    } else if (activeTab == "archived") {
      if (Object.keys(_conditions).length) {
        return { ...archiveCondition, ..._conditions };
      } else {
        return archiveCondition;
      }
    }
  };

  useEffect(() => {
    const _conditions: any = getFilterQuery();
    setQueryCondition(_conditions);
  }, [filters]);

  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      if (activeTab === "nurses") {
        setQueryCondition(initialCondition);
      } else {
        setQueryCondition(archiveCondition);
      }
    }
  }, [searchKey]);
  
  useEffect(() => {
    if (search.length) {
      const type = new URLSearchParams(search).get("type");
      const addressMA = new URLSearchParams(search).get("address");
      const speciality: string | null = new URLSearchParams(search).get(
        "speciality"
      );
      const licenseState = new URLSearchParams(search).get("state");
      let _speciality: string[] = [];
      let _type: string[] = [];
      let _licenseState: string[] = [];
      let _addressMA: string[] = [];
      if (speciality?.split(",")?.length && speciality) {
        _speciality = speciality?.split(",");
      }
      if (addressMA?.split(",")?.length && addressMA) {
        _addressMA = addressMA?.split(",");
      }
      if (type?.split(",")?.length && type) {
        _type = type?.split(",");
      }
      if (licenseState?.split(",")?.length && licenseState) {
        _licenseState = licenseState?.split(",");
      }
      setFilters({
        ...filters,
        speciality: _speciality,
        status: _type,
        licenseState: _licenseState,
        addressMA: _addressMA,
      });
    }
  }, [search]);

  const getResetFn = (f: any) => {
    resetRef.current = f;
  };

  const onHandleArchive = (isArchive: boolean) => {
    Swal.fire({
      title: `Do you want to ${
        isArchive ? "archive" : "unarchive"
      } these nurses?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UpdateNursesMutation({
          variables: {
            where: {
              id: { _in: selected.map((s) => s.id) },
            },
            object: {
              is_archived: isArchive,
            },
          },
        });
        refetch();
        Swal.fire(
          `${isArchive == true ? "Archive" : "Unarchive"} Successful!`,
          "",
          "success"
        );

        setSelected([]);
        if (resetRef.current) {
          resetRef?.current();
        }
      }
    });
  };

  const setQueryOnTabChange = () => {
    const _conditions: any = getFilterQuery();
    setQueryCondition(_conditions);
  };

  useEffect(() => {
    if (activeTab) {
      setQueryOnTabChange();
      setPageData({
        limit: 50,
        pageNo: 1,
      });
    }
  }, [activeTab]);

  function toggle() {
    setModal(!modal);
  }

  return (
    <React.Fragment>
      <div className="page-content Nurses AppBody">
        <MetaTags>
          <title>Nurses | Caregigs</title>
        </MetaTags>
        <div className="Nurses-container admin">
          <div
            className={
              sideBarOpen == true ? "Nurses-sidebar" : "Nurses-sidebar off"
            }
          >
            <div
              className="Nurses-sidebar_toggler"
              onClick={() => {
                setSideBarOpen(!sideBarOpen);
              }}
            >
              <FiArrowLeft className="Nurses-sidebar_toggler-i1" size={20} />
              {/* <FiArrowRight className="Nurses-sidebar_toggler-i2"  /> */}
            </div>
            <NurseFilter
              filterKey={filterKey}
              setFilterKey={setFilterKey}
              setFilters={setFilters}
              filters={filters}
              activeTab={activeTab}
            />
          </div>

          <div
            className={
              sideBarOpen == true ? "Nurses-mainBody" : "Nurses-mainBody off"
            }
          >
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Nurses"
              links={[
                { title: "Home", link: "/" },
                { title: "Nurses", link: "#" },
              ]}
            />

            <div className="Nurses-card">
              <Row>
                <Col
                  lg="12"
                  className="d-flex justify-content-center align-items-center mb-3"
                >
                  <div className="MarketPlaceProfileTabs">
                    {tabs.map((tab, i) => (
                      <>
                        <div
                          className={
                            activeTab == tab.value
                              ? "active front-size-16 MarketPlaceProfileTabs_tab"
                              : "front-size-16 MarketPlaceProfileTabs_tab"
                          }
                          onClick={() => setActiveTab(tab.value)}
                        >
                          {tab.name}
                        </div>
                      </>
                    ))}
                  </div>
                </Col>
              </Row>
              {loading && (
                <Row className="justify-content-center">
                  <Loader size="md" />
                </Row>
              )}
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box d-block">
                    <div className="position-relative">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <FormGroup>
                          <Input
                            name="search"
                            placeholder="Search nurses"
                            type="text"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col sm="8">
                  <div className="text-sm-end d-flex justify-content-end">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <button
                          type="button"
                          className={`btn ${
                            activeTab === "nurses"
                              ? "btn-secondary"
                              : "btn-success"
                          } shadow`}
                          disabled={selected.length === 0}
                          onClick={(e) =>
                            onHandleArchive(
                              activeTab === "nurses" ? true : false
                            )
                          }
                        >
                          <FiArchive />{" "}
                          {activeTab === "nurses" ? "Archive" : "Unarchive"}
                        </button>{" "}
                        {activeTab === "nurses" && (
                          <>
                            <NavLink to="/create-nurse" className=" ">
                              <button
                                type="button"
                                className="btn btn-primary shadow"
                                onClick={() => setModal(true)}
                              >
                                <FiPlus /> Create Nurse
                              </button>
                            </NavLink>{" "}
                            <button
                              type="button"
                              className="btn btn-primary shadow"
                              disabled={selected.length === 0}
                              onClick={() => setModal(true)}
                            >
                              <FiSend /> Send Notification
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="8">
                  Total Nurses:{" "}
                  {data?.customers_aggregate?.aggregate?.count || 0}
                </Col>
              </Row>
              <MarketplaceListView
                searchKey={searchKey}
                filterValue={filterValue}
                onClickInvite={onClickInvite}
                customerList={customerList}
                PageData={PageData}
                setPageData={setPageData}
                total={data?.customers_aggregate?.aggregate?.count || 0}
                handleSelectRow={setSelected}
                resetSelection={getResetFn}
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        toggle={toggle}
        modal={modal}
        notificationTokens={selected.map(
          (cust: any) => cust.notification_token
        )}
      />
    </React.Fragment>
  );
};
export default withRouter(Nurses);

import React, { useEffect, useState } from "react";
import { TagGroup, Tag, IconButton, Col, Row } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import Swal from "sweetalert2";
import { showAlert } from "../../../helpers/index";

import TextNotificationModal from "components/textNotification/TextNotificationModal";

//GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { DeleteSubscriber } from "gql/notificationSubscribers/mutation";
import { FetchSubscriber } from "gql/notificationSubscribers/query";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const TextNotification: React.FC = () => {
  const {
    auth: { user },
  } = useSelector(authState);
  const [tags, setTags] = useState<string[]>([]);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const [DeleteSubscriberMutation] = useMutation(DeleteSubscriber);

  const { loading, data, refetch, error, called } = useQuery(FetchSubscriber, {
    variables: {
      where: {
        organisation_id: { _eq: user.organisation_id },
        email: { _is_null: true },
      },
    },
  });

  const removeTag = (tag: any) => {
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to delete this Contact?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await DeleteSubscriberMutation({
            variables: {
              id: tag,
            },
          });
          refetch();
          showAlert({
            title: "Deleted!",
            message: "Contact removed successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

  const renderInput = () => {
    return (
      <IconButton
        className="tag-add-btn"
        onClick={() => setNotificationModalOpen(true)}
        icon={<PlusIcon />}
        appearance="ghost"
        size="xs"
        style={{ marginLeft: 12 }}
      />
    );
  };

  useEffect(() => {
    if (data) {
      setTags(data.notification_subscribers);
    }
  }, [data]);

  function toggle() {
    setNotificationModalOpen(!notificationModalOpen);
  }

  return (
    <Row>
      <p
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          color: "#7E8299",
          marginBottom: 15,
        }}
      >
        Click to add phone number for Subscription!
      </p>
      <TagGroup>
        {tags.map((item: any, index: any) => (
          <Tag
            key={index}
            closable
            onClose={() => removeTag(item.id)}
            color="blue"
          >
            {item.contact_name}: {item.contact_number}
          </Tag>
        ))}
        {renderInput()}
      </TagGroup>
      <TextNotificationModal
        toggle={toggle}
        modal={notificationModalOpen}
        userData={user}
      />
    </Row>
  );
};

export default TextNotification;

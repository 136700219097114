import React from "react"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts";

const StackedColumnChart: React.FC<{
  periodData: any;
  xAxisData: any
}> = ({ periodData, xAxisData }) => {
  const options: ApexOptions = {
    chart: {
      stacked: !0,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      // show: true,
      categories: xAxisData,
      labels: {
        show: true
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return String(value)
        },
      }
    },
    colors: ["#556ee6", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[periodData]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}
export default StackedColumnChart

import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Col, Form, FormGroup, Input, Row, Modal, Spinner } from "reactstrap";
// datatable related plugins
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "rsuite";
import "../../../assets/scss/custom.scss";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import "../datatables.scss";


//Functions
import {
  EmployeeColumns,
  FormatData,
  BuildEmployeeSearchQuery,
  BuildEmployeeFilterQuery,
} from "./employees.functions";

//components
import EmployeeModal from "components/employees/EmployeeModal";

// icons
import { FiPlus, FiDownloadCloud } from "react-icons/fi";

//filters
import EmployeeFilter from "components/Filters/employees/EmployeeFilter.jsx";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { FetchEmployees } from "../../../gql/employees/query";
import { UpdateEmployee } from "gql/employees/mutation";
//Redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//types
import employeeType from "./employees.types";

const Employees = () => {
  const history = useHistory();
  const params: any = useParams();
  const { ExportCSVButton } = CSVExport;
  const {
    auth: { user },
  } = useSelector(authState);
  const initialCondition = {
    createdby: { _eq:  user.role === "moderator" ?  params.id : user.id },
    isactive: { _eq: true },
  };
  const [modal_center, setmodal_center] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>(initialCondition);
  const [selectedEmployee, setSelectedEmployee] = useState<
    employeeType | undefined
  >();
  const [getEmployeeList, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchEmployees, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    });
  const [UpdateEmployeeMutation] = useMutation(UpdateEmployee);

  const defaultSorted: any = [];

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const onEditClick = (_employee: employeeType) => {
    setSelectedEmployee(_employee);
    setIsEdit(true);
    toggle();
  };
  const handleChangeStatus = (_employee: employeeType) => {
    Swal.fire({
      title: "Are you sure want to delete this employee?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{
          await UpdateEmployeeMutation({
            variables: {
              id: _employee.id,
              object: {
                isactive: false,
              },
            },
          });
          refetch();
          Swal.fire("Employee Deleted Successfully!", "", "success");
        }catch(err){
          Swal.fire("Something went wrong!", "", "error");
        }
      }
    });
  };
  const handleSearch = (value: string) => {
    const conditionTemp = BuildEmployeeSearchQuery(value, null);
    setQueryCondition({ ...initialCondition, ...conditionTemp });
  };

  const handleRefetch = () => {
    if (called) {
      refetch();
    } else {
      getEmployeeList();
    }
  };
  function toggle() {
    setModal(!modal);
  }
  useEffect(() => {
    if (user?.id) {
      getEmployeeList();
    }
  }, [user]);
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      setQueryCondition(initialCondition);
    }
  }, [searchKey]);
  //Apply filters
  useEffect(() => {
    const conditionTemp = BuildEmployeeFilterQuery(filterValue, null);
    setQueryCondition({ ...initialCondition, ...conditionTemp });
  }, [filterValue]);
  return (
    <React.Fragment>
      {user.role === "provider" && 
        <div>
         <Row className="mb-3">
        
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>Staff</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            <Form
              className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center"
              style={{ marginRight: 50 }}
            >
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setModal(true)}
                  >
                    <FiPlus /> Add Staff
                  </button>
                </div>
              </div>
            </Form>
          </Col>
         </Row>
        </div>
      }
      
      <div>
        <ToolkitProvider
          keyField="id"
          columns={EmployeeColumns(
            searchKey,
            filterValue,
            history,
            handleChangeStatus,
            onEditClick
          )}
          data={FormatData(
            Array.isArray(data?.employees) ? data.employees : []
          )}
          bootstrap4
          exportCSV={{ onlyExportSelection: true, exportAll: true }}
        >
          {(toolkitProps) => (
            <React.Fragment>
              <Row>
                <>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                id="exampleEmail"
                                name="email"
                                placeholder="Search employees"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div
                        className="text-sm-end d-flex justify-content-end"
                        style={{ marginRight: 30 }}
                      >
                        <ExportCSVButton
                          {...toolkitProps.csvProps}
                          style={{
                            border: "1px solid #74788d",
                            marginRight: 10,
                          }}
                        >
                          <FiDownloadCloud />
                          &nbsp;&nbsp; Export
                        </ExportCSVButton>
                        <EmployeeFilter
                          onFilterChange={setFilterValue}
                          activeFilters={["status", "created_at", "location"]}
                          filterQueryConditions={null}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm="8">
                      Total Staff:{" "}
                      {data?.employees_aggregate?.aggregate?.count || 0}
                    </Col>
                  </Row>
                  {loading && (
                    <Col xl="12" className="text-center">
                      <Spinner color="primary" />
                    </Col>
                  )}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={{
                            mode: "checkbox",
                            onSelectAll: (isSelected) => {
                              console.log(isSelected);
                            },
                          }}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          noDataIndication={() => "No employees found!"}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              </Row>
              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline mt-3 w-100">
                    <Pagination
                      total={data?.employees_aggregate?.aggregate?.count || 0}
                      limit={PageData.limit}
                      activePage={PageData.pageNo}
                      onChangePage={(pageNo) =>
                        setPageData({ ...PageData, pageNo })
                      }
                      limitOptions={[30, 50, 100]}
                      layout={["limit", "pager"]}
                      onChangeLimit={(limit) =>
                        setPageData({ ...PageData, limit })
                      }
                      className="d-flex justify-content-between"
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
      <EmployeeModal
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        toggle={toggle}
        modal={modal}
        refetch={handleRefetch}
        selectedEmployee={selectedEmployee}
      />
    </React.Fragment>
  );
};

export default Employees;

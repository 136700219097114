import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { layoutState, toggleLeftmenu } from "toolkit/layout/layout.slice";

const Navbar = (props) => {
  const {
    layout: { leftMenu },
  } = useSelector(layoutState);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/schedule"
                  >
                    Schedule
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/marketplace"
                  >
                    Marketplace
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/Timecards"
                  >
                    Timecards
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/job-openings"
                  >
                    Gig Shifts
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/requests"
                  >
                    Gig Requests
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/invitations"
                  >
                   Invitations
                  </NavLink>
                </li>
                {/* links */}
                {/* links */}
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    dispatch(toggleLeftmenu(!leftMenu));
                  }}
                >
                  <NavLink
                    className="nav-link dropdown-toggle arrow-none"
                    to="/settings/locations"
                  >
                    Settings
                  </NavLink>
                </li>
                {/* links */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default Navbar;

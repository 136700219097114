import { gql } from "@apollo/client";
export const FetchRates = gql`
  query FetchRates($limit: Int, $offset: Int, $where: rate_history_bool_exp) {
    rate_history(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      nurse_rate
      nurse_overtime_rate
      contract_rate
      contract_overtime_rate
      created_at
      updated_at
      position {
        name
      }
    }
    rate_history_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

import React, { CSSProperties } from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { Link } from "react-router-dom"
import { FiX, FiEdit } from "react-icons/fi";
import { Button } from "reactstrap";

//types
import NoteType from "./notes.types"

const wrapStyle: CSSProperties = {
	display: 'block', 
	width: 230, 
	overflow: 'hidden', 
	whiteSpace: 'nowrap', 
	textOverflow: 'ellipsis'
}
export const FormatData = (notes: NoteType[]) => {
	const _notes = notes.map((elem: NoteType) => {
		return {
			id: elem.id,
			title: elem.title,
			note: elem.note,
			created_at: elem.created_at
		}
	})
	return _notes
}
const formatHighlighter = (text: string, searchKey: string, filterValue: any) => {
	let searchWords = [searchKey]
	if (Array.isArray(filterValue?.role)) {
		searchWords = [...searchWords, ...filterValue?.role]
	}
	return <Highlighter
		searchWords={searchWords}
		autoEscape={true}
		textToHighlight={String(text)}
		highlightStyle={{ background: "#ff0" }}
	/>
}
export const NotesColumns = (searchKey: string, filterValue: any, history: any, selectDocument: (row: NoteType) => void, handleDeleteDocument: (id: string) => void) => [
	{
		text: "id",
		dataField: "id",
		hidden: true,
	},
	{
		dataField: "title",
		text: "Title",
		// eslint-disable-next-line react/display-name
		formatter: (cellContent: string, row: any) => (
			<Link to="#" onClick={() => selectDocument(row)}>
				<span style={wrapStyle}>
					{formatHighlighter(cellContent, searchKey, filterValue)}
				</span>
			</Link>
		),
	},
	{
		dataField: "note",
		text: "Note",
		formatter: (cellContent: string, row: any) => { return <span style={wrapStyle}>{formatHighlighter(cellContent, searchKey, filterValue)}</span> }
	},
	{
		text: "Created at",
		dataField: "created_at",
		sort: true,
		formatter: (cellContent: string, row: any) => moment(cellContent).format("MM/DD/YYYY")
	},
	{
		dataField: "menu",
		isDummyField: true,
		editable: false,
		text: "Action",
		// eslint-disable-next-line react/display-name
		formatter: (cellContent: string, row: any) => {
			return (
				<div className="d-flex justify-content-start">
					<Button
						color="warning"
						outline
						size="sm"
						style={{ height: 30, marginRight: 5 }}
						to="#"
						onClick={() => selectDocument(row)}
					>
						<FiEdit size={15} />
						Edit
					</Button>
					<Button
						color="danger"
						outline
						size="sm"
						style={{ height: 30 }}
						to="#"
						onClick={() => handleDeleteDocument(row.id)}
					>
						<FiX size={15} />
						Delete
					</Button>
				</div>
			);
		},
	},
]
export const BuildNoteSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ title: { _ilike: `%${value}%` } },
				{ note: { _ilike: `%${value}%` } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
import { gql } from "@apollo/client"
export const FetchJobOpenings =
  gql`query FetchJobOpenings (\$limit:Int,\$offset:Int,\$where:job_openings_bool_exp){
    job_openings (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      organisation_id
      location {
        id
        name
      }
      isactive
      fee_amount
      currency
      fee_period
      requirement
      created_at
      createdby
      maple_shift_id
      position {
        id
        name
        type
        maple_worker_requirement
        start_time
        end_time
      }
      user {
        name
      }
      job_date
      job_requests_aggregate {
        aggregate {
          count
        }
      }
      filled
      job_requests (where: {is_confirmed: {_eq: true}}) {
        id
      }
    }
    job_openings_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `

export const FetchJobOpeningsForScheduler =
  gql`query FetchJobOpeningsForScheduler (\$where:job_openings_bool_exp){
    job_openings (where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      fee_amount
      requirement
      location {
        id
        name
      }
      isactive
      created_at
      is_open_shift
      position {
        id
        name
        type
        start_time
        end_time
        text_color
        background_color
      }
      job_date
      job_requests_aggregate(where: {status: {_eq: "requested"}, active: {_eq: true}}) {
        aggregate {
          count
        }
      }
    }
  }
  `
import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { Dropdown } from "rsuite";
import { GoKebabVertical } from "react-icons/go";

// icons
import { FiClock } from "react-icons/fi";

//types
import timecardsType from "./shifts.types";

//utils
import {
  getTotalShiftHour,
  getTotalBreakTime,
  formatTotalHours,
} from "helpers";

export const FormatData = (employees: timecardsType[]) => {
  const _employees = employees.map((elem: timecardsType) => {
    let total_minutes = 0;
    let facility_cost = 0;
    let formatted_status = elem.status?.toUpperCase();
    const _elem = elem.job_opening || elem.invitation;
    total_minutes = getTotalShiftHour(
      elem.clock_in_time,
      elem.clock_out_time,
      elem.break_times,
      elem.actual_break_time
    );
    facility_cost = (_elem?.fee_amount / 60) * total_minutes;
    const break_time = getTotalBreakTime(
      elem.break_times,
      elem.actual_break_time
    );

    const cancelled_at = moment(elem.cancelled_at).isValid()
      ? moment(elem.cancelled_at).format("ddd, MMM DD, YYYY,hh:mm A")
      : "N/A";

    if (elem.status === "completed") {
      formatted_status = "COMPLETED";
    } else if (
      elem.is_confirmed &&
      !["cancelled", "rejected"].includes(elem.status)
    ) {
      formatted_status = "CONFIRMED";
    }
    return {
      id: elem.id,
      status: elem.status,
      active: elem.active,
      created_at: elem.created_at,
      job_date: _elem?.job_date,
      name:
        elem.customer?.firstname?.length && elem.customer?.lastname?.length
          ? `${elem.customer?.firstname} ${elem.customer?.lastname}`
          : "Guest User",
      customer_id: elem.customer?.id,
      position_name: _elem?.position?.name || "N/A",
      position_id: _elem?.position?.id,
      position_start_time: _elem?.position?.start_time,
      position_end_time: _elem?.position?.end_time,
      location_name: _elem?.location?.name || "N/A",
      location_id: _elem?.location?.id,
      fee_amount: `$${_elem?.fee_amount}`,
      fee_amount_object: _elem,
      fee_amount_type: elem.job_opening
        ? "job_opening"
        : elem.invitation
        ? "invitation"
        : "",
      currency: _elem?.currency,
      fee_period: _elem?.fee_period,
      clock_in: elem.clock_in_time || "N/A",
      clock_out: elem.clock_out_time || "N/A",
      cancelled_at: cancelled_at || "N/A",
      total_hours:
        isNaN(total_minutes) || total_minutes < 0
          ? "N/A"
          : (total_minutes / 60).toFixed(2),
      facility_cost:
        isNaN(total_minutes) || total_minutes < 0
          ? "N/A"
          : `$${facility_cost.toFixed(2)}`,
      is_confirmed: elem.is_confirmed,
      organisation_name: _elem?.organisation?.name,
      facility_id: _elem?.organisation?.createdby,
      formatted_status,
      break_times: elem.break_times,
      total_break_time: break_time > 0 ? formatTotalHours(break_time) : "N/A",
      nurse_rate: elem?.customer?.nurse_rate,
      nurse_overtime_rate: elem?.customer?.nurse_overtime_rate,
      nurse_type: elem?.customer?.questionnaire?.qualificatioType,
      contract_rate: _elem.position?.contract_rate,
      contract_overtime_rate: _elem.position?.contract_overtime_rate,
      regular_hours: elem.regular_hours,
      overtime_hours: elem.overtime_hours,
      facility_regular_charges: elem.facility_regular_charges,
      facility_overtime_charges: elem.facility_overtime_charges,
      facility_total_charges: elem.facility_total_charges,
      nurse_regular_charges: elem.nurse_regular_charges,
      nurse_overtime_charges: elem.nurse_overtime_charges,
      nurse_total_charges: elem.nurse_total_charges,
      caregigs_margin: elem.caregigs_margin,
      notes: elem.notes,
      regular_exact_hours:
        elem.regular_hours > 0 ? formatTotalHours(elem.regular_hours) : "N/A",
      overtime_exact_hours:
        elem.overtime_hours > 0 ? formatTotalHours(elem.overtime_hours) : "N/A",
      facility_regular_charges_table:
        elem.facility_regular_charges > 0
          ? `$ ${elem.facility_regular_charges}`
          : "N/A",
      facility_overtime_charges_table:
        elem.facility_overtime_charges > 0
          ? `$ ${elem.facility_overtime_charges}`
          : "N/A",
      facility_total_charges_table:
        elem.facility_total_charges > 0
          ? `$ ${elem.facility_total_charges}`
          : "N/A",
      nurse_regular_charges_table:
        elem.nurse_regular_charges > 0
          ? `$ ${elem.nurse_regular_charges}`
          : "N/A",
      nurse_overtime_charges_table:
        elem.nurse_overtime_charges > 0
          ? `$ ${elem.nurse_overtime_charges}`
          : "N/A",
      nurse_total_charges_table:
        elem.nurse_total_charges > 0 ? `$ ${elem.nurse_total_charges}` : "N/A",
      margin: elem.caregigs_margin > 0 ? `$ ${elem.caregigs_margin}` : "N/A",
      invitationBy: elem?.invitation?.invitation_by,
      cancellation_reason: elem?.cancellation_reason,
      maple_shift_id: elem.job_opening?.maple_shift_id,
      maple_shift_assignment_id: elem?.maple_shift_assignment_id,
      maple_shift_completion_id: elem?.maple_shift_completion_id,
      job_opening_id: elem?.job_opening?.id
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const JobRequestColumns = (
  searchKey: string,
  filterValue: any,
  // onClockClick: (id: string) => void,
  activeTab: string,
  onClockClick: (shift: timecardsType) => void,
  handleCancelShift: (shift: any) => void,
  handleDeleteShift: (shift: any) => void,
  onReassignShift: (shift: any) => void,
  handleNotifyCancelShift: (shift: any) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
    csvExport: false,
  },
  {
    dataField: "img",
    text: "#",
    csvExport: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        {!user.customer_avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.name.charAt(0)}
            </span>
          </div>
        ) : (
          <div></div>
        )}
      </>
    ),
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) =>
      user.customer_id ? (
        <Link
          to={`/marketplace-profile/${user.customer_id}`}
          className="text-primary"
        >
          {formatHighlighter(user.name, searchKey, filterValue)}
        </Link>
      ) : (
        formatHighlighter(user.name, searchKey, filterValue)
      ),
  },
  {
    text: "Facility Name",
    dataField: "organisation_name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <Link
        to={`/facilities-profile/${row.facility_id}`}
        className="text-primary"
      >
        {formatHighlighter(cellContent, searchKey, filterValue)}
      </Link>
    ),
  },
  {
    dataField: "position_name",
    text: "Position",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "maple_shift_id",
    text: "",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <Badge pill style={{backgroundColor:"blue", color:"#fff"}} className="font-size-11 m-2 p-2">
        {cellContent ? "Maple Shift" : ""}
      </Badge>
    ),
  },
  {
    dataField: "nurse_type",
    text: "Nurse Type",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "location_name",
    text: "Location",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "job_date",
    text: "Date",
    sort: true,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "position_start_time",
    text: "Shift Start",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "position_end_time",
    text: "Shift End",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "clock_in",
    text: "Clock In",
    sort: true,
  },
  {
    dataField: "clock_out",
    text: "Clock Out",
    sort: true,
  },
  {
    dataField: "cancelled_at",
    text: "Cancelled At",
    hidden: activeTab == "cancelled_shifts" ? false : true,
    sort: true,
  },
  // {
  //   dataField: "fee_amount",
  //   text: "Rate",
  //   sort: true,
  // },
  {
    dataField: "total_break_time",
    text: "Total Break Time",
    sort: true,
  },
  {
    dataField: "total_hours",
    text: "Total Hours",
    sort: true,
  },
  {
    dataField: "regular_exact_hours",
    text: "Regular Hours",
    sort: true,
  },
  {
    dataField: "overtime_exact_hours",
    text: "Overtime Hours",
    sort: true,
  },
  {
    dataField: "facility_regular_charges_table",
    text: "Facility Regular Charges",
    sort: true,
  },
  {
    dataField: "facility_overtime_charges_table",
    text: "Facility Overtime Charges",
    sort: true,
  },
  {
    dataField: "nurse_regular_charges_table",
    text: "Nurse Regular Charges",
    sort: true,
  },
  {
    dataField: "nurse_overtime_charges_table",
    text: "Nurse Overtime Charges",
    sort: true,
  },
  {
    dataField: "facility_total_charges_table",
    text: "Facility Bill",
    sort: true,
  },
  {
    dataField: "nurse_total_charges_table",
    text: "Nurse Bill",
    sort: true,
  },
  {
    dataField: "margin",
    text: "Caregigs Margin",
    sort: true,
  },
  {
    dataField: "formatted_status",
    text: "Status",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <span>
        <Badge
          pill
          className={`badge ${
            cellContent === "REQUESTED"
              ? "bg-warning"
              : cellContent === "APPROVED" ||
                (row.is_confirmed &&
                  !["CANCELLED", "REJECTED"].includes(cellContent))
              ? "bg-success"
              : "bg-danger"
          } font-size-11 m-2 p-2`}
        >
          {formatHighlighter(cellContent, searchKey, filterValue)}
        </Badge>
      </span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    align: "center",
    headerAlign: "center",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <Dropdown
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        <Dropdown.Item onClick={() => onClockClick(row)}>
          Edit Shift
        </Dropdown.Item>
        {activeTab !== "cancelled_shifts" && (
          <Dropdown.Item onClick={() => handleCancelShift(row)}>
            Cancel Shift
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => handleDeleteShift(row)}>
          Delete Shift
        </Dropdown.Item>
        {activeTab === "cancelled_shifts" && row.cancellation_reason != null && (
          <>
            <Dropdown.Item onClick={() => onReassignShift(row)}>
              Reassign Shift
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNotifyCancelShift(row)}>
              Notify Facility
            </Dropdown.Item>
          </>
        )}
      </Dropdown>
    ),
  },
];
export const BuildJobRequestSearchQuery = (
  value: string,
  facility: string | null
) => {
  if (value.length >= 3) {
    if (facility) {
      const conditionTemp = {
        _and: [
          {
            _or: [
              {
                job_opening: { createdby: { _eq: facility } },
              },
              {
                invitation: { createdby: { _eq: facility } },
              },
            ],
          },
          {
            _or: [
              {
                customer: {
                  _or: [
                    { firstname: { _ilike: `%${value}%` } },
                    { lastname: { _ilike: `%${value}%` } },
                  ],
                },
              },
              {
                job_opening: {
                  _or: [
                    {
                      position: { name: { _ilike: `%${value}%` } },
                    },
                    {
                      location: { name: { _ilike: `%${value}%` } },
                    },
                    {
                      organisation: { name: { _ilike: `%${value}%` } },
                    },
                  ],
                },
              },
              {
                invitation: {
                  _or: [
                    {
                      position: { name: { _ilike: `%${value}%` } },
                    },
                    {
                      location: { name: { _ilike: `%${value}%` } },
                    },
                    {
                      organisation: { name: { _ilike: `%${value}%` } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      };
      return conditionTemp;
    } else {
      const conditionTemp = {
        _or: [
          {
            customer: {
              _or: [
                { firstname: { _ilike: `%${value}%` } },
                { lastname: { _ilike: `%${value}%` } },
              ],
            },
          },
          {
            job_opening: {
              _or: [
                {
                  position: { name: { _ilike: `%${value}%` } },
                },
                {
                  location: { name: { _ilike: `%${value}%` } },
                },
                {
                  organisation: { name: { _ilike: `%${value}%` } },
                },
              ],
            },
          },
          {
            invitation: {
              _or: [
                {
                  position: { name: { _ilike: `%${value}%` } },
                },
                {
                  location: { name: { _ilike: `%${value}%` } },
                },
                {
                  organisation: { name: { _ilike: `%${value}%` } },
                },
              ],
            },
          },
        ],
      };
      return conditionTemp;
    }
  } else {
    const conditionTemp = {};
    // if (queryCondition !== null) {
    //   conditionTemp = { ...queryCondition };
    // }
    return conditionTemp;
  }
};
export const BuildJobRequestFilterQuery = (
  filterValue: any,
  facility: string | null
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    // if (queryCondition !== null) {
    //   conditionTemp = { ...queryCondition };
    // }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          _or: [
            {
              _and: [
                { job_opening: { job_date: { _gte: job_date_from } } },
                { job_opening: { job_date: { _lte: job_date_till } } },
              ],
            },
            {
              _and: [
                { invitation: { job_date: { _gte: job_date_from } } },
                { invitation: { job_date: { _lte: job_date_till } } },
              ],
            },
          ],
        };
      }
    }
    let position_id = undefined,
      location_id = undefined,
      flag = false;

    if (filterValue?.hasOwnProperty("position")) {
      position_id = { _in: filterValue.position };
      flag = true;
    }
    if (filterValue?.hasOwnProperty("location")) {
      location_id = { _in: filterValue.location };
      flag = true;
    }
    if (flag) {
      conditionTemp = {
        _and: [
          conditionTemp,
          {
            _or: [
              {
                job_opening: { position_id, location_id },
              },
              {
                invitation: { position_id, location_id },
              },
            ],
          },
        ],
      };
    }
    if (facility) {
      return {
        _and: [
          {
            _or: [
              {
                job_opening: { createdby: { _eq: facility } },
              },
              {
                invitation: { createdby: { _eq: facility } },
              },
            ],
          },
          conditionTemp,
        ],
      };
    } else {
      return conditionTemp;
    }
  } else {
    const conditionTemp = {};
    // if (queryCondition !== null) {
    //   conditionTemp = { ...queryCondition };
    // }
    return conditionTemp;
  }
};
export const generateFilterQueryFromURL = (search: string) => {
  const position = new URLSearchParams(search).get("position");
  const location = new URLSearchParams(search).get("location");
  const job_date = new URLSearchParams(search).get("job_date");
  const obj = {
    position: position?.split(","),
    location: location?.split(","),
    job_date: job_date?.split(","),
  };
  return obj;
};

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Card, CardBody, Col, CardTitle } from "reactstrap";
import MiniCards from "../Marketplace/ContactsProfile/mini-card";
import { map } from "lodash";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import * as moment from "moment";
import { Loader, Placeholder } from "rsuite";
import uniqolor from 'uniqolor';

//components
import StackedColumnChart from "components/dashboard/StackedColumnChart";
import PieChart from "components/dashboard/PieChart";
import LatestOTPTable from "components/dashboard/latestOTPs/LatestOTPTable";

//GraphQL
import { useQuery } from "@apollo/client";
import { DashboardAnalytics, CustomerAggregateByNurseTypes, CustomerAggregateByStatus, CustomerAggregateByNurseTypesForMassachusetts, CustomerAggregateByStatusForMassachusetts } from "gql/dashboard/query";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getCustomerStatsApi, getLicenseStateAggregateApi } from "toolkit/admin/admin.api";
import { authState } from "toolkit/auth/auth.slice";

const Dashboard = () => {
  const { Graph } = Placeholder
  const dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authState);
  const { loading, data } = useQuery(DashboardAnalytics);
  const { loading: nurseTypeAggregateLoading, data: nurseTypeAggregateData } = useQuery(CustomerAggregateByNurseTypes);
  const { loading: nurseStatusAggregateLoading, data: nurseStatusAggregateData } = useQuery(CustomerAggregateByStatus);
  const { loading: nurseTypeAggregateMassaLoading, data: nurseTypeAggregateMassaData } = useQuery(CustomerAggregateByNurseTypesForMassachusetts);
  const { loading: nurseStatusAggregateMassaLoading, data: nurseStatusAggregateMassaData } = useQuery(CustomerAggregateByStatusForMassachusetts);


  const [miniCards, setMiniCards] = useState([
    {
      title: "Total Nurses",
      iconClass: "bx-check-circle",
      text: "0",
      link: "nurses",
    },
    {
      title: "Total Facilities",
      iconClass: "bx bxs-building",
      text: "0",
      link: "facilities",
    },
    {
      title: "Pending Interviews",
      iconClass: "bx-hourglass",
      text: "0",
      link: "calendar",
    },
    {
      title: "Total Shifts",
      iconClass: "bx-package",
      text: "0",
      link: "all-shifts",
    },
  ]);

  const [periodData, setPeriodData] = useState({
    name: "Total Nurses",
    data: [],
  });
  const [xAxisData, setXAxisData] = useState([]);

  const [periodDateRange, setPeriodDateRange] = useState({
    startDate: moment().subtract(7, "d").format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
  });

  const [nurseTypeAggregate, setNurseTypeAggregate] = useState([])
  const [nurseTypeMassaAggregate, setNurseTypeMassaAggregate] = useState([])
  const [nurseStatusAggregate, setNurseStatusAggregate] = useState([])
  const [nurseStatusMassaAggregate, setNurseStatusMassaAggregate] = useState([])
  const [licenseStates, setLicenseStates] = useState({
    series: [],
    labels: [],
    colors: []
  })

  const handleChangePeriodDate = (action) => {
    if (action === "prev") {
      let endDate = periodDateRange.startDate;
      let startDate = moment(periodDateRange.startDate, "DD/MM/YYYY")
        .subtract(7, "d")
        .format("DD/MM/YYYY");
      setPeriodDateRange({ startDate, endDate });
    } else {
      let endDate = moment(periodDateRange.endDate, "DD/MM/YYYY")
        .add(7, "d")
        .format("DD/MM/YYYY");
      let startDate = periodDateRange.endDate;
      setPeriodDateRange({ startDate, endDate });
    }
  };
  const fetchRevenueData = async () => {
    const resp = await dispatch(
      getCustomerStatsApi({
        formData: {
          from_date: periodDateRange.startDate,
          to_date: periodDateRange.endDate,
        },
        token,
      })
    ).catch((err) => {
      console.log(err);
    });
    if (resp.type === "admin/get-customer-stats/fulfilled") {
      if (resp.payload?.length) {
        const totalArray = resp.payload.map((item) => item.count);
        const daysArray = resp.payload.map(
          (item) => `${moment(item.date).format("Do MMM")} (${item.day})`
        );
        setPeriodData({
          name: "Total Nurses",
          data: totalArray,
        });
        setXAxisData(daysArray);
      } else {
        setXAxisData(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon"]);
      }
    } else {
      setXAxisData(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon"]);
    }
  };
  const fetchLicenseStates = async() =>{
    const resp = await dispatch(getLicenseStateAggregateApi({token}))
    .catch((err) => {
      console.log(err);
    });
    if (resp.type === "admin/get-license-state-aggregate/fulfilled") {
      if (resp.payload?.length) {
        setLicenseStates({
          series: resp.payload.map((val) => parseInt(val.count)),
          labels: resp.payload.map((val) => val.license_state),
          colors: resp.payload.map((val) => uniqolor(val.license_state).color)
        })
      }
    }
  }

  useEffect(() => {
    if (data) {
      let _minicards = [...miniCards];
      _minicards[0].text = String(
        data?.customers_aggregate?.aggregate?.count || 0
      );
       _minicards[0].tooltipText = String(
         data?.archived_nurses?.aggregate?.count || 0
       );
      _minicards[1].text = String(data?.users_aggregate?.aggregate?.count || 0);
      _minicards[2].text = String(
        data?.pending_interviews?.aggregate?.count || 0
      );
      _minicards[3].text = String(
        data?.job_requests_aggregate?.aggregate?.count || 0
      );
      setMiniCards(_minicards);
    }
  }, [data]);
  useEffect(() => {
    fetchRevenueData();
  }, [periodDateRange]);
  useEffect(()=>{
    if(nurseTypeAggregateData){
      let LPNCount = nurseTypeAggregateData?.LPN_aggregate?.aggregate?.count || 0
      let CNACount = nurseTypeAggregateData?.CNA_aggregate?.aggregate?.count || 0
      let RNCount = nurseTypeAggregateData?.RN_aggregate?.aggregate?.count || 0
      setNurseTypeAggregate([LPNCount, CNACount, RNCount])
    }
  }, [nurseTypeAggregateData])
  useEffect(()=>{
    if(nurseTypeAggregateMassaData){
      let LPNCount = nurseTypeAggregateMassaData?.LPN_aggregate?.aggregate?.count || 0
      let CNACount = nurseTypeAggregateMassaData?.CNA_aggregate?.aggregate?.count || 0
      let RNCount = nurseTypeAggregateMassaData?.RN_aggregate?.aggregate?.count || 0
      setNurseTypeMassaAggregate([LPNCount, CNACount, RNCount])
    }
  }, [nurseTypeAggregateMassaData])
  useEffect(()=>{
    if(nurseStatusAggregateData){
      let verifiedCount = nurseStatusAggregateData?.verified_aggregate?.aggregate?.count || 0
      let conditionallyVerifiedCount = nurseStatusAggregateData?.conditionally_verified_aggregate?.aggregate?.count || 0
      let documentsSubmittedCount = nurseStatusAggregateData?.documents_submitted_aggregate?.aggregate?.count || 0
      let selectedCount = nurseStatusAggregateData?.selected_aggregate?.aggregate?.count || 0
      let tobeInterviewedCount = nurseStatusAggregateData?.to_be_interviewed_aggregate?.aggregate?.count || 0
      let signedUpCount = nurseStatusAggregateData?.signed_up_aggregate?.aggregate?.count || 0
      let rejectedCount = nurseStatusAggregateData?.rejected_aggregate?.aggregate?.count || 0
      setNurseStatusAggregate([verifiedCount, conditionallyVerifiedCount, documentsSubmittedCount, selectedCount, tobeInterviewedCount, signedUpCount, rejectedCount])
    }
  }, [nurseStatusAggregateData])
  useEffect(()=>{
    if(nurseStatusAggregateMassaData){
      let verifiedCount = nurseStatusAggregateMassaData?.verified_aggregate?.aggregate?.count || 0
      let conditionallyVerifiedCount = nurseStatusAggregateMassaData?.conditionally_verified_aggregate?.aggregate?.count || 0
      let documentsSubmittedCount = nurseStatusAggregateMassaData?.documents_submitted_aggregate?.aggregate?.count || 0
      let selectedCount = nurseStatusAggregateMassaData?.selected_aggregate?.aggregate?.count || 0
      let tobeInterviewedCount = nurseStatusAggregateMassaData?.to_be_interviewed_aggregate?.aggregate?.count || 0
      let signedUpCount = nurseStatusAggregateMassaData?.signed_up_aggregate?.aggregate?.count || 0
      let rejectedCount = nurseStatusAggregateMassaData?.rejected_aggregate?.aggregate?.count || 0
      setNurseStatusMassaAggregate([verifiedCount, conditionallyVerifiedCount, documentsSubmittedCount, selectedCount, tobeInterviewedCount, signedUpCount, rejectedCount])
    }
  }, [nurseStatusAggregateMassaData])
  useEffect(()=>{
    fetchLicenseStates()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content AppBody">
        <MetaTags>
          <title>
            Dashboard | Caregigs - On Demand Healthcare Professionals
          </title>
        </MetaTags>
        <div className="">
          <h4 className="mb-2">Dashboard</h4>
          <Row>
            {map(miniCards, (card, key) => (
              <MiniCards
                title={card.title}
                text={loading ? <Loader /> : card.text}
                iconClass={card.iconClass}
                key={"_card_" + key}
                link={card.link}
                tooltipText={loading ? <Loader /> : card.tooltipText}
              />
            ))}
          </Row>

          <Card className="overflow-hidden p-2">
            <CardBody className="p-3 bg-">
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">New Registration Over Time</h4>
                <div className="ms-auto">
                  <ul className="nav nav-pills">
                    <li className="nav-item rev-date-range">
                      <FiChevronLeft
                        style={{ marginRight: 10, cursor: "pointer" }}
                        onClick={() => handleChangePeriodDate("prev")}
                      />
                      {moment(periodDateRange.startDate, "DD/MM/YYYY").format(
                        "DD MMM"
                      )}{" "}
                      -{" "}
                      {moment(periodDateRange.endDate, "DD/MM/YYYY").format(
                        "DD MMM"
                      )}
                      {periodDateRange.endDate !==
                        moment().format("DD/MM/YYYY") && (
                        <FiChevronRight
                          style={{ marginLeft: 10, cursor: "pointer" }}
                          onClick={() => handleChangePeriodDate("next")}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="clearfix"></div>
              <StackedColumnChart
                periodData={periodData}
                xAxisData={xAxisData}
              />
            </CardBody>
          </Card>
          <Row>
            <Col lg={3}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">Nurse types</CardTitle>
                  {!nurseTypeAggregateLoading &&
                  nurseTypeAggregate.length > 0 ? (
                    <PieChart
                      series={nurseTypeAggregate}
                      labels={["LPN", "CNA", "RN"]}
                      colors={["#556ee6", "#F7A76C", "#EC7272"]}
                      type={"Speciality"}
                      chartType={"donut"}
                    />
                  ) : (
                    <Graph height={370} active />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">Status</CardTitle>
                  {!nurseStatusAggregateLoading &&
                  nurseStatusAggregate.length > 0 ? (
                    <PieChart
                      series={nurseStatusAggregate}
                      labels={[
                        "Verified",
                        "Conditionally Verified",
                        "Documents Submitted",
                        "Selected",
                        "To be Interviewed",
                        "Signed Up",
                        "Rejected"
                      ]}
                      colors={[
                        "#34c38f",
                        "#808080",
                        "#f1b34c",
                        "#019ef7",
                        "#ec7272",
                        "#c24442",
                        "#F7A76C"
                      ]}
                      type={"Status"}
                      chartType={"donut"}
                    />
                  ) : (
                    <Graph height={370} active />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">Massachusetts Nurse types</CardTitle>
                  {!nurseTypeAggregateMassaLoading &&
                  nurseTypeMassaAggregate.length > 0 ? (
                    <PieChart
                      series={nurseTypeMassaAggregate}
                      labels={["LPN", "CNA", "RN"]}
                      colors={["#556ee6", "#F7A76C", "#EC7272"]}
                      type={"SpecialityMA"}
                      chartType={"donut"}
                    />
                  ) : (
                    <Graph height={370} active />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">Massachusetts Nurses Status</CardTitle>
                  {!nurseStatusAggregateMassaLoading &&
                  nurseStatusMassaAggregate.length > 0 ? (
                    <PieChart
                      series={nurseStatusMassaAggregate}
                      labels={[
                        "Verified",
                        "Conditionally Verified",
                        "Documents Submitted",
                        "Selected",
                        "To be Interviewed",
                        "Signed Up",
                        "Rejected"
                      ]}
                      colors={[
                        "#34c38f",
                        "#808080",
                        "#f1b34c",
                        "#019ef7",
                        "#ec7272",
                        "#c24442",
                        "#F7A76C"
                      ]}
                      type={"StatusMA"}
                      chartType={"donut"}
                    />
                  ) : (
                    <Graph height={370} active />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">License state</CardTitle>
                  {licenseStates.series.length > 0 ? (
                    <PieChart
                      series={licenseStates.series}
                      labels={licenseStates.labels}
                      colors={licenseStates.colors}
                      height={"450"}
                      type={"State"}
                      chartType={"pie"}
                    />
                  ) : (
                    <Graph height={370} active />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden p-2">
                <CardBody className="p-3 bg-">
                  <CardTitle className="mb-4">Latest OTPs</CardTitle>
                  <LatestOTPTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Dropdown } from "rsuite";
import { GoKebabVertical } from "react-icons/go";
import { Badge, Button } from "reactstrap";

//types
import jobOpeningType from "./openings.types";
export const FormatData = (employees: jobOpeningType[]) => {
  const _employees = employees.map((elem: jobOpeningType) => {
    return {
      id: elem.id,
      name: elem.name || "N/A",
      organization_id: elem?.organization_id,
      location_name: elem.location?.name || "N/A",
      location_id: elem.location?.id,
      isactive: elem.isactive,
      shift_time: `${elem.position?.start_time} - ${elem.position?.end_time}`,
      position_start_time: elem?.position?.start_time,
      position_end_time: elem?.position?.end_time,
      job_date: elem.job_date,
      created_at: elem.created_at,
      position_name: elem.position?.name || "N/A",
      position_id: elem.position?.id,
      fee_amount: elem.fee_amount,
      currency: elem.currency,
      fee_period: elem.fee_period,
      filled: elem.filled,
      total_requests: elem.job_requests_aggregate?.aggregate?.count || 0,
      is_confirmed: elem.job_requests?.length > 0 ? true : false,
      organisation_name: elem.user?.name || "N/A",
      createdBy: elem.createdby,
      maple_shift_id: elem.maple_shift_id,
      job_type: elem.position?.type,
      maple_worker_requirement: elem.position?.maple_worker_requirement,
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const JobOpeningColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  onassignNurse: (shift: any) => void,
  onEditClick: (shift: any) => void,
  handleDelete: (shift: any) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    text: "Job Type",
    dataField: "job_type",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    text: "Maple Worker Requirement",
    dataField: "maple_worker_requirement",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "position_name",
    text: "Position",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "location_name",
    text: "Location",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    text: "Facility Name",
    dataField: "organisation_name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <Link
        to={`/facilities-profile/${row.facility_id}`}
        className="text-primary"
      >
        {formatHighlighter(cellContent, searchKey, filterValue)}
      </Link>
    ),
  },
  {
    dataField: "job_date",
    text: "Job Date",
    sort: true,
    formatter: (cellContent: Date, user: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "shift_time",
    text: "Shift Time",
    sort: true,
    formatter: (cellContent: string, user: any) => <span>{cellContent}</span>,
  },
  {
    dataField: "fee_amount",
    text: "Rate",
    sort: true,
    hidden: true,
    formatter: (cellContent: string, row: any) => (
      <span>
        {cellContent} {row.currency}
      </span>
    ),
  },
  {
    dataField: "filled",
    text: "Status",
    sort: true,
    formatter: (cellContent: boolean, row: any) => (
      // <span className={cellContent ? "text-warning" : "text-success"}>{cellContent ? "Open" : "Filled"}</span>
      <span>
        <Badge
          pill
          to="#"
          className={`badge ${
            cellContent ? "bg-success" : "bg-warning"
          } font-size-11 m-2 p-2`}
        >
          {cellContent ? (row.is_confirmed ? "Confirmed" : "Approved") : "Open"}
        </Badge>{" "}
      </span>
    ),
  },
  {
    dataField: "created_at",
    text: "Created On",
    sort: true,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    align: "center",
    headerAlign: "center",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <Dropdown
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        <Dropdown.Item onClick={() => onassignNurse(row)}>
          Assign Nurse
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onEditClick(row)}>
          Update Shift
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleDelete(row)}>
          Delete Shift
        </Dropdown.Item>
      </Dropdown>
    ),
  },
];
export const BuildJobOpeningSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { position: { name: { _ilike: `%${value}%` } } },
        { location: { name: { _ilike: `%${value}%` } } },
        // { status: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildJobOpeningFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      if (filterValue.status === "open") {
        conditionTemp = {
          ...conditionTemp,
          filled: { _eq: false },
        };
      } else if (filterValue.status === "confirmed") {
        conditionTemp = {
          ...conditionTemp,
          is_confirmed: { _eq: true },
          filled: { _eq: true },
        };
      } else if (filterValue.status === "approved") {
        conditionTemp = {
          ...conditionTemp,
          is_confirmed: { _eq: false },
          filled: { _eq: true },
        };
      }
    }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { job_date: { _gte: job_date_from } },
            { job_date: { _lte: job_date_till } },
          ],
        };
      }
    }
    if (filterValue?.hasOwnProperty("position")) {
      conditionTemp = {
        ...conditionTemp,
        position_id: { _in: filterValue.position },
      };
    }
    if (filterValue?.hasOwnProperty("location")) {
      conditionTemp = {
        ...conditionTemp,
        location_id: { _in: filterValue.location },
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";

import GigshiftLogo from "assets/images/onboarding/Gigshift.png";
import BannerContent from "constants/bannerContent.json";

import OnboardingBanner from "components/Onboarding/OnboardingBanner";

import OpeningsTable from "./OpeningsTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import {
  onboardingState,
  handleSetGigShiftsBanner,
} from "toolkit/onboarding/onboarding.slice";

const JobOpeningList: React.FC<{
  //
}> = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { user },
  } = useSelector(authState);
  const { showGigShiftsBanner } = useSelector(onboardingState);

  return (
    <React.Fragment>
      <div className="page-content GigShiftPage AppBody">
        <MetaTags>
          <title>Gig Shifts | Caregigs</title>
        </MetaTags>
        <div className="GigShiftPage-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Shift Details"
            links={[
              { title: "Home", link: "/" },
              { title: "Shift Details", link: "#" },
            ]}
          />

          {/* onboarding */}
          {showGigShiftsBanner && (
            <OnboardingBanner
              media={GigshiftLogo}
              title={BannerContent.gigshift.title}
              subTitle={BannerContent.gigshift.subtitle}
              color={"rgb(249 255 213)"}
              handleClose={() => dispatch(handleSetGigShiftsBanner(false))}
            />
          )}
          <OpeningsTable queryCondition={null} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(JobOpeningList);

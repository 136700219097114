import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

//Helpers
import isValidUrl from "helpers/url_checker";

//components
import AutoComplete from "components/Common/AutoComplete";

const MarketplaceMapView: React.FC<{
  customerList: any[];
  facilityList: any[];
  onClickProfile: (customer: any) => void;
}> = ({ customerList, facilityList, onClickProfile }) => {
  const [address, setAddress] = useState<string>("");
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const fetchCurrentLocation = async () => {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude,
            lng = position.coords.longitude;
          resolve({
            lat,
            lng,
          });
        },
        (err) => reject(err)
      );
    });
  };
  async function initialize() {
    const center: any = await fetchCurrentLocation();
    const mapOptions = {
      zoom: 14,
      center: new (window as any).google.maps.LatLng(
        parseFloat(center.lat),
        parseFloat(center.lng)
      ),
      mapTypeId: (window as any).google.maps.MapTypeId.ROADMAP,
      draggable: true,
    };
    const map = new (window as any).google.maps.Map(
      document.getElementById("map_canvas"),
      mapOptions
    );
    setMapInstance(map);
  }
  const handleSetAddress = (address: any) => {
    let lat = 0,
      lng = 0;
    if (address.geometry) {
      lat = address.geometry.location.lat();
      lng = address.geometry.location.lng();
    }
    setAddress(address.formatted_address);
    mapInstance?.setCenter({
      lat,
      lng,
    });
  };
  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(customerList) &&
      mapInstance &&
      Array.isArray(facilityList)
    ) {
      const _markers: any[] = [];
      if (markers.length) {
        markers.map((_marker) => {
          _marker.setMap(null);
        });
      }
      customerList.map((customer) => {
        const image = "/avatar.jpg";
        // if (isValidUrl(customer.profile_picture)) {
        //   image = customer.profile_picture;
        // }
        const marker = new (window as any).CustomMarker(
          new (window as any).google.maps.LatLng(
            parseFloat(customer.lat),
            parseFloat(customer.lng)
          ),
          mapInstance,
          {
            ...customer,
            photo_url: image,
            marker_id: String(Date.now()),
            callback: () => {
              onClickProfile({
                id: customer.id,
                profile_picture: customer.profile_picture,
                firstname: customer.firstname,
                lastname: customer.lastname,
                preferred_rate: customer.preferred_rate || 0,
                profession: customer.profession,
                tags: customer.tags,
                pendingInvitations:
                  customer.pendingInvitations?.aggregate?.count || 0,
                completedShifts:
                  customer.completedShifts?.aggregate?.count || 0,
                futureShifts: customer.futureShifts?.aggregate?.count || 0,
                questionnaire: customer.questionnaire || {},
                contactno1: customer.contactno1,
                email: customer.email,
                address: customer.address || "N/A",
              });
            },
          }
        );
        _markers.push(marker);
      });
      facilityList.map((customer) => {
        const image = "/facilityImg.png";
        // if (isValidUrl(customer.profile_picture)) {
        //   image = customer.profile_picture;
        // }
        const marker = new (window as any).CustomMarker(
          new (window as any).google.maps.LatLng(
            parseFloat(customer.lat),
            parseFloat(customer.lng)
          ),
          mapInstance,
          {
            ...customer,
            photo_url: image,
            marker_id: String(Date.now()),
            callback: () => {
              onClickProfile({
                id: customer.id,
                name: customer.name || "N/A",
                address: customer.address || "N/A",
              });
            },
          }
        );
        _markers.push(marker);
      });
      setMarkers(_markers);
    }
  }, [customerList, mapInstance, facilityList]);

  return (
    <Row>
      {/* <AutoComplete
        onSelect={handleSetAddress}
        name="address"
        onChange={(e) => setAddress(e.target.value)}
        onBlur={() => {
          //
        }}
        value={address}
        invalid={false}
        placeholder={"Search Address"}
      /> */}
      {/* <div className="mb-3" /> */}
      <div id="map_canvas"></div>
    </Row>
  );
};
export default MarketplaceMapView;

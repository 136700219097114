import React, { useEffect, useState, useRef } from "react";
import {
	Col,
	Form,
	FormGroup,
	Input,
	Row,
	Modal,
	Spinner
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";

// datatable related plugins
import Swal from "sweetalert2";
import BootstrapTable from 'react-bootstrap-table-next';
import { Pagination } from 'rsuite';
import "../../../assets/scss/custom.scss"
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit';
import "../datatables.scss"

//Functions
import { PositionColumns, FormatData, BuildPositionSearchQuery, BuildPositionFilterQuery } from "./positions.functions"

// icons
import { FiPlus, FiDownloadCloud } from "react-icons/fi";


//components
import PositionModal from "components/positions/PositionModal";

//filters
import PositionFilter from "components/Filters/positions/PositionFilter";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client"
import { FetchPositions } from "../../../gql/positions/query"
import { UpdatePosition } from "gql/positions/mutation";

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";
import positionType from "./positions.types";

const Positions = () => {
	const history = useHistory();
    const params: any = useParams();
	const { ExportCSVButton } = CSVExport
	const { auth: { user } } = useSelector(authState)
	const initialCondition = {
		createdby: { _eq: user.role === "moderator" ?  params.id : user.id },
		active: {_eq: true}
	}
	const [modal_center, setmodal_center] = useState(false);
	const [filterValue, setFilterValue] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>(initialCondition)
	const [selectedPosition, setSelectedPosition] = useState<positionType | undefined>()
	const [getPositionList, { loading, data, refetch, error, called }] = useLazyQuery(FetchPositions, {
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const [UpdatePositionMutation] = useMutation(UpdatePosition)

	const defaultSorted: any = [];

	const onEditClick = (_position: positionType, isClone: boolean) => {
		setSelectedPosition(_position)
		if(!isClone){
			setIsEdit(true)
		}
		toggle()
	}
	const handleChangeStatus = (_position:positionType) => {
		Swal.fire({
			title: 'Are you sure want to delete this position?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async(result) => {
			if (result.isConfirmed) {
				try{
					await UpdatePositionMutation({
						variables: {
							id: _position.id,
							object: {
								active: false
							}
						}
					})
					refetch()
					Swal.fire('Position Deleted Successfully!', '', 'success')
				}catch(err){
					Swal.fire('Something went wrong!', '', 'error')
				}
				
			}
		})
	}
	const handleSearch = (value: string) => {
		const conditionTemp = BuildPositionSearchQuery(value, null)
		setQueryCondition({...initialCondition, ...conditionTemp})
	}

	const handleRefetch = () => {
		if (called) {
			refetch()
		} else {
			getPositionList()
		}
	}
	function toggle() {
		setModal(!modal);
	}
	useEffect(() => {
		if (user?.id) {
			getPositionList()
		}
	}, [user])
	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [searchKey])
	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildPositionFilterQuery(filterValue, null)
		setQueryCondition({...initialCondition, ...conditionTemp})
	}, [filterValue])
	return (
		<React.Fragment>
			{user.role === "provider" &&
			 <div>
				<Row className="mb-3">
					<Col xl={3} sm={6}>
						<div className="mt-2">
							<h5>Positions</h5>
						</div>
					</Col>
					<Col xl={9} sm={6}>
						<Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
							<div className="search-box mb-2 me-2">
								<div className="position-relative">
									<button 
										type="button" 
										className="btn btn-primary btn-sm"
										onClick={()=>setModal(true)}
									> <FiPlus  /> Add Position</button>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			 </div> 
			}
			<div>
				<ToolkitProvider
					keyField='id'
					columns={PositionColumns(searchKey, filterValue, history, handleChangeStatus, onEditClick)}
					data={FormatData(Array.isArray(data?.positions) ? data.positions : [])}
					bootstrap4
					exportCSV={{ onlyExportSelection: true, exportAll: true }}
				>
					{toolkitProps => (
						<React.Fragment>
							<Row>
								<>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box me-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<FormGroup>
															<Input
																id="exampleEmail"
																name="email"
																placeholder="Search positions"
																type="text"
																onChange={(e) => setSearchKey(e.target.value)}
																value={searchKey}
															/>
															<i className="bx bx-search-alt search-icon" />
														</FormGroup>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
													<ExportCSVButton
														{...toolkitProps.csvProps}
														style={{ border: "1px solid #74788d", marginRight: 10 }}
													> <FiDownloadCloud  />&nbsp;&nbsp; Export</ExportCSVButton>
													<PositionFilter
														onFilterChange={setFilterValue}
														activeFilters={["status", "created_at", "location", "area"]}
														filterQueryConditions={null}
													/>
												</div>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col sm="8">
											Total Positions: {data?.positions_aggregate?.aggregate?.count || 0}
										</Col>
									</Row>
									{loading && (
										<Col xl="12" className="text-center">
											<Spinner color="primary" />
										</Col>
									)}
									<Row>
										<Col xl="12">
											<div className="table-responsive">
												<BootstrapTable
													key={`${searchKey}_${JSON.stringify(filterValue)}`}
													bordered={false}
													striped={false}
													defaultSorted={defaultSorted}
													selectRow={{
														mode: 'checkbox',
														onSelectAll: (isSelected)=>{console.log(isSelected)}
													}}
													classes={
														"table align-middle table-nowrap table-check"
													}
													headerWrapperClasses={"thead-light"}
													{...toolkitProps.baseProps}
													noDataIndication={() => "No positions found!"}
												/>
											</div>
										</Col>
									</Row>
								</>
							</Row>
							<Row className="align-items-md-center mt-30">
								<Col className="inner-custom-pagination d-flex">
									<div className="d-inline mt-3 w-100">
										<Pagination
											total={data?.positions_aggregate?.aggregate?.count || 0}
											limit={PageData.limit}
											activePage={PageData.pageNo}
											onChangePage={(pageNo) => setPageData({ ...PageData, pageNo })}
											limitOptions={[30, 50, 100]}
											layout={['limit', 'pager']}
											onChangeLimit={(limit) => setPageData({ ...PageData, limit })}
											className="d-flex justify-content-between"
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
			</div>
			<PositionModal 
				isEdit={isEdit}
				setIsEdit={setIsEdit}
				toggle={toggle}
				modal={modal}
				refetch={handleRefetch}
				selectedPosition={selectedPosition}
				role={user.role}
			/>
		</React.Fragment>
	)
}

export default Positions

import { gql } from "@apollo/client"
export const UpdateDocument =
  gql`mutation UpdateDocument(\$id:uuid!, \$object: documents_set_input) {
    update_documents_by_pk(pk_columns: {id: \$id}, _set: \$object) {
        id
      }
    }`
export const InsertDocumentOne =
  gql`mutation InsertDocumentOne(\$object: documents_insert_input!) {
  insert_documents_one(object: \$object) {
    id
  }
}`
export const DeleteDocument =
gql`mutation DeleteDocument(\$id:uuid!) {
  delete_documents_by_pk(id: \$id) {
    id
  }
}`
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";
import { SelectPicker, Checkbox } from "rsuite";
import InputColor from "react-input-color";
import SketchColorPicker from "components/Common/SketchColorPicker";

// icons
import { FiCheck } from "react-icons/fi";

import { numbers, upperCaseLetters, lowerCaseLetters, specialCharacters } from "constants/characters";

//types
import employeeType, {
  employeeInputType,
} from "pages/Settings/employees/employees.types";

import SelectPickerPaginate from "../Filters/SelectPickerPaginate";

//graphql
import { FetchLocationsForFilter } from "gql/locations/query";
import { InsertEmployeeOne, UpdateEmployee } from "gql/employees/mutation";
import { useMutation, useApolloClient } from "@apollo/client";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { profileState } from "toolkit/profile/profile.slice";

//constants
import nurseTypes from "constants/nurseTypes";

//helpers
import { uploadImage } from "helpers";

const initialState: employeeInputType = {
  firstname: "",
  lastname: "",
  prefix: "",
  profession: "",
  isactive: true,
  email: "",
  contactno1: "",
  contactno2: "",
  location_id: "",
  type: "",
  is_supervisor: false,
  // role: ""
};
const EmployeeModal: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  refetch: () => void;
  selectedEmployee: any;
}> = ({ isEdit, setIsEdit, toggle, modal, refetch, selectedEmployee }) => {
  const client = useApolloClient();
  const { profile } = useSelector(profileState);
  const {
    auth: { user },
  } = useSelector(authState);
  const [CreateEmployeeMutation, { loading, error }] =
    useMutation(InsertEmployeeOne);
  const [UpdateEmployeeMutation, { loading: loadingUpdateEmployee }] =
    useMutation(UpdateEmployee);
  const [emp, setEmp] = useState<employeeInputType>(initialState);
  const [locationId, setLocationId] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [customState, setCustomState] = useState<any>({
    text_color: "#000",
    background_color: "#fff",
  });
  const [file, setFile] = useState<any>(null);
  const [password, setPassword] = useState("");


  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (emp && emp.firstname) || "",
      lastname: (emp && emp.lastname) || "",
      prefix: (emp && emp.prefix) || "",
      profession: (emp && emp.profession) || "",
      email: (emp && emp.email) || "",
      contactno1: (emp && emp.contactno1) || "",
      contactno2: (emp && emp.contactno2) || "",
      is_supervisor: (emp && emp.is_supervisor) || false,
      // role: (emp && emp.role) || '',
      // type: (emp && emp.type) || '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter First Name"),
      lastname: Yup.string().required("Please Enter Last Name"),
      // prefix: Yup.string().required("Please Enter Prefix"),
      // profession: Yup.string().required("Please Enter Profession"),
      email: Yup.string().required("Please Enter Email"),
      contactno1: Yup.string()
        .length(10, "Phone No. Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Please Enter Primary Phone No."),
      contactno2: Yup.string()
        .test(
          "contactno2_test",
          "Secondary phone must be different than primary phone",
          function (value) {
            const { contactno1 } = this.parent;
            return contactno1 !== value;
          }
        )
        .length(10, "Phone No. Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        ),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        let avatar = selectedEmployee?.avatar;
        if (file) {
          const formData = new FormData();
          formData.append("uploads", file);
          avatar = await uploadImage(formData);
        }
        await UpdateEmployeeMutation({
          variables: {
            id: selectedEmployee?.id,
            object: {
              firstname: values.firstname,
              lastname: values.lastname,
              organisation_id: profile.organisation_id,
              // prefix: values.prefix,
              // profession: values.profession,
              isactive: true,
              email: values.email,
              contactno1: String(values.contactno1),
              contactno2: String(values.contactno2),
              location_id: locationId,
              type: type || null,
              text_color: customState.text_color,
              background_color: customState.background_color,
              role: values.is_supervisor ? "supervisor" : "employee",
              is_supervisor: values.is_supervisor,
              avatar,
            },
          },
        });
        setIsEdit(false);
        // Swal.fire("Staff edited successfully", "", "success")
        showAlert({
          title: "Updated!",
          message: "Staff edited successfully",
          type: "success",
        });
        handleClose();
        refetch();
      } else {
        let avatar: null | string = null;
        if (file) {
          const formData = new FormData();
          formData.append("uploads", file);
          avatar = await uploadImage(formData);
        }
        CreateEmployeeMutation({
          variables: {
            object: {
              firstname: values.firstname,
              lastname: values.lastname,
              // prefix: values.prefix,
              // profession: values.profession,
              organisation_id: profile.organisation_id,
              isactive: true,
              email: values.email,
              contactno1: String(values.contactno1),
              contactno2: String(values.contactno2),
              location_id: locationId,
              type: type || null,
              text_color: customState.text_color,
              background_color: customState.background_color,
              role: "employee",
              is_supervisor: values.is_supervisor,
              avatar,
            },
          },
        })
          .then(() => {
            Swal.fire("Staff added successfully", "", "success");
            client.refetchQueries({
              include: ["EmployeesFilter"],
            });
            handleClose();
            refetch();
          })
          .catch((err) => {
            Swal.fire("Something went wrong", "", "warning");
          });
      }
    },
  });
  const handleClose = () => {
    toggle();
    validation.resetForm();
    setEmp(initialState);
    setIsEdit(false);
    setCustomState({
      text_color: "#000",
      background_color: "#fff",
    });
    setLocationId("");
  };

  useEffect(() => {
    if (isEdit && selectedEmployee?.id) {
      setEmp({
        ...emp,
        firstname: selectedEmployee.firstname,
        lastname: selectedEmployee.lastname,
        prefix: selectedEmployee.prefix,
        profession: selectedEmployee.profession,
        email: selectedEmployee.email,
        contactno1: String(selectedEmployee.contactno1),
        contactno2: String(selectedEmployee.contactno2),
        location_id: selectedEmployee.location_id,
        type: selectedEmployee.type,
        is_supervisor: selectedEmployee.is_supervisor,
      });
      setType(selectedEmployee.type);
      setLocationId(selectedEmployee.location_id);
      setCustomState({
        text_color: selectedEmployee.text_color,
        background_color: selectedEmployee.background_color,
      });
    }
  }, [selectedEmployee, isEdit]);

  const handleGeneratePassword = () => {
    let characterList = "";

       characterList = characterList + lowerCaseLetters + upperCaseLetters + numbers + specialCharacters;
    setPassword(createPassword(characterList));
  };
  const createPassword = (characterList: string) => {
    let password = "";
    const characterListLength = characterList.length;

    for (let i = 0; i <= 10; i++) {
      const characterIndex = Math.round(Math.random() * characterListLength);
      password = password + characterList.charAt(characterIndex);
    }
    return password;
  };

  return (
    <Modal
      className="customModal"
      isOpen={modal}
      toggle={handleClose}
      size="md"
      backdrop="static"
    >
      <ModalHeader toggle={handleClose} tag="h4">
        {!!isEdit ? "Edit Employee" : "Add Employee"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <div className="mb-3">
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="firstname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={
                        validation.touched.firstname &&
                        validation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstname &&
                    validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="mb-3">
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="lastname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname &&
                        validation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastname &&
                    validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label for="exampleFile">Avatar</Label>
                    <Input
                      id="exampleFile"
                      name="file"
                      type="file"
                      accept="image/*"
                      onChange={(e: any) => setFile(e.target.files[0])}
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row>
					<Col xs={6}>
						<div className="mb-3">
							<Label className="form-label">Prefix</Label>
							<Input
								name="prefix"
								type="text"
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.prefix || ""}
								invalid={
									validation.touched.prefix && validation.errors.prefix ? true : false
								}
							/>
							{validation.touched.prefix && validation.errors.prefix ? (
								<FormFeedback type="invalid">{validation.errors.prefix}</FormFeedback>
							) : null}
						</div>
					</Col>
					<Col xs={6}>
						<div className="mb-3">
							<Label className="form-label">Profession</Label>
							<Input
								name="profession"
								type="text"
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.profession || ""}
								invalid={
									validation.touched.profession && validation.errors.profession ? true : false
								}
							/>
							{validation.touched.profession && validation.errors.profession ? (
								<FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
							) : null}
						</div>
					</Col>
				</Row> */}
              <div className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Label className="form-label">Text Color</Label>
                    <Row>
                      <Col xs="8" style={{ paddingRight: 0 }}>
                        <Input
                          name="text_color"
                          type="text"
                          value={customState.text_color}
                          disabled={true}
                        />
                      </Col>
                      <Col xs="4" style={{ paddingLeft: 5 }}>
                        {/* <InputColor
							initialValue={customState.text_color}
							onChange={(color) => setCustomState({ ...customState, text_color: color.hex })}
							placement="right"
						/> */}
                        <SketchColorPicker
                          color={customState.text_color || "#000"}
                          onChange={(color) =>
                            setCustomState({
                              ...customState,
                              text_color: color,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Label className="form-label">Background Color</Label>
                    <Row>
                      <Col xs="8" style={{ paddingRight: 0 }}>
                        <Input
                          name="background_color"
                          type="text"
                          value={customState.background_color}
                          disabled={true}
                        />
                      </Col>
                      <Col xs="4" style={{ paddingLeft: 5 }}>
                        {/* <InputColor
							initialValue={customState.background_color}
							onChange={(color) => setCustomState({ ...customState, background_color: color.hex })}
							placement="right"
						/> */}
                        <SketchColorPicker
                          color={customState.background_color || "#fff"}
                          onChange={(color) =>
                            setCustomState({
                              ...customState,
                              background_color: color,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label className="form-label">Type</Label>
                <div>
                  <SelectPicker
                    data={nurseTypes}
                    placeholder={"Select Type"}
                    style={{ width: "100%" }}
                    menuStyle={{ zIndex: 9999 }}
                    onChange={(data: string) => setType(data)}
                    value={type}
                    searchable={false}
                    placement="bottomEnd"
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <Row>
                <Col xs={6}>
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      label="Password"
                      type="text"
                      value={password}
                    />
                  </div>
                </Col>
                <Col xs={6} className="d-flex justify-content-center align-items-center mb-3">
                  <button type="button" className="btn btn-info btn-sm d-flex justify-content-center align-items-center" onClick={handleGeneratePassword}>
                     Generate Password
                  </button>
                </Col>
              </Row> */}
              <Row>
                <Col xs={6}>
                  <div className="mb-3">
                    <Label className="form-label">Primary Phone</Label>
                    <Input
                      name="contactno1"
                      label="Phone"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contactno1 || ""}
                      invalid={
                        validation.touched.contactno1 &&
                        validation.errors.contactno1
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactno1 &&
                    validation.errors.contactno1 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactno1}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="mb-3">
                    <Label className="form-label">Secondary Phone</Label>
                    <Input
                      name="contactno2"
                      label="Phone"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contactno2 || ""}
                      invalid={
                        validation.touched.contactno2 &&
                        validation.errors.contactno2
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactno2 &&
                    validation.errors.contactno2 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactno2}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="mb-3">
                <Label className="form-label">Location</Label>
                <div>
                  <SelectPickerPaginate
                    query={FetchLocationsForFilter}
                    placeholder="Select Location"
                    value={locationId}
                    onChange={(location: any) => setLocationId(location)}
                    arrKey="locations"
                    objectLabel="name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      isactive: { _eq: true },
                      createdby: { _eq: user?.id },
                    }}
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center">
                  <Checkbox
                    checked={validation.values.is_supervisor}
                    onChange={(value: any, checked: boolean) =>
                      validation.setFieldValue("is_supervisor", checked)
                    }
                  />{" "}
                  Supervisor
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                  disabled={loading || loadingUpdateEmployee}
                >
                  <FiCheck />
                  &nbsp; Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default EmployeeModal;

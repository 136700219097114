import { gql } from "@apollo/client"
export const FetchNotes =
  gql`query FetchNotes (\$limit:Int,\$offset:Int,\$where:notes_bool_exp){
    notes (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      title
      note
      created_at
    }
    notes_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
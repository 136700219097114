const nurseStages = [
  {
    id: 1,
    color: "#f7c6c2",
    position: "Verified",
  },
  {
    id: 2,
    color: "#e5eac2",
    position: "Conditionally Verified",
  },
  {
    id: 3,
    color: "#f4e3c5",
    position: "Documents Submit",
  },
  {
    id: 4,
    color: "#f4eeca",
    position: "Selected",
  },
  {
    id: 5,
    color: "#e5eac2",
    position: "To be Interviewed",
  },
  {
    id: 6,
    color: "#d7e4b9",
    position: "Signed Up",
  },
  {
    id: 7,
    color: "#BEBEBE",
    position: "Archived",
  },
];
export default nurseStages;

import React, { useEffect, useState, useRef, ReactNode } from "react";
import { Col, Row, Spinner, Button } from "reactstrap";
import { Pagination, Dropdown as DropdownRsuite } from "rsuite";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import moment from "moment";
import { FiArrowLeft } from "react-icons/fi";
import LoadingOverlay from "react-loading-overlay-ts";

//helpers
import {
  showAlert,
  uploadImage,
  getFilename,
  getDocumentName,
} from "helpers/index";

//components
import ImageViewer from "components/documents/ImageViewer";
import DocumentRejectModal from "components/documents/DocumentRejectModal";
import UploadCertificationModal from "components/documents/UploadCertificationModal";

//GraphQL
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { FetchDocuments, FetchDocumentEnums } from "gql/documents/query";
import {
  UpdateDocument,
  InsertDocumentOne,
  DeleteDocument,
} from "gql/documents/mutation";

//Functions
import {
  DocumentsColumns,
  FormatData,
  BuildDocumentSearchQuery,
  BuildDocumentFilterQuery,
} from "./documents.functions";

//type
import DocumentType from "./documents.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//data
import {
  IDDropdownData,
  TBScreeningDropdownData,
  empTypeDropdownData,
} from "constants/documentCategories";

const DocumentTable: React.FC<{
  queryCondition: any;
  user: any;
  onBack: () => void;
  category: any;
}> = (props) => {
  const client = useApolloClient();
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const {
    auth: { user },
  } = useSelector(authState);
  const [UpdateDocumentMutation] = useMutation(UpdateDocument);
  const [InsertDocumentOneMutation] = useMutation(InsertDocumentOne);
  const [DeleteDocumentMutation] = useMutation(DeleteDocument);

  const isPDF = [
    "CORI",
    "BACKGROUND_AUTHORIZATION",
    "EMPLOYMENT_TYPE",
    "DEMENTIA_TRAINING",
  ].includes(props?.category);

  const initialCondition = {
    practioner_id: { _eq: props.user.id },
    category: { _neq: "LICENSE" },
  };
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>(initialCondition);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null
  );
  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false);
  const [certificationModalOpen, setCertificationModalOpen] =
    useState<boolean>(false);

  const [documentType, setDocumentType] = useState<string>("");
  const [isUploading, setUploading] = useState<boolean>(false);

  const [getDocumentList, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchDocuments, {
      variables: {
        where: queryCondition,
      },
    });
  const defaultSorted: any = [];

  const handleSearch = (value: string) => {
    const conditionTemp = BuildDocumentSearchQuery(value, null);
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };

  const handleSelectDocument = (_document: DocumentType) => {
    setSelectedDocument(_document);
    setShowModal(true);
  };

  const handleVerifyDocument = async (
    status: string,
    _document: DocumentType
  ) => {
    Swal.fire({
      title: ``,
      text: `Are you sure want to ${
        status === "verified" ? "approve" : "reject"
      } this document?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (status === "verified") {
          try {
            await UpdateDocumentMutation({
              variables: {
                id: _document.id,
                object: {
                  status,
                  verified_at: moment().toDate(),
                },
              },
            });
            refetch();
            showAlert({
              title: "Updated!",
              message: "Document verified successfully",
              type: "success",
            });
          } catch (err) {
            showAlert({
              title: "Error!",
              message: "Something Went Wrong",
              type: "danger",
            });
          }
        } else {
          setSelectedDocument(_document);
          setRejectionModalOpen(true);
        }
      }
    });
  };
  const renderUploadDropdownItems = () => {
    const jsx: ReactNode[] = [];
    if (props.category == "ID") {
      IDDropdownData.map((data, i) => {
        jsx.push(
          <DropdownRsuite.Item eventKey={data.value} key={i}>
            {data.label}
          </DropdownRsuite.Item>
        );
      });
    } else if (props.category === "TB_SCREENING") {
      TBScreeningDropdownData.map((data, i) => {
        jsx.push(
          <DropdownRsuite.Item eventKey={data.value} key={i}>
            {data.label}
          </DropdownRsuite.Item>
        );
      });
    } else if (props.category === "EMPLOYMENT_TYPE") {
      empTypeDropdownData.map((data, i) => {
        jsx.push(
          <DropdownRsuite.Item eventKey={data.value} key={i}>
            {data.label}
          </DropdownRsuite.Item>
        );
      });
    }
    if (jsx.length) {
      return jsx;
    } else {
      return null;
    }
  };
  const handleOpenFilePicker = (fileType: string) => {
    setDocumentType(fileType);
    ["ID", "TB_SCREENING", "WORK_AUTHORIZATION", "CPR"].includes(props.category)
      ? setCertificationModalOpen(true)
      : uploadRef?.current?.click();
  };
  const handleDeleteDocument = async (_document: DocumentType) => {
    Swal.fire({
      title: `Are you sure want to delete this document?`,
      text: `This action is non-reversible`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await DeleteDocumentMutation({
            variables: {
              id: _document.id,
            },
          });
          refetch();
          showAlert({
            title: "Deleted!",
            message: "Document deleted successfully",
            type: "success",
          });
        } catch (err) {
          showAlert({
            title: "Error!",
            message: "Something Went Wrong",
            type: "danger",
          });
        }
      }
    });
  };
  const handleUploadFile = async (files: FileList | null) => {
    if (files) {
      try {
        setUploading(true);
        const documentName: string = getDocumentName(documentType);
        const formData = new FormData();
        formData.append("uploads", files[0], getFilename(files));
        const documentURL = await uploadImage(formData);
        await InsertDocumentOneMutation({
          variables: {
            object: {
              name: documentName,
              practioner_id: props.user.id,
              createdby: props.user.id,
              updatedby: props.user.id,
              document_url: documentURL,
              status: "verified",
              category: documentType,
              verified_at: new Date(),
            },
          },
        });
        client.refetchQueries({
          include: ["FetchDocuments"],
        });
        showAlert({
          title: "Success!",
          message: "Document uploaded successfully",
          type: "success",
        });
      } catch (err) {
        showAlert({
          title: "Error!",
          message: "Something Went Wrong",
          type: "danger",
        });
      } finally {
        setUploading(false);
      }
    }
  };
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      let _cond = {};
      if (props.queryCondition !== null) {
        _cond = props.queryCondition;
      } else {
        _cond = initialCondition;
      }
      setQueryCondition(_cond);
    }
  }, [searchKey]);
  //Apply filters
  useEffect(() => {
    const conditionTemp = BuildDocumentFilterQuery(filterValue, null);
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  }, [filterValue]);

  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition);
    } else {
      setQueryCondition(initialCondition);
    }
  }, [props.queryCondition]);

  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getDocumentList();
    }
  }, [queryCondition]);
  const keyField = "id";

  return (
    <LoadingOverlay
      active={isUploading}
      spinner
      text="Uploading document..."
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgb(0 0 0 / 1.2%)",
        }),
        spinner: (base) => ({
          ...base,
          width: 30,
          "& svg circle": {
            stroke: "#009EF7",
          },
        }),
      }}
    >
      <Row>
        <Col lg="12">
          <div className="cursor-pointer d-flex justify-content-between">
            <div onClick={() => props.onBack()}>
              <FiArrowLeft size={20} />
            </div>
            {user?.role === "moderator" && (
              <>
                {["ID", "TB_SCREENING", "EMPLOYMENT_TYPE"].includes(
                  props.category
                ) ? (
                  <DropdownRsuite
                    title="Upload"
                    onSelect={handleOpenFilePicker}
                  >
                    {renderUploadDropdownItems()}
                  </DropdownRsuite>
                ) : (
                  <Button
                    className="font-size-11 mx-2 p-2"
                    onClick={() => handleOpenFilePicker(props.category)}
                    // onClick={() => setCertificationModalOpen(true)}
                    type="button"
                  >
                    Upload
                  </Button>
                )}
              </>
            )}
          </div>
          <input
            type="file"
            id="file"
            ref={uploadRef}
            style={{ display: "none" }}
            onChange={(e) => handleUploadFile(e.target.files)}
            accept={isPDF ? "application/pdf" : "image/*, application/pdf"}
          />
          <ToolkitProvider
            keyField={keyField}
            data={FormatData(
              Array.isArray(data?.documents) ? data.documents : []
            )}
            columns={DocumentsColumns(
              searchKey,
              filterValue,
              history,
              handleSelectDocument,
              user?.role,
              handleVerifyDocument,
              handleDeleteDocument
            )}
            bootstrap4
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                {loading && (
                  <Col xl="12" className="text-center">
                    <Spinner color="primary" />
                  </Col>
                )}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        key={`${searchKey}_${JSON.stringify(filterValue)}`}
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={{
                        // 	mode: 'checkbox',
                        // 	onSelectAll: (isSelected) => { console.log(isSelected) }
                        // }}
                        classes={"table align-middle table-nowrap table-hover"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        noDataIndication={() => "No documents found!"}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline mt-3 w-100">
                      <Pagination
                        total={data?.documents_aggregate?.aggregate?.count || 0}
                        limit={PageData.limit}
                        activePage={PageData.pageNo}
                        onChangePage={(pageNo) =>
                          setPageData({ ...PageData, pageNo })
                        }
                        limitOptions={[30, 50, 100]}
                        layout={["limit", "pager"]}
                        onChangeLimit={(limit) =>
                          setPageData({ ...PageData, limit })
                        }
                        className="d-flex justify-content-between"
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
          {/* </CardBody>
				</Card> */}
        </Col>
        <ImageViewer
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          document={selectedDocument}
        />
        <DocumentRejectModal
          isOpen={rejectionModalOpen}
          toggle={() => setRejectionModalOpen(false)}
          document={selectedDocument}
          refetch={refetch}
        />
        <UploadCertificationModal
          isOpen={certificationModalOpen}
          toggle={() => setCertificationModalOpen(false)}
          user={props.user}
          docType={documentType}
        />
      </Row>
    </LoadingOverlay>
  );
};

export default DocumentTable;

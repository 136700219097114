import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FiCheck, FiX } from "react-icons/fi";

//types
import facilityType from "./facilities.types";
export const FormatData = (employees: facilityType[]) => {
  const _employees = employees.map((elem: facilityType) => {
    return {
      id: elem.id,
      name: elem.name,
      organisation_name: elem.organisation?.name || "N/A",
      organisation_id: elem.organisation?.id,
      organisation_avatar: elem.organisation?.avatar,
      email: elem.email,
      phonenumber: elem.phonenumber,
      total_emp: elem.employees_aggregate?.aggregate?.count || 0,
      total_job_openings: elem.job_openings_aggregate?.aggregate?.count || 0,
      total_positions: elem.positions_aggregate?.aggregate?.count || 0,
      created_at: elem.created_at,
      isactive: elem.isactive,
      view_ssn: elem.organisation?.view_ssn || "N/A",
      verification: elem.organisation?.verification,
      is_maple_user: elem?.is_maple_user
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const FacilitiesColumns = (
  searchKey: string,
  filterValue: any,
  handleViewRequest: (id: string, action: string) => void,
  handleVerifyRequest: (id: string, action: string) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "organisation_avatar",
    text: "#",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <>
        {/* {!cellContent ? ( */}
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {row.name?.charAt(0)?.toUpperCase()}
          </span>
        </div>
        {/*) : (
					<div>
						 <img
                  className="rounded-circle avatar-xs"
                  src={images[user.img]}
                  alt=""
                /> 
					</div>
				)}*/}
      </>
    ),
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <Link className="text-primary" to={`/facilities-profile/${row.id}`}>
        {formatHighlighter(row.name, searchKey, filterValue)}
      </Link>
    ),
  },
  {
    dataField: "is_maple_user",
    text: "",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <Badge pill color="secondary" className="font-size-11 m-2 p-2">
        {cellContent ? "Maple Partner" : ""}
      </Badge>
    ),
  },
  {
    dataField: "organisation_name",
    text: "Organisation",
    sort: true,
    formatter: (cellContent: string) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent: string) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "phonenumber",
    text: "Phone",
    formatter: (cellContent: string) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "total_emp",
    text: "Total Employee",
  },
  {
    dataField: "total_job_openings",
    text: "Total Gig Shifts",
  },
  {
    dataField: "total_positions",
    text: "Total Positions",
  },
  {
    dataField: "created_at",
    text: "Registered on",
    formatter: (cellContent: string, row: any) =>
      moment(cellContent).format("MM/DD/YYYY"),
  },
  {
    dataField: "verification",
    text: "Verification",
    formatter: (cellContent: string, row: any) => (
      <Badge
        pill
        to="#"
        className={`badge ${
          cellContent == "approved"
            ? "bg-success"
            : cellContent == "pending"
            ? "bg-warning"
            : cellContent == "rejected"
            ? "bg-danger"
            : ""
        } font-size-11 m-2 p-2`}
      >
        {cellContent === "approved"
          ? "Approved"
          : cellContent == "pending"
          ? "Pending"
          : cellContent == "rejected"
          ? "Rejected"
          : ""}
      </Badge>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "",
    // hidden: role !== "moderator",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => {
      return (
        <div className="d-flex justify-content-start">
          {row.verification == "pending" && (
            <>
              <Button
                color="success"
                outline
                size="sm"
                style={{ height: 30, marginRight: 5 }}
                to="#"
                onClick={() =>
                  handleVerifyRequest(row.organisation_id, "approved")
                }
              >
                <FiCheck size={15} />
                Approve
              </Button>

              <Button
                color="danger"
                outline
                size="sm"
                style={{ height: 30, marginLeft: 5 }}
                to="#"
                onClick={() =>
                  handleVerifyRequest(row.organisation_id, "rejected")
                }
              >
                <FiX />
                Reject
              </Button>
            </>
          )}

          {row.verification == "approved" && (
            <Button
              color="danger"
              outline
              size="sm"
              style={{ height: 30, marginLeft: 5 }}
              to="#"
              onClick={() =>
                handleVerifyRequest(row.organisation_id, "rejected")
              }
            >
              <FiX />
              Reject
            </Button>
          )}
          {row.verification == "rejected" && (
            <Button
              color="success"
              outline
              size="sm"
              style={{ height: 30, marginRight: 5 }}
              to="#"
              onClick={() =>
                handleVerifyRequest(row.organisation_id, "approved")
              }
            >
              <FiCheck size={15} />
              Approve
            </Button>
          )}
        </div>
      );
    },
  },
  {
    dataField: "view_ssn",
    text: "SSN Status",
    formatter: (cellContent: string, row: any) => (
      <Badge
        pill
        to="#"
        className={`badge ${
          cellContent == "Requested"
            ? "bg-warning"
            : cellContent == "Grant"
            ? "bg-success"
            : cellContent == "Deny"
            ? "bg-danger"
            : "bg-light"
        } font-size-11 m-2 p-2`}
      >
        {cellContent}
      </Badge>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "View SSN",
    // hidden: role !== "moderator",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => {
      return (
        <div className="d-flex justify-content-start">
          {row.view_ssn == "Requested" && (
            <>
              <Button
                color="success"
                outline
                size="sm"
                style={{ height: 30, marginRight: 5 }}
                to="#"
                onClick={() => handleViewRequest(row.organisation_id, "Grant")}
              >
                <FiCheck size={15} />
                Approve
              </Button>

              <Button
                color="danger"
                outline
                size="sm"
                style={{ height: 30, marginLeft: 5 }}
                to="#"
                onClick={() => handleViewRequest(row.organisation_id, "Deny")}
              >
                <FiX />
                Deny
              </Button>
            </>
          )}

          {row.view_ssn == "Grant" && (
            <Button
              color="danger"
              outline
              size="sm"
              style={{ height: 30, marginLeft: 5 }}
              to="#"
              onClick={() => handleViewRequest(row.organisation_id, "Deny")}
            >
              <FiX />
              Deny
            </Button>
          )}
          {row.view_ssn == "Deny" && (
            <Button
              color="success"
              outline
              size="sm"
              style={{ height: 30, marginRight: 5 }}
              to="#"
              onClick={() => handleViewRequest(row.organisation_id, "Grant")}
            >
              <FiCheck size={15} />
              Approve
            </Button>
          )}
        </div>
      );
    },
  },
  {
    dataField: "isactive",
    text: "Status",
    sort: true,
    formatter: (cellContent: boolean, row: any) => (
      <Badge
        pill
        to="#"
        className={`badge ${
          cellContent ? "bg-success" : "bg-danger"
        } font-size-11 m-2 p-2`}
      >
        {cellContent ? "Active" : "Blocked"}
      </Badge>
    ),
  },
];
export const BuildFacilitiesSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { phonenumber: { _ilike: `%${value}%` } },
        { organisation: { name: { _ilike: `%${value}%` } } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildFacilitiesFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        isactive: { _eq: filterValue.status },
      };
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        const created_at_from = moment(filterValue.created_at[0]).format(
          "YYYY-MM-DD"
        );
        const created_at_till = moment(filterValue.created_at[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { created_at: { _gte: created_at_from } },
            { created_at: { _lte: created_at_till } },
          ],
        };
      }
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

import { gql } from "@apollo/client"
export const UpdateJobOpening =
	gql`mutation UpdateJobOpening(\$id:uuid!, \$object: job_openings_set_input) {
    update_job_openings_by_pk(pk_columns: {id: \$id}, _set: \$object) {
        id
      }
    }`

export const InsertJobOpeningOne =
	gql`mutation InsertJobOpeningOne(\$objects: [job_openings_insert_input!]!) {
  insert_job_openings(objects: \$objects) {
    returning {
      id
      job_date
    }
  }
}
`

export const UpdateJobOpeningBulk =
	gql`mutation UpdateJobOpeningBulk(\$ids:[uuid!]!, \$object: job_openings_set_input) {
    update_job_openings(where: {id: {_in: \$ids}}, _set: \$object) {
        returning {
          id
        }
      }
    }`
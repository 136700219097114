import React from "react";
import { Card, CardBody, Container, Col } from "reactstrap";
import MetaTags from "react-meta-tags";
import { BrowserRouter, Route, Switch, useRouteMatch } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// icons
import { FiPlus } from "react-icons/fi";

// import Component
import LeftBar from "./LeftBar";
// import FileList from "./FileList"
import RecentFile from "./RecentFile";
import Locations from "./locations/Locations.tsx";
import Employees from "./employees/Employees.tsx";
import Positions from "./positions/Positions.tsx";
import NotificationSubscriber from "./notification/NotificationSubscriber";
import TextNotification from "./notification/TextNotification";

const Index = () => {
  let { path, url } = useRouteMatch();
  return (
    <React.Fragment>
      <div className="page-content SettingsPage AppBody">
        <MetaTags>
          <title>Settings| Caregigs</title>
        </MetaTags>
        <div className="SettingsPage-container">
          {/* breadcrubs */}
          <Breadcrumbs
            title="Home"
            links={[
              { title: "Home", link: "/" },
              { title: "Settings", link: "#" },
            ]}
          />
          {/*  */}
          {/* <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex"> */}
          <div className="row">
            <div className="col-lg-2">
              <LeftBar />
            </div>
            <div className="col-lg-10">
              <div className="SettingsPage-card">
                <div>
                  <Switch>
                    {/* <Route exact path="/settings/general">
                            <FileList />
                          </Route> */}
                    <Route exact path="/settings/locations">
                      <Locations />
                    </Route>
                    <Route exact path="/settings/positions">
                      <Positions />
                    </Route>
                    <Route exact path="/settings/staff">
                      <Employees />
                    </Route>
                    <Route exact path="/settings/email-notification">
                      <Col>
                        <div className="mt-2 mb-3">
                          <h5
                            style={{
                              borderBottomColor: "#E4E6EF",
                              paddingBottom: 7,
                              borderBottomWidth: 1,
                              borderBottomStyle: "solid",
                            }}
                          >
                            Email Notification
                          </h5>
                        </div>
                      </Col>
                      <NotificationSubscriber />
                    </Route>
                    <Route exact path="/settings/text-notification">
                      <Col>
                        <div className="mt-2 mb-3">
                          <h5
                            style={{
                              borderBottomColor: "#E4E6EF",
                              paddingBottom: 7,
                              borderBottomWidth: 1,
                              borderBottomStyle: "solid",
                            }}
                          >
                            Text Notification
                          </h5>
                        </div>
                      </Col>
                      <TextNotification />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Index;

import { gql } from "@apollo/client"
export const FetchUserByEmail =
  gql`query FetchUserByEmail(\$email:String) {
  users(where: {email: {_eq: \$email}}, limit: 1) {
    name
    id
  }
}`
export const FetchUserByPhone =
  gql`query FetchUserByPhone(\$phonenumber:String) {
  users(where: {phonenumber: {_eq: \$phonenumber}}, limit: 1) {
    name
    id
  }
}`
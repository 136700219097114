import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import RequestTable from "./RequestTable";
import BannerContent from "constants/bannerContent.json";

import OnboardingBanner from "components/Onboarding/OnboardingBanner";
import Gigrequest from "assets/images/onboarding/Gigrequest.png";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import {onboardingState, handleSetGigRequestsBanner} from "toolkit/onboarding/onboarding.slice";

const RequestList: React.FC = () => {
  const dispatch = useDispatch()
  const { showGigRequestsBanner } = useSelector(onboardingState)
  return (
    <React.Fragment>
      <div className="page-content GigRequestPage AppBody">
        <MetaTags>
          <title>Requests | Caregigs</title>
        </MetaTags>

        <div className="GigRequestPage-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs 
            title="Requests" 
            links={[{title: "Home", link: "/"}, {title: "Requests", link: "#"}]} 
          />

          {/* onboarding */}
          {showGigRequestsBanner && (
            <OnboardingBanner
              media={Gigrequest}
              title={BannerContent.gigrequest.title}
              subTitle={BannerContent.gigrequest.subtitle}
              color={"rgb(239 228 255)"}
              handleClose={()=>dispatch(handleSetGigRequestsBanner(false))}
            />
          )}

          <RequestTable queryCondition={null} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(RequestList);

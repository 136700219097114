import { gql } from "@apollo/client"
export const InsertNoteOne =
gql`mutation InsertNoteOne(\$object: notes_insert_input!) {
  insert_notes_one(object: \$object) {
    id
  }
}`

export const UpdateNote =
gql`mutation UpdateNote(\$id:uuid!, \$object: notes_set_input) {
  update_notes_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`

export const DeleteNote =
gql`mutation DeleteNote(\$id:uuid!) {
  delete_notes_by_pk(id: \$id) {
    id
  }
}`
import React, { useState } from "react";
//components
import DocumentList from "./documents/DocumentList";
import DocumentTable from "./documents/DocumentTable";
import DocumentI9 from "./documents/DocumentI9";

//data
import { IDEnums, TBScreeningEnums, EmploymentTypeEnums } from "constants/documentCategories";

const Certifications: React.FC<{ user: any }> = ({ user }) => {
  const [openTable, setOpenTable] = useState<boolean>(false);
  const [queryCondition, setQueryCondition] = useState<any>(null);
  const [category, setCategory] = useState<string>("");
  const handleClickDocumentList = (category: string) => {
    setCategory(category);
    if (category === "ID") {
      setQueryCondition({
        category: { _in: IDEnums },
        practioner_id: { _eq: user.id },
      });
    } else if (category === "TB_SCREENING") {
      setQueryCondition({
        category: {_in: TBScreeningEnums},
        practioner_id: { _eq: user.id }
      })
    }else if(category === "EMPLOYMENT_TYPE"){
      setQueryCondition({
        category: {_in: EmploymentTypeEnums},
        practioner_id: { _eq: user.id }
      })
    }else{
      setQueryCondition({
        category: { _eq: category },
        practioner_id: { _eq: user.id },
      });
    }
    setOpenTable(true);
  };
  return (
    <div>
      {!openTable ? (
        <DocumentList
          queryCondition={null}
          user={user}
          onClickList={handleClickDocumentList}
        />
      ) : category == "I_9" ? (
        <DocumentI9
          queryCondition={queryCondition}
          user={user}
          onBack={() => setOpenTable(false)}
        />
      ) : (
        <DocumentTable
          queryCondition={queryCondition}
          user={user}
          onBack={() => setOpenTable(false)}
          category={category}
        />
      )}
    </div>
  );
};

export default Certifications;

import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Spinner,
	Form,
	FormGroup,
	Input,
	Button
} from "reactstrap";
import { Pagination } from 'rsuite';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { showAlert } from "helpers/index";
import moment from "moment";

// icons
import { FiPlus } from "react-icons/fi";

//components
import CreateNoteModal from "components/notes/CreateNoteModal";

//GraphQL
import { useLazyQuery, useMutation } from "@apollo/client"
import { FetchNotes } from "gql/notes/query"
import { UpdateNote, DeleteNote } from "gql/notes/mutation"

//Functions
import { NotesColumns, FormatData, BuildNoteSearchQuery } from "./notes.functions"

//type
import NoteType from "./notes.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const NotesTable: React.FC<{
	queryCondition: any;
	user: any
}> = props => {
	const { auth: { user } } = useSelector(authState);
	const [UpdateNoteMutation] = useMutation(UpdateNote)
	const [DeleteNoteMutation] = useMutation(DeleteNote)

	const initialCondition = {
		customer_id: { _eq: props.user.id },
		createdby: { _eq: user.id }
	}
	const [filterValue, setFilterValue] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>(initialCondition)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [selectedNote, setSelectedNote] = useState<NoteType | null>(null)

	const [getNotes, { loading, data, refetch, error, called }] = useLazyQuery(FetchNotes, {
		variables: {
			where: queryCondition
		}
	})
	const defaultSorted: any = [];

	const handleSearch = (value: string) => {
		const conditionTemp = BuildNoteSearchQuery(value, null)
		let _cond = {}
		if (props.queryCondition !== null) {
			_cond = props.queryCondition
		} else {
			_cond = initialCondition
		}
		setQueryCondition({ ..._cond, ...conditionTemp })
	}

	const handleSelectDocument = (note: NoteType) => {
		setSelectedNote(note)
		setShowModal(true)
	}

	const handleDeleteDocument = async (id: string) => {
		Swal.fire({
			title: ``,
			text: `Are you sure want to delete this note?`,
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await DeleteNoteMutation({variables: { id }})
					refetch()
					showAlert({
						title: "Deleted!",
						message: "Note deleted successfully",
						type: "success",
					});
				} catch (err) {
					showAlert({
						title: "Error!",
						message: "Something Went Wrong",
						type: "danger",
					});
				}
			}
		})
	}
	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			let _cond = {}
			if (props.queryCondition !== null) {
				_cond = props.queryCondition
			} else {
				_cond = initialCondition
			}
			setQueryCondition(_cond)
		}
	}, [searchKey])

	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [props.queryCondition])

	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getNotes()
		}
	}, [queryCondition])

	const keyField = "id";
	return (
		<Row>
			<Col lg="12">
				<ToolkitProvider
					keyField={keyField}
					data={FormatData(Array.isArray(data?.notes) ? data.notes : [])}
					columns={NotesColumns(searchKey, filterValue, history, handleSelectDocument, handleDeleteDocument)}
					bootstrap4
					search
				>
					{toolkitProps => (
						<React.Fragment>
							<div className="mb-2 justify-content-between d-flex">
								<div className="search-box me-2 mb-2 d-inline-block">
									<div className="position-relative">
										<Form
											onSubmit={(e) => {
												e.preventDefault();
											}}
										>
											<FormGroup>
												<Input
													id="noteSearch"
													name="search"
													placeholder="Search notes"
													type="text"
													onChange={(e) => setSearchKey(e.target.value)}
													value={searchKey}
												/>
												<i className="bx bx-search-alt search-icon" />
											</FormGroup>
										</Form>
									</div>
								</div>
								<Button
									color="primary"
									size="sm"
									style={{ height: 35 }}
									to="#"
									onClick={() => setShowModal(true)}
								>

									<FiPlus size={15} />
									Create Note
								</Button>
							</div>
							<Row className="mb-3">
								<Col sm="8">
									Total Notes:{" "}
									{data?.notes_aggregate?.aggregate?.count || 0}
								</Col>
							</Row>
							{loading && (
								<Col xl="12" className="text-center">
									<Spinner color="primary" />
								</Col>
							)}
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										<BootstrapTable
											key={`${searchKey}_${JSON.stringify(filterValue)}`}
											bordered={false}
											striped={false}
											defaultSorted={defaultSorted}
											// selectRow={{
											// 	mode: 'checkbox',
											// 	onSelectAll: (isSelected) => { console.log(isSelected) }
											// }}
											classes={
												"table align-middle table-nowrap table-hover"
											}
											headerWrapperClasses={"thead-light"}
											{...toolkitProps.baseProps}
											noDataIndication={() => "No notes found!"}
										/>
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-30">
								<Col className="inner-custom-pagination d-flex">
									<div className="d-inline mt-3 w-100">
										<Pagination
											total={data?.notes_aggregate?.aggregate?.count || 0}
											limit={PageData.limit}
											activePage={PageData.pageNo}
											onChangePage={(pageNo) => setPageData({ ...PageData, pageNo })}
											limitOptions={[30, 50, 100]}
											layout={['limit', 'pager']}
											onChangeLimit={(limit) => setPageData({ ...PageData, limit })}
											className="d-flex justify-content-between"
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
				{/* </CardBody>
				</Card> */}
			</Col>
			<CreateNoteModal
				isOpen={showModal}
				toggle={() => setShowModal(false)}
				note={selectedNote}
				refetch={refetch}
			/>
		</Row>
	);
};

export default NotesTable;
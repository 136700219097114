import { gql } from "@apollo/client"
export const FetchFacilities =
  gql`query FetchFacilities (\$limit:Int,\$offset:Int,\$where:users_bool_exp){
    users (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      is_maple_user
      organisation {
        id
        name
        avatar
        view_ssn
        verification
      }
      email
      phonenumber
      employees_aggregate(where: {isactive: {_eq: true}}) {
        aggregate {
          count
        }
      }
      job_openings_aggregate(where: {isactive: {_eq: true}}) {
        aggregate {
          count
        }
      }
      positions_aggregate(where: {active: {_eq: true}}) {
        aggregate {
          count
        }
      }
      created_at
      isactive
    }
    users_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
export const FetchFacilityDetails =
  gql`query FetchFacilityDetails (\$id:uuid!){
    users_by_pk(id: \$id) {
      id
      name
      is_maple_user
      organisation {
        id
        name
        view_ssn
        avatar
      }
      email
      phonenumber
      employees_aggregate {
        aggregate {
          count
        }
      }
      job_openings_aggregate {
        aggregate {
          count
        }
      }
      positions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  `
export const FetchFacilitiesForFilter =
  gql`query FetchFacilitiesForFilter (\$limit:Int,\$offset:Int,\$where:users_bool_exp){
    users (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      email
      phonenumber
      isactive
      organisation {
        id
        name
        avatar
      }
    }
    users_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
  export const FetchFacilitiesViewForFilter = gql`
    query FetchFacilitiesViewForFilter(
      $limit: Int
      $offset: Int
      $where: users_organisation_view_bool_exp
    ) {
      users: users_organisation_view(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { created_at: desc_nulls_last }
      ) {
        id
        name
        email
        phonenumber
        isactive
        organisation_id
        organisation_name
        organisation_avatar
      }
      users_organisation_view_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
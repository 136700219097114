import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Badge, Label } from "reactstrap";

const renderTags = (tags: string[]) => {
  const jsx: any = [];
  map(tags, (tag, index: number) =>
    jsx.push(
      <Link
        to="#"
        className="badge bg-light font-size-11"
        key={"_skill_" + index}
      >
        {tag}
      </Link>
    )
  );
  return jsx;
};
export const formatCustomerStatus = (customer: any) => {
  if (
    customer.verified &&
    customer.onboarding_documents_verified &&
    customer.onboarding_documents_submitted
  ) {
    return { status: "Verified", color: "bg-success", value: "verified" };
  }
  if (
    customer.verified &&
    (!customer.onboarding_documents_verified ||
      !customer.onboarding_documents_submitted)
  ) {
    return {
      status: "Conditionally Verified",
      color: "bg-primary",
      value: "conditionally_verified",
    };
  }
  if (customer.onboarding_documents_submitted) {
    return {
      status: "Documents Submitted",
      color: "bg-warning",
      value: "documents_submitted",
    };
  }
  if (customer.onboarding_interview_status === "selected") {
    return { status: "Selected", color: "bg-success", value: "selected" };
  }
  if (customer.onboarding_interview_status === "rejected") {
    return { status: "Rejected", color: "bg-danger", value: "rejected" };
  }
  if (
    customer.onboarding_application_form_submitted &&
    customer.onboarding_interview_status !== "rejected"
  ) {
    return {
      status: "To be Interviewed",
      color: "bg-warning",
      value: "to_be_interviewed",
    };
  }
  return { status: "Signed Up", color: "bg-warning", value: "signed_up" };
};
export const FormatData = (customers: any[]) => {
  const _customers = customers.map((elem: any) => {
    let address = "N/A";
    if (elem.address?.cityName) {
      address = `${elem.address?.cityName}`;
    }
    if (elem.address?.state) {
      address += `, ${elem.address?.state}`;
    }
    return {
      id: elem.id,
      profile_picture: elem.profile_picture,
      firstname: elem.firstname,
      lastname: elem.lastname,
      name:
        elem.firstname && elem.lastname
          ? `${elem.firstname} ${elem.lastname}`
          : "N/A",
      preferred_rate: elem.preferred_rate || 0,
      profession: elem.profession,
      tags: elem.tags,
      pendingInvitations: elem.pendingInvitations?.aggregate?.count || 0,
      completedShifts: elem.completedShifts?.aggregate?.count || 0,
      futureShifts: elem.futureShifts?.aggregate?.count || 0,
      questionnaire: elem.questionnaire || {},
      contactno1: elem.contactno1 || "N/A",
      email: elem.email,
      address,
      type: elem.questionnaire?.qualificatioType || "N/A",
      education_info: elem.education_info,
      verified: elem.verified,
      onboarding_application_form_submitted:
        elem.onboarding_application_form_submitted,
      onboarding_documents_verified: elem.onboarding_documents_verified,
      onboarding_documents_submitted: elem.onboarding_documents_submitted,
      onboarding_interview_comments: elem.onboarding_interview_comments,
      onboarding_interview_date: elem.onboarding_interview_date,
      onboarding_interview_link: elem.onboarding_interview_link,
      onboarding_interview_scheduled: elem.onboarding_interview_scheduled,
      onboarding_interview_status: elem.onboarding_interview_status,
      notification_token: elem.notification_token,
      created_at: elem.created_at,
      status: formatCustomerStatus(elem),
      statusValue: formatCustomerStatus(elem).status,
      is_phone_verified: elem.is_phone_verified,
      ssn: elem.ssn,
      dob: elem.dob,
      doorNo: elem.address?.doorNo,
      streetName: elem.address?.streetName,
      city: elem.address?.cityName,
      representative_info: elem.representative_info,
      reference: elem.reference,
      blocked_facilities: elem.blocked_facilities,
      rating: elem.rating?.toFixed(1) || "N/A",
      nurse_rate: elem.nurse_rate,
      nurse_overtime_rate: elem.nurse_overtime_rate,
      note: elem?.notes[0]?.note || "N/A",
      is_interested: elem?.is_interested,
      is_terminated: elem?.is_terminated,
      is_location_pending: elem?.is_location_pending,
    };
  });
  return _customers;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const CustomerColumns = (
  searchKey: string,
  filterValue: any,
  onClickInvite: (user: any) => void,
  role: string
) => [
  {
    dataField: "id",
    text: "id",
    hidden: true,
    csvExport: false,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (cellContent: string, customer: any) => (
      <div className="d-flex gap-3 justify-content-between">
        <Link
          className="text-primary"
          to={`/marketplace-profile/${customer.id}`}
          // onClick={() => onClickProfile(customer)}
        >
          {formatHighlighter(`${cellContent}`, searchKey, filterValue)}
        </Link>
        {customer.pendingInvitations > 0 && (
          <Link to="#" className="badge bg-warning font-size-11">
            Invited
          </Link>
        )}
      </div>
    ),
  },
  {
    dataField: "rating",
    text: "Rating",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <>
        {cellContent == "N/A" ? (
          <></>
        ) : (
          <i className="bx bxs-star text-warning" />
        )}
        {cellContent}
      </>
    ),
  },
  {
    dataField: "type",
    text: "Type",
    sort: false,
  },
  {
    dataField: "preferred_rate",
    text: "Rate ($/Hr)",
    sort: true,
    hidden: role !== "moderator",
    formatter: (cellContent: string, customer: any) => `$${cellContent}`,
  },
  {
    dataField: "contactno1",
    text: "Phone",
    sort: true,
    formatter: (cellContent: string, customer: any) => (
      <div className="d-flex gap-3">
        {cellContent === "N/A" ? (
          formatHighlighter(cellContent, searchKey, filterValue)
        ) : (
          <a className="text-primary" href={`tel:${cellContent}`}>
            {formatHighlighter(cellContent, searchKey, filterValue)}
          </a>
        )}
      </div>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent: string, customer: any) => (
      <div className="d-flex gap-3">
        <a className="text-primary" href={`mailto:${cellContent}`}>
          {formatHighlighter(cellContent, searchKey, filterValue)}
        </a>
      </div>
    ),
  },
  {
    dataField: "address",
    text: "Address",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Registered on",
    sort: true,
    hidden: role !== "moderator",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MM/DD/YYYY"),
  },
  {
    dataField: "note",
    text: "Notes",
    sort: true,
    hidden: role === "provider",
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    csvExport: false,
    hidden: role !== "moderator",
    formatter: (cellContent: any, row: any) => (
      <>
        <Badge
          pill
          to="#"
          className={`badge ${cellContent.color} font-size-11 m-2 p-2`}
          style={{ width: 150 }}
        >
          {formatHighlighter(
            cellContent.status?.toUpperCase(),
            searchKey,
            filterValue
          )}
        </Badge>
        {!row.is_interested && (
          <Badge
            pill
            to="#"
            className="m-2 p-2"
            color="dark"
            style={{ fontSize: 9 }}
          >
            NOT INTERESTED
          </Badge>
        )}
        {row.is_terminated && (
          <Badge
            pill
            to="#"
            className="m-2 p-2"
            color="danger"
            style={{ fontSize: 9 }}
          >
            TERMINATED
          </Badge>
        )}
        {row.is_location_pending && (
          <Badge
            pill
            to="#"
            className="m-2 p-2"
            color="info"
            style={{ fontSize: 9 }}
          >
            LOCATION PENDING
          </Badge>
        )}
      </>
    ),
  },

  //for export only
  {
    dataField: "statusValue",
    text: "Status",
    sort: false,
    hidden: true,
  },
  {
    dataField: "tags",
    text: "Tags",
    sort: false,
    csvExport: false,
    hidden: role === "moderator",
    formatter: (cellContent: [], customer: any) => (
      <div className="d-flex gap-1">{renderTags(cellContent)}</div>
    ),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    csvExport: false,
    hidden: role === "moderator",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <button
        type="submit"
        className="btn btn-primary save-user"
        onClick={() => onClickInvite(row)}
      >
        Invite
      </button>
    ),
  },
];
export const BuildCustomerFilterQuery = (filters: any) => {
  let _conditions: any = {};
  if (filters.speciality?.length) {
    const _speciality: any[] = [];
    filters.speciality.map((sp: string) => {
      _speciality.push({
        questionnaire: { _contains: { qualificatioType: sp } },
      });
    });
    _conditions._or = _speciality;
  }
  if (filters.gender?.length) {
    const _gender = { gender: { _in: filters.gender } };
    _conditions = { ..._conditions, ..._gender };
  }
  if (filters.rating?.length) {
    const _rating = { rating: { _gte: filters.rating } };
    _conditions = { ..._conditions, ..._rating };
  }
  if (filters.price?.length) {
    const _price = {
      _and: [
        { preferred_rate: { _gte: filters.price[0] } },
        { preferred_rate: { _lte: filters.price[1] } },
      ],
    };

    _conditions = { ..._conditions, ..._price };
  }
  return _conditions;
};
export const BuildCustomerSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { firstname: { _ilike: `%${value}%` } },
        { lastname: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { contactno1: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildCustomerFilterQueryForAdmin = (filters: any) => {
  let _conditions = {};
  if (filters.speciality?.length) {
    const _speciality: any[] = [];
    filters.speciality.map((sp: string) => {
      _speciality.push({
        questionnaire: { _contains: { qualificatioType: sp } },
      });
    });
    _conditions = { _or: _speciality };
  }
  if (filters.addressMA?.length) {
    const _addressMA: any[] = [];
    filters.addressMA.map((elem: string) => {
      _addressMA.push({
        address: { _contains: { state: elem } },
      });
    });
    _conditions = { _and: [_conditions, { _or: _addressMA }] };
  }
  if (filters.status?.length) {
    const status: any[] = [];
    filters.status.map((st: string) => {
      if (st === "verified") {
        status.push({
          _and: [
            { verified: { _eq: true } },
            { onboarding_documents_verified: { _eq: true } },
          ],
        });
      } else if (st === "conditionally_verified") {
        status.push({
          is_deleted: { _eq: false },
          verified: { _eq: true },
          _or: [
            { onboarding_documents_verified: { _eq: false } },
            { onboarding_documents_submitted: { _eq: false } },
          ],
        });
      } else if (st === "documents_submitted") {
        status.push({
          _and: [
            { onboarding_documents_submitted: { _eq: true } },
            { verified: { _eq: false } },
          ],
        });
      } else if (st === "selected") {
        status.push({
          _and: [
            { onboarding_interview_status: { _eq: "selected" } },
            { verified: { _eq: false } },
            { onboarding_documents_submitted: { _eq: false } },
          ],
        });
      } else if (st === "to_be_interviewed") {
        status.push({
          _and: [
            { onboarding_application_form_submitted: { _eq: true } },
            { onboarding_interview_status: { _neq: "selected" } },
            { onboarding_interview_status: { _neq: "rejected" } },
            { onboarding_documents_submitted: { _eq: false } },
            { verified: { _eq: false } },
          ],
        });
      } else if (st === "signed_up") {
        status.push({ onboarding_application_form_submitted: { _eq: false } });
      } else if (st === "rejected") {
        status.push({ onboarding_interview_status: { _eq: "rejected" } });
      }
    });
    if (Object.keys(_conditions).length) {
      _conditions = { _and: [_conditions, { _or: status }] };
    } else {
      _conditions = { ..._conditions, _or: status };
    }
  }
  if (filters.licenseState?.length) {
    const _states: any[] = [];
    filters.licenseState.map((ls: string) => {
      _states.push({
        documents: {
          category: { _eq: "LICENSE" },
          document_info: { _contains: { issusingAuthority: ls } },
        },
      });
    });
    if (Object.keys(_conditions).length) {
      _conditions = { _and: [_conditions, { _or: _states }] };
    } else {
      _conditions = { ..._conditions, _or: _states };
    }
  }
  if (filters.revoked?.length) {
    const status: any[] = [];
    filters.revoked.map((st: string) => {
      if (st === "terminated") {
        status.push({ is_terminated: { _eq: true } });
      } else if (st === "not-interested") {
        status.push({ is_interested: { _eq: false } });
      } else if (st === "location-pending") {
        status.push({ is_location_pending: { _eq: true } });
      }
    });
    if (Object.keys(_conditions).length) {
      _conditions = { _and: [_conditions, { _or: status }] };
    } else {
      _conditions = { ..._conditions, _or: status };
    }
  }
  return _conditions;
};

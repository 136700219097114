import { gql } from "@apollo/client"
export const FetchInvitations =
  gql`query FetchInvitations (\$limit:Int,\$offset:Int,\$where:invitations_bool_exp){
    invitations (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      organisation_id
      location {
        id
        name
      }
      isactive
      fee_amount
      currency
      fee_period
      requirement
      created_at
      position {
        id
        name
        type
        start_time
        end_time
      }
      job_date
      customer {
        id
        firstname
        lastname
      }
      status
      job_requests {
        id
        status
        clock_in_time
      }
    }
    invitations_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
export const FetchInvitationsForScheduler =
  gql`query FetchInvitationsForScheduler (\$where:invitations_bool_exp){
    invitations (where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      organisation_id
      location {
        id
        name
      }
      isactive
      created_at
      position {
        id
        name
        type
        start_time
        end_time
        text_color
        background_color
      }
      job_date
      customer {
        id
        firstname
        lastname
      }
      status
    }
  }
  `
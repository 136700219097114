import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";
import { useParams } from "react-router-dom";
import moment from "moment";

import SelectPickerPaginate from "components/Filters/SelectPickerPaginate";

// icons
import { FiSend } from "react-icons/fi";

//function
import { useDispatch, useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//GraphQL
import { FetchPositionsForFilter } from "gql/positions/query";
import { FetchJobOpeningsForScheduler } from "gql/jobOpenings/query";
import { UpdateJobOpeningBulk } from "gql/jobOpenings/mutation";
import { InsertJobRequests } from "gql/jobRequests/mutation";
import { FetchCustomersForFilter } from "gql/customers/query";
import { UpdateJobRequest } from "gql/jobRequests/mutation";
import { FetchFacilitiesViewForFilter } from "gql/facilities/query";
import { FetchCustomerDetailsAdmin } from "gql/customers/query";
import { InsertInvitation } from "gql/invitations/mutation";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { notifySubscribers } from "toolkit/admin/admin.api";

const initialState: any = {
  customer_id: "",
};

const ReassignShiftModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  selectedShift: any;
}> = ({ toggle, modal, selectedShift }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const params: any = useParams();
  const {
    auth: { token },
  } = useSelector(authState);
  const [state, setState] = useState<any>(initialState);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);

  const handleClose = () => {
    toggle();
  };

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer_id: (state && state.customer_id) || "",
    },
    validationSchema: Yup.object({
      customer_id: Yup.string().required("Please Select Nurse"),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);

        const formData = {
          event: "SHIFT_REASSIGN",
          prevNurseName: selectedShift.name,
          nurseType: selectedShift.nurse_type,
          cancelledDateTime: selectedShift.cancelled_at,
          organisation_id: selectedShift.fee_amount_object.organisation.id,
          nurse_id: validation.values.customer_id,
          position_id: selectedShift.position_id,
          date: selectedShift.job_date,
        };
        await dispatch(notifySubscribers({ formData, token }));

        await UpdateJobRequestMutation({
          variables: {
            id: selectedShift?.id,
            object: {
              practitioner_id: validation.values.customer_id,
              status: "approved",
            },
          },
        });
        client.refetchQueries({
          include: ["FetchJobRequests"],
        });
        showAlert({
          title: "Updated!",
          message: "Nurse assigned successfully!",
          type: "success",
        });
        validation.resetForm();
        handleClose();
      } catch (err) {
        console.log(err);
        showAlert({
          title: "Error!",
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    },
  });

  return (
    <Modal
      isOpen={modal}
      size="md"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Reassign Shift
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label htmlFor="formrow-assignto-Input">Select Nurse</Label>
                <SelectPickerPaginate
                  query={FetchCustomersForFilter}
                  placeholder="Select Nurse"
                  value={validation.values.customer_id}
                  onChange={(newValue) => {
                    validation.setFieldValue("customer_id", newValue);
                  }}
                  arrKey="customers"
                  objectLabel="firstname+lastname"
                  objectValue="id"
                  style={{ width: "100%" }}
                  paginate={true}
                  filterCondition={{
                    verified: { _eq: true },
                    is_deleted: { _eq: false },
                  }}
                  getFullObj={false}
                  searchValue=""
                  menuStyle={{ zIndex: 9999 }}
                  cleanable={false}
                  className={
                    validation.touched.customer_id &&
                    validation.errors.customer_id
                      ? "border-danger"
                      : ""
                  }
                />
                {validation.touched.customer_id &&
                validation.errors.customer_id ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.customer_id}
                  </div>
                ) : null}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={btnLoading}
                >
                  <FiSend /> &nbsp; Assign
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default ReassignShiftModal;

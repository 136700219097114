import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import ShiftTable from "./ShiftTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

const ShiftList: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="page-content Timecardpage AppBody">
        <MetaTags>
          <title>Shifts | Caregigs</title>
        </MetaTags>
        <div className="Timecardpage-container admin">
          {/* breadcrumb */}
          <Breadcrumbs 
            title="Shifts" 
            links={[{title: "Home", link: "/"}, {title: "Shifts", link: "#"}]} 
          />
           <ShiftTable 
            queryCondition={null} 
            facility={null}
            mapleUser={null}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ShiftList);

import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useHistory } from "react-router-dom";
import { Col, Row, Label, FormFeedback, Input, Form, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import {
  CheckboxGroup,
  Checkbox,
  SelectPicker,
  Radio,
  RadioGroup,
  InputGroup,
} from "rsuite";
import { ValueType } from "rsuite/esm/Checkbox";

//constants
import nurseTypes from "constants/nurseTypes";
import {
  nurseShiftList,
  genderList,
  nurseIntendList,
  negativeTBTest,
  covidTest
} from "constants/nurseOnboarding";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import InputNumberField from "components/leads/InputNumberField";
import AutoComplete from "components/Common/AutoComplete";

//helpers
import { getAddressComponents, showAlert } from "helpers";

//function
import { useDispatch, useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { createNurseApi } from "toolkit/admin/admin.api";

const AddLeads: React.FC = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {auth: {token}} = useSelector(authState)
  const today = new Date();
  today.setHours(0, 0, 0, 0)
  const [experience, setExperience] = useState<any>({
    totalExp: "0",
    postAcuteCareExp: "0",
    acuteCareExp: "0",
    clinicExp: "0",
    homeHealthExp: "0",
    hospieExp: "0",
    urgentCareExp: "0",
    otherExp: "0",
  });
  const [addressState, setAddressState] = useState<any>({
    address: "",
    lat: 0,
    lng: 0,
    city: "",
    state: "",
  });
  const [intersetedShifts, setShiftInterested] = useState<ValueType[]>([]);
  const [intendToWork, setShiftIntended] = useState("");
  const [TBNegative, setNegativeTb] = useState("");
  const [covidVaccinated, setCovid] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      doorNo: "",
      streetName: "",
      cityName: "",
      dob: "",
      email: "",
      phone: "",
      rate: "",
      address: "",
      password: "",
      gender: "",
      nurseType: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter First Name"),
      lastname: Yup.string().required("Please Enter Last Name"),
      doorNo: Yup.number().required("Please Enter Door No."),
      streetName: Yup.string().required("Please Enter Street Name"),
      cityName: Yup.string().required("Please Enter City Name"),
      email: Yup.string().email().required("Please Enter Email"),
      phone: Yup.string()
        .length(10, "Phone No. Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Please Enter Phone No."),
      address: Yup.string().required("Please Enter Address"),
      password: Yup.string().required("Please Enter Password"),
      gender: Yup.string().required("Please Select Gender"),
      nurseType: Yup.string().required("Please Select Type"),
      dob: Yup.date().max(today, "Future Date Selection Not Allowed"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const formData = {
          firstname: values.firstname,
          lastname: values.lastname,
          address: {
            state: addressState.state,
            doorNo: values.doorNo,
            cityName: addressState.city,
            fullAdress: addressState.address,
            streetName: values.streetName
          },
          email: values.email,
          contactno1: values.phone,
          preferred_rate: values.rate,
          questionnaire: {
            status: "",
            license: "",
            zipCode: "",
            TBNegative,
            experience: {
              totalExp: parseInt(experience.totalExp),
              postAcuteCareExp: parseInt(experience.postAcuteCareExp),
              acuteCareExp: parseInt(experience.acuteCareExp),
              clinicExp: parseInt(experience.clinicExp),
              homeHealthExp: parseInt(experience.homeHealthExp),
              hospieExp: parseInt(experience.hospieExp),
              urgentCareExp: parseInt(experience.urgentCareExp),
              otherExp: parseInt(experience.otherExp),
            },
            intendToWork,
            covidVaccinated,
            intersetedShifts,
            qualificatioType: values.nurseType,
            preferredTimeToCall: "",
            physicalHealthPastYear: ""
          },
          password: values.password,
          gender: values.gender,
          dob: values.dob,
          lat: addressState.lat,
          lng: addressState.lng,
        }
        const resp = await dispatch(createNurseApi({formData, token}));
        if(resp.type === "admin/create-nurse/fulfilled"){
          showAlert({
            title: "Updated!",
            message: `Nurse created successfully!`,
            type: "success",
          });
          if(resp?.payload?.data?.user_info?.id){
            history.push(`/marketplace-profile/${resp?.payload?.data?.user_info?.id}`)
          }
        }else{
          let message = "Something went wrong!";
          if (resp.payload?.error?.message) {
            message = resp.payload?.error?.message;
          }
          showAlert({
            title: "Error!",
            message,
            type: "danger",
          });
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    },
  });
  const handleSetAddress = (address: any) => {
    let lat = 0,
      lng = 0,
      city = "",
      state = "";
    if (address.geometry) {
      lat = address.geometry.location.lat();
      lng = address.geometry.location.lng();
    }
    if (
      Array.isArray(address?.address_components) &&
      address?.address_components?.length
    ) {
      const address_components = getAddressComponents(
        address.address_components
      );
      city = address_components.city;
      state = address_components.state;
    }
    validation.setFieldValue("address", address.formatted_address);
    validation.setFieldValue("cityName", city);
    setAddressState({
      address: address.formatted_address,
      lat,
      lng,
      city,
      state,
    });
  };
  return (
    <React.Fragment>
      <div className="page-content Nurses AppBody">
        <MetaTags>
          <title>Create Nurse | Caregigs</title>
        </MetaTags>
        <div className="Nurses-container admin">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Create Nurse"
            links={[
              { title: "Home", link: "/" },
              { title: "Nurses", link: "/nurses" },
              { title: "Create Nurse", link: "/#" },
            ]}
          />

          <div className="Nurses-card">
            <Row className="mb-2">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                className="p-4"
              >
                <Row form>
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname &&
                              validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                          validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname &&
                              validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                          validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Type</Label>
                        <div>
                          <SelectPicker
                            data={nurseTypes}
                            placeholder={"Select Type"}
                            style={{ width: "100%" }}
                            onChange={(data: string) => {
                              validation.setFieldValue("nurseType", data)
                            }}
                            value={validation.values.nurseType}
                            searchable={false}
                            placement="bottomEnd"
                            className={(validation.touched.nurseType && validation.errors.nurseType) ? "border-danger" : ""}
                          />
                        </div>
                        {validation.touched.nurseType && validation.errors.nurseType ? (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {validation.errors.nurseType}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <Input
                          name="phone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Preferred Rate</Label>
                        <InputGroup>
                          <InputGroup.Addon>$</InputGroup.Addon>
                          <Input
                            name="rate"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.rate || ""}
                            invalid={
                              validation.touched.rate && validation.errors.rate
                                ? true
                                : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.rate && validation.errors.rate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.rate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Gender</Label>
                        <SelectPicker
                          data={genderList}
                          placeholder={"Select Gender"}
                          style={{ width: "100%" }}
                          onChange={(data: string) => {
                            validation.setFieldValue("gender", data)
                          }}
                          value={validation.values.gender}
                          searchable={false}
                          placement="bottomEnd"
                          className={(validation.touched.gender && validation.errors.gender) ? "border-danger" : ""}
                        />
                        {validation.touched.gender && validation.errors.gender ? (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {validation.errors.gender}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">DOB</Label>
                        <Input
                          name="dob"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={moment(validation.values.dob).format("YYYY-MM-DD") || ""}
                          invalid={
                            validation.touched.dob && validation.errors.dob ? true : false
                          }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                          <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Label className="form-label mb-3 h4">Address</Label>
                  <Row>
                    <div className="mb-3">
                      <Label className="form-label">Address</Label>
                      <AutoComplete
                        onSelect={handleSetAddress}
                        name="address"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address && validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address && validation.errors.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Door No.</Label>
                        <Input
                          name="doorNo"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.doorNo || ""}
                          invalid={
                            validation.touched.doorNo &&
                              validation.errors.doorNo
                              ? true
                              : false
                          }
                        />
                        {validation.touched.doorNo &&
                          validation.errors.doorNo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.doorNo}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Street Name</Label>
                        <Input
                          name="streetName"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.streetName || ""}
                          invalid={
                            validation.touched.streetName &&
                              validation.errors.streetName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.streetName &&
                          validation.errors.streetName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.streetName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">City</Label>
                        <Input
                          name="cityName"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cityName || ""}
                          invalid={
                            validation.touched.cityName &&
                              validation.errors.cityName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.cityName &&
                          validation.errors.cityName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.cityName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          What type of shift interested in?
                        </Label>
                        <CheckboxGroup
                          name="nurseShiftList"
                          inline
                          onChange={(val: ValueType[]) => setShiftInterested(val)}
                        >
                          {nurseShiftList.map((item) => (
                            <Checkbox
                              className="font-size-13"
                              key={item.id}
                              value={item.value}
                            >
                              {item.label}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          How many shifts do the nurse intended to work with
                          Caregigs each month ?
                        </Label>
                        <RadioGroup
                          name="nurseIntendList"
                          inline
                        // onChange={(value) =>
                        //   setFilters({ ...filters, speciality: value })
                        // }
                        >
                          {nurseIntendList.map((item) => (
                            <Radio
                              className="font-size-13"
                              key={item.id}
                              value={item.value}
                              onChange={(value) => setShiftIntended(item.value)}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Have received a negative TB test within the last year?
                        </Label>
                        <RadioGroup
                          name="negativeTBTest"
                          inline
                        >
                          {negativeTBTest.map((item) => (
                            <Radio
                              className="font-size-13"
                              key={item.id}
                              value={item.value}
                              onChange={(value) => setNegativeTb(item.value)}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Fully vaccinated against COVID-19?
                        </Label>
                        <RadioGroup
                          name="covidtest"
                          inline
                        >
                          {covidTest.map((item) => (
                            <Radio
                              className="font-size-13"
                              key={item.id}
                              value={item.value}
                              onChange={(value) => setCovid(item.value)}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <InputNumberField
                      fieldTitle="Total Work Experience:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          totalExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Post-Acute Care (SNF, ALF, CCRC):"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          postAcuteCareExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Acute Care Inpatient Hospitals:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          acuteCareExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Ambulatory Clinics/Surgical Centers:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          clinicExp: experienceValue,
                        })
                      }
                    />
                  </Row>
                  <Row>
                    <InputNumberField
                      fieldTitle="Home Health:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          homeHealthExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Hospice:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          hospieExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Urgent Care:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          urgentCareExp: experienceValue,
                        })
                      }
                    />
                    <InputNumberField
                      fieldTitle="Other Experience Duration:"
                      onChange={(experienceValue) =>
                        setExperience({
                          ...experience,
                          otherExp: experienceValue,
                        })
                      }
                    />
                  </Row>
                </Row>
                <div className="d-flex justify-content-end mt-1">
                  <Button
                    className="mx-2 p-2 d-flex justify-content flex-end"
                    color="primary"
                    type="submit"
                    size="lg"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddLeads);

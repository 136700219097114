import { gql } from "@apollo/client"
export const FetchAreas =
  gql`query FetchAreas (\$limit:Int,\$offset:Int,\$where:areas_bool_exp){
    areas (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      user_id
      active
      created_at
    }
    areas_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
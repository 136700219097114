import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineExclamationCircle } from "react-icons/ai";

// components
import ToolTip from "components/toolTip/ToolTip";

const MiniCards = (props) => {
  const { title, text, iconClass, link, tooltipText } = props;
  return (
    <React.Fragment>
      <Col md="3">
        <NavLink to={"/" + link} className=" ">
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium mb-2">
                    {title}{" "}
                    {title == "Total Nurses" && (
                      <ToolTip message={`Archived Nurses: ${tooltipText}`}>
                        <AiOutlineExclamationCircle />
                      </ToolTip>
                    )}
                  </p>
                  <h4 className="mb-0">{text}</h4>
                </div>
                <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                  <span className="avatar-title">
                    <i className={"bx " + iconClass + " font-size-24"} />
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </NavLink>
      </Col>
    </React.Fragment>
  );
};

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.any,
  title: PropTypes.string,
  link: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default MiniCards;

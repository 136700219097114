import PropTypes from "prop-types";
import React from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
import { layoutState } from "./toolkit/layout/layout.slice";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "rsuite/dist/rsuite.min.css";
import "flatpickr/dist/themes/material_blue.css";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}v1/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("TOKEN");
  // return the headers to the context so httpLink can read them
  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
        "x-hasura-role": "public",
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  const {
    layout: { layoutType },
  } = useSelector(layoutState);
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <ApolloProvider client={client}>
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
                restricted={route.restricted}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                role={route.role}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </ApolloProvider>
  );
};
export default App;

import React from 'react'

const More:React.FC<{user: any}> = ( {user}) => {
  return (
    <div>
      More
    </div>
  )
}

export default More

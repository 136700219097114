import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";

import GigshiftLogo from "assets/images/onboarding/Gigshift.png";
import BannerContent from "constants/bannerContent.json";

import OnboardingBanner from "components/Onboarding/OnboardingBanner";

import MapleShiftTable from "./MapleShiftTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const MapleShiftList: React.FC<{
  //
}> = (props) => {
  return (
    <React.Fragment>
      <div className="page-content GigShiftPage AppBody">
        <MetaTags>
          <title>Maple Shifts | Caregigs</title>
        </MetaTags>
        <div className="GigShiftPage-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Maple Shifts"
            links={[
              { title: "Home", link: "/" },
              { title: "Maple Shifts", link: "#" },
            ]}
          />
          <MapleShiftTable queryCondition={null} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MapleShiftList);

import React from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { Link } from "react-router-dom"
import { Dropdown } from 'rsuite';
import { GoKebabVertical } from 'react-icons/go'

import locationType from "./locations.types"
export const FormatData = (locations: locationType[]) => {
  const admins = locations.map((elem: locationType) => {
    const employees_count = elem?.employees_aggregate?.aggregate?.count;
    const positions_count = elem?.positions_aggregate?.aggregate?.count;
    return {
      id: elem.id,
      name: elem.name,
      email: elem.email,
      created_at: elem.created_at,
      address: elem.address || "N/A",
      type: elem.type,
      active: elem.isactive,
      buildings: (Array.isArray(elem?.buildings) && elem?.buildings?.length > 0) ? elem.buildings.join(", ") : "N/A",
      employees_count: isNaN(employees_count) ? 0 : employees_count,
      positions_count: isNaN(positions_count) ? 0 : positions_count,
      contactno: elem.contactno || "",
      lat:elem.lat,
      lng: elem.lng,
      areas_text: (Array.isArray(elem?.areas) && elem?.areas?.length > 0) ? elem.areas.map(area => area.area.name).join(", ") : "N/A",
      areas: elem?.areas,
      facility_details: elem.facility_details
    }
  })
  return admins
}
const formatHighlighter = (text: string, searchKey: string, filterValue: any) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role]
  }
  return <Highlighter
    searchWords={searchWords}
    autoEscape={true}
    textToHighlight={String(text)}
    highlightStyle={{ background: "#ff0" }}
  />
}
export const LocationColumns = (searchKey: string, filterValue: any, history: any, handleDelete: (id: string) => void, onEditClick: (location: locationType) => void, onQRClick: (location: locationType) => void) => [
  {
    dataField: 'id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'name',
    text: 'Location Name',
    sort: true,
    formatter: (cellContent: string, location: any) => (
      <div className="d-flex gap-3">
        <Link className="text-primary" to="#" onClick={() => onEditClick(location)}>
          {formatHighlighter(location.name, searchKey, filterValue)}
        </Link>
      </div>
    ),
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: (cellContent: string, location: any) => formatHighlighter(cellContent, searchKey, filterValue)
  }, 
  // {
  //   dataField: 'type',
  //   text: 'Type',
  //   sort: true,
  //   formatter: (cellContent: string, location: any) => formatHighlighter(cellContent, searchKey, filterValue)
  // }, 
  {
    dataField: 'positions_count',
    text: 'Positions',
    sort: true
  }, {
    dataField: 'employees_count',
    text: 'Staff',
    sort: true
  }, 
  // {
  //   dataField: 'areas_text',
  //   text: 'Areas',
  //   sort: true,
  //   formatter: (col: string, row: any) => { return <span style={{ display: 'block', width: 130, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{formatHighlighter(col, searchKey, filterValue)}</span> }
  // }, 
  {
    dataField: 'active',
    text: 'Status',
    sort: true,
    formatter: (cellContent: boolean, location: any) => (
      <p className={cellContent ? "text-success" : "text-danger"} style={{marginBottom: 0}}>{cellContent ? "Active" : "Inactive"}</p>
    )
  }, {
    text: "Created At",
    dataField: "created_at",
    sort: false,
    formatter: (cellContent: Date, row: any) => moment(cellContent).format("MM/DD/YYYY h:mm A")
  }, {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <Dropdown 
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        <Dropdown.Item onClick={()=> onEditClick(row)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={()=> onQRClick(row)}>
          Generate QR
        </Dropdown.Item>
        <Dropdown.Item onClick={()=> handleDelete(row.id)}>
          Delete
        </Dropdown.Item>
      </Dropdown>
    ),
}]
export const BuildLocationSearchQuery = (value: string, queryCondition: any) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { type: { _ilike: `%${value}%` } },
        { address: { _ilike: `%${value}%` } },
      ]
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildLocationFilterQuery = (filterValue: any, queryCondition: any) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("status")) {
      console.log(filterValue.active)
      conditionTemp = {
        ...conditionTemp,
        isactive: { _eq: filterValue.status === "true" ? true : false }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        const created_at_from = moment(filterValue.created_at[0]).format("YYYY-MM-DD")
        const created_at_till = moment(filterValue.created_at[1]).format("YYYY-MM-DD")
        conditionTemp = {
          ...conditionTemp,
          _and: [{ created_at: { _gte: created_at_from } }, { created_at: { _lte: created_at_till } }]
        }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
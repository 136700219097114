import { gql } from "@apollo/client";
export const FetchJobRequests = gql`
  query FetchJobRequests(
    $limit: Int
    $offset: Int
    $where: job_requests_bool_exp
  ) {
    job_requests(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { job_date: desc }
    ) {
      id
      status
      rejected_reason
      cancellation_reason
      created_at
      active
      clock_in_time
      clock_out_time
      cancelled_at
      in_sign
      out_sign
      in_sign_notes
      out_sign_notes
      actual_break_time
      payout_id
      is_confirmed
      job_date
      rating
      review
      break_times
      regular_hours
      overtime_hours
      facility_regular_charges
      facility_overtime_charges
      facility_total_charges
      nurse_regular_charges
      nurse_overtime_charges
      nurse_total_charges
      caregigs_margin
      notes
      maple_shift_assignment_id
      maple_shift_completion_id
      job_opening {
        id
        name
        job_date
        fee_amount
        currency
        fee_period
        maple_shift_id
        location {
          id
          name
        }
        position {
          id
          name
          type
          start_time
          end_time
          contract_rate
          contract_overtime_rate
        }
        filled
        organisation {
          name
          createdby
        }
      }
      invitation {
        id
        name
        job_date
        fee_amount
        currency
        fee_period
        invitation_by
        location {
          id
          name
        }
        position {
          id
          name
          type
          start_time
          end_time
          contract_rate
          contract_overtime_rate
        }
        organisation {
          id
          name
          createdby
        }
      }
      customer {
        id
        firstname
        lastname
        nurse_rate
        nurse_overtime_rate
        questionnaire
      }
    }
    job_requests_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const FetchJobRequestsForScheduler = gql`
  query FetchJobRequestsForScheduler($where: job_requests_bool_exp) {
    job_requests(where: $where, order_by: { created_at: desc_nulls_last }) {
      id
      status
      created_at
      active
      is_confirmed
      job_opening {
        id
        name
        fee_amount
        requirement
        job_date
        location {
          id
          name
        }
        position {
          name
          id
          abbreviation
          location {
            id
            name
          }
          text_color
          background_color
          start_time
          end_time
          type
        }
        filled
      }
      invitation {
        id
        name
        fee_amount
        requirement
        job_date
        location {
          id
          name
        }
        position {
          name
          id
          abbreviation
          location {
            id
            name
          }
          text_color
          background_color
          start_time
          end_time
          type
        }
      }
      customer {
        id
        firstname
        lastname
        email
        contactno1
        profile_picture
        gender
        preferred_rate
      }
      employee {
        id
        firstname
        lastname
        profession
        email
        contactno1
        contactno2
        type
        address
        text_color
        background_color
      }
    }
  }
`;

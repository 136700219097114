import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";
import { useParams } from "react-router-dom";

// icons
import { FiSend } from "react-icons/fi";

//types
import { notificationFormStateType } from "./notificationModal.types";

//function
import { useDispatch, useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { sendNotificationApi } from "toolkit/admin/admin.api";

const NotificationModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  notificationTokens: string[]
}> = ({ toggle, modal, notificationTokens }) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const {auth: {token}} = useSelector(authState)
  const [state, setState] = useState<notificationFormStateType>({
    title: "",
    message: ""
  })
  const [btnLoading, setBtnLoading] = useState<boolean>(false)

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: state.title,
      message: state.title,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true)
        const formData = {
          message: values.message,
          notification_token: notificationTokens,
          payload: {},
          title: values.title,
          type: "CUSTOM",
          customer_id: params?.id,
          role: "customer"
        }
        const resp = await dispatch(sendNotificationApi({formData, token}));
        if(resp.type === "admin/send-notification/fulfilled"){
          showAlert({
            title: "Updated!",
            message: `Notification sent successfully!`,
            type: "success",
          });
          handleClose()
        }else{
          showAlert({
            title: "Error!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      } catch (err) {
        showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
      } finally {
        setBtnLoading(false)
      }
    },
  });

  const handleClose = () => {
    toggle();
    validation.resetForm()
  };
  return (
    <Modal
      isOpen={modal}
      size="md"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Send Notification
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Title</Label>
                <Input
                  name="title"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title}
                  invalid={
                    validation.touched.title &&
                      validation.errors.title
                      ? true
                      : false
                  }
                />
                {validation.touched.title &&
                  validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Message</Label>
                <Input 
                  name="message" 
                  label="Message" 
                  type="textarea" 
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.message}
                  invalid={
                    validation.touched.message &&
                      validation.errors.message
                      ? true
                      : false
                  }
                />
                {validation.touched.message &&
                  validation.errors.message ? (
                  <FormFeedback type="invalid">
                    {validation.errors.message}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="text-end">
                <button 
                  className="btn btn-primary" 
                  type="submit"
                  disabled={btnLoading}
                >
                  <FiSend /> &nbsp; Send
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default NotificationModal;

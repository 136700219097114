import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { FiEdit2, FiChevronRight, FiLock } from "react-icons/fi";
import Breadcrumbs from "../../components/Common/Breadcrumb";

//components
import EditProfile from "../../components/profile/EditProfile";
import ChangePassword from "../../components/profile/ChangePassword";

const Profile:React.FC = () => {
  const [tab, setTab] = useState<number>(1);
  return (
    <React.Fragment>
      <div className="page-content ProfilePage AppBody">
        <MetaTags>
          <title>Profile | Caregigs - On Demand Healthcare Professionals</title>
        </MetaTags>
        <div className="ProfilePage-container" style={{marginBottom: "10rem"}}>
          <div className="row" >
            <div className="col-12" >
              <Breadcrumbs 
                title="Home" 
                links={[{title: "Home", link: "/"}, {title: "Profile", link: "#"}]} 
              />
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-3">
              <div className="ProfilePage-card card_sticky_top">
                <h4 className="ProfilePage-header d-none d-lg-flex" >Menu</h4>
                <div className="ProfilePage-menu" >
                  <div
                    className={`ProfilePage-menu_item ${
                      tab == 1 ? "active" : null
                    } `}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    <FiEdit2 className="ProfilePage-menu_item-icon" style={{marginTop: -4}}/>
                    <p style={{marginBottom: 0}}>Edit Profile</p>
                    <div className="ProfilePage-menu_item_activeIcon">
                      <FiChevronRight />
                    </div>
                  </div>
                  <div
                    className={`ProfilePage-menu_item ${
                      tab == 2 ? "active" : null
                    } `}
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    <FiLock className="ProfilePage-menu_item-icon" style={{marginTop: -3}} />
                    <p style={{marginBottom: 0}}>Change Password</p>
                    <div className="ProfilePage-menu_item_activeIcon">
                      <FiChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              {tab == 1 && (
                <EditProfile />
              )}
              {tab == 2 && (
                <ChangePassword />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Profile;
import React, { useState, useEffect } from 'react';
import { Panel, Loader } from "rsuite";
import { Button } from "reactstrap";
import { FiCheck, FiX, FiRefreshCw } from "react-icons/fi";
import Swal from 'sweetalert2';
import { showAlert } from "helpers/index";
import moment from "moment";

//components
import DocumentRejectModal from "components/documents/DocumentRejectModal";
import ImageViewer from "components/documents/ImageViewer";

//GraphQL
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { FetchDocuments } from "gql/documents/query";
import { UpdateDocument } from "gql/documents/mutation";

//helpers
import { getImage, getDocumentStatus } from "helpers";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";


export interface DocumentType {
	id: string;
	status: string;
	name: string;
	document_url: string
	category: string;
	document_info: {
		documentNumber: string;
		issusingAuthority: string;
		expireDate: string;
	}
}

const Licenses: React.FC<{ user: any }> = ({ user }) => {
	const { auth: { token, user: { role } } } = useSelector(authState)
	const [documents, setDocuments] = useState<DocumentType[]>([])
	const [images, setImages] = useState<string[]>([])
	const [selectedDocument, setSelectedDocument] = useState<any>(null)
	const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)

	const [UpdateDocumentMutation] = useMutation(UpdateDocument)
	const [getDocumentList, { loading, data, refetch, error, called }] = useLazyQuery(FetchDocuments, {
		variables: {
			where: { practioner_id: { _eq: user.id }, category: { _eq: "LICENSE" } }
		}
	})
	const handleGetImages = async (documents: DocumentType[]) => {
		const promises: any[] = []
		documents.map((document: DocumentType) => {
			if(document.document_url?.length){
				promises.push(getImage(document.document_url, token, "image"))
			}
		})
		const _images = await Promise.all(promises)
		setImages(_images)
	}
	const handleVerifyDocument = async (status: string, document: DocumentType) => {
		Swal.fire({
			title: ``,
			text: `Are you sure want to ${status === "verified" ? "approve" : "reject"} this document?`,
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (status === "verified") {
					try {
						await UpdateDocumentMutation({
							variables: {
								id: document.id,
								object: {
									status,
									verified_at: moment().toDate()
								}
							}
						})
						refetch()
						showAlert({
							title: "Updated!",
							message: "Document verified successfully",
							type: "success",
						});
					} catch (err) {
						showAlert({
							title: "Error!",
							message: "Something Went Wrong",
							type: "danger",
						});
					}
				} else {
					setSelectedDocument(document)
					setRejectionModalOpen(true)
				}
			}
		})
	}
	useEffect(() => {
		if (user?.id) {
			getDocumentList()
		}
	}, [user])
	useEffect(() => {
		if (Array.isArray(data?.documents) && data?.documents?.length) {
			setDocuments(data?.documents)
			handleGetImages(data?.documents)
		}
	}, [data])
	return (
		<div>
			{loading ? (
				<div className="d-flex justify-content-center mt-5">
					<Loader size="md" />
				</div>
			) : (
				<>
					{documents.length > 0 ? (
						<>
						{documents.map((document: DocumentType, i: number) => (
						<Panel 
							bordered 
							header={role === "moderator" ? "" : "License"} 
							key={i}
							className="mb-3"
						>
							<div className="information-info mb-3" >
								{role === "moderator" && (
									<div className='d-flex justify-content-between'>
										<p>Status: <span className={getDocumentStatus(document).class}>{getDocumentStatus(document).value}</span></p>
										<div className='btn-group'>
											{document?.status !== "verified" && (
												<Button
													color="success"
													outline
													size="sm"
													style={{ height: 30, marginRight: 5 }}
													to="#"
													onClick={() => handleVerifyDocument("verified", document)}
												>
													<FiCheck size={15} />
													Approve
												</Button>
											)}
											{document.status !== "not_verified" && (
											<Button
												color="danger"
												outline
												size="sm"
												style={{ height: 30 }}
												to="#"
												onClick={() => handleVerifyDocument("not_verified", document)}
											>
												{document.status === "verified" ? <FiRefreshCw size={12} /> : <FiX size={15} />}
												{document.status === "verified" ? " Revoke" : "Reject"}
											</Button>
											)}
										</div>
									</div>
								)}
								<p>Issuing Authority: {document?.document_info?.issusingAuthority || user?.questionnaire?.status}</p>
								<p>Document Number: {document?.document_info?.documentNumber || user?.questionnaire?.license}</p>
								<p>Expiration Date: {document?.document_info?.expireDate || "N/A"}</p>
							</div>
							{images[i]?.length > 0 && (
								<img 
									src={document?.document_url.includes("_pdf_") ? "/pdf_placeholder.webp" : images[i]} 
									height="150" 
									className='cursor-pointer'
									onClick={()=>{
										setShowModal(true)
										setSelectedDocument(document)
									}}
								/>
							)}
						</Panel>
						))}
						</>
					) : (
						<p>License Details to go here</p>
					)}
				</>
			)}
			<DocumentRejectModal
				isOpen={rejectionModalOpen}
				toggle={() => setRejectionModalOpen(false)}
				document={selectedDocument}
				refetch={refetch}
			/>
			<ImageViewer
				isOpen={showModal}
				toggle={() => {
					setShowModal(false)
					setSelectedDocument(null)
				}}
				document={selectedDocument}
			/>
		</div>
	)
}

export default Licenses

import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Input,
	Form,
	Col,
	Row,
	FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";

// icons
import { FiCheck } from "react-icons/fi";
import DocumentType from "pages/MarketPlaceProfile/tabs/documents/documents.types";

//graphql
import { useMutation } from "@apollo/client";
import { UpdateDocument } from "gql/documents/mutation";
import { UpdateCustomer } from "gql/profile/mutation";

const DocumentRejectModal: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	document: DocumentType | null;
	refetch: () => void
}> = ({ isOpen, toggle, document, refetch }) => {
	const [notes, setNotes] = useState<string>("")
	const [btnLoading, setBtnLoading] = useState<boolean>(false)

	const [UpdateDocumentMutation] = useMutation(UpdateDocument)
	const [UpdateCustomerMutation] = useMutation(UpdateCustomer)

	const handleClose = () => {
		toggle()
		validation.resetForm()
		setNotes("")
	}
	// validation
	const validation: any = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			notes: notes || ""
		},
		validationSchema: Yup.object({
			notes: Yup.string().required("Please Enter Notes")
		}),
		onSubmit: async (values) => {
			try {
				setBtnLoading(true)
				const promises: any[] = []
				promises.push(UpdateDocumentMutation({
					variables: {
						id: document?.id,
						object: {
							status: "not_verified",
							verified_at: null
						}
					}
				}))
				promises.push(UpdateCustomerMutation({
					variables: {
						id: document?.createdby,
						object: {
							onboarding_documents_comments: values.notes
						}
					}
				}))
				await Promise.all(promises)
				refetch()
				showAlert({
          title: "Updated!",
          message: "Document rejected successfully!",
          type: "success",
        });
				handleClose()
			} catch (err) {
				showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
			} finally {
				setBtnLoading(false)
			}
		},
	});

	useEffect(() => {
		if (document?.customer?.onboarding_documents_comments) {
			setNotes(document?.customer?.onboarding_documents_comments)
		}
	}, [document])
	return (
		<Modal
			isOpen={isOpen}
			size="md"
			backdrop="static"
			className="customModal"
			toggle={handleClose}
		>
			<ModalHeader toggle={handleClose} tag="h4">
				Rejection Note
			</ModalHeader>
			<ModalBody>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
				>
					<Row form>
						<Col xs={12}>
							<div className="mb-3">
								<Label className="form-label">Notes</Label>
								<Input
									name="notes"
									label="Notes"
									type="textarea"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.notes || ""}
									invalid={
										validation.touched.notes &&
											validation.errors.notes
											? true
											: false
									}
								/>
								{validation.touched.notes &&
									validation.errors.notes ? (
									<FormFeedback type="invalid">
										{validation.errors.notes}
									</FormFeedback>
								) : null}
							</div>
							<div className="text-end">
								<button
									className="btn btn-primary"
									type="submit"
									disabled={btnLoading}
								>
									<FiCheck /> &nbsp;
									Submit
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</Modal>
	)
}
export default DocumentRejectModal
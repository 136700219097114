import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Button, Row, Col, Breadcrumb, Panel, PanelGroup } from "rsuite";
import { Form, Input } from "reactstrap";

import { useDebounce } from "react-use";
import Highlighter from "react-highlight-words";

import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";

//GraphQL
import { useLazyQuery } from "@apollo/client";
import { FetchFaqs } from "gql/faq/query";

import { BuildJobRequestSearchQuery } from "../Admin/FAQs/faqs.functions";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
const FacilityHelpSection: React.FC = () => {
  const initialCondition = { category: { _eq: "Facility" } };

  const [searchKey, setSearchKey] = useState("");
  const {
    auth: { token },
  } = useSelector(authState);
  const [queryCondition, setQueryCondition] = useState<any>({
    category: { _eq: "Facility" },
  });
  const [getFaqs, { loading, data, refetch, error, called }] = useLazyQuery(
    FetchFaqs,
    {
      variables: {
        where: queryCondition,
      },
    }
  );

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobRequestSearchQuery(value, null);
    let _cond = {};
    if (queryCondition !== null) {
      _cond = queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };

  useDebounce(
    () => {
      if (searchKey.length > 0) {
        handleSearch(searchKey);
      } else {
        let _cond = {};
        _cond = initialCondition;
        setQueryCondition(_cond);
      }
    },
    1500,
    [searchKey]
  );

  useEffect(() => {
    // if (Object.keys(queryCondition).length) {
    getFaqs();
    // }
  }, []);

  const formatHighlighter = (text: string, searchKey: string) => {
    const searchWords = [searchKey];

    return (
      <Highlighter
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={String(text)}
        highlightStyle={{ background: "#ff0" }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="HelpPage" style={{ height: "auto" }}>
        <div className="HelpPage-Navbar">
          <Link to="/help">
            <img
              src={"/caregigs_logo.png"}
              alt=""
              className="HelpPage-NavbarLogo"
            />
          </Link>
          <div className="HelpPage-NavbarItems">
            <div className="HelpPage-NavbarItem1">
              <NavLink to={"/help/contact-us"}>
                <p className="HelpPage-NavbarItemText1">Contact Us</p>
              </NavLink>
            </div>
            <div>
              <NavLink to={"/login"}>
                <p className="HelpPage-NavbarItemText2">
                  {token ? "Dashboard" : "Log In"}
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="help-breadcrumb">
          <Breadcrumb separator={<AngleRightIcon />}>
            <Breadcrumb.Item>
              <Link to="/help">Help</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Facilities</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: -45 }}
          >
            <Col md={6}>
              <Input
                name="search"
                placeholder="Search here..."
                type="text"
                onChange={(e: any) => setSearchKey(e.target.value)}
                value={searchKey}
                style={{ borderRadius: 20 }}
              />
            </Col>
            <Col>
              <Button
                type="submit"
                color="blue"
                appearance="subtle"
                className="ml-5 border border-primary"
                style={{ borderRadius: 20 }}
                size="sm"
                onClick={(e) => handleSearch(searchKey)}
                disabled={searchKey.length == 0 ? true : false}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="HelpPage-ClipboardCardContainer">
          <Col md={12} className="HelpPage-ClipboardCard">
            <p className="HelpPage-ClipboardCardHeader">Facilities</p>
            <p className="HelpPage-ClipboardCardSubtitle">
              Help articles for our partner facilities.
            </p>
            <PanelGroup accordion bordered style={{ marginBottom: 60 }}>
              {data?.faqs?.map((event: any, i: any) => (
                <Panel
                  header={event.title}
                  key={i}
                  defaultExpanded={searchKey ? true : false}
                >
                  <p className="HelpPage-ClipboardCardDescription">
                    {formatHighlighter(event.description, searchKey)}
                  </p>
                </Panel>
              ))}
            </PanelGroup>
          </Col>
        </Row>
        <div className="HelpPage-footer">
          <p className="HelpPage-footerText">
            © 2023 Caregigs LLC | All Rights Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(FacilityHelpSection);

import { gql } from "@apollo/client";

export const FetchSubscriber = gql`
  query FetchSubscriber($where: notification_subscribers_bool_exp) {
    notification_subscribers(where: $where) {
      id
      email
      contact_name
      contact_number
    }
  }
`;

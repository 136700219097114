import React, { ReactNode, useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { List } from "rsuite";
import Swal from "sweetalert2";

import {
  AiOutlineQuestionCircle,
  AiOutlineCheck,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { BsExclamationCircle } from "react-icons/bs";
import moment from "moment";

import { showAlert } from "helpers/index";
//data
import {
  IDEnums,
  TBScreeningEnums,
  EmploymentTypeEnums,
  I9Enums,
  DementiaTrainingEnums,
  SSNCardEnums,
  WorkAuthorizationEnums,
} from "constants/documentCategories";

//GraphQL
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { FetchDocuments } from "gql/documents/query";
import { UpdateCustomer } from "gql/profile/mutation";
import { UpdateSSNPermission } from "gql/facilities/mutation";
import { FetchFacilityDetails } from "gql/facilities/query";

//type
import DocumentType from "./documents.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const DocumentList: React.FC<{
  queryCondition: any;
  user: any;
  onClickList: (category: string) => void;
}> = (props) => {
  const client = useApolloClient();
  const [UpdateCustomerMutation] = useMutation(UpdateCustomer);
  const [UpdateSSNMutation] = useMutation(UpdateSSNPermission);
  const initialCondition = {
    practioner_id: { _eq: props.user.id },
    category: { _neq: "LICENSE" },
  };
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const {
    auth: { user },
  } = useSelector(authState);
  const { loading, data } = useQuery(FetchDocuments, {
    variables: {
      where: initialCondition,
    },
    fetchPolicy: "no-cache",
  });
  const checkDocumentStatus = (documents: DocumentType[]) => {
    let status = "";
    const _uploaded = documents.filter(
      (document: DocumentType) => document.status === "uploaded"
    );
    const _verified = documents.filter(
      (document: DocumentType) => document.status === "verified"
    );
    if (_uploaded.length) {
      status = "Action Needed";
    } else if (_verified.length === documents.length) {
      status = "Verified";
    } else {
      status = "Not Verified";
    }
    return status;
  };
  const getDocumentStatus = (category: string) => {
    let status = "Missing";
    if (
      [
        "CORI",
        "BACKGROUND_AUTHORIZATION",
        "RESUME",
        "ANNUAL_PHYSICAL",
        "COVID_VACCINE",
        "COVID_TEST_RESULTS",
        "FLU_SHOT",
        "CPR",
        "DEMENTIA_TRAINING",
        "US_SSN_CARD",
        "WORK_AUTHORIZATION",
      ].includes(category)
    ) {
      const _cori = documents.filter(
        (document: DocumentType) => document.category === category
      );
      if (_cori.length) {
        status = checkDocumentStatus(_cori);
      }
    } else if (category === "TB_SCREENING") {
      const _tb_screening = documents.filter((document: DocumentType) =>
        TBScreeningEnums.includes(document.category)
      );
      if (_tb_screening.length) {
        status = checkDocumentStatus(_tb_screening);
      }
    } else if (category === "ID") {
      const _id = documents.filter((document: DocumentType) =>
        IDEnums.includes(document.category)
      );
      if (_id.length) {
        status = checkDocumentStatus(_id);
      }
    } else if (category === "EMPLOYMENT_TYPE") {
      const _emp_type = documents.filter((document: DocumentType) =>
        EmploymentTypeEnums.includes(document.category)
      );
      if (_emp_type.length) {
        status = checkDocumentStatus(_emp_type);
      }
    } else if (category === "I_9") {
      const _i9 = documents.filter((document: DocumentType) =>
        I9Enums.includes(document.category)
      );
      if (_i9.length) {
        status = checkDocumentStatus(_i9);
      }
    } else if (category === "DEMENTIA_TRAINING") {
      const _dementia_training = documents.filter((document: DocumentType) =>
        DementiaTrainingEnums.includes(document.category)
      );
      if (_dementia_training.length) {
        status = checkDocumentStatus(_dementia_training);
      }
    } else if (category === "US_SSN_CARD") {
      const _us_ssn_card = documents.filter((document: DocumentType) =>
        SSNCardEnums.includes(document.category)
      );
      if (_us_ssn_card.length) {
        status = checkDocumentStatus(_us_ssn_card);
      }
    } else if (category === "WORK_AUTHORIZATION") {
      const _work_auth = documents.filter((document: DocumentType) =>
        WorkAuthorizationEnums.includes(document.category)
      );
      if (_work_auth.length) {
        status = checkDocumentStatus(_work_auth);
      }
    }
    return status;
  };
  const renderStatusIcon = (status: string) => {
    let icon: ReactNode = null;
    if (status === "Missing")
      icon = (
        <AiOutlineQuestionCircle
          className="mx-2"
          style={{ color: "#f1c451" }}
        />
      );
    else if (status === "Verified")
      icon = <AiOutlineCheck className="mx-2" style={{ color: "#34c38f" }} />;
    else if (status === "Not Verified")
      icon = (
        <BsExclamationCircle className="mx-2" style={{ color: "#f46a6a" }} />
      );
    else if (status === "Action Needed")
      icon = (
        <AiOutlineExclamationCircle
          className="mx-2"
          style={{ color: "#f1c451" }}
        />
      );
    return icon;
  };
  const renderDocumentStatus = (category: string) => {
    let jsx: ReactNode = null;
    if (loading) {
      jsx = <Spinner size="sm" />;
    } else {
      const status = getDocumentStatus(category);
      jsx = (
        <>
          <div
            style={{
              color:
                status === "Verified"
                  ? "#34c38f"
                  : status === "Not Verified"
                  ? "#f46a6a"
                  : "#f1c451",
            }}
          >
            {status}
          </div>
          {renderStatusIcon(status)}
        </>
      );
    }
    return jsx;
  };
  const handleUpdateDocumentVerifyStatus = async (status: boolean) => {
    try {
      await UpdateCustomerMutation({
        variables: {
          id: props.user.id,
          object: {
            onboarding_documents_verified: status,
            onboarding_documents_submitted: status,
            onboarding_documents_verified_date: moment().format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          },
        },
      });
      client.refetchQueries({
        include: ["FetchCustomerDetailsAdmin"],
      });
    } catch (err) {}
  };
  useEffect(() => {
    if (Array.isArray(data?.documents) && data?.documents?.length) {
      setDocuments(data.documents);
    }
  }, [data]);
  useEffect(() => {
    if (
      Array.isArray(data?.documents) &&
      data?.documents?.length &&
      user?.role === "moderator"
    ) {
      const verifiedDocuments = data.documents
        .filter((doc: DocumentType) => doc.status === "verified")
        .map((doc: DocumentType) => doc.category);
      const _check_cori = verifiedDocuments.includes("CORI");
      const _check_bg = verifiedDocuments.includes("BACKGROUND_AUTHORIZATION");
      const _check_ssn = verifiedDocuments.includes("US_SSN_CARD");
      const _check_id = verifiedDocuments.some(
        (doc: string) => IDEnums.indexOf(doc) >= 0
      );
      // const _check_tb_screening = verifiedDocuments.some(
      //   (doc: string) => TBScreeningEnums.indexOf(doc) >= 0
      // );
      const _check_vaccine = verifiedDocuments.includes("COVID_VACCINE");

      if (
        _check_cori &&
        _check_id &&
        _check_bg &&
        _check_vaccine &&
        _check_ssn
      ) {
        if (!props.user.onboarding_documents_verified) {
          //check if already verified
          handleUpdateDocumentVerifyStatus(true);
        }
      } else {
        //if one of the document is rejected
        if (props.user.onboarding_documents_verified) {
          //skip if already not verified
          handleUpdateDocumentVerifyStatus(false);
        }
      }
    }
  }, [data, props.user]);

  const handleViewRequest = () => {
    try {
      Swal.fire({
        title: ``,
        text: `Do you want to request access to view the Document?`,
        showCancelButton: true,
        confirmButtonText: "Request",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UpdateSSNMutation({
            variables: {
              id: user.organisation_id,
              object: {
                view_ssn: "Requested",
              },
            },
          });
          showAlert({
            title: "Requested!",
            message: "Request sent successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

  const [facilityProfile, setFacilityProfile] = useState<any>(null);
  const {
    loading: orgLoading,
    data: orgData,
    refetch,
  } = useQuery(FetchFacilityDetails, {
    variables: {
      id: user.id,
    },
  });

  useEffect(() => {
    if (orgData?.users_by_pk) {
      const customerData = [orgData?.users_by_pk];
      if (customerData?.length) {
        setFacilityProfile(customerData[0]);
      }
    }
  }, [orgData]);

  return (
    <Row>
      <Col lg="12">
        <React.Fragment>
          <Row>
            <Col xl="12">
              <div className="table-responsive">
                <List hover>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        if (user?.role === "moderator") {
                          props.onClickList("CORI");
                        } else {
                          showAlert({
                            title: "",
                            message:
                              "As per DPH regulations we can't show you the document!",
                            type: "warning",
                          });
                        }
                      }}
                    >
                      <Col>CORI Form</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("CORI")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() =>
                        props.onClickList("BACKGROUND_AUTHORIZATION")
                      }
                    >
                      <Col>Background Authorization Form</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("BACKGROUND_AUTHORIZATION")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("RESUME")}
                    >
                      <Col>Resume</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("RESUME")}
                      </Col>
                    </Row>
                  </List.Item>
                  {/* <List.Item>
                    <Row className="cursor-pointer" onClick={()=>props.onClickList("EMPLOYMENT_TYPE")}>
                      <Col>Employment Type</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("EMPLOYMENT_TYPE")}
                      </Col>
                    </Row>
                  </List.Item> */}
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("ID")}
                    >
                      <Col>ID</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("ID")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        if (user?.role === "moderator") {
                          props.onClickList("US_SSN_CARD");
                        } else if (
                          facilityProfile.organisation.view_ssn == "Grant"
                        ) {
                          props.onClickList("US_SSN_CARD");
                        } else if (
                          facilityProfile.organisation.view_ssn == "Deny"
                        ) {
                          showAlert({
                            title: "",
                            message: "Your request has been denied!",
                            type: "warning",
                          });
                          handleViewRequest();
                        } else if (
                          facilityProfile.organisation.view_ssn == "Requested"
                        ) {
                          showAlert({
                            title: "",
                            message: "Waiting for admin approval!",
                            type: "warning",
                          });
                        } else {
                          handleViewRequest();
                        }
                      }}
                    >
                      <Col>Social Security Card</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("US_SSN_CARD")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("ANNUAL_PHYSICAL")}
                    >
                      <Col>Physical Test</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("ANNUAL_PHYSICAL")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("TB_SCREENING")}
                    >
                      <Col>TB Screening</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("TB_SCREENING")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("COVID_VACCINE")}
                    >
                      <Col>Covid-19 Vaccine</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("COVID_VACCINE")}
                      </Col>
                    </Row>
                  </List.Item>
                  {/* <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("COVID_TEST_RESULTS")}
                    >
                      <Col>Covid Test Results</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("COVID_TEST_RESULTS")}
                      </Col>
                    </Row>
                  </List.Item> */}
                  {/* <List.Item>
                    <Row className="cursor-pointer" onClick={()=>props.onClickList("I_9")}>
                      <Col>I-9</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("I_9")}
                      </Col>
                    </Row>
                  </List.Item> */}
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("FLU_SHOT")}
                    >
                      <Col>Flu-Shot</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("FLU_SHOT")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => props.onClickList("CPR")}
                    >
                      <Col>CPR</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("CPR")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        if (user?.role === "moderator") {
                          props.onClickList("DEMENTIA_TRAINING");
                        } else {
                          showAlert({
                            title: "",
                            message:
                              "As per DPH regulations we can't show you the document!",
                            type: "warning",
                          });
                        }
                      }}
                    >
                      <Col>Dementia Training Certificate</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("DEMENTIA_TRAINING")}
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        if (user?.role === "moderator") {
                          props.onClickList("WORK_AUTHORIZATION");
                        } else {
                          showAlert({
                            title: "",
                            message:
                              "As per DPH regulations we can't show you the document!",
                            type: "warning",
                          });
                        }
                      }}
                    >
                      <Col>Work Authorization</Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        {renderDocumentStatus("WORK_AUTHORIZATION")}
                      </Col>
                    </Row>
                  </List.Item>
                </List>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      </Col>
    </Row>
  );
};
export default DocumentList;

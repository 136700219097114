import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal, Label, Input, FormFeedback } from "reactstrap";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";
import { Checkbox } from "rsuite";

import SelectPickerPaginate from "components/Filters/SelectPickerPaginate";

//helpers
import { enumerateDaysBetweenDates } from "../../helpers/index";

//GraphQL
import { FetchPositionsForFilter } from "gql/positions/query";
import { FetchJobOpeningsForScheduler } from "gql/jobOpenings/query";
import { UpdateJobOpeningBulk } from "gql/jobOpenings/mutation";
import { InsertJobRequests } from "gql/jobRequests/mutation";
import { FetchCustomersForFilter, FetchCustomersViewForFilter} from "gql/customers/query";
import { FetchFacilitiesViewForFilter } from "gql/facilities/query";
import { FetchCustomerDetailsAdmin } from "gql/customers/query";
import { InsertInvitation } from "gql/invitations/mutation";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";

//function
import { useDispatch, useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { notifySubscribers } from "toolkit/admin/admin.api";
import { FetchEmployees } from "gql/employees/query";

const initialState: any = {
  fee_amount: "0",
  currency: "",
  fee_period: "",
  facility_details: "",
  special_instructions: "",
  customer_id: "",
  facility_id: "",
  position_id: "",
  available_from: new Date(),
  available_till: new Date(),
};

const CreateShiftAdmin: React.FC<{
  modal_center: boolean;
  tog_center: () => void;
  selectedDate: Date;
}> = ({ modal_center, tog_center, selectedDate }) => {
  const client = useApolloClient();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const {
    auth: { token, user },
  } = useSelector(authState);
  const dispatch = useDispatch();

  const [state, setState] = useState<any>(initialState);
  const [isRecurring, setRecurring] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<any>();
  const [selectedFacility, setSelectedFacility] = useState<any>();
  const [CreateJobRequestMutation, { loading }] =
    useMutation(InsertJobRequests);
  const [UpdateJobOpeningBulkMutation] = useMutation(UpdateJobOpeningBulk);
  const [CreateInvitationMutation, { loading: invitationLoading }] =
    useMutation(InsertInvitation);

  const handleReset = () => {
    setState({
      ...state,
      position_id: "",
      available_from: selectedDate,
      available_till: selectedDate,
      customer_id: "",
      facility_id: "",
      special_instructions: "",
      fee_amount: "0",
    });
    setSelectedPosition(undefined);
    setSelectedFacility(undefined);
    setRecurring(false);
    validation.resetForm();
  };
  const handleRefetch = () => {
    client.refetchQueries({
      include: ["FetchJobRequests"],
    });
    handleReset();
  };
  const handleClose = () => {
    tog_center();
    handleReset();
  };
  const handleCreateShift = async (dates: Date[], values: any) => {
    const overlappingOpenShifts = await checkOpenShifts(dates, values);
    const objects: any[] = [];
    dates.map((date: Date) => {
      objects.push({
        name: "N/A",
        location_id: selectedPosition.location.id,
        isactive: true,
        fee_amount: values.fee_amount ? parseInt(values.fee_amount) : 0,
        currency: "USD",
        fee_period: "per_hour",
        job_date: moment(date).format("YYYY-MM-DD"),
        requirement: {
          facility_details: [""],
          special_instructions: [values.special_instructions],
        },
        position_id: values.position_id,
        customer_id: values.customer_id,
        organisation_id: selectedFacility.organisation_id,
        status: "accepted",
        createdby: selectedFacility.id,
        updatedby: selectedFacility.id,
        invitation_by: "admin",
      });
    });
    try {
      const resp = await CreateInvitationMutation({
        variables: {
          objects,
        },
      });
      if (overlappingOpenShifts.length) {
        //overriding overlapping open shifts
        await UpdateJobOpeningBulkMutation({
          variables: {
            ids: overlappingOpenShifts.map((ovShift: any) => ovShift.id),
            object: {
              is_open_shift: false,
            },
          },
        });
      }
      if (resp?.data) {
        const invitationIds = resp.data?.insert_invitations?.returning;
        if (invitationIds.length && Array.isArray(invitationIds)) {
          const jobRequestObjects: any[] = [];
          invitationIds.map((invite) => {
            // console.log(moment(invite.job_date).isBefore(moment(), "day"));
            jobRequestObjects.push({
              invitation_id: invite.id,
              job_date: invite.job_date,
              status: moment(invite.job_date).isBefore(moment(), "day")
                ? "completed"
                : "approved",
              practitioner_id: values.customer_id,
              createdby: selectedFacility.id,
              updatedby: selectedFacility.id,
              is_confirmed: true,
              invitation_by: "admin",
            });
          });
          const formData = {
            event: "NURSE_SHIFT_CONFIRM",
            organisation_id: selectedFacility.organisation_id,
            nurse_id: values.customer_id,
            position_id: values.position_id,
            date: moment(validation.values.available_from).format("LL"),
          };
          await CreateJobRequestMutation({
            variables: {
              objects: jobRequestObjects,
            },
          });
          await dispatch(notifySubscribers({ formData, token }));
          handleClose();
          handleRefetch();
          Swal.fire("Shift Created Successfully", "", "success");
        }
      } else {
        handleClose();
        handleRefetch();
        Swal.fire("Shift Created Successfully", "", "success");
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong", "", "warning");
    }
  };
  const checkDateOverlap = async (dates: Date[], values: any) => {
    try {
      const resp = await client.query({
        query: FetchJobOpeningsForScheduler,
        variables: {
          where: {
            createdby: { _eq: values.facility_id },
            job_date: {
              _in: dates.map((date) => moment(date).format("YYYY-MM-DD")),
            },
            position_id: { _eq: values.position_id },
            isactive: { _eq: true },
          },
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_openings || [];
    } catch (err) {
      return null;
    }
  };
  const checkOpenShifts = async (dates: Date[], values: any) => {
    try {
      const resp = await client.query({
        query: FetchJobOpeningsForScheduler,
        variables: {
          where: {
            createdby: { _eq: values.facility_id },
            job_date: {
              _in: dates.map((date) => moment(date).format("YYYY-MM-DD")),
            },
            position_id: { _eq: values.position_id },
            isactive: { _eq: false },
            is_open_shift: { _eq: true },
          },
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_openings || [];
    } catch (err) {
      return null;
    }
  };
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fee_amount: (state && state.fee_amount) || "",
      currency: (state && state.currency) || "USD",
      // facility_details: (state && state.facility_details) || '',
      special_instructions: (state && state.special_instructions) || "",
      customer_id: (state && state.customer_id) || "",
      facility_id: (state && state.facility_id) || "",
      position_id: (state && state.position_id) || "",
      available_from: (state && state.available_from) || new Date(),
      available_till: (state && state.available_till) || new Date(),
    },
    validationSchema: Yup.object({
      fee_amount: Yup.number()
        .min(0, "Rate should not be in negative value")
        .required("Please Enter Rate"),
      customer_id: Yup.string().required("Please Select Nurse"),
      facility_id: Yup.string().required("Please Select Facility"),
      position_id: Yup.string().required("Please Select Position"),
      available_till: Yup.date().test(
        "available_till_test",
        "Shift End Date Should Not be Prior to Start Date",
        function (value) {
          const { available_from } = this.parent;
          if (isRecurring) {
            return !moment(value).isBefore(available_from);
          } else {
            return true;
          }
        }
      ),
    }),
    onSubmit: async (values) => {
      const dates = enumerateDaysBetweenDates(
        values.available_from,
        isRecurring ? values.available_till : values.available_from
      );
      const overlappingShifts = await checkDateOverlap(dates, values);
      // console.log(dates);
      // console.log(overlappingShifts);
      //   if (dates == overlappingShifts.job_date) {
      //     showAlert({
      //       title: "Warning",
      //       message: "Selected dates are assigned!",
      //       type: "warning",
      //     });
      //   } else
      if (Array.isArray(overlappingShifts) && overlappingShifts?.length > 0) {
        //check for overlapping shifts

        Swal.fire({
          title: `Some Shift Dates are Overlapping!`,
          text: "Do you want to create other non-overlapping shifts?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          icon: "warning",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const nonOverlappingDates: Date[] = [];
            const overlappingDates: string[] = overlappingShifts.map(
              (_shift) => _shift.job_date
            );
            dates.map((date) => {
              if (
                !overlappingDates.includes(moment(date).format("YYYY-MM-DD"))
              ) {
                nonOverlappingDates.push(date);
                console.log(nonOverlappingDates, "NON-OVERLAPPING DATES");
              }
            });
            handleCreateShift(nonOverlappingDates, values);
          }
        });
      } else {
        handleCreateShift(dates, values);
      }
    },
    // },
  });
  const handleSelectPosition = async (newValue: string) => {
    validation.setFieldValue("position_id", newValue);
    const resp = await client.query({
      query: FetchPositionsForFilter,
      variables: {
        limit: 1,
        offset: 0,
        where: { id: { _eq: newValue } },
      },
    });
    if (Array.isArray(resp.data?.positions) && resp.data?.positions?.length) {
      setSelectedPosition(resp.data?.positions[0]);
    }
  };

  useQuery(FetchCustomerDetailsAdmin, {
    variables: {
      id: validation.values.customer_id,
    },
    onCompleted: (data) => {
      if (data?.customers_by_pk?.nurse_rate) {
        validation.setFieldValue(
          "fee_amount",
          data?.customers_by_pk?.nurse_rate
        );
      } else {
        validation.setFieldValue("fee_amount", parseInt("0"));
      }
    },
    skip: !validation.values.customer_id,
  });

  return (
    <>
      <Modal
        size={"lg"}
        isOpen={modal_center}
        toggle={() => {
          handleClose();
        }}
        centered
        // scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create Shift</h5>
          <button
            type="button"
            onClick={() => {
              handleClose();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label htmlFor="formrow-assignto-Input">
                    Select Facility
                  </Label>
                  <SelectPickerPaginate
                    query={FetchFacilitiesViewForFilter}
                    placeholder="Select Facility"
                    value={JSON.stringify(selectedFacility)}
                    onChange={(newValue) => {
                      const facilityJson = JSON.parse(newValue);
                      validation.setFieldValue("facility_id", facilityJson.id);
                      setSelectedFacility(facilityJson);
                    }}
                    arrKey="users"
                    objectLabel="organisation_name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      isactive: { _eq: true },
                      role: { _eq: "provider" },
                      is_maple_user: { _eq: false },
                    }}
                    getFullObj={true}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.facility_id &&
                      validation.errors.facility_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.facility_id &&
                  validation.errors.facility_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.facility_id}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="formrow-assignto-Input">Select Nurse</Label>
                  <SelectPickerPaginate
                    query={FetchCustomersViewForFilter}
                    placeholder="Select Nurse"
                    value={validation.values.customer_id}
                    onChange={(newValue) => {
                      validation.setFieldValue("customer_id", newValue);
                    }}
                    arrKey="customers_view"
                    objectLabel="firstname+lastname"
                    formatLabel={(item) =>
                      `${item.firstname} ${item.lastname} (${item.nurse_type})`
                    }
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      verified: { _eq: true },
                      is_deleted: { _eq: false },
                    }}
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.customer_id &&
                      validation.errors.customer_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.customer_id &&
                  validation.errors.customer_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.customer_id}
                    </div>
                  ) : null}
                </div>
                {validation.values.facility_id && (
                  <div className="mb-3">
                    <Label htmlFor="formrow-position-Input">Position</Label>
                    <SelectPickerPaginate
                      query={FetchPositionsForFilter}
                      placeholder="Select..."
                      value={validation.values.position_id}
                      onChange={(newValue) => {
                        handleSelectPosition(newValue);
                      }}
                      arrKey="positions"
                      objectLabel="name"
                      objectValue="id"
                      style={{ width: "100%" }}
                      paginate={true}
                      filterCondition={{
                        createdby: { _eq: validation.values.facility_id },
                        active: { _eq: true },
                      }}
                      getFullObj={false}
                      searchValue=""
                      menuStyle={{ zIndex: 9999 }}
                      disabled={false}
                      cleanable={false}
                      className={
                        validation.touched.position_id &&
                        validation.errors.position_id
                          ? "border-danger"
                          : ""
                      }
                    />
                    {validation.touched.position_id &&
                    validation.errors.position_id ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {validation.errors.position_id}
                      </div>
                    ) : null}
                  </div>
                )}
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-Input">Rate ($/hr)</Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="formrow-Input"
                        name="fee_amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fee_amount || parseInt("0")}
                        invalid={
                          validation.touched.fee_amount &&
                          validation.errors.fee_amount
                            ? true
                            : false
                        }
                        disabled={false}
                      />
                      {validation.touched.fee_amount &&
                      validation.errors.fee_amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.fee_amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <Row>
                    <Col md={isRecurring ? 6 : 12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">
                          {isRecurring
                            ? `Recurring Shift Start Date`
                            : `Shift Date`}
                        </Label>
                        <Input
                          name="available_from"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            moment(validation.values.available_from).format(
                              "YYYY-MM-DD"
                            ) || ""
                          }
                          invalid={
                            validation.touched.available_from &&
                            validation.errors.available_from
                              ? true
                              : false
                          }
                        />
                        {validation.touched.available_from &&
                        validation.errors.available_from ? (
                          <FormFeedback type="invalid">
                            {validation.errors.available_from}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {isRecurring && (
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Recurring Shift End Date
                          </Label>
                          <Input
                            name="available_till"
                            type="date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              moment(validation.values.available_till).format(
                                "YYYY-MM-DD"
                              ) || ""
                            }
                            invalid={
                              validation.touched.available_till &&
                              validation.errors.available_till
                                ? true
                                : false
                            }
                          />
                          {validation.touched.available_till &&
                          validation.errors.available_till ? (
                            <FormFeedback type="invalid">
                              {validation.errors.available_till}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        checked={isRecurring}
                        onChange={(value: any, checked: boolean) =>
                          setRecurring(checked)
                        }
                        id="recurring_checkbox"
                      />
                      <label
                        htmlFor="recurring_checkbox"
                        style={{ marginBottom: 0 }}
                      >
                        Allow Recurring Shifts
                      </label>
                    </div>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputCity">
                        Special Instructions
                      </Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        maxLength={225}
                        rows="3"
                        name="special_instructions"
                        label="Special Instructions"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.special_instructions || ""}
                        invalid={
                          validation.touched.special_instructions &&
                          validation.errors.special_instructions
                            ? true
                            : false
                        }
                        disabled={false}
                      />
                      {validation.touched.special_instructions &&
                      validation.errors.special_instructions ? (
                        <FormFeedback type="invalid">
                          {validation.errors.special_instructions}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary ms-1"
                      disabled={loading || invitationLoading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default CreateShiftAdmin;

import { gql } from "@apollo/client"
export const FetchInterviews =
  gql`query FetchInterviews (\$limit:Int,\$offset:Int,\$where:customers_bool_exp){
    customers (limit: \$limit, offset: \$offset, where: \$where){
        id
        firstname
        lastname
        onboarding_interview_date
        onboarding_interview_link
        onboarding_interview_scheduled
        onboarding_interview_comments
    }
    customers_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
import React, { useState, useEffect } from "react"
import { Form } from "reactstrap"
import MetaTags from 'react-meta-tags';
import "react-datepicker/dist/react-datepicker.css";
import { SelectPicker, DatePicker, Loader } from 'rsuite'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from "moment";
import scheduleLogo from "assets/images/onboarding/Schedule-01.png";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import BannerContent from "constants/bannerContent.json";

// icons
import { FiArrowLeft, FiFilter, FiPlus, FiPrinter, FiRefreshCcw } from "react-icons/fi";

//import helpers
import { printCalendar } from "helpers";

// import Component
import CheckPickerPaginate from "components/Filters/CheckPickerPaginate"
import PositionViewCalendar from "components/scheduler/calendars/PositionViewCalendar"
import StaffViewCalendar from "components/scheduler/calendars/StaffViewCalendar"
import CreateShift from "components/scheduler/CreateShift"
import InvitationListModal from "components/invitation/InvitationListModal"
import CalendarView from "components/scheduler/calendars/CalendarView"
import OnboardingBanner from "components/Onboarding/OnboardingBanner";

//GraphQL
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { FetchLocationsForFilter, FetchLocationsForScheduler } from "gql/locations/query"
import { FetchEmployeesForFilter } from "gql/employees/query"
import { FetchPositionsForFilter } from "gql/positions/query"
import { FetchJobOpeningsForScheduler } from "gql/jobOpenings/query"
import { FetchJobRequestsForScheduler } from "gql/jobRequests/query"
import { FetchInvitationsForScheduler } from "gql/invitations/query"

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";
import { handleSetPositions, handleSetLocations, handleSetJobOpenings, handleSetJobRequests, handleSetInvitations, handleSetEmployees, schedulerState, handleSetGridFilters, handleSetShiftModal, handleSetInvitationModal } from "../../toolkit/scheduler/scheduler.slice";
import {onboardingState, handleSetScheduleBanner} from "toolkit/onboarding/onboarding.slice";

const displayGridData = [{ label: "Positions", value: "positions" }, { label: "Staff", value: "staff" }, { label: "Calendar", value: "calendar" }]
const timeUnitData = [{ label: "Weeks", value: "weeks" }, { label: "Months", value: "months" }, { label: "Days", value: "days" }]
const timeData = [{ label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }, { label: "4", value: "4" }, { label: "5", value: "5" }, { label: "6", value: "6" }, { label: "7", value: "7" }, { label: "8", value: "8" }]
const Index = () => {
	const dispatch = useDispatch()
	const client = useApolloClient()
	const { auth: { user } } = useSelector(authState)
	const { showScheduleBanner } = useSelector(onboardingState)
	const {
		gridFilters,
		modal_open,
		selectedPosition,
		selectedDate,
		selectedCellData,
		shiftModalDisplay,
		selectedEmployee,
		invitation_modal_open
	} = useSelector(schedulerState)
	const initialPositionCondition = {
		createdby: { _eq: user.id },
		active: { _eq: true }
	}
	const initialLocationCondition = {
		isactive: { _eq: true },
		createdby: { _eq: user?.id }
	}
	const initialJobOpeningCondition = {
		createdby: { _eq: user.id },
		filled: { _eq: false },
		_or: [{
			isactive: { _eq: true },
		}, {
			is_open_shift: { _eq: true }
		}],
		job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
	}
	const initialJobRequestCondition = {
		_or: [{
			job_opening: {
				createdby: { _eq: user.id },
				job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
			}
		}, {
			invitation: {
				createdby: { _eq: user.id },
				job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
			}
		}],
		status: { _nin: ["requested", "cancelled", "rejected"] },
		active: { _eq: true }
	}
	const initialInvitationCondition = {
		createdby: { _eq: user.id },
		status: { _neq: "cancelled" },
		job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") },
		isactive: { _eq: true }
	}
	const initialEmployeeCondition = {
		createdby: { _eq: user.id },
		isactive: { _eq: true }
	}
	const [positions, setPositions] = useState([]);
	const [sideBarOpen, setSideBarOpen] = useState(true);
	const [filterValues, setFilterValues] = useState({});
	const [PageData, setPageData] = useState({
		limit: 100,
		offset: 0
	})
	const [positionQueryCondition, setPositionQueryCondition] = useState(initialPositionCondition)
	const [locationQueryCondition, setLocationQueryCondition] = useState(initialLocationCondition)
	const [jobOpeningQueryCondition, setJobOpeningQueryCondition] = useState(initialJobOpeningCondition)
	const [jobRequestQueryCondition, setJobRequestQueryCondition] = useState(initialJobRequestCondition)
	const [invitationQueryCondition, setInvitationQueryCondition] = useState(initialInvitationCondition)
	const [employeeQueryCondition, setEmployeeQueryCondition] = useState(initialEmployeeCondition)
	const [isRefreshing, setRefreshing] = useState(false)

	const [getLocationList, { loading: locationsLoading, data: locationsData }] = useLazyQuery(FetchLocationsForScheduler, {
		variables: {
			where: locationQueryCondition
		},
		fetchPolicy: "no-cache"
	})

	const [getPositionList, { loading: positionsLoading, data: positionsData }] = useLazyQuery(FetchPositionsForFilter, {
		variables: {
			limit: PageData.limit,
			offset: PageData.offset,
			where: positionQueryCondition
		}
	})

	const [getJobOpenings, { loading: jobOpeningLoading, data: jobOpeningData }] = useLazyQuery(FetchJobOpeningsForScheduler, {
		variables: {
			where: jobOpeningQueryCondition
		}
	})

	const [getJobRequests, { loading: jobRequestLoading, data: jobRequestData }] = useLazyQuery(FetchJobRequestsForScheduler, {
		variables: {
			where: jobRequestQueryCondition
		}
	})

	const [getInvitations, { loading: invitationLoading, data: invitationData }] = useLazyQuery(FetchInvitationsForScheduler, {
		variables: {
			where: invitationQueryCondition
		},
		fetchPolicy: "no-cache"
	})

	const [getEmployees, { loading: employeesLoading, data: employeesData }] = useLazyQuery(FetchEmployeesForFilter, {
		variables: {
			where: employeeQueryCondition
		}
	})

	const handleFilter = () => {
		let isLocationFilterEnabled = false,
			isEmpFilterEnabled = false,
			_conditionsEmployee = {};
		if (filterValues.location && filterValues.location?.length) {
			let _conditionsLocation = { location: { id: { _in: filterValues.location } } }
			isLocationFilterEnabled = true
			setPositionQueryCondition({ ...positionQueryCondition, ..._conditionsLocation })
			setLocationQueryCondition({ ...locationQueryCondition, id: { _in: filterValues.location } })
			if (gridFilters.display === "staff") {
				setJobRequestQueryCondition({ 
					...jobRequestQueryCondition, 
					_or: [{
						job_opening: {
							location_id: { _in: filterValues.location },
							createdby: { _eq: user.id },
							job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
						}
					}, {
						invitation: {
							location_id: { _in: filterValues.location },
							createdby: { _eq: user.id },
							job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
						}
					}],
				})
				// setJobRequestQueryCondition({ ...jobRequestQueryCondition, job_opening: { location_id: { _in: filterValues.location } } })
				_conditionsEmployee = { ..._conditionsEmployee, location_id: { _in: filterValues.location } }
			}
		}
		if (filterValues.employee && filterValues.employee?.length) {
			isEmpFilterEnabled = true
			setJobRequestQueryCondition({ ...jobRequestQueryCondition, employee_id: { _in: filterValues.employee } })
			_conditionsEmployee = { ..._conditionsEmployee, id: { _in: filterValues.employee } }
			// setEmployeeQueryCondition({ ...employeeQueryCondition, id: { _in: filterValues.employee } })
		}
		setEmployeeQueryCondition(_conditionsEmployee)
		if (!isLocationFilterEnabled) {
			setPositionQueryCondition(initialPositionCondition)
			setLocationQueryCondition(initialLocationCondition)
		}
		if (!isEmpFilterEnabled) {
			if (filterValues.location && filterValues.location?.length) {
				setJobRequestQueryCondition({
					...jobRequestQueryCondition,
					// job_opening: { location_id: { _in: filterValues.location } },
					_or: [{
						job_opening: {
							location_id: { _in: filterValues.location },
							createdby: { _eq: user.id },
							job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
						}
					}, {
						invitation: {
							location_id: { _in: filterValues.location },
							createdby: { _eq: user.id },
							job_date: { _lte: moment().add(1, 'M').format("YYYY-MM-DD"), _gte: moment().format("YYYY-MM-DD") }
						}
					}],
					employee_id: undefined
				})
			} else {
				setJobRequestQueryCondition(initialJobRequestCondition)
			}
			setEmployeeQueryCondition({
				...initialEmployeeCondition,
				location_id: filterValues.location?.length ? { _in: filterValues.location } : undefined
			})
		}
	}
	function tog_center(open) {
		dispatch(handleSetShiftModal({
			modal_open: open,
			selectedPosition: null,
			selectedDate: new Date(),
			selectedCellData: null,
			shiftModalDisplay: "position"
		}))
	}
	function tog_center_invitation(open) {
		dispatch(handleSetInvitationModal({
			invitation_modal_open: open,
			selectedPosition: null,
			selectedDate: new Date(),
			selectedCellData: null,
		}))
	}
	const handlePrint = () => {
		const input = document.getElementById('calendar');
		window.scrollTo(0, 0);
		html2canvas(input, {
			scrollX: -window.scrollX,
			scrollY: -window.scrollY,
			windowWidth: document.documentElement.offsetWidth,
			windowHeight: input.scrollHeight,
		})
			.then((canvas) => {
				const imgData = canvas
					.toDataURL("image/png")
					.replace("image/png", "image/octet-stream");
				let imgWidth = 210;
				let pageHeight = 295;
				let imgHeight = canvas.height * imgWidth / canvas.width;
				let heightLeft = imgHeight;
				let doc = new jsPDF('p', 'mm');
				let position = 0;
				doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
				heightLeft -= pageHeight;

				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					doc.addPage();
					doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
					heightLeft -= pageHeight;
				}
				doc.save('caregigs.pdf');
			})
	}
	const handleRefresh = async () => {
		try {
			setRefreshing(true)
			await client.refetchQueries({
				include: [
					"FetchJobOpeningsForScheduler",
					"FetchJobRequestsForScheduler",
					"FetchInvitationsForScheduler",
					"LocationFilter",
					"EmployeesFilter",
					"PositionFilter",
					"FetchLocationsForScheduler"
				]
			})
		} catch (err) {

		} finally {
			setRefreshing(false)
		}
	}
	useEffect(() => {
		if (user?.id) {
			getPositionList()
			getJobOpenings()
			getJobRequests()
			getInvitations()
			getEmployees()
			getLocationList()
		}
	}, [user])
	useEffect(() => {
		if (Array.isArray(positionsData?.positions)) {
			setPositions(positionsData.positions)
			dispatch(handleSetPositions(positionsData.positions))
		}
	}, [positionsData])
	useEffect(() => {
		if (Array.isArray(locationsData?.locations)) {
			const _locations = locationsData.locations.map((_location) => {
				return {
					fieldType: "location",
					isOpen: false,
					label: `${_location.name} (${_location.positions.length})`,
					data: _location
				}
			})
			dispatch(handleSetLocations(_locations))
		}
	}, [locationsData])
	useEffect(() => {
		if (Array.isArray(jobOpeningData?.job_openings)) {
			dispatch(handleSetJobOpenings(jobOpeningData.job_openings))
		}
	}, [jobOpeningData])
	useEffect(() => {
		if (Array.isArray(jobRequestData?.job_requests)) {
			dispatch(handleSetJobRequests(jobRequestData.job_requests))
		}
	}, [jobRequestData])
	useEffect(() => {
		if (Array.isArray(invitationData?.invitations)) {
			dispatch(handleSetInvitations(invitationData.invitations))
		}
	}, [invitationData])
	useEffect(() => {
		if (Array.isArray(employeesData?.employees)) {
			dispatch(handleSetEmployees(employeesData.employees))
		}
	}, [employeesData])
	useEffect(() => {
		handleFilter()
	}, [filterValues])
	useEffect(() => {
		let job_date = { _lte: moment(gridFilters.date).add(parseInt(gridFilters.time), gridFilters.timeUnit).format("YYYY-MM-DD"), _gte: moment(gridFilters.date).format("YYYY-MM-DD") }
		setJobOpeningQueryCondition({
			...jobOpeningQueryCondition,
			job_date
		})
		setInvitationQueryCondition({
			...invitationQueryCondition,
			job_date
		})
		setJobRequestQueryCondition({
			...jobRequestQueryCondition,
			_or: [{
				job_opening: {
					createdby: { _eq: user.id },
					job_date
				}
			}, {
				invitation: {
					createdby: { _eq: user.id },
					job_date
				}
			}],
			// job_opening: {
			// 	createdby: { _eq: user.id },
			// 	job_date
			// },
		})
	}, [gridFilters])
	return (
		<React.Fragment>
			<div className="page-content SchedulePage AppBody">
				<MetaTags>
					<title>Scheduler| Caregigs</title>
				</MetaTags>
				<div className="SchedulePage-container">

					<div className={ sideBarOpen == true ?  "SchedulePage-sidebar" : "SchedulePage-sidebar off"} >

						<div className="SchedulePage-sidebar_toggler" onClick={() => {setSideBarOpen(!sideBarOpen)}} >
							<FiArrowLeft className="SchedulePage-sidebar_toggler-i1" />
							{/* <FiArrowRight className="SchedulePage-sidebar_toggler-i2"  /> */}
						</div>


						<div className="SchedulePage-sidebar_inner" >
							<h4 className="SchedulePage-header" >
								<FiFilter  /> Filter
							</h4>	

							<Form className="">
								<div>
									<SelectPicker
										data={displayGridData}
										placeholder={"Display"}
										// style={{ width: 150 }}
										onChange={(display) => dispatch(handleSetGridFilters({ ...gridFilters, display }))}
										value={gridFilters.display}
										searchable={false}
										placement="bottomEnd"
										cleanable={false}
									/>
								</div>	
								<div>
									<DatePicker
										// style={{ width: 150 }}
										value={gridFilters.date}
										onChange={(date) => dispatch(handleSetGridFilters({ ...gridFilters, date }))}
										onClean={(event) => dispatch(handleSetGridFilters({ ...gridFilters, date: new Date() }))}
									/>
								</div>	
								<div className="row">
								<div className="col-5">
									<SelectPicker
										data={timeData}
										placeholder={"Time"}
										// style={{ width: 150 }}
										onChange={(time) => dispatch(handleSetGridFilters({ ...gridFilters, time }))}
										value={gridFilters.time}
										searchable={false}
										placement="bottomEnd"
										cleanable={false}
									/>
								</div>	
								<div className="col-7">
									<SelectPicker
										data={timeUnitData}
										placeholder={"Time Unit"}
										// style={{ width: 150 }}
										onChange={(timeUnit) => dispatch(handleSetGridFilters({ ...gridFilters, timeUnit }))}
										value={gridFilters.timeUnit}
										searchable={false}
										placement="bottomEnd"
										cleanable={false}
									/>
								</div>	
								</div>
								<div>
									<CheckPickerPaginate
										query={FetchLocationsForFilter}
										placeholder="Location"
										value={Array.isArray(filterValues.location) ? filterValues.location : []}
										onChange={(location) => setFilterValues({ ...filterValues, location })}
										arrKey="locations"
										objectLabel="name"
										objectValue="id"
										// style={{ width: 150 }}
										paginate={true}
										filterCondition={{
											isactive: { _eq: true },
											createdby: { _eq: user?.id }
										}}
										placement="bottomStart"
									/>
								</div>
								<div>
									<CheckPickerPaginate
										query={FetchEmployeesForFilter}
										placeholder="Staff"
										value={Array.isArray(filterValues.employee) ? filterValues.employee : []}
										onChange={(employee) => setFilterValues({ ...filterValues, employee })}
										arrKey="employees"
										objectLabel="firstname+lastname"
										objectValue="id"
										// style={{ width: 150 }}
										paginate={true}
										filterCondition={{
											isactive: { _eq: true },
											createdby: { _eq: user?.id }
										}}
										placement="bottomStart"
									/>
								</div>
								{/* <Col xs={12}>
									<button type="button" className="btn btn-primary w-md form-control form-control-sm" onClick={handleFilter}>Submit</button>
								</Col> */}
								{/* <Col xs={12}>
									<button type="button" className="btn btn-primary w-md form-control form-control-sm" onClick={handleFilter}>Go</button>
								</Col> */}
							</Form>
							
						</div>
					</div>

					<div className={sideBarOpen == true ?  "SchedulePage-mainBody" : "SchedulePage-mainBody off"} >

						{/* Render Breadcrumbs */}
						<Breadcrumbs 
							title="Schedule" 
							links={[{title: "Home", link: "/"}, {title: "Schedule", link: "#"}]} 
						/>

						{/* onBording */}
						{showScheduleBanner && (
							<OnboardingBanner 
								media={scheduleLogo} 
								title={BannerContent.schedule.title} 
								subTitle={BannerContent.schedule.subtitle} 
								color={'#EFEBFC'}
								handleClose={()=>dispatch(handleSetScheduleBanner(false))}
							/>
						)}

						{/* card */}
						<div className="SchedulePage-card" >
							<div className="SchedulePage-card_top" >
								<div className="d-flex" >
									<button
										className="btn btn-primary btn-sm "
										onClick={() => tog_center(true)}
									>
										<FiPlus  /> Create Shift
									</button>
									{gridFilters.display === "calendar" && (
										<button
											className="btn btn-primary btn-sm "
											onClick={()=>printCalendar("calendar")}
											style={{ marginLeft: 10 }}
										>
											<FiPrinter  /> Print Calendar
										</button>
									)}
									<button
										className="btn btn-outline-light btn-sm "
										onClick={handleRefresh}
										style={{ marginLeft: 10 }}
										disabled={isRefreshing}
									>
										{isRefreshing ? <Loader /> : <FiRefreshCcw  />}
									</button>
								</div>
							</div>
							<div className="SchedulePage-card_body" >
								{gridFilters.display === "positions" && (
									<PositionViewCalendar positions={positions} />
								)}
								{gridFilters.display === "staff" && (
									<StaffViewCalendar
										positions={positions}
										employees={Array.isArray(employeesData?.employees) ? employeesData?.employees : []}
									/>
								)}
								{gridFilters.display === "calendar" && (
									<CalendarView
										positions={positions}
										employees={Array.isArray(employeesData?.employees) ? employeesData?.employees : []}
									/>
								)}

							</div>
						</div>
					</div>
				</div>
			</div>
			<CreateShift
				modal_center={modal_open}
				tog_center={() => tog_center(false)}
				position={selectedPosition}
				selectedDate={selectedDate}
				selectedCellData={selectedCellData}
				display={shiftModalDisplay}
				selectedEmployee={selectedEmployee}
			/>
			<InvitationListModal
				isOpen={invitation_modal_open}
				handleClose={() => tog_center_invitation(false)}
				position={selectedPosition}
				selectedDate={selectedDate}
			/>
		</React.Fragment>
	)
}
export default Index

import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { FiCheck, FiX, FiRefreshCw, FiTrash } from "react-icons/fi";
import { Button } from "reactstrap";

//types
import DocumentType from "./documents.types";

//helpers
import { getDocumentStatus } from "helpers";

export const FormatData = (employees: DocumentType[]) => {
  console.log("emp", employees);
  const _employees = employees.map((elem: DocumentType) => {
    return {
      id: elem.id,
      status: elem.status,
      name: elem.name,
      document_url: elem.document_url,
      category: elem.category,
      category_title: elem.enum_document_type.comment,
      verified_at: elem.verified_at,
      createdby: elem.createdby,
      customer: elem.customer,
      expiration_date: elem.document_info?.expireDate || "N/A",
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const DocumentsColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  selectDocument: (row: DocumentType) => void,
  role: string,
  handleVerifyDocument: (status: string, document: DocumentType) => void,
  handleDeleteDocument: (document: DocumentType) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "category_title",
    text: "Document Type",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <>
        <Link to="#" onClick={() => selectDocument(row)}>
          {cellContent}
        </Link>
      </>
    ),
  },
  {
    dataField: "name",
    text: "Document Name",
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <span className={getDocumentStatus(row).class}>
        {getDocumentStatus(row).value}
      </span>
    ),
  },
  {
    text: "Verification",
    dataField: "verified_at",
    sort: true,
    formatter: (cellContent: string, row: any) =>
      row.status === "verified"
        ? `via Caregigs on ${moment(cellContent).format("MM/DD/YYYY")}`
        : "Action Needed",
  },
  {
    text: "Expiration Date",
    dataField: "expiration_date",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <>
        {cellContent == "N/A" ? (
          <p>N/A</p>
        ) : (
          moment(cellContent).format("MM/DD/YYYY")
        )}
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    hidden: role !== "moderator",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => {
      return (
        <div className="d-flex justify-content-start">
          <>
            {row.status !== "verified" && (
              <Button
                color="success"
                outline
                size="sm"
                style={{ height: 30, marginRight: 5 }}
                to="#"
                onClick={() => handleVerifyDocument("verified", row)}
              >
                <FiCheck size={15} />
                Approve
              </Button>
            )}
            {row.status !== "not_verified" && (
              <Button
                color="danger"
                outline
                size="sm"
                style={{ height: 30 }}
                to="#"
                onClick={() => handleVerifyDocument("not_verified", row)}
              >
                {row.status === "verified" ? (
                  <FiRefreshCw size={12} />
                ) : (
                  <FiX size={15} />
                )}
                {row.status === "verified" ? " Revoke" : "Reject"}
              </Button>
            )}
            <Button
              color="danger"
              outline
              size="sm"
              style={{ height: 30, marginLeft: 5 }}
              to="#"
              onClick={() => handleDeleteDocument(row)}
            >
              <FiTrash />
              Delete
            </Button>
          </>
        </div>
      );
    },
  },
];
export const BuildDocumentSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        {
          customer: {
            _or: [
              { firstname: { _ilike: `%${value}%` } },
              { lastname: { _ilike: `%${value}%` } },
            ],
          },
        },
        { job_opening: { position: { name: { _ilike: `%${value}%` } } } },
        { job_opening: { location: { name: { _ilike: `%${value}%` } } } },
        { status: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildDocumentFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        status: { _eq: filterValue.status },
      };
    }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { job_opening: { job_date: { _gte: job_date_from } } },
            { job_opening: { job_date: { _lte: job_date_till } } },
          ],
        };
      }
    }
    if (filterValue?.hasOwnProperty("position")) {
      conditionTemp = {
        ...conditionTemp,
        job_opening: { position_id: { _in: filterValue.position } },
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

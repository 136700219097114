import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  Label,
  Modal,
  InputGroup,
} from "reactstrap";

import {
  CheckboxGroup,
  Checkbox,
  Loader,
  Nav,
  Panel,
  PanelGroup,
  Radio,
  RadioGroup,
  Button,
} from "rsuite";
import { useDebounce } from "react-use";

import marketplaceLogo from "assets/images/onboarding/Marketplace.png";
import BannerContent from "constants/bannerContent.json";

// icons
import { FiRefreshCw, FiX } from "react-icons/fi";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import MarketplaceListView from "components/marketplace/MarketplaceListView";
import MarketplaceMapView from "components/marketplace/MarketplaceMapView";
import OnboardingBanner from "components/Onboarding/OnboardingBanner";

import InvitationModal from "components/invitation/InvitationModal";
// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  onboardingState,
  handleSetMarketplaceBanner,
} from "toolkit/onboarding/onboarding.slice";
import { profileState } from "toolkit/profile/profile.slice";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { FetchCustomers } from "../../gql/customers/query";

//Functions
import { BuildCustomerFilterQuery } from "./marketplace.functions";

//constants
import nurseTypes from "constants/nurseTypes";

const ContactsGrid = (props) => {
  const dispatch = useDispatch();
  const { showMarketplaceBanner } = useSelector(onboardingState);
  const { profile } = useSelector(profileState);
  const initialCondition = {
    verified: { _eq: true },
    is_deleted: { _eq: false },
  };
  const initialFilter = {
    discount: [],
    price: [],
    speciality: [],
    gender: [],
    rating: "",
  };
  // eslint-disable-next-line no-unused-vars
  const [FilterSpeciality, setFilterSpeciality] = useState(nurseTypes);
  const [FilterGender, setFilterGender] = useState([
    { id: 1, label: "Male", value: "male" },
    { id: 2, label: "Female", value: "female" },
    { id: 3, label: "Others", value: "others" },
  ]);
  const [filterRating, setFilterRating] = useState([
    { id: 1, label: "4 & Above", value: "4" },
    { id: 2, label: "3 & Above", value: "3" },
    { id: 3, label: "2 & Above", value: "2" },
    { id: 4, label: "1 & Above", value: "1" },
  ]);
  const [customerList, setCustomerList] = useState([]);
  const [activeTab, setActiveTab] = useState("list");
  const [priceRange, setPriceRange] = useState([]);
  const [filters, setFilters] = useState(initialFilter);
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [filterKey, setFilterKey] = useState(uuid());
  const [getCustomerList, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchCustomers, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    });

  const onUpdate = (render, handle, value) => {
    setPriceRange(value);
  };

  const onClickInvite = (user) => {
    setSelectedUser(user);
    setInviteModalOpen(true);
  };
  const onClickProfile = (user) => {
    setSelectedUser(user);
    setProfileModalOpen(true);
  };
  useEffect(() => {
    if (Array.isArray(data?.customers)) {
      setCustomerList(data?.customers);
    }
  }, [data]);
  // setFilters({...filters, price: value})
  useDebounce(
    () => {
      setFilters({ ...filters, price: priceRange });
    },
    1500,
    [priceRange]
  );
  useEffect(() => {
    if (profile?.id) {
      let _conditions = BuildCustomerFilterQuery(filters);
      if (Object.keys(_conditions).length) {
        setQueryCondition({
          ...queryCondition,
          ..._conditions,
          _not: {
            blocked_facilities: { _contains: [profile?.id] },
          },
        });
      } else {
        setQueryCondition({
          ...initialCondition,
          _not: {
            blocked_facilities: { _contains: [profile?.id] },
          },
        });
      }
    }
  }, [filters, profile]);
  useEffect(() => {
    if (queryCondition && profile?.id) {
      getCustomerList();
    }
  }, [queryCondition, profile]);
  const handleSubmit = (e) => {
    // e.preventDefault();
    e.target.reset();
  };
  return (
    <React.Fragment>
      <div className="page-content Marketplacepage AppBody">
        <MetaTags>
          <title>Marketplace | Caregigs</title>
        </MetaTags>
        <div className="Marketplacepage-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Marketplace"
            links={[
              { title: "Home", link: "/" },
              { title: "Marketplace", link: "#" },
            ]}
          />

          {/* onboarding */}
          {showMarketplaceBanner && (
            <OnboardingBanner
              media={marketplaceLogo}
              title={BannerContent.marketplace.title}
              subTitle={BannerContent.marketplace.subtitle}
              color={"rgb(206, 255, 226)"}
              handleClose={() => dispatch(handleSetMarketplaceBanner(false))}
            />
          )}

          {/* <Row>
            <Col lg="12" className="d-flex justify-content-center align-items-center mb-3">
              <Nav activeKey={activeTab} onSelect={setActiveTab} appearance="subtle">
                <Nav.Item eventKey="list">
                  List View
                </Nav.Item>
                <Nav.Item eventKey="map">
                  Map View
                </Nav.Item>
              </Nav>
            </Col>
          </Row> */}
          <Row>
            <Col lg="3">
              <div className="Marketplacepage-card" key={filterKey}>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <CardTitle className="mb-4">Filter</CardTitle>
                    <button
                      className="btn btn-light"
                      style={{ height: 35 }}
                      onClick={() => {
                        setFilters(initialFilter);
                        setFilterKey(uuid());
                      }}
                    >
                      <FiRefreshCw />
                    </button>
                  </div>
                  <Panel header="Specialty" defaultExpanded collapsible>
                    <div>
                      <CheckboxGroup
                        name="specialityList"
                        onChange={(value) =>
                          setFilters({ ...filters, speciality: value })
                        }
                      >
                        {FilterSpeciality.map((item) => (
                          <Checkbox
                            className="font-size-13"
                            key={item.id}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    </div>
                  </Panel>
                  {/* <Panel header="Rate" collapsible>
                    <div className="pb-3 pt-4">
                      <Nouislider
                        range={{ min: 0, max: 100 }}
                        tooltips={true}
                        start={[20, 30]}
                        connect
                        onSlide={onUpdate}
                      />
                    </div>
                  </Panel> */}
                  <Panel header="Gender" collapsible>
                    <div>
                      <CheckboxGroup
                        name="genderList"
                        onChange={(value) =>
                          setFilters({ ...filters, gender: value })
                        }
                      >
                        {FilterGender.map((item) => (
                          <Checkbox key={item.id} value={item.value}>
                            {item.label}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    </div>
                  </Panel>
                  <Panel header="Age" collapsible>
                    <div>
                      <div className="form-check mt-2">
                        <Input type="checkbox" className="form-check-input" />
                        <Label className="form-check-label">Under 40</Label>
                      </div>
                      <div className="form-check mt-2">
                        <Input type="checkbox" className="form-check-input" />
                        <Label className="form-check-label">40-50</Label>
                      </div>
                    </div>
                  </Panel>
                  <Panel header="Rating" collapsible>
                    <Form onSubmit={handleSubmit}>
                      <div className="d-flex justify-content-end">
                        <Button size="xs" type="submit">
                          <FiX />
                          Clear
                        </Button>
                      </div>
                      <div>
                        <RadioGroup
                          name="ratingList"
                          onChange={(value) =>
                            setFilters({ ...filters, rating: value })
                          }
                        >
                          {filterRating.map((item) => (
                            <Radio key={item.id} value={item.value}>
                              <i className="bx bx-star text-warning" />{" "}
                              {item.label}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </div>
                    </Form>
                  </Panel>
                </div>
              </div>
            </Col>
            <Col lg="9">
              <div className="Marketplacepage-card">
                {loading && (
                  <Row className="justify-content-center">
                    <Loader size="md" />
                  </Row>
                )}
                {activeTab === "list" ? (
                  <MarketplaceListView
                    searchKey={searchKey}
                    filterValue={filterValue}
                    onClickInvite={onClickInvite}
                    customerList={customerList}
                    PageData={PageData}
                    setPageData={setPageData}
                    total={data?.customers_aggregate?.aggregate?.count || 0}
                    handleSelectRow={() => {
                      //
                    }}
                  />
                ) : (
                  <MarketplaceMapView
                    customerList={customerList}
                    onClickProfile={onClickProfile}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <InvitationModal
        isEdit={false}
        setIsEdit={() => {
          //
        }}
        toggle={() => setInviteModalOpen(false)}
        modal={inviteModalOpen}
        refetch={() => {
          //
        }}
        customer={selectedUser}
        selectedPosition={null}
        selectedDate={new Date()}
      />
    </React.Fragment>
  );
};

ContactsGrid.propTypes = {
  // products: PropTypes.array,
  history: PropTypes.object,
};

export default withRouter(ContactsGrid);

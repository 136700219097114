import { gql } from "@apollo/client"
export const InsertEmployeeOne =
gql`mutation InsertEmployeeOne(\$object: employees_insert_input!) {
  insert_employees_one(object: \$object) {
    id
  }
}
`

export const UpdateEmployee =
gql`mutation UpdateEmployee(\$id:uuid!, \$object: employees_set_input) {
  update_employees_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`
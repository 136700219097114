import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  // InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";

import Flatpickr from "react-flatpickr";
import moment from "moment";
import InputColor from "react-input-color";
import { SelectPicker, InputGroup } from "rsuite";

// icons
import { FiCheck } from "react-icons/fi";

//types
import positionType, {
  positionInputType,
} from "pages/Settings/positions/positions.types";

import SelectPickerPaginate from "../Filters/SelectPickerPaginate";

//graphql
import { FetchLocationsForFilter } from "gql/locations/query";
import {
  FetchPositionByName,
  FetchPositionByAbbreviation,
} from "gql/positions/query";
import { InsertPositionOne, UpdatePosition } from "gql/positions/mutation";
import { InsertRateOne } from "gql/rates/mutation";
import { FetchAreas } from "gql/areas/query";
import { FetchRates } from "gql/rates/query";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { profileState } from "toolkit/profile/profile.slice";

//constants
import nurseTypes from "constants/nurseTypes";

const initialState: positionInputType = {
  name: "",
  abbreviation: "",
  active: true,
  organisation_id: "",
  location_id: "",
  requirement: "",
  count: 0,
  filled: false,
  type: "",
  notes: "",
  start_time: "",
  end_time: "",
  break_time: "",
  text_color: "",
  background_color: "",
  area_id: "",
};
const PositionModal: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  refetch: () => void;
  selectedPosition: positionType | undefined;
  role: any;
}> = ({
  isEdit,
  setIsEdit,
  toggle,
  modal,
  refetch,
  selectedPosition,
  role,
}) => {
  const client = useApolloClient();
  const { profile } = useSelector(profileState);
  const {
    auth: { user },
  } = useSelector(authState);
  const [CreatePositionMutation, { loading, error }] =
    useMutation(InsertPositionOne);
  const [UpdatePositionMutation, { loading: loadingUpdatePosition }] =
    useMutation(UpdatePosition);
  const [CreateRateMutation] = useMutation(InsertRateOne);

  const [position, setPosition] = useState<any>(initialState);
  const [locationId, setLocationId] = useState<string>("");
  const [customState, setCustomState] = useState<any>({
    text_color: "#000",
    background_color: "#fff",
  });
  const [type, setType] = useState<string>("");

  // const { refetch: FetchRateHistory } = useQuery(FetchRates);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (position && position.name) || "",
      abbreviation: (position && position.abbreviation) || "",
      requirement: (position && position.requirement) || "",
      filled: (position && position.filled) || false,
      notes: (position && position.notes) || "",
      break_time: (position && position.break_time) || "0",
      location_id: (position && position.location_id) || "",
      start_time: (position && position.start_time) || "",
      end_time: (position && position.end_time) || "",
      area_id: (position && position.area_id) || "",
      contract_rate: (position && position.contract_rate) || "",
      contract_overtime_rate:
        (position && position.contract_overtime_rate) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Position Name"),
      abbreviation: Yup.string().required("Please Enter Abbreviation"),
      // requirement: Yup.string().required("Please Enter Requirements"),
      break_time: Yup.string().required("Please Enter Break Time"),
      location_id: Yup.string().required("Please Select Location"),
      start_time: Yup.string()
        .test("not empty", "Start time can't be empty", function (value) {
          return !!value;
        })
        .test(
          "start_time_test",
          "Start time & end time should not be same",
          function (value) {
            const { end_time } = this.parent;
            let _start_date: any = value,
              _end_date: any = end_time;
            if (!moment(end_time).isValid()) {
              _end_date = moment(end_time, "H:mm").toDate();
            }
            if (!moment(value).isValid()) {
              _start_date = moment(value, "H:mm").toDate();
            }
            return !moment(_start_date).isSame(_end_date);
          }
        ),
      end_time: Yup.string().required("End time can't be empty"),
    }),
    onSubmit: async (values) => {
      const { data } = await client.query({
        query: FetchPositionByName,
        variables: {
          name: values.name,
          createdby: user?.id,
        },
        fetchPolicy: "no-cache",
      });
      const { data: positionByAbbreviation } = await client.query({
        query: FetchPositionByAbbreviation,
        variables: {
          abbreviation: values.abbreviation,
          createdby: user?.id,
        },
        fetchPolicy: "no-cache",
      });
      if (isEdit) {
        if (
          data?.positions?.length > 0 &&
          Array.isArray(data?.positions) &&
          values.name !== selectedPosition?.name
        ) {
          Swal.fire(
            "Position name already exists. Please create a new name",
            "",
            "warning"
          );
        } else if (
          positionByAbbreviation?.positions?.length > 0 &&
          Array.isArray(positionByAbbreviation?.positions) &&
          values.abbreviation !== selectedPosition?.abbreviation
        ) {
          Swal.fire(
            "Position abbreviation already exists. Please create a new abbreviation",
            "",
            "warning"
          );
        } else {
          await UpdatePositionMutation({
            variables: {
              id: selectedPosition?.id,
              object: {
                name: values.name,
                abbreviation: values.abbreviation,
                active: true,
                organisation_id: profile.organisation_id,
                requirement: [values.requirement],
                count: 0,
                filled: false,
                type: type,
                location_id: values.location_id,
                notes: values.notes,
                start_time:
                  typeof values.start_time === "string"
                    ? values.start_time
                    : moment(values.start_time).format("H:mm"),
                end_time:
                  typeof values.end_time === "string"
                    ? values.end_time
                    : moment(values.end_time).format("H:mm"),
                break_time: String(values.break_time),
                text_color: customState.text_color,
                background_color: customState.background_color,
                area_id: values.area_id || null,
                contract_rate: values.contract_rate,
                contract_overtime_rate: values.contract_overtime_rate,
              },
            },
          });
          role === "moderator" &&
            (await CreateRateMutation({
              variables: {
                object: {
                  position_id: selectedPosition?.id,
                  contract_rate: values.contract_rate,
                  contract_overtime_rate: values.contract_overtime_rate,
                },
              },
            }));
          // Swal.fire("Position edited successfully", "", "success")
          showAlert({
            title: "Updated!",
            message: "Position edited successfully",
            type: "success",
          });
          client.refetchQueries({
            include: ["PositionFilter"],
          });
          setIsEdit(false);
          handleClose();
          refetch();
        }
      } else {
        if (data?.positions?.length > 0 && Array.isArray(data?.positions)) {
          Swal.fire(
            "Position name already exists please create a new name",
            "",
            "warning"
          );
        } else if (
          positionByAbbreviation?.positions?.length > 0 &&
          Array.isArray(positionByAbbreviation?.positions) &&
          values.abbreviation !== selectedPosition?.abbreviation
        ) {
          Swal.fire(
            "Position abbreviation already exists. Please create a new abbreviation",
            "",
            "warning"
          );
        } else {
          CreatePositionMutation({
            variables: {
              object: {
                name: values.name,
                abbreviation: values.abbreviation,
                active: true,
                organisation_id: profile.organisation_id,
                requirement: [values.requirement],
                count: 0,
                filled: false,
                type: type,
                location_id: values.location_id,
                notes: values.notes,
                start_time: moment(values.start_time).format("H:mm"),
                end_time: moment(values.end_time).format("H:mm"),
                break_time: String(values.break_time),
                text_color: customState.text_color,
                background_color: customState.background_color,
                area_id: values.area_id || null,
              },
            },
          })
            .then(() => {
              client.refetchQueries({
                include: ["PositionFilter"],
              });
              Swal.fire("Position added successfully", "", "success");
              handleClose();
              refetch();
            })
            .catch((err) => {
              Swal.fire("Something went wrong", "", "warning");
            });
        }
      }
    },
  });
  const handleClose = () => {
    toggle();
    validation.resetForm();
    setPosition(initialState);
    setCustomState({
      start_time: "",
      end_time: "",
      text_color: "#000",
      background_color: "#fff",
    });
    setLocationId("");
    setType("");
    setIsEdit(false);
  };
  useEffect(() => {
    if (isEdit && selectedPosition?.id) {
      setPosition({
        ...location,
        name: selectedPosition.name,
        abbreviation: selectedPosition.abbreviation,
        requirement:
          selectedPosition.requirement?.length > 0
            ? selectedPosition.requirement[0]
            : "",
        filled: selectedPosition.filled,
        notes: selectedPosition.notes,
        break_time: selectedPosition.break_time,
        location_id: selectedPosition?.location_id,
        start_time: selectedPosition.start_time,
        end_time: selectedPosition.end_time,
        area_id: selectedPosition.area?.id || "",
        contract_rate: selectedPosition?.contract_rate || "",
        contract_overtime_rate: selectedPosition?.contract_overtime_rate || "",
      });
      setCustomState({
        ...customState,
        text_color: selectedPosition.text_color,
        background_color: selectedPosition.background_color,
      });
      setType(selectedPosition.type);
    } else if (selectedPosition?.id) {
      //for clone functionality
      setPosition({
        ...location,
        requirement:
          selectedPosition.requirement?.length > 0
            ? selectedPosition.requirement[0]
            : "",
        filled: selectedPosition.filled,
        notes: selectedPosition.notes,
        break_time: selectedPosition.break_time,
        location_id: selectedPosition?.location_id,
        start_time: selectedPosition.start_time,
        end_time: selectedPosition.end_time,
        area_id: selectedPosition.area?.id || "",
      });
      setCustomState({
        ...customState,
        text_color: selectedPosition.text_color,
        background_color: selectedPosition.background_color,
      });
      setType(selectedPosition.type);
    }
  }, [selectedPosition, isEdit]);

  const nurseType = [
    { id: 1, label: "Generic", value: "GENERIC" },
    ...nurseTypes,
  ];

  return (
    <Modal
      className="customModal"
      isOpen={modal}
      toggle={handleClose}
      size="md"
      backdrop="static"
    >
      <ModalHeader toggle={handleClose} tag="h4">
        {!!isEdit ? "Edit Position" : "Add Position"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col xs={6}>
                    <Label className="form-label">Abbreviation</Label>
                    <Input
                      name="abbreviation"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.abbreviation || ""}
                      invalid={
                        validation.touched.abbreviation &&
                        validation.errors.abbreviation
                          ? true
                          : false
                      }
                    />
                    {validation.touched.abbreviation &&
                    validation.errors.abbreviation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.abbreviation}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Label className="form-label">Text Color</Label>
                    <Row>
                      <Col xs="8" style={{ paddingRight: 0 }}>
                        <Input
                          name="text_color"
                          type="text"
                          value={customState.text_color}
                          disabled={true}
                        />
                      </Col>
                      <Col xs="4" style={{ paddingLeft: 5 }}>
                        <InputColor
                          initialValue={customState.text_color}
                          onChange={(color) =>
                            setCustomState({
                              ...customState,
                              text_color: color.hex,
                            })
                          }
                          placement="right"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Label className="form-label">Background Color</Label>
                    <Row>
                      <Col xs="8" style={{ paddingRight: 0 }}>
                        <Input
                          name="background_color"
                          type="text"
                          value={customState.background_color}
                          disabled={true}
                        />
                      </Col>
                      <Col xs="4" style={{ paddingLeft: 5 }}>
                        <InputColor
                          initialValue={customState.background_color}
                          onChange={(color) =>
                            setCustomState({
                              ...customState,
                              background_color: color.hex,
                            })
                          }
                          placement="right"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label className="form-label">Location</Label>
                <div>
                  <SelectPickerPaginate
                    query={FetchLocationsForFilter}
                    placeholder="Select Location"
                    value={validation.values.location_id}
                    onChange={(newValue) =>
                      validation.setFieldValue("location_id", newValue)
                    }
                    arrKey="locations"
                    objectLabel="name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      isactive: { _eq: true },
                      createdby: { _eq: user?.id },
                    }}
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.location_id &&
                      validation.errors.location_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.location_id &&
                  validation.errors.location_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.location_id}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">Area</Label>
                <div>
                  <SelectPickerPaginate
                    // ref={areaRef}
                    query={FetchAreas}
                    placeholder="Select Area"
                    value={validation.values.area_id}
                    onChange={(area: string) =>
                      validation.setFieldValue("area_id", area)
                    }
                    arrKey="areas"
                    objectLabel="name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{ user_id: { _eq: user?.id } }}
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">Type</Label>
                <div>
                  <SelectPicker
                    data={nurseType}
                    placeholder={"Select Type"}
                    style={{ width: "100%" }}
                    menuStyle={{ zIndex: 9999 }}
                    onChange={(data: string) => setType(data)}
                    value={type}
                    searchable={false}
                    placement="bottomEnd"
                    cleanable={false}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">Start Time</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                          }}
                          onChange={(value) =>
                            validation.setFieldValue("start_time", value[0])
                          }
                          value={validation.values.start_time}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>
                      {validation.touched.start_time &&
                      validation.errors.start_time ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.start_time}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">End Time</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                          }}
                          onChange={(value) =>
                            validation.setFieldValue("end_time", value[0])
                          }
                          value={validation.values.end_time}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>
                      {validation.touched.end_time &&
                      validation.errors.end_time ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.end_time}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label className="form-label">Break Time (minutes)</Label>
                <Input
                  name="break_time"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.break_time || ""}
                  invalid={
                    validation.touched.break_time &&
                    validation.errors.break_time
                      ? true
                      : false
                  }
                  min={0}
                />
                {validation.touched.break_time &&
                validation.errors.break_time ? (
                  <FormFeedback type="invalid">
                    {validation.errors.break_time}
                  </FormFeedback>
                ) : null}
              </div>
              {role === "moderator" && (
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">Regular Rate</Label>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Input
                          name="contract_rate"
                          type="number"
                          onChange={(e: any) => {
                            validation.setFieldValue(
                              "contract_rate",
                              e.target.value
                            );
                            const calc = e.target.value
                              ? e.target.value * 1.5
                              : 0;
                            validation.setFieldValue(
                              "contract_overtime_rate",
                              calc
                            );
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.contract_rate || ""}
                          invalid={
                            validation.touched.contract_rate &&
                            validation.errors.contract_rate
                              ? true
                              : false
                          }
                        />
                      </InputGroup>
                      {validation.touched.contract_rate &&
                      validation.errors.contract_rate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contract_rate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">Overtime Rate</Label>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Input
                          name="contract_overtime_rate"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contract_overtime_rate || ""}
                          invalid={
                            validation.touched.contract_overtime_rate &&
                            validation.errors.contract_overtime_rate
                              ? true
                              : false
                          }
                        />
                      </InputGroup>
                      {validation.touched.contract_overtime_rate &&
                      validation.errors.contract_overtime_rate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contract_overtime_rate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              )}
              <div className="mb-3">
                <Label className="form-label">Requirements</Label>
                <Input
                  name="requirement"
                  label="Requirements"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.requirement || ""}
                  invalid={
                    validation.touched.requirement &&
                    validation.errors.requirement
                      ? true
                      : false
                  }
                />
                {validation.touched.requirement &&
                validation.errors.requirement ? (
                  <FormFeedback requirement="invalid">
                    {validation.errors.requirement}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Notes</Label>
                <Input
                  name="notes"
                  label="Notes"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.notes || ""}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                  disabled={loading || loadingUpdatePosition}
                >
                  <FiCheck /> &nbsp;Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default PositionModal;

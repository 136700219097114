import React from "react";

// icons
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
const NextPrevButtons: React.FC<{
	handleNextPrev: (action: string) => void
}> = ({ handleNextPrev }) => {
	return (
		<div className="btn-group calendar_move_btn">
			<button
				className="btn btn-light btn-sm"
				onClick={() => handleNextPrev("prev")}
			>
				<FiChevronLeft />
			</button>
			<button
				className="btn btn-light btn-sm"
				onClick={() => handleNextPrev("next")}
			>
				<FiChevronRight />
			</button>
		</div>
	)
}
export default NextPrevButtons
import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link, useHistory } from "react-router-dom";

import { Button, Row, Col } from "rsuite";
import { Form, FormGroup, Input } from "reactstrap";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const Help: React.FC = () => {
  const history = useHistory();
  const [searchKey, setSearchKey] = useState("");
  const {
    auth: { token },
  } = useSelector(authState);

  const onClickFilter = (value: any) => {
    // const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    if (value.length) {
      searchParams.set("search_text", value);
    } else {
      searchParams.delete("search_text");
    }
    history.push({ pathname: "/help/search", search: searchParams.toString() });
  };
  // console.log(searchKey);
  return (
    <React.Fragment>
      <div className="HelpPage">
        <div className="HelpPage-Navbar">
          <img
            src={"/caregigs_logo.png"}
            alt=""
            className="HelpPage-NavbarLogo"
          />
          <div className="HelpPage-NavbarItems">
            <div className="HelpPage-NavbarItem1">
              <NavLink to={"/help/contact-us"}>
                <p className="HelpPage-NavbarItemText1">Contact Us</p>
              </NavLink>
            </div>
            <div>
              <NavLink to={"/login"}>
                <p className="HelpPage-NavbarItemText2">
                  {token ? "Dashboard" : "Log In"}
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col md={6}>
              <Input
                name="search"
                placeholder="Search here..."
                type="text"
                onChange={(e: any) => setSearchKey(e.target.value)}
                value={searchKey}
                style={{ borderRadius: 20 }}
              />
            </Col>
            <Col>
              <Button
                type="submit"
                color="blue"
                appearance="subtle"
                className="ml-5 border border-primary"
                style={{ borderRadius: 20 }}
                size="sm"
                onClick={() => {
                  onClickFilter(searchKey);
                }}
                disabled={searchKey.length == 0 ? true : false}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="HelpPage-LogoContainer">
          <img src={"/caregigs_logo.png"} alt="" className="HelpPage-Logo" />
        </div>

        <Row className="HelpPage-CardContainer">
          <Col md={7} className="HelpPage-Card">
            <Link to="/help/nurses" style={{ textAlign: "center" }}>
              <p className="HelpPage-CardHeader">Nurses</p>
              <p className="HelpPage-CardDescription">
                Help content for our nurses!
              </p>
            </Link>
          </Col>

          <Col md={7} className="HelpPage-Card">
            <Link to="/help/facilities" style={{ textAlign: "center" }}>
              <p className="HelpPage-CardHeader">Facilities</p>
              <p className="HelpPage-CardDescription">
                Help content for our partner facilities!
              </p>
            </Link>
          </Col>
        </Row>
        <div className="HelpPage-footer">
          <p className="HelpPage-footerText">
            © 2023 Caregigs LLC | All Rights Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Help);

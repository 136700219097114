import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import RequestTable from "../../pages/Requests/RequestTable";

const RequestsModal: React.FC<{
	toggle: () => void;
	modal: boolean;
	refetch: () => void;
	jobOpeningId: string
}> = ({
	toggle,
	modal,
	refetch,
	jobOpeningId
}) => {
		const handleClose = () => {
			toggle();
		}
		return (
			<Modal isOpen={modal} toggle={handleClose} size="xl" scrollable={true}>
				<ModalHeader toggle={handleClose} tag="h4">
					Applied Requests
				</ModalHeader>
				<ModalBody>
					<RequestTable
						queryCondition={{job_opening_id: {_eq: jobOpeningId}, active: { _eq: true },
						// status: { _in: ["requested"] }
					}}
					/>
				</ModalBody>
			</Modal>
		)
	}
export default RequestsModal
import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

interface LinkItem {
  title: string;
  link: string
}
const Breadcrumbs: React.FC<{
  links: LinkItem[];
  title: string
}> = ({ links, title }) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {links.map((item: LinkItem, i: number) => (
                <BreadcrumbItem key={i}>
                  <Link to={item.link}>{item.title}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default Breadcrumbs

import React, { useState } from "react";
import {
  Card,
  Button,
  FormGroup,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import Swal from "sweetalert2";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// icons
import { FiEdit2, FiLock } from "react-icons/fi";

//redux
import { useDispatch, useSelector } from "react-redux";
import { changePasswordApi } from "toolkit/profile/profile.api";
import { authState } from "toolkit/auth/auth.slice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authState);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please Enter Old Password"),
      new_password: Yup.string().required("Please Enter New Password"),
      confirm_password: Yup.string()
        .required("Please Enter Confirm Password")
        .oneOf([Yup.ref("new_password")], "Confirm password do not match."),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);
        const resp = await dispatch(
          changePasswordApi({
            token,
            formData: values,
          })
        );
        if (resp.type === "user/change-password/fulfilled") {
          Swal.fire("Password updated successfully", "", "success");
          validation.resetForm();
        } else {
          let message = "Something went wrong!";
          if (resp?.payload?.error?.message) {
            message = resp?.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
        }
      } catch (err) {
        Swal.fire("Something went wrong!", "", "warning");
      } finally {
        setBtnLoading(false);
      }
    },
  });
  return (
    <div className="ProfilePage-card">
      <div className="row w-100 mb-4">
				<div className="col">
					<div className="ProfilePage-header d-flex" >
						<FiLock style={{marginTop: 4}}  /> <div style={{paddingLeft: 10}} >Change Password</div>
					</div>
				</div>
			</div>
      
      <div className="row w-100">
        <Form
          className="form-horizontal ProfilePage-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="row w-100" >
            <div className="col-12 col-lg-12 mb-3">
              <FormGroup>
                <Label for="old_password">Old Password</Label>
                <Input
                  id="old_password"
                  name="old_password"
                  className="form-control"
                  placeholder="Enter Old Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.old_password || ""}
                  invalid={
                    validation.touched.old_password &&
                    validation.errors.old_password
                      ? true
                      : false
                  }
                />
                {validation.touched.old_password &&
                validation.errors.old_password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.old_password}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </div>
            <div className="col-12 col-lg-12 mb-3">
              <FormGroup>
                <Label for="new_password">New Password</Label>
                <Input
                  id="new_password"
                  name="new_password"
                  className="form-control"
                  placeholder="Enter New Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.new_password || ""}
                  invalid={
                    validation.touched.new_password &&
                    validation.errors.new_password
                      ? true
                      : false
                  }
                />
                {validation.touched.new_password &&
                validation.errors.new_password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.new_password}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </div>
            <div className="col-12 col-lg-12 mb-3">
              <FormGroup>
                <Label for="confirm_password">Confirm Password</Label>
                <Input
                  id="confirm_password"
                  name="confirm_password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirm_password || ""}
                  invalid={
                    validation.touched.confirm_password &&
                    validation.errors.confirm_password
                      ? true
                      : false
                  }
                />
                {validation.touched.confirm_password &&
                validation.errors.confirm_password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirm_password}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </div>
          </div>
          <div className="row w-100">
            <div className="col-12 col-lg-12">
              <div className="">
                <Button
                  className="cus-btn"
                  color="primary"
                  disabled={btnLoading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;

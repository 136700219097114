import React, { useEffect, useState } from "react";
import { List } from "rsuite";
import ProfileModal from "components/profile/ProfileModal";

//helpers
import { getImage } from "helpers";
//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const NurseDetails: React.FC<{
  customer: any;
}> = ({ customer }) => {
  const {
    auth: { token },
  } = useSelector(authState);
  const [image, setImage] = useState<string>("/placeholder.jpg");
  const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);

  const handleGetImage = async (link: string) => {
    const _image = await getImage(link, token, "image");
    setImage(_image as string);
  };

  useEffect(() => {
    if (customer?.customer.profile_picture) {
      handleGetImage(customer?.customer.profile_picture);
    } else if (customer?.gender === "female") {
      setImage("/avatar-female.jpg");
    } else {
      setImage("/avatar.jpg");
    }
  }, [customer]);
  return (
    <div>
      <h4 className="font-weight-semibold">Nurse Details</h4>
      <img
        src={image}
        alt=""
        style={{ height: 160, width: 160, borderRadius: 10 }}
        className="mt-3"
      />
      <div className="text-muted mt-3">
        <List size="sm" hover>
          <List.Item>
            <strong>Name:</strong>{" "}
            {`${customer?.customer.firstname} ${customer.customer.lastname}`}
          </List.Item>
          <List.Item>
            <strong>Email:</strong> {customer.customer.email}
          </List.Item>
          <List.Item>
            <strong>Contact 1:</strong> {customer.customer.contactno1}
          </List.Item>
          <List.Item>
            <strong>Gender:</strong> {customer.customer.gender || "N/A"}
          </List.Item>
          <List.Item>
            <strong>Nurse Type:</strong>{" "}
            {customer?.invitation?.position?.type || "N/A"}
          </List.Item>
        </List>
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setProfileModalOpen(true)}
        >
          View More Details
        </button>
      </div>
      <ProfileModal
        user={customer}
        isOpen={isProfileModalOpen}
        toggle={() => setProfileModalOpen(false)}
      />
    </div>
  );
};
export default NurseDetails;

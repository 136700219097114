import React, { CSSProperties } from "react"

//types
import ReferenceType from "./references.types"

const wrapStyle: CSSProperties = {
	display: 'block', 
	width: 230, 
	overflow: 'hidden', 
	whiteSpace: 'nowrap', 
	textOverflow: 'ellipsis'
}
export const FormatData = (notes: ReferenceType[]) => {
	const _notes = notes.map((elem: ReferenceType) => {
		return {
			name: elem.name,
			email: elem.email,
			notes: elem.notes,
			phone: elem.phone,
			organisation: elem.organisation,
		}
	})
	return _notes
}
export const ReferencesColumns = () => [
	{
		text: "Name",
		dataField: "name",
	},
	{
		text: "Email",
		dataField: "email",
	},
	{
		dataField: "notes",
		text: "Notes",
		formatter: (cellContent: string) => { return <span style={wrapStyle}>{cellContent}</span> }
	},
	{
		text: "Phone",
		dataField: "phone",
	},
	{
		text: "Organisation",
		dataField: "organisation",
	},
]
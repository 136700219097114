import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useHistory } from "react-router-dom";
import { getNurseStatus } from "helpers";

const PieChart: React.FC<{
  series: number[];
  labels: string[];
  colors: string[];
  height: string;
  type: string;
  chartType: any;
}> = ({ series, labels, colors, height = "320", type, chartType }) => {
  const history = useHistory();
  const options: ApexOptions = {
    labels,
    colors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (type == "Speciality") {
            history.push(
              `/nurses?speciality=${
                config.w.config.labels[config.dataPointIndex]
              }`
            );
          }
          if (type == "Status") {
            history.push(
              `/nurses?type=${getNurseStatus(
                config.w.config.labels[config.dataPointIndex]
              )}`
            );
          }
          if (type == "State") {
            history.push(
              `/nurses?state=${config.w.config.labels[config.dataPointIndex]}`
            );
          }
          if (type == "SpecialityMA") {
            history.push(
              `/nurses?speciality=${
                config.w.config.labels[config.dataPointIndex]
              }&address=MA%2CMassachusetts`
            );
          }
          if (type == "StatusMA") {
            history.push(
              `/nurses?type=${getNurseStatus(
                config.w.config.labels[config.dataPointIndex]
              )}&address=MA%2CMassachusetts`
            );
          }
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type={chartType}
      height={height}
      className="apex-charts"
    />
  );
};

export default PieChart;

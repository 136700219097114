import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from "reactstrap";

import { FiTrash2 } from "react-icons/fi";
import * as Yup from "yup";
import {
  useFormik,
  FieldArray,
  Field,
  ErrorMessage,
  FormikProvider,
} from "formik";
import { showAlert } from "helpers/index";
import Swal from "sweetalert2";

import Flatpickr from "react-flatpickr";
import * as _Moment from "moment";
import { Moment } from "moment";
import { extendMoment } from "moment-range";

import * as mtz from "moment-timezone";

import { UpdateJobRequest } from "gql/jobRequests/mutation";
import { UpdateInvitation } from "gql/invitations/mutation";
import { UpdateJobOpening } from "gql/jobOpenings/mutation";
import { InsertRateOne } from "gql/rates/mutation";
import { UpdateNurses } from "gql/customers/mutation";
import { UpdatePosition } from "gql/positions/mutation";

import { useMutation } from "@apollo/client";
import { breakTimeType } from "pages/Admin/Shifts/shifts.types";
import { getTotalBreakTime } from "helpers";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import {
  createMapleShiftCompletionApi,
  updateMapleShiftCompletionApi,
  notifySubscribers,
} from "toolkit/admin/admin.api";
interface BreakTimeType {
  start_time: string;
  end_time: string;
}

const moment = extendMoment(_Moment);

const PayrollModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  selectedShift: any;
  refetch: () => void;
}> = ({ toggle, modal, selectedShift, refetch }) => {
  const {
    auth: { user, token },
  } = useSelector(authState);
  const dispatch = useDispatch();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);
  const [UpdateInvitationMutation] = useMutation(UpdateInvitation);
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);
  const [CreateNurseRateMutation] = useMutation(InsertRateOne);
  const [CreatePositionRateMutation] = useMutation(InsertRateOne);
  const [UpdateCustomerRateMutation] = useMutation(UpdateNurses);
  const [UpdatePositionMutation] = useMutation(UpdatePosition);
  const [btnLoading, setBtnLoading] = useState(false);
  const convertToUtcIsoFormat = (timestamp: string): string => {
    const formattedTimestamp = moment(timestamp).format("YYYY-MM-DD HH:mm");
    const easternDateTime = mtz.tz(formattedTimestamp, "America/New_York");
    const utcDateTime = easternDateTime.clone().utc();
    return utcDateTime.toISOString();
  };

  const convertEasternToUTC = (date: string, time: string) => {
    const easternDateTime = mtz.tz(`${date} ${time}`, "America/New_York");
    const utcDateTime = easternDateTime.clone().utc();
    return utcDateTime.toISOString();
  };

  // Function to generate punches array from data
  const generatePunches = (
    data: any[]
  ): { type: string; punchTime: string }[] => {
    const punches: { type: string; punchTime: string }[] = [];

    // Iterate over data and create objects for START_BREAK and END_BREAK
    data.forEach((item: any) => {
      punches.push(
        {
          type: "START_BREAK",
          punchTime: convertToUtcIsoFormat(item?.start_time),
        },
        {
          type: "END_BREAK",
          punchTime: convertToUtcIsoFormat(item?.end_time),
        }
      );
    });

    return punches;
  };

  const handleUpdateShift = async (
    clock_in_time: string,
    clock_out_time: string,
    break_times: any,
    note: any,
    nurse_rate: any,
    nurse_overtime_rate: any,
    contract_rate: any,
    contract_overtime_rate: any,
    maple_completion_id?: string
  ) => {
    try {
      setBtnLoading(true);

      const formData = {
        event: "SHIFT_COMPLETED",
        organisation_id: selectedShift.fee_amount_object.organisation.id,
        nurse_id: selectedShift.customer_id,
        position_id: selectedShift.position_id,
        date: moment(selectedShift.job_date).format("LL"),
        clock_in_time: clock_in_time,
        clock_out_time: clock_out_time,
        standard_hours: (total_regular_minutes / 60).toFixed(2),
        standard_cost: facility_regular_rate,
        overtime_hours: (total_overtime_minutes / 60).toFixed(2),
        overtime_cost: facility_overtime_rate,
        total_hours: (
          (total_regular_minutes + total_overtime_minutes) /
          60
        ).toFixed(2),
        total_cost: facility_total_charges,
      };
      // await dispatch(notifySubscribers({ formData, token }));

      await UpdateJobRequestMutation({
        variables: {
          id: selectedShift?.id,
          object: {
            clock_in_time,
            clock_out_time,
            break_times,
            status: "completed",
            in_sign_notes: "Approved by Caregigs Admin",
            is_confirmed: true,
            out_sign_notes: "Approved by Caregigs Admin",
            regular_hours: total_regular_minutes,
            overtime_hours: total_overtime_minutes,
            facility_regular_charges: facility_regular_rate,
            facility_overtime_charges: facility_overtime_rate,
            facility_total_charges: facility_total_charges,
            nurse_regular_charges: nurse_regular_charges,
            nurse_overtime_charges: nurse_overtime_charges,
            nurse_total_charges: nurse_total_charges,
            caregigs_margin: margin,
            notes: note,
            maple_shift_completion_id: maple_completion_id ?? null,
          },
        },
      });
      if (selectedShift.fee_amount_type == "job_opening") {
        await UpdateJobOpeningMutation({
          variables: {
            id: selectedShift?.fee_amount_object?.id,
            object: {
              fee_amount: nurse_rate,
            },
          },
        });
      } else if (selectedShift.fee_amount_type == "invitation") {
        await UpdateInvitationMutation({
          variables: {
            id: selectedShift?.fee_amount_object?.id,
            object: {
              fee_amount: nurse_rate,
            },
          },
        });
      }
      await CreateNurseRateMutation({
        variables: {
          object: {
            customer_id: selectedShift.customer_id,
            nurse_rate: nurse_rate,
            nurse_overtime_rate: nurse_overtime_rate,
          },
        },
      });
      await UpdateCustomerRateMutation({
        variables: {
          where: {
            id: { _eq: selectedShift.customer_id },
          },
          object: {
            nurse_rate: nurse_rate,
            nurse_overtime_rate: nurse_overtime_rate,
          },
        },
      });
      await CreatePositionRateMutation({
        variables: {
          object: {
            position_id: selectedShift?.position_id,
            contract_rate: contract_rate,
            contract_overtime_rate: contract_overtime_rate,
          },
        },
      });
      await UpdatePositionMutation({
        variables: {
          id: selectedShift?.position_id,
          object: {
            contract_rate: contract_rate,
            contract_overtime_rate: contract_overtime_rate,
          },
        },
      });
      refetch();
      showAlert({
        title: "Updated!",
        message: "Update successful!",
        type: "success",
      });
      handleClose();
    } catch (err) {
      console.log(err);
      showAlert({
        title: "Error!",
        message: "Something went wrong",
        type: "danger",
      });
    } finally {
      setBtnLoading(false);
    }
  };
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      start_date:
        moment(selectedShift?.clock_in).isValid() && selectedShift?.clock_in
          ? moment(selectedShift?.clock_in).format("YYYY-MM-DD")
          : "",
      start_time:
        moment(selectedShift?.clock_in).isValid() && selectedShift?.clock_in
          ? moment(selectedShift?.clock_in).format("HH:mm")
          : "",
      end_date:
        moment(selectedShift?.clock_out).isValid() && selectedShift?.clock_out
          ? moment(selectedShift?.clock_out).format("YYYY-MM-DD")
          : "",
      end_time:
        moment(selectedShift?.clock_out).isValid() && selectedShift?.clock_out
          ? moment(selectedShift?.clock_out).format("HH:mm")
          : "",
      rate:
        (selectedShift?.fee_amount_object.fee_amount &&
          selectedShift?.fee_amount_object.fee_amount) ||
        "0",
      break_times: [],
      nurse_rate:
        (selectedShift?.nurse_rate && selectedShift?.nurse_rate) || "0",
      nurse_overtime_rate:
        (selectedShift?.nurse_overtime_rate &&
          selectedShift?.nurse_overtime_rate) ||
        "0",
      contract_rate:
        (selectedShift?.contract_rate && selectedShift?.contract_rate) || "0",
      contract_overtime_rate:
        (selectedShift?.contract_overtime_rate &&
          selectedShift?.contract_overtime_rate) ||
        "0",
      total_hours:
        (selectedShift?.total_hours && selectedShift?.total_hours) || "0",
      note: (selectedShift?.notes && selectedShift?.notes) || "",
    },
    validationSchema: Yup.object({
      fee_amount: Yup.number().min(0, "Rate should not be in negative value"),
      start_date: Yup.string()
        .test(
          "start_date_not_empty",
          "Clock-In date can't be empty",
          function (value) {
            return !!value;
          }
        )
        .test(
          "start_date_future_date_test",
          "Clock-In date should not be a future date",
          function (value) {
            return !moment(value).isAfter();
          }
        ),
      start_time: Yup.string()
        .test("not empty", "Clock-In time can't be empty", function (value) {
          return !!value;
        })
        .test(
          "start_time_test",
          "Clock-In/Out time should not be same",
          function (value) {
            const { end_time } = this.parent;
            let _start_date: any = value,
              _end_date: any = end_time;
            if (!moment(end_time).isValid()) {
              _end_date = moment(end_time, "H:mm").toDate();
            }
            if (!moment(value).isValid()) {
              _start_date = moment(value, "H:mm").toDate();
            }
            return !moment(_start_date).isSame(_end_date);
          }
        ),
      end_time: Yup.string()
        .test(
          "end_time_not_empty",
          "Clock-Out time can't be empty",
          function (value) {
            return !!value;
          }
        )
        .test(
          "end_time_prior_test",
          "Clock Out time should not be prior to Clock In time",
          function (value) {
            const { start_time, start_date, end_date } = this.parent;
            if (start_date && start_date && end_date) {
              const clock_in = moment(`${start_date} ${start_time}`);
              const clock_out = moment(`${end_date} ${value}`);
              return clock_in.isBefore(clock_out);
            } else {
              return true;
            }
          }
        ),
      end_date: Yup.string()
        .test(
          "clock_till_not_empty",
          "Clock-Out date can't be empty",
          function (value) {
            return !!value;
          }
        )
        .test(
          "clock_till_test",
          "Clock-Out Date Should Not be Prior to Clock-In Date",
          function (value) {
            const { start_date } = this.parent;
            return !moment(value).isBefore(start_date);
          }
        ),

      break_times: Yup.array().of(
        Yup.object().shape({
          start_time: Yup.string()
            .test(
              "break_start_time_not_empty",
              "Break-Start time can't be empty",
              function (value) {
                return !!value;
              }
            )
            .test(
              "break_start_time_past_date_test",
              "Break Start time should not be a past date/time",
              function (value) {
                const clock_in = moment(
                  `${validation.values.start_date} ${validation.values.start_time}`
                );
                return !moment(value).isBefore(clock_in);
              }
            )
            .test(
              "break_start_time_future_date_test",
              "Break Start time should not be a future date/time",
              function (value) {
                const clock_out = moment(
                  `${validation.values.end_date} ${validation.values.end_time}`
                );
                return !moment(value).isAfter(clock_out);
              }
            ),
          end_time: Yup.string()
            .test(
              "break_end_time_not_empty",
              "Break-End time can't be empty",
              function (value) {
                return !!value;
              }
            )
            .test(
              "break_end_time_future_date_test",
              "Break End time should not be a future date/time",
              function (value) {
                const clock_out = moment(
                  `${validation.values.end_date} ${validation.values.end_time}`
                );
                return !moment(value).isAfter(clock_out);
              }
            )
            .test(
              "break_end_time_past_date_test",
              "Break End time should not be a past date/time",
              function (value) {
                const clock_in = moment(
                  `${validation.values.start_date} ${validation.values.start_time}`
                );
                return !moment(value).isBefore(clock_in);
              }
            ),
        })
      ),
      note: Yup.string(),
      nurse_rate: Yup.number().min(0, "Rate should not be in negative value"),
      nurse_overtime_rate: Yup.number().min(
        0,
        "Rate should not be in negative value"
      ),
      contract_rate: Yup.number().min(
        0,
        "Rate should not be in negative value"
      ),
      contract_overtime_rate: Yup.number().min(
        0,
        "Rate should not be in negative value"
      ),
    }),
    onSubmit: async (values) => {
      const clock_in_time = moment(
        `${values.start_date} ${values.start_time}`
      ).format("ddd, MMM DD, YYYY,hh:mm A");
      const clock_out_time = moment(
        `${values.end_date} ${values.end_time}`
      ).format("ddd, MMM DD, YYYY,hh:mm A");
      const break_times = values.break_times;
      const note = values.note;
      const nurse_rate = values.nurse_rate;
      const nurse_overtime_rate = values.nurse_overtime_rate;
      const contract_rate = values.contract_rate;
      const contract_overtime_rate = values.contract_overtime_rate;
      const breakPunches = validation?.values?.break_times.length
        ? generatePunches(validation.values.break_times)
        : [
            {
              type: "SKIP_BREAK",
              punchTime: convertEasternToUTC(
                validation.values.start_date,
                validation.values.start_time
              ),
            },
          ];

      const punches = [
        ...breakPunches,
        {
          type: "CLOCK_IN",
          punchTime: convertEasternToUTC(
            validation.values.start_date,
            validation.values.start_time
          ),
        },
        {
          type: "CLOCK_OUT",
          punchTime: convertEasternToUTC(
            validation.values.end_date,
            validation.values.end_time
          ),
        },
      ];
      let mapleResponse: any = null;
      if (selectedShift?.maple_shift_id) {
        if (
          selectedShift?.maple_shift_assignment_id &&
          selectedShift?.maple_shift_completion_id === null
        ) {
          //create shift completion
          const mapleShiftCreateCompletionObject = {
            mapleShiftId: selectedShift?.maple_shift_id,
            facilityId: selectedShift?.facility_id,
            punches: punches,
          };
          mapleResponse = await dispatch(
            createMapleShiftCompletionApi({
              formData: mapleShiftCreateCompletionObject,
              token,
            })
          );
        } else if (selectedShift?.maple_shift_completion_id) {
          //update shift completion
          const mapleShiftUpdateCompletionObject = {
            mapleShiftId: selectedShift?.maple_shift_id,
            mapleShiftCompletionId: selectedShift?.maple_shift_completion_id,
            punches: punches,
          };
          mapleResponse = await dispatch(
            updateMapleShiftCompletionApi({
              formData: mapleShiftUpdateCompletionObject,
              token,
            })
          );
        }

        if (mapleResponse?.payload?.data) {
          if (selectedShift.status !== "completed") {
            Swal.fire({
              title: `Are you sure want to update this shift?`,
              text: "This shift will be treated as a completed shift",
              showCancelButton: true,
              confirmButtonText: "Yes",
              icon: "warning",
            }).then(async (result) => {
              if (result.isConfirmed) {
                handleUpdateShift(
                  clock_in_time,
                  clock_out_time,
                  break_times,
                  note,
                  nurse_rate,
                  nurse_overtime_rate,
                  contract_rate,
                  contract_overtime_rate,
                  mapleResponse?.payload?.data?.id
                );
              }
            });
          } else {
            handleUpdateShift(
              clock_in_time,
              clock_out_time,
              break_times,
              note,
              nurse_rate,
              nurse_overtime_rate,
              contract_rate,
              contract_overtime_rate,
              mapleResponse?.payload?.data?.id
            );
          }
          const formData = {
            event: "SHIFT_COMPLETED",
            organisation_id: selectedShift.fee_amount_object.organisation.id,
            nurse_id: selectedShift.customer_id,
            position_id: selectedShift.position_id,
            date: moment(selectedShift.job_date).format("LL"),
            clock_in_time,
            clock_out_time,
            standard_hours: (total_regular_minutes / 60).toFixed(2),
            standard_cost: facility_regular_rate,
            overtime_hours: (total_overtime_minutes / 60).toFixed(2),
            overtime_cost: facility_overtime_rate,
            total_hours: (
              (total_regular_minutes + total_overtime_minutes) /
              60
            ).toFixed(2),
            total_cost: facility_total_charges,
          };
          // await dispatch(notifySubscribers({ formData, token }));
        } else {
          showAlert({
            title: "Error!",
            message:
              `${
                JSON.parse(mapleResponse?.payload?.error?.message)?.errors[0]
                  ?.title
              }` ?? "Something went wrong",
            type: "danger",
          });
        }
      } else {
        if (selectedShift.status !== "completed") {
          Swal.fire({
            title: `Are you sure want to update this shift?`,
            text: "This shift will be treated as a completed shift",
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: "warning",
          }).then(async (result) => {
            if (result.isConfirmed) {
              handleUpdateShift(
                clock_in_time,
                clock_out_time,
                break_times,
                note,
                nurse_rate,
                nurse_overtime_rate,
                contract_rate,
                contract_overtime_rate
              );
            }
          });
        } else {
          handleUpdateShift(
            clock_in_time,
            clock_out_time,
            break_times,
            note,
            nurse_rate,
            nurse_overtime_rate,
            contract_rate,
            contract_overtime_rate
          );
        }
        const formData = {
          event: "SHIFT_COMPLETED",
          organisation_id: selectedShift.fee_amount_object.organisation.id,
          nurse_id: selectedShift.customer_id,
          position_id: selectedShift.position_id,
          date: moment(selectedShift.job_date).format("LL"),
          clock_in_time: clock_in_time,
          clock_out_time: clock_out_time,
          standard_hours: (total_regular_minutes / 60).toFixed(2),
          standard_cost: facility_regular_rate,
          overtime_hours: (total_overtime_minutes / 60).toFixed(2),
          overtime_cost: facility_overtime_rate,
          total_hours: (
            (total_regular_minutes + total_overtime_minutes) /
            60
          ).toFixed(2),
          total_cost: facility_total_charges,
        };
        // await dispatch(notifySubscribers({ formData, token }));
      }
    },
  });
  const handleClose = () => {
    toggle();
    validation.resetForm();
  };

  useEffect(() => {
    if (Array.isArray(selectedShift?.break_times)) {
      validation.setFieldValue("break_times", selectedShift.break_times);
    }
    if (selectedShift?.regular_hours) {
      setRegularHour(String(Math.floor(selectedShift.regular_hours / 60)));
      setRegularMinutes(String(selectedShift.regular_hours % 60));
    }
    if (selectedShift?.overtime_hours) {
      setOvertimeHour(String(Math.floor(selectedShift.overtime_hours / 60)));
      setOvertimeMinutes(String(selectedShift.overtime_hours % 60));
    }
  }, [selectedShift]);

  const round = (value: any, precision: any) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const total_minutes = moment
    .duration(
      moment(
        `${validation.values.end_date} ${validation.values.end_time}`
      ).diff(
        moment(
          `${validation.values.start_date} ${validation.values.start_time}`
        )
      )
    )
    .asMinutes();

  const break_time = getTotalBreakTime(validation.values.break_times, 0);
  const working_total_minutes = total_minutes - break_time;
  const Hours = Math.floor(working_total_minutes / 60);
  const minutes = working_total_minutes % 60;

  const [regularHour, setRegularHour] = useState<string>("0");
  const [regularMinutes, setRegularMinutes] = useState<string>("0");
  const [overtimeHour, setOvertimeHour] = useState<string>("0");
  const [overtimeMinutes, setOvertimeMinutes] = useState<string>("0");
  useEffect(() => {
    if (!selectedShift?.regular_hours) {
      setRegularHour(`${Hours}`);
      setRegularMinutes(`${minutes}`);
    }
  }, [Hours, minutes, selectedShift]);

  const total_regular_minutes =
    parseFloat(regularHour) * 60 + parseFloat(regularMinutes);
  // const total_regular_minutes =
  //   working_total_minutes -
  //   (parseFloat(overtimeHour) * 60 + parseFloat(overtimeMinutes));
  const total_regular_hour = total_regular_minutes / 60;
  const facility_regular_rate = round(
    validation.values.contract_rate * total_regular_hour,
    2
  );
  const nurse_regular_charges = round(
    validation.values.nurse_rate * total_regular_hour,
    2
  );

  const total_overtime_minutes =
    parseFloat(overtimeHour) * 60 + parseFloat(overtimeMinutes);
  const total_overtime_hour = total_overtime_minutes / 60;
  const facility_overtime_rate = round(
    validation.values.contract_overtime_rate * total_overtime_hour,
    2
  );
  const nurse_overtime_charges = round(
    validation.values.nurse_overtime_rate * total_overtime_hour,
    2
  );

  const facility_total_charges = round(
    facility_regular_rate + facility_overtime_rate,
    2
  );
  const nurse_total_charges = round(
    nurse_regular_charges + nurse_overtime_charges,
    2
  );
  const margin = round(facility_total_charges - nurse_total_charges, 2);

  console.log(
    "break-times",
    validation?.values?.break_times.length,
    validation?.values?.break_times
  );

  return (
    <Modal
      isOpen={modal}
      size="xl"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
      scrollable={true}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Update Shift
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={validation}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">Clock-In Date</Label>
                      <Input
                        name="start_date"
                        type="date"
                        onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        value={validation.values.start_date}
                        invalid={
                          validation.touched.start_date &&
                          validation.errors.start_date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.start_date &&
                      validation.errors.start_date ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.start_date}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">Clock-In Time</Label>
                      <div className="flatpickr-input-group">
                        <InputGroup>
                          <div className="flatpickr">
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                              onChange={(value) =>
                                validation.setFieldValue(
                                  "start_time",
                                  moment(value[0]).format("HH:mm")
                                )
                              }
                              value={validation.values.start_time}
                            />
                          </div>
                          <div className="input-group-append">
                            <div className="flatpickr-timer">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </div>
                        </InputGroup>
                      </div>
                      {validation.touched.start_time &&
                      validation.errors.start_time ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.start_time}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Clock-Out Date
                      </Label>
                      <Input
                        name="end_date"
                        type="date"
                        onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        value={validation.values.end_date}
                        invalid={
                          validation.touched.end_date &&
                          validation.errors.end_date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.end_date &&
                      validation.errors.end_date ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.end_date}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Clock-Out Time
                      </Label>
                      <div className="flatpickr-input-group">
                        <InputGroup>
                          <div className="flatpickr">
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                              onChange={(value) =>
                                validation.setFieldValue(
                                  "end_time",
                                  moment(value[0]).format("HH:mm")
                                )
                              }
                              value={validation.values.end_time}
                            />
                          </div>
                          <div className="input-group-append">
                            <div className="flatpickr-timer">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </div>
                        </InputGroup>
                      </div>
                      {validation.touched.end_time &&
                      validation.errors.end_time ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.end_time}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <FieldArray
                  name="break_times"
                  render={(arrayHelpers) => {
                    const breakTimes = validation.values.break_times;
                    return (
                      <div>
                        {breakTimes && breakTimes.length > 0
                          ? breakTimes.map(
                              (
                                elementInArray: breakTimeType,
                                index: number
                              ) => (
                                <Row key={index}>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-email-Input">
                                        Break-Time Start
                                      </Label>
                                      <div className="flatpickr-input-group">
                                        <InputGroup>
                                          <div className="flatpickr">
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              options={{
                                                enableTime: true,
                                                noCalendar: false,
                                                dateFormat: "Y-m-d H:i",
                                              }}
                                              value={moment(
                                                validation.values.break_times[
                                                  index
                                                ].start_time,
                                                "ddd, MMM DD, YYYY,hh:mm A"
                                              ).toDate()}
                                              onChange={(value) =>
                                                validation.setFieldValue(
                                                  `break_times.${index}.start_time`,
                                                  moment(value[0]).format(
                                                    "ddd, MMM DD, YYYY,hh:mm A"
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="input-group-append">
                                            <div className="flatpickr-timer">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </div>
                                        </InputGroup>
                                      </div>
                                      {validation.touched.break_times?.[
                                        index
                                      ] &&
                                        validation.errors.break_times?.[
                                          index
                                        ] && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {
                                              validation.errors.break_times[
                                                index
                                              ]?.start_time
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-email-Input">
                                        Break-Time End
                                      </Label>
                                      <div className="flatpickr-input-group">
                                        <InputGroup>
                                          <div className="flatpickr">
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              options={{
                                                enableTime: true,
                                                noCalendar: false,
                                                dateFormat: "Y-m-d H:i",
                                              }}
                                              // value={`breakTimes.${index}.end_time`}
                                              value={moment(
                                                validation.values.break_times[
                                                  index
                                                ].end_time,
                                                "ddd, MMM DD, YYYY,hh:mm A"
                                              ).toDate()}
                                              onChange={(value) =>
                                                validation.setFieldValue(
                                                  `break_times.${index}.end_time`,
                                                  moment(value[0]).format(
                                                    "ddd, MMM DD, YYYY,hh:mm A"
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="input-group-append">
                                            <div className="flatpickr-timer">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </div>
                                        </InputGroup>
                                      </div>
                                      {validation.touched.break_times?.[
                                        index
                                      ] &&
                                        validation.errors.break_times?.[
                                          index
                                        ] && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {
                                              validation.errors.break_times[
                                                index
                                              ]?.end_time
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                  <Col
                                    md={1}
                                    className="d-flex align-self-center justify-content-end"
                                  >
                                    <FiTrash2
                                      size={15}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Col>
                                </Row>
                              )
                            )
                          : null}
                        <Col>
                          <div className="text-start">
                            <button
                              onClick={() =>
                                arrayHelpers.push({
                                  start_time: "",
                                  end_time: "",
                                })
                              }
                              className="btn btn-primary save-user mb-1"
                              type="button"
                            >
                              Add Break
                            </button>
                          </div>
                        </Col>
                      </div>
                    );
                  }}
                />
                <Row>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Total Hours</Label>
                      <div className="d-flex flex-row">
                        <Input
                          name="hours"
                          type="number"
                          style={{ textAlign: "right" }}
                          min={0}
                          disabled={true}
                          value={Hours}
                        />
                        <p className="d-flex align-self-center">:</p>
                        <Input
                          name="minutes"
                          type="number"
                          style={{ textAlign: "center" }}
                          min={0}
                          max={59}
                          disabled={true}
                          value={minutes}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Regular Hours</Label>
                      <div className="d-flex flex-row">
                        <Input
                          name="regularHour"
                          type="number"
                          style={{ textAlign: "right" }}
                          min={0}
                          value={regularHour}
                          onChange={(e: any) => {
                            setRegularHour(e.target.value);
                            // setOvertimeHour(String(Hours - e.target.value));
                          }}
                          max={Hours}
                        />
                        <p className="d-flex align-self-center">:</p>
                        <Input
                          name="regularMinutes"
                          type="number"
                          style={{ textAlign: "center" }}
                          value={regularMinutes}
                          max={
                            String(Hours) == String(regularHour) ? minutes : ""
                          }
                          onChange={(e: any) => {
                            setRegularMinutes(e.target.value);
                            // setOvertimeMinutes(
                            //   String(minutes - e.target.value)
                            // );
                            if (e.target.value > 59) {
                              setRegularMinutes("0");
                              setRegularHour(
                                String(parseFloat(regularHour) + 1)
                              );
                              // setOvertimeMinutes("0");
                              // setOvertimeHour(
                              //   String(parseFloat(overtimeHour) + 1)
                              // );
                            } else if (e.target.value < 0) {
                              setRegularMinutes("59");
                              setRegularHour(
                                String(parseFloat(regularHour) - 1)
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Overtime Hours</Label>
                      <div className="d-flex flex-row">
                        <Input
                          name="overtimeHour"
                          type="number"
                          style={{ textAlign: "right" }}
                          min={0}
                          max={Hours}
                          value={overtimeHour}
                          onChange={(e: any) => {
                            setOvertimeHour(e.target.value);
                            // setRegularHour(String(Hours - e.target.value));
                          }}
                        />
                        <p className="d-flex align-self-center">:</p>
                        <Input
                          name="overtimeMinutes"
                          type="number"
                          style={{ textAlign: "center" }}
                          // min={0}
                          value={overtimeMinutes}
                          onChange={(e: any) => {
                            setOvertimeMinutes(e.target.value);
                            // setRegularMinutes(String(minutes - e.target.value));
                            if (e.target.value > 59) {
                              setOvertimeMinutes("0");
                              setOvertimeHour(
                                String(parseFloat(overtimeHour) + 1)
                              );
                            } else if (e.target.value < 0) {
                              setOvertimeMinutes("59");
                              setOvertimeHour(
                                String(parseFloat(overtimeHour) - 1)
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Label className="form-label text-primary">
                    Facility Rate
                  </Label>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Regular Rate</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="contract_rate"
                          type="number"
                          value={validation.values.contract_rate || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.contract_rate &&
                            validation.errors.contract_rate
                              ? true
                              : false
                          }
                          style={{ border: "none", paddingLeft: 10 }}
                        />
                        {validation.touched.contract_rate &&
                        validation.errors.contract_rate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contract_rate}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Overtime Rate</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="contract_overtime_rate"
                          type="number"
                          value={validation.values.contract_overtime_rate || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.contract_overtime_rate &&
                            validation.errors.contract_overtime_rate
                              ? true
                              : false
                          }
                          style={{ border: "none", paddingLeft: 10 }}
                        />
                        {validation.touched.contract_overtime_rate &&
                        validation.errors.contract_overtime_rate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contract_overtime_rate}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Label className="form-label text-primary">Nurse Rate</Label>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Regular Rate</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="nurse_rate"
                          type="number"
                          value={validation.values.nurse_rate || ""}
                          style={{ border: "none", paddingLeft: 10 }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.nurse_rate &&
                            validation.errors.nurse_rate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.nurse_rate &&
                        validation.errors.nurse_rate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nurse_rate}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Overtime Rate</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="nurse_overtime_rate"
                          type="number"
                          value={validation.values.nurse_overtime_rate || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.nurse_overtime_rate &&
                            validation.errors.nurse_overtime_rate
                              ? true
                              : false
                          }
                          style={{ border: "none", paddingLeft: 10 }}
                        />
                        {validation.touched.nurse_overtime_rate &&
                        validation.errors.nurse_overtime_rate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nurse_overtime_rate}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Label className="form-label text-primary">
                  Facility Charge
                </Label>
                <Row>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Regular Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="facility_regular_rate"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={facility_regular_rate}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Overtime Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="facility_overtime_rate"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={facility_overtime_rate}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Total Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="facility_total_charges"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={facility_total_charges}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Label className="form-label text-primary">Nurse Charge</Label>
                <Row>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Regular Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="nurse_regular_charges"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={nurse_regular_charges}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Overtime Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="nurse_overtime_charges"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={nurse_overtime_charges}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3 mt-2">
                      <Label className="form-label">Total Charges</Label>
                      <InputGroup
                        style={{ outline: "auto", outlineColor: "#eceff2" }}
                      >
                        <InputGroupText style={{ border: "none" }}>
                          $
                        </InputGroupText>
                        <Input
                          name="nurse_total_charges"
                          type="number"
                          style={{ border: "none", paddingLeft: 10 }}
                          disabled={true}
                          value={nurse_total_charges}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Col>
                  <div className="mb-3 mt-2">
                    <Label className="form-label text-primary mb-2">
                      Margin
                    </Label>
                    <InputGroup
                      style={{ outline: "auto", outlineColor: "#eceff2" }}
                    >
                      <InputGroupText style={{ border: "none" }}>
                        $
                      </InputGroupText>
                      <Input
                        name="margin"
                        type="number"
                        style={{ border: "none", paddingLeft: 10 }}
                        disabled={true}
                        value={margin}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Label className="form-label">Notes</Label>
                    <Input
                      name="note"
                      label="Notes"
                      type="textarea"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.note || ""}
                      invalid={
                        validation.touched.note && validation.errors.note
                          ? true
                          : false
                      }
                    />
                    {/* {validation.touched.note && validation.errors.note ? (
                      <FormFeedback type="invalid">
                        {validation.errors.note}
                      </FormFeedback>
                    ) : null} */}
                  </div>
                </Col>
                <Row style={{ position: "absolute", bottom: 7, right: 0 }}>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                        disabled={btnLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-secondary save-user"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  );
};
export default PayrollModal;

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";

// icons
import { FiCheck } from "react-icons/fi";

//types

import SelectPickerPaginate from "../Filters/SelectPickerPaginate";

//graphql
import { FetchLocationsForFilter } from "gql/locations/query";
import { FetchPositionsForFilter } from "gql/positions/query";
import { FetchFacilitiesViewForFilter } from "gql/facilities/query";
import {
  InsertJobOpeningOne,
  UpdateJobOpening,
} from "gql/jobOpenings/mutation";
import { InsertJobRequests } from "gql/jobRequests/mutation";

import { FetchCustomersViewForFilter } from "gql/customers/query";

import { useMutation, useApolloClient } from "@apollo/client";

//helpers
import { enumerateDaysBetweenDates } from "../../helpers/index";
import { showAlert } from "helpers/index";

//redux
import { useDispatch, useSelector } from "react-redux";
import { profileState } from "toolkit/profile/profile.slice";
//function
import { authState } from "toolkit/auth/auth.slice";
import {
  createMapleShiftApi,
  updateMapleShiftApi,
  getMapleShiftAssignementApi,
  createMapleShiftAssignmentApi,
} from "toolkit/admin/admin.api";

const initialState: any = {
  facility_details: "",
  special_instructions: "",
  customer_id: "",
  facility_id: "",
  position_id: "",
  location_id: "",
  available_from: new Date(),
};
const CreateMapleShiftModal: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  refetch: () => void;
  selectedShift: any;
}> = ({ isEdit, setIsEdit, toggle, modal, refetch, selectedShift }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authState);
  const client = useApolloClient();
  const [CreateJobOpeningMutation, { loading }] =
    useMutation(InsertJobOpeningOne);
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);
  const [CreateJobRequestMutation] = useMutation(InsertJobRequests);

  const [state, setState] = useState<any>(initialState);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedFacility, setSelectedFacility] = useState<any>();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer_id: (state && state.customer_id) || "",
      facility_id: (state && state.facility_id) || "",
      position_id: (state && state.position_id) || "",
      location_id: (state && state.location_id) || "",
      available_from: (state && state.available_from) || new Date(),
      special_instructions: (state && state.special_instructions) || "",
    },
    validationSchema: Yup.object({
      location_id: Yup.string().required("Please Select Location"),
      position_id: Yup.string().required("Please Select Position"),
      available_from: Yup.date(),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        try {
          const formData = {
            mapleShiftId: selectedShift?.maple_shift_id,
            positionId: validation.values.position_id,
            jobDate: moment(values.available_from).format("YYYY-MM-DD"),
          };
          const mapleResponse = await dispatch(
            updateMapleShiftApi({ formData, token })
          );
          if (mapleResponse?.payload?.data) {
            await UpdateJobOpeningMutation({
              variables: {
                id: selectedShift?.id,
                object: {
                  position_id: validation.values.position_id,
                  location_id: validation.values.location_id,
                  job_date: moment(values.available_from).format("YYYY-MM-DD"),
                },
              },
            });
            Swal.fire("Shift Updated Successfully", "", "success");
          } else {
            showAlert({
              title: "Error!",
              message:
                `${
                  JSON.parse(mapleResponse?.payload?.error?.message)?.errors[0]
                    ?.title
                }` ?? "Something went wrong",
              type: "danger",
            });
          }
          setIsEdit(false);
          handleClose();
          handleRefetch();
        } catch (error) {
          Swal.fire("Something went wrong", "", "warning");
          console.log(error);
        }
      } else {
        try {
          const objects: any[] = [];
          const formData = {
            facilityId: validation.values.facility_id,
            positionId: validation.values.position_id,
            jobDate: moment(values.available_from).format("YYYY-MM-DD"),
            workerRequirement: selectedCustomer?.id
              ? selectedCustomer?.nurse_type == "LPN"
                ? "LVN"
                : selectedCustomer?.nurse_type
              : null,
            nurseId: selectedCustomer?.id ? selectedCustomer?.id : null,
          };
          const mapleResponse = await dispatch(
            createMapleShiftApi({ formData, token })
          );
          if (mapleResponse?.payload?.data) {
            const dates = enumerateDaysBetweenDates(
              values.available_from,
              values.available_from
            );
            dates.map((date: Date) => {
              objects.push({
                name: "N/A",
                location_id: validation.values.location_id,
                currency: "USD",
                fee_period: "per_hour",
                job_date: moment(date).format("YYYY-MM-DD"),
                requirement: {
                  facility_details: [""],
                  special_instructions: [values.special_instructions],
                },
                position_id: validation.values.position_id,
                organisation_id: selectedFacility?.organisation_id,
                createdby: validation.values.facility_id,
                updatedby: validation.values.facility_id,
                maple_shift_id: mapleResponse?.payload?.data[0]?.id,
                filled: selectedCustomer?.id ? true : false,
              });
            });
            const jobOpeningData = await CreateJobOpeningMutation({
              variables: {
                objects,
              },
            });
            if (selectedCustomer?.id) {
              await CreateJobRequestMutation({
                variables: {
                  objects: {
                    job_opening_id:
                      jobOpeningData.data?.insert_job_openings?.returning[0]
                        ?.id,
                    practitioner_id: selectedCustomer?.id,
                    createdby: validation.values.facility_id,
                    updatedby: validation.values.facility_id,
                    is_confirmed: true,
                    status: "approved",
                    job_date:
                      jobOpeningData.data?.insert_job_openings?.returning[0]
                        ?.job_date,
                    maple_shift_assignment_id:
                      mapleResponse?.payload?.data[0]?.relationships?.assignment
                        ?.id,
                  },
                },
              });
            }
            Swal.fire("Shift Created Successfully", "", "success");
          } else {
            showAlert({
              title: "Error!",
              message:
                `${
                  JSON.parse(mapleResponse?.payload?.error?.message)?.errors[0]
                    ?.title
                }` ?? "Something went wrong",
              type: "danger",
            });
          }
          handleClose();
          handleRefetch();
          refetch();
        } catch (err) {
          Swal.fire("Something went wrong", "", "warning");
          console.log(err);
        }
      }
    },
  });

  useEffect(() => {
    if (isEdit && selectedShift?.id) {
      setState({
        ...state,
        available_from: selectedShift?.job_date,
        facility_id: selectedShift?.createdBy,
        location_id: selectedShift?.location_id,
        position_id: selectedShift?.position_id,
      });
    }
  }, [selectedShift, isEdit]);

  const handleClose = () => {
    toggle();
    validation.resetForm();
    setState(initialState);
  };
  const handleRefetch = () => {
    client.refetchQueries({
      include: ["FetchJobOpenings"],
    });
  };

  return (
    <Modal
      className="customModal"
      isOpen={modal}
      toggle={handleClose}
      backdrop="static"
    >
      <ModalHeader toggle={handleClose} tag="h4">
        {!!isEdit ? "Edit Maple Shift" : "Add Maple Shift"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-4">
                <Label className="form-label">Select Facility</Label>
                <div>
                  <SelectPickerPaginate
                    query={FetchFacilitiesViewForFilter}
                    placeholder="Select Facility"
                    value={JSON.stringify(selectedFacility)}
                    onChange={(newValue) => {
                      const facilityJson = JSON.parse(newValue);
                      validation.setFieldValue("facility_id", facilityJson.id);
                      setSelectedFacility(facilityJson);
                    }}
                    // value={validation.values.facility_id}
                    // onChange={(newValue) =>
                    //   validation.setFieldValue("facility_id", newValue)
                    // }
                    arrKey="users"
                    objectLabel="organisation_name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      isactive: { _eq: true },
                      role: { _eq: "provider" },
                      is_maple_user: { _eq: true },
                    }}
                    getFullObj={true}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.facility_id &&
                      validation.errors.facility_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.facility_id &&
                  validation.errors.facility_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.facility_id}
                    </div>
                  ) : null}
                </div>
              </div>
              {validation.values.facility_id && (
                <div className="mb-4">
                  <Label className="form-label">Location</Label>
                  <div>
                    <SelectPickerPaginate
                      query={FetchLocationsForFilter}
                      placeholder="Select Location"
                      value={validation.values.location_id}
                      onChange={(newValue) =>
                        validation.setFieldValue("location_id", newValue)
                      }
                      arrKey="locations"
                      objectLabel="name"
                      objectValue="id"
                      style={{ width: "100%" }}
                      paginate={true}
                      filterCondition={{
                        isactive: { _eq: true },
                        createdby: { _eq: validation.values.facility_id },
                      }}
                      getFullObj={false}
                      searchValue=""
                      menuStyle={{ zIndex: 9999 }}
                      cleanable={false}
                      className={
                        validation.touched.location_id &&
                        validation.errors.location_id
                          ? "border-danger"
                          : ""
                      }
                    />
                    {validation.touched.location_id &&
                    validation.errors.location_id ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {validation.errors.location_id}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {validation.values.facility_id && (
                <div className="mb-4">
                  <Label className="form-label">Position</Label>
                  <div>
                    <SelectPickerPaginate
                      query={FetchPositionsForFilter}
                      placeholder="Select Position"
                      value={validation.values.position_id}
                      onChange={(newValue) =>
                        validation.setFieldValue("position_id", newValue)
                      }
                      arrKey="positions"
                      objectLabel="name+type"
                      formatLabel={(item) => `${item.name} - (${item.type})`}
                      objectValue="id"
                      style={{ width: "100%" }}
                      paginate={true}
                      filterCondition={{
                        createdby: { _eq: validation.values.facility_id },
                        active: { _eq: true },
                      }}
                      getFullObj={false}
                      searchValue=""
                      menuStyle={{ zIndex: 9999 }}
                      cleanable={false}
                      className={
                        validation.touched.position_id &&
                        validation.errors.position_id
                          ? "border-danger"
                          : ""
                      }
                      searchable={false}
                    />
                    {validation.touched.position_id &&
                    validation.errors.position_id ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {validation.errors.position_id}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="mb-4">
                <Row>
                  <Col md={12}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-email-Input">Shift Date</Label>
                      <Input
                        name="available_from"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          moment(validation.values.available_from).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        invalid={
                          validation.touched.available_from &&
                          validation.errors.available_from
                            ? true
                            : false
                        }
                      />
                      {validation.touched.available_from &&
                      validation.errors.available_from ? (
                        <FormFeedback type="invalid">
                          {validation.errors.available_from}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              {validation.values.facility_id && (
                <div className="mb-3">
                  <Label htmlFor="formrow-assignto-Input">Select Nurse</Label>
                  <SelectPickerPaginate
                    query={FetchCustomersViewForFilter}
                    placeholder="Select Nurse"
                    value={JSON.stringify(selectedCustomer)}
                    // onChange={(newValue) => {
                    //   validation.setFieldValue("customer_id", newValue);
                    // }}
                    onChange={(newValue) => {
                      const customerJson = JSON.parse(newValue);
                      validation.setFieldValue("customer_id", customerJson?.id);
                      setSelectedCustomer(customerJson);
                    }}
                    arrKey="customers_view"
                    objectLabel="firstname"
                    formatLabel={(item) =>
                      `${item.firstname} ${item.lastname} (${item.nurse_type})`
                    }
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      verified: { _eq: true },
                      is_deleted: { _eq: false },
                      // nurse_type:
                      //   selectedShift?.maple_worker_requirement == "CAREGIVER"
                      //     ? { _eq: "CNA", _is_null: false }
                      //     : selectedShift?.maple_worker_requirement == "NURSE"
                      //     ? { _in: ["LPN", "RN"], _is_null: false }
                      //     : selectedShift?.maple_worker_requirement == "LVN"
                      //     ? { _eq: "LPN", _is_null: false }
                      //     : {
                      //         _eq: selectedShift?.maple_worker_requirement,
                      //         _is_null: false,
                      //       },
                    }}
                    getFullObj={true}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={true}
                    className={
                      validation.touched.customer_id &&
                      validation.errors.customer_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.customer_id &&
                  validation.errors.customer_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.customer_id}
                    </div>
                  ) : null}
                </div>
              )}
              {/* <div className="mb-4">
                <Label className="form-label">Special Instructions</Label>
                <Input
                  name="special_instructions"
                  label="Special Instructions"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.special_instructions || ""}
                  invalid={
                    validation.touched.special_instructions &&
                    validation.errors.special_instructions
                      ? true
                      : false
                  }
                />
                {validation.touched.special_instructions &&
                validation.errors.special_instructions ? (
                  <FormFeedback type="invalid">
                    {validation.errors.special_instructions}
                  </FormFeedback>
                ) : null}
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                  disabled={loading}
                >
                  <FiCheck className="mr-4" />
                  &nbsp; Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default CreateMapleShiftModal;

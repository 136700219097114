export const IDEnums = [
  "PERMANENT_RESIDENT_CARD",
  "PHOTO_ID",
  "DRIVERS_LICENSE",
  "FOREIGN_PASSPORT",
  "PASSPORT",
];
export const TBScreeningEnums = [
  "CHEST_X_RAY",
  "TB_BLOOD_TEST",
  "2_STEP_INTRADERMAL",
  "1_STEP_INTRADERMAL",
  "TB_SCREENING",
];
export const EmploymentTypeEnums = ["W9_FORM", "FORM_1099", "W4_FORM"];
export const DementiaTrainingEnums = ["DEMENTIA_TRAINING"];
export const WorkAuthorizationEnums = ["WORK_AUTHORIZATION"];
export const SSNCardEnums = ["US_SSN_CARD"];
export const IDDropdownData = [
  {
    label: "Permanent Resident Card (Form I-551)",
    value: "PERMANENT_RESIDENT_CARD",
  },
  {
    label: "Photo ID",
    value: "PHOTO_ID",
  },
  {
    label: "Drivers License",
    value: "DRIVERS_LICENSE",
  },
  {
    label: "Foreign Passport",
    value: "FOREIGN_PASSPORT",
  },
  {
    label: "Passport",
    value: "PASSPORT",
  },
];
export const TBScreeningDropdownData = [
  {
    label: "Chest X-Ray",
    value: "CHEST_X_RAY",
  },
  {
    label: "TB Blood Test",
    value: "TB_BLOOD_TEST",
  },
  {
    label: "2 Step Intradermal",
    value: "2_STEP_INTRADERMAL",
  },
  {
    label: "1 Step Intradermal",
    value: "1_STEP_INTRADERMAL",
  },
  {
    label: "TB Screening",
    value: "TB_SCREENING",
  },
];

export const empTypeDropdownData = [
  {
    label: "W4 Form",
    value: "W4_FORM",
  },
  {
    label: "W9 Form",
    value: "W9_FORM",
  },
  {
    label: "Form 1099",
    value: "FORM_1099",
  },
];

export const I9Enums = [
  "US_PASSPORT",
  "US_PASSPORT_CARD",
  "ALIEN_REG_RECEIPT_CARD",
  "PERMANENT_RESIDENT_CARD",
  "EMP_AUTH_DOCUMENT",
  "FOREIGN_PASSPORT_I94",
  "FORM_I94_ARRIVAL_DEPARTURE",
  "PASSPORT_FSM",
  "PASSPORT_RMI",
  "FOREIGN_PASSPORT_I551",
  "DRIVER_LICENSE",
  "SCHOOL_ID_WITH_PHOTO",
  "VOTER_REGISTRATION_CARD",
  "US_MILITARY_CARD",
  "MILITARY_DEPENDENT_ID",
  "US_COAST_GUARD_MMD",
  "NATIVE_AMERICAN_TRIBAL_DOC",
  "SCHOOL_RECORD",
  "CLINIC_DOC_HOSPITAL_RECORD",
  "DAY_CARE_RECORD",
  "DRIVER_LICENSE_CANADIAN_GOV",
  "CONSULAR_REPORT_BIRTH_ABROAD_FS240",
  // "US_SSN_CARD",
  "CERT_BIRTH_ABROAD_US_DEPT_STATE_FS545",
  "CERT_REPORT_BIRTH_US_DEPT_STATE_DS1350",
  "BIRTH_CERTIFICATE",
  "US_CITIZEN_ID_I197",
  "ID_CARD_USE_OF_RESIDENT_CITIZEN_I179",
];

export const ssnDropdownData = [
  {
    label: "Grant",
    value: "Grant",
  },
  {
    label: "Deny",
    value: "Deny",
  },
];

export const availabilityDropdownData = [
  {
    label: "Interested",
    value: "interested",
  },
  {
    label: "Not-Interested",
    value: "not interested",
  },
];

export const terminationDropdownData = [
  {
    label: "Terminate",
    value: "terminate",
  },
  {
    label: "Not-Terminate",
    value: "not terminate",
  },
];

export const locationPendingDropdownData = [
  {
    label: "Location Pending",
    value: "location pending",
  },
  {
    label: "Location Confirmed",
    value: "location confirmed",
  },
];

export const revokedFilters = [
  {
    id: 1,
    label: "Terminated",
    value: "terminated",
  },
  { id: 2, label: "Not Interested", value: "not-interested" },
  { id: 3, label: "Location Pending", value: "location-pending" },
];

import React from "react";

const Steps: React.FC<{
  step: number;
  content: string;
  flexWay: any;
  colour: string;
  contentAlign: any;
}> = ({ step, content, flexWay, colour, contentAlign}) => {
  return (
    <>
      <div className="custom-onboarding-steps">
        <div className="custom-onboarding-steps_container" style={{flexDirection: flexWay, backgroundColor: colour}}>
          <div className="custom-onboarding-steps_circle">{step}</div>
          <div className="custom-onboarding-steps_content" style={{textAlign: contentAlign}}>{content}</div>
        </div>
      </div>
    </>
  );
};
export default Steps;

import React from "react";
import { Moment } from "moment";
//icons
import {
	BsChevronRight,
	BsChevronDown,
} from "react-icons/bs";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
	schedulerState,
	handleToggleExpandAll,
} from "toolkit/scheduler/scheduler.slice";
const CalendarTop: React.FC<{
	onScroll: (leftOffset: number) => void;
	calendarType: string
}> = ({
	onScroll,
	calendarType
}) => {
		const dispatch = useDispatch();
		const { expandAll, daterange } = useSelector(schedulerState);

		return (
			<div className="main_calendar-top">
				<div className="main_calendar-top-left">
					{/* position */}
					<div
						className="GridLeftHeader"
						style={{
							cursor: "pointer",
							height: 42,
							width: 230,
						}}
						onClick={() => {
							if(calendarType === "positionCalendar")
								dispatch(handleToggleExpandAll())
							else
								return
						}}
					>
						{calendarType === "positionCalendar" && (
							expandAll ? <BsChevronDown /> : <BsChevronRight />
						)}
						<span style={{ marginLeft: 5 }}>{calendarType === "staffCalendar" ? "Staffs" : "Positions"}</span>
					</div>
					{/* position */}
				</div>
				<div
					className="main_calendar-top-right"
					id="main_calendar-top-right"
					onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => onScroll(event.currentTarget.scrollLeft)}
				>
					{/* dates */}
					{daterange.map((date: Moment) => (
						<div
							key={`${date}`}
							// onClick={() => tog_center()}
							className="GridTopHeader"
							style={{ height: 42, width: 150, flexShrink: 0 }}
						>
							<div className="d-flex">
								<div
									style={{
										background:
											date.format("dddd") === "Saturday" ||
												date.format("dddd") === "Sunday"
												? "#000000"
												: "#009EF7",
										color: "#FFFFFF",
										width: "30px",
										paddingTop: "5px",
										textAlign: "center",
										verticalAlign: "middle",
										paddingBottom: "5px",
									}}
								>
									<span style={{ fontSize: "15px", color: "#FFFFFF" }}>
										{" "}
										{date.format("DD")}
									</span>
								</div>
								<div style={{ padding: "5px", fontSize: "10px" }}>
									{date.format("MMM")}
									<br />
									{date.format("dddd")}
								</div>
							</div>
						</div>
					))}
					{/* dates */}
				</div>
				<div
					style={{ width: 7, height: "100%", backgroundColor: "#f8f8fb" }}
				></div>
			</div>
		)
	}
export default CalendarTop
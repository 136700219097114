import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Input,
	Form,
	Col,
	Row,
	FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";

// icons
import { FiCheck } from "react-icons/fi";

//graphql
import { useMutation, useApolloClient } from "@apollo/client";
import { UpdateCustomer } from "gql/profile/mutation";
import { FetchCustomers } from "gql/customers/query";

//type
import InterviewType from "pages/Admin/calendar/interviews.types";

//components
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

const CreateInterviewModal: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	customer: InterviewType | null;
	showExtraActions?: boolean
}> = ({ isOpen, toggle, customer, showExtraActions = false }) => {
	const client = useApolloClient()
	const today = new Date();
	today.setHours(0, 0, 0, 0)
	const [notes, setNotes] = useState<string>("")
	const [interviewDate, setInterviewDate] = useState<Date>(new Date())
	const [interviewTime, setInterviewTime] = useState<string>("")
	const [btnLoading, setBtnLoading] = useState<boolean>(false)
	const [selectedCustomer, setSelectedCustomer] = useState<string>("")

	const [UpdateCustomerMutation] = useMutation(UpdateCustomer)

	const handleClose = () => {
		toggle()
		validation.resetForm()
		setNotes("")
	}
	// validation
	const validation: any = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			notes: notes || "",
			interviewDate,
			interviewTime,
			selectedCustomer
		},
		validationSchema: Yup.object({
			notes: Yup.string().required("Please Enter Notes"),
			interviewDate: Yup.date().min(today, "Past Date Selection Not Allowed"),
			interviewTime: Yup.string().required("Please Enter Interview Time"),
			selectedCustomer: Yup.string().test(
				"selectedCustomer_test",
				"Please Select Candidate",
				function (value) {
					if (customer === null && !value) {
						return false
					} else {
						return true
					}
				}
			)
		}),
		onSubmit: async (values) => {
			try {
				setBtnLoading(true)
				const time = typeof values.interviewTime === "string" ? values.interviewTime : moment(values.interviewTime).format("HH:mm:ss")
				const _date = typeof values.interviewDate === "string" ? values.interviewDate : moment(values.interviewDate).format("YYYY-MM-DD")
				const onboarding_interview_date = moment(`${_date} ${time}`, "YYYY-MM-DD HH:mm:ss").toDate()
				const meeting_link = `${process.env.REACT_APP_MEETING_DOMAIN}caregigs-interview-${customer?.id || values.selectedCustomer}`
				await UpdateCustomerMutation({
					variables: {
						id: customer?.id || values.selectedCustomer,
						object: {
							onboarding_interview_scheduled: true,
							onboarding_interview_date,
							onboarding_interview_status: "scheduled",
							onboarding_interview_comments: values.notes,
							onboarding_interview_link: meeting_link
						}
					}
				})
				showAlert({
					title: "Updated!",
					message: `Interview ${customer?.onboarding_interview_scheduled ? "rescheduled" : "scheduled"} successfully!`,
					type: "success",
				});
				client.refetchQueries({
					include: ["FetchCustomerDetails", "FetchInterviews","FetchCustomerDetailsAdmin"]
				})
				handleClose()
			} catch (err) {
				showAlert({
					title: "Error!",
					message: "Something went wrong!",
					type: "danger",
				});
			} finally {
				setBtnLoading(false)
			}
		},
	});

	useEffect(() => {
		if (customer?.id) {
			const _date = moment(customer?.onboarding_interview_date)
			setNotes(customer?.onboarding_interview_comments)
			setInterviewDate(_date.toDate())
			setInterviewTime(_date.format("HH:mm:ss"))
		}
	}, [customer, isOpen])
	return (
		<Modal
			isOpen={isOpen}
			size="md"
			backdrop="static"
			className="customModal"
			toggle={handleClose}
		>
			<ModalHeader toggle={handleClose} tag="h4">
				{customer?.onboarding_interview_scheduled ? "Reschedule" : "Schedule"} Interview
			</ModalHeader>
			<ModalBody>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
				>
					{customer === null && (
						<Row form>
							<Col xs={12}>
								<div className="mb-3">
									<SelectPickerPaginate
										query={FetchCustomers}
										placeholder="Select Candidate"
										value={validation.values.selectedCustomer}
										onChange={newValue => {
											validation.setFieldValue("selectedCustomer", newValue)
										}}
										arrKey="customers"
										objectLabel="firstname+lastname"
										objectValue="id"
										style={{ width: "100%" }}
										paginate={true}
										filterCondition={{
											verified: { _eq: false },
											is_deleted: { _eq: false }
										}}
										getFullObj={false}
										searchValue={""}
										menuStyle={{ zIndex: 9999 }}
										disabled={false}
										cleanable={false}
										className={(validation.touched.employee && validation.errors.employee) ? "border-danger" : ""}
									/>
									{validation.touched.selectedCustomer &&
									validation.errors.selectedCustomer ? (
									<div
										className="invalid-feedback"
										style={{ display: "block" }}
									>
										{validation.errors.selectedCustomer}
									</div>
								) : null}
								</div>
							</Col>
						</Row>
					)}
					<Row form>
						<Col xs={12}>
							<div className="mb-3">
								<Label className="form-label">Interview Date</Label>
								<Input
									name="interviewDate"
									label="Interview Date"
									type="date"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={moment(validation.values.interviewDate).format("YYYY-MM-DD") || ""}
									invalid={
										validation.touched.interviewDate &&
											validation.errors.interviewDate
											? true
											: false
									}
								/>
								{validation.touched.interviewDate &&
									validation.errors.interviewDate ? (
									<FormFeedback type="invalid">
										{validation.errors.interviewDate}
									</FormFeedback>
								) : null}
							</div>
						</Col>
					</Row>
					<Row form>
						<Col xs={12}>
							<div className="mb-3">
								<Label htmlFor="formrow-email-Input">Interview Time</Label>
								<Flatpickr
									className="form-control d-block bg-white"
									placeholder="Interview Time"
									options={{
										enableTime: true,
										noCalendar: true,
										dateFormat: "H:i",
									}}
									onChange={(value) =>
										validation.setFieldValue("interviewTime", value[0])
									}
									value={validation.values.interviewTime}
								/>
								{validation.touched.interviewTime &&
									validation.errors.interviewTime ? (
									<div
										className="invalid-feedback"
										style={{ display: "block" }}
									>
										{validation.errors.interviewTime}
									</div>
								) : null}
							</div>
						</Col>
					</Row>
					<Row form>
						<Col xs={12}>
							<div className="mb-3">
								<Label className="form-label">Notes</Label>
								<Input
									name="notes"
									label="Notes"
									type="textarea"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.notes || ""}
									invalid={
										validation.touched.notes &&
											validation.errors.notes
											? true
											: false
									}
								/>
								{validation.touched.notes &&
									validation.errors.notes ? (
									<FormFeedback type="invalid">
										{validation.errors.notes}
									</FormFeedback>
								) : null}
							</div>
							<div
								className={showExtraActions ? "d-flex btn-group" : "text-end"}
								role="group"
							>
								{showExtraActions && (
									<>
										<a
											className="btn btn-success"
											type="button"
											style={{ flex: 1 }}
											href={customer?.onboarding_interview_link}
											target="_blank"
											rel="noreferrer"
										>
											Join Meeting
										</a>
										<Link
											className="btn btn-secondary"
											type="button"
											style={{ flex: 1 }}
											to={`/marketplace-profile/${customer?.id}`}
										>
											View Profile
										</Link>
									</>
								)}
								<button
									className="btn btn-primary"
									type="submit"
									disabled={btnLoading}
									style={showExtraActions ? { flex: 1 } : {}}
								>
									<FiCheck /> &nbsp;
									Submit
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</Modal>
	)
}
export default CreateInterviewModal
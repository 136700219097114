import { gql } from "@apollo/client"
export const FetchEmployees =
  gql`query FetchEmployees (\$limit:Int,\$offset:Int,\$where:employees_bool_exp){
    employees (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      firstname
      lastname
      avatar
      prefix
      profession
      email
      contactno1
      contactno2
      isactive
      address
      created_at
      text_color
      background_color
      organisation {
        id
        name
      }
      location {
        id
        name
      }
      type
      role
      is_supervisor
    }
    employees_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
  export const FetchEmployeesForFilter =
  gql`query EmployeesFilter(\$limit:Int,\$offset:Int,\$where:employees_bool_exp) {
  employees(limit: \$limit, offset: \$offset, where: \$where) {
    firstname
    lastname
    avatar
    id
    text_color
    background_color
    profession
    email
    contactno1
    contactno2
    address
    type
  }
}`
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Form, FormGroup, Input } from "reactstrap";
import { Pagination } from "rsuite";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDebounce } from "react-use";
import Swal from "sweetalert2";

import { showAlert } from "helpers/index";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { FetchFacilities } from "gql/facilities/query";
import { UpdateSSNPermission } from "gql/facilities/mutation";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//Functions
import {
  FacilitiesColumns,
  FormatData,
  BuildFacilitiesSearchQuery,
  BuildFacilitiesFilterQuery,
} from "./facilities.functions";

//filters
import FacilityFilter from "components/Filters/facilities/FacilityFilter";

const FacilityTable: React.FC<{
  queryCondition: any;
}> = (props) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const initialCondition = {
    role: { _eq: "provider" },
    organisation: { isactive: { _eq: true } }
  };
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>({});

  const [getFacilities, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchFacilities, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    });
  const [UpdateSSNMutation] = useMutation(UpdateSSNPermission);

  const defaultSorted: any = [];

  const handleSearch = (value: string) => {
    const conditionTemp = BuildFacilitiesSearchQuery(value, null);
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };
  useDebounce(
    () => {
      if (searchKey.length > 0) {
        handleSearch(searchKey);
      } else {
        let _cond = {};
        if (props.queryCondition !== null) {
          _cond = props.queryCondition;
        } else {
          _cond = initialCondition;
        }
        setQueryCondition(_cond);
      }
    },
    1500,
    [searchKey]
  );
  //Apply filters
  useEffect(() => {
    const conditionTemp = BuildFacilitiesFilterQuery(filterValue, null);
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  }, [filterValue]);

  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition);
    } else {
      setQueryCondition(initialCondition);
    }
  }, [props.queryCondition]);

  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getFacilities();
    }
  }, [queryCondition]);

  const handleViewRequest = async (id: string, action: string) => {
    try {
      Swal.fire({
        title: ``,
        text: `Do you want to ${action} access to view the Document?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
			// console.log(id, action)
          await UpdateSSNMutation({
            variables: {
              id: id,
              object: {
                view_ssn: action,
              },
            },
          });
		  refetch();
          showAlert({
            title: "Requested!",
            message: "Request sent successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

   const handleVerifyRequest = async (id: string, action: string) => {
     try {
       Swal.fire({
         title: ``,
         text: `Do you want to ${action} Verification to the Facility?`,
         showCancelButton: true,
         confirmButtonText: "Yes",
         icon: "warning",
       }).then(async (result) => {
         if (result.isConfirmed) {
           // console.log(id, action)
           await UpdateSSNMutation({
             variables: {
               id: id,
               object: {
                 verification: action,
               },
             },
           });
           refetch();
           showAlert({
             title: "Requested!",
             message: "Request sent successfully",
             type: "success",
           });
         }
       });
     } catch (err) {
       showAlert({
         title: "Error!",
         message: "Something Went Wrong",
         type: "danger",
       });
     }
   };

  const keyField = "id";
  return (
    <Row>
      <Col lg="12">
        <div className="Timecardpage-card">
          <div>
            <ToolkitProvider
              keyField={keyField}
              data={FormatData(Array.isArray(data?.users) ? data.users : [])}
              columns={FacilitiesColumns(
                searchKey,
                filterValue,
                handleViewRequest,
                handleVerifyRequest
              )}
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box d-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                name="email"
                                placeholder="Search facilities"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end d-flex justify-content-end">
                        <FacilityFilter
                          onFilterChange={setFilterValue}
                          activeFilters={["status", "created_at"]}
                          filterQueryConditions={null}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm="8">
                      Total Facilities:{" "}
                      {data?.users_aggregate?.aggregate?.count || 0}
                    </Col>
                  </Row>
                  {loading && (
                    <Col xl="12" className="text-center">
                      <Spinner color="primary" />
                    </Col>
                  )}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={{
                            mode: "checkbox",
                            onSelectAll: (isSelected) => {
                              console.log(isSelected);
                            },
                          }}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          noDataIndication={() => "No facilities found!"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline mt-3 w-100">
                        <Pagination
                          total={data?.users_aggregate?.aggregate?.count || 0}
                          limit={PageData.limit}
                          activePage={PageData.pageNo}
                          onChangePage={(pageNo) =>
                            setPageData({ ...PageData, pageNo })
                          }
                          limitOptions={[30, 50, 100, 200, 300]}
                          layout={["limit", "pager"]}
                          onChangeLimit={(limit) =>
                            setPageData({ ...PageData, limit })
                          }
                          className="d-flex justify-content-between"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default FacilityTable;

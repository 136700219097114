import React from "react";
import {
	Col,
	Row,
	Spinner,
	Form,
	FormGroup,
	Input,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


//Functions
import { ReferencesColumns, FormatData } from "./notes.functions"


//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const ReferencesTable: React.FC<{
	user: any
}> = props => {
	const { auth: { user } } = useSelector(authState);
	const defaultSorted: any = [];
	const keyField = "id";
	return (
		<Row>
			<Col lg="12">
				<ToolkitProvider
					keyField={keyField}
					data={FormatData(Array.isArray(props.user?.reference) ? props.user?.reference : [])}
					columns={ReferencesColumns()}
					bootstrap4
					search
				>
					{toolkitProps => (
						<React.Fragment>
							<Row className="mb-3">
								<Col sm="8">
									Total References:{" "}
									{props.user?.reference?.length || 0}
								</Col>
							</Row>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										<BootstrapTable
											bordered={false}
											striped={false}
											defaultSorted={defaultSorted}
											classes={
												"table align-middle table-nowrap table-hover"
											}
											headerWrapperClasses={"thead-light"}
											{...toolkitProps.baseProps}
											noDataIndication={() => "No references found!"}
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
				{/* </CardBody>
				</Card> */}
			</Col>
		</Row>
	);
};

export default ReferencesTable;
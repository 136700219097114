import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AutoSizer from "react-virtualized-auto-sizer";
import _ from "lodash";

import { VariableSizeGrid as Grid } from "react-window";
//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { FaRegClock, FaCheckCircle } from "react-icons/fa";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { GoAlert } from "react-icons/go";
import {
  BsFillCircleFill,
} from "react-icons/bs";
import "../../../pages/Schedule/grid.scss";

//parts
import NextPrevButtons from "./parts/NextPrevButtons";
import CalendarTop from "./parts/CalendarTop";
import PositionCalendarLeft from "./parts/PositionCalendarLeft";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  schedulerState,
  handleSetShiftModal,
  handleSetInvitationModal,
  handleToggleLocation,
} from "toolkit/scheduler/scheduler.slice";
const moment = extendMoment(Moment);

const Cell = (props) => {
  const dispatch = useDispatch();
  const formatTime = (time) => {
    return moment(time, "HH:mm").format("hh:mm A");
  };
  const getCellValue = (
    jobOpenings,
    jobRequests,
    invitations,
    _position,
    rangedate
  ) => {
    let cellValue = null;
    let _jobRequest = jobRequests.filter((job) => {
      if (job.job_opening !== null) {
        return (
          job.job_opening?.job_date === rangedate.format("YYYY-MM-DD") &&
          _position?.id === job.job_opening?.position?.id
        );
      } else if (job.invitation !== null) {
        return (
          job.invitation?.job_date === rangedate.format("YYYY-MM-DD") &&
          _position?.id === job.invitation?.position?.id
        );
      } else {
        return false;
      }
    });
    let _jobOpening = jobOpenings.filter(
      (job) =>
        job.job_date === rangedate.format("YYYY-MM-DD") &&
        _position?.id === job.position.id
    );
    let _invitation = invitations.filter(
      (job) =>
        job.job_date === rangedate.format("YYYY-MM-DD") &&
        _position?.id === job.position.id
    );

    if (_jobRequest.length) {
      _jobRequest = _jobRequest.slice(-1).pop();
      let label = "";
      if (_jobRequest.employee?.id) {
        label = `${_jobRequest.employee?.firstname} ${_jobRequest.employee?.lastname}`;
      } else {
        label = `${_jobRequest.customer?.firstname} ${_jobRequest.customer?.lastname}`;
      }
      cellValue = {
        label,
        count: 0,
        start_time: formatTime(
          _jobRequest?.job_opening !== null
            ? _jobRequest?.job_opening?.position?.start_time
            : _jobRequest?.invitation?.position?.start_time
        ),
        end_time: formatTime(
          _jobRequest?.job_opening !== null
            ? _jobRequest?.job_opening?.position?.end_time
            : _jobRequest?.invitation?.position?.end_time
        ),
        background: _jobRequest.employee?.background_color,
        color: _jobRequest.employee?.text_color,
        isNurseConnect: _jobRequest.employee?.id ? false : true,
        type: "jobRequest",
        data: _jobRequest,
      };
    } else if (_jobOpening.length) {
      _jobOpening = _jobOpening.slice(-1).pop();
      cellValue = {
        label: _jobOpening.is_open_shift ? "Open Shift" : "Caregigs Shift",
        count: _jobOpening.job_requests_aggregate?.aggregate?.count || 0,
        start_time: formatTime(_jobOpening.position?.start_time),
        end_time: formatTime(_jobOpening.position?.end_time),
        // background: _jobOpening.position.background_color,
        // color: _jobOpening.position.text_color,
        background: "#fff",
        color: "#0009",
        isNurseConnect: _jobOpening.is_open_shift ? false : true,
        type: "jobOpening",
        data: _jobOpening,
      };
    } else if (_invitation.length) {
      let count = _invitation.length;
      _invitation = _invitation.slice(-1).pop();
      cellValue = {
        label: "Caregigs Invitation",
        count,
        start_time: formatTime(_invitation.position?.start_time),
        end_time: formatTime(_invitation.position?.end_time),
        // background: _invitation.position.background_color,
        // color: _invitation.position.text_color,
        background: "#fff",
        color: "#0009",
        isNurseConnect: true,
        type: "invitation",
        data: _invitation,
      };
    }
    return cellValue;
  };

  const getStatusIcon = (cellValue) => {
    if (
      cellValue.data?.status === "active" &&
      cellValue.type === "jobRequest"
    ) {
      return <FaRegClock size={16} color="#78A451" className="status-icon" />;
    } else if (
      cellValue.data?.status === "completed" &&
      cellValue.type === "jobRequest"
    ) {
      return (
        <FaCheckCircle size={16} color="#78A451" className="status-icon" />
      );
    } else if (
      cellValue.data?.status === "approved" &&
      cellValue.data?.is_confirmed &&
      cellValue.type === "jobRequest"
    ) {
      return (
        <MdAssignmentTurnedIn
          size={16}
          color="#78A451"
          className="status-icon"
        />
      );
    } else if (cellValue.type === "jobOpening" && cellValue.count === 0) {
      return (
        <GoAlert
          size={16}
          color="rgba(245, 202, 98, 1)"
          className="status-icon"
        />
      );
    } else if (cellValue.type === "jobOpening" && cellValue.count > 0) {
      return (
        <BsFillCircleFill
          size={16}
          color="rgba(123, 164, 225, 1)"
          className="status-icon"
        />
      );
    }
  };
  function getCell(props) {
    const {
      locations,
      jobOpenings,
      jobRequests,
      invitations,
      daterange,
    } = useSelector(schedulerState);
    const rangedate = daterange[props.columnIndex];
    let _location = locations[props.rowIndex];
    if (_location?.fieldType === "position") {
      let background = "#fff";
      let color = "#0009";
      let cellValue = getCellValue(
        jobOpenings,
        jobRequests,
        invitations,
        _location.data,
        rangedate
      );
      if (cellValue !== null) {
        background = cellValue.background;
        color = cellValue.color;
      }
      let divstyle = JSON.parse(JSON.stringify(props.style));
      divstyle.background = background;
      divstyle.color = color;
      divstyle.cursor = "pointer";
      return (
        <>
          {cellValue === null ? (
            <div
              onClick={() => {
                dispatch(
                  handleSetShiftModal({
                    modal_open: true,
                    selectedPosition: _location.data,
                    selectedDate: rangedate.toDate(),
                    selectedCellData: null,
                    shiftModalDisplay: "position",
                  })
                );
              }}
              className="GridItemValue"
              style={divstyle}
            >
              <span className="p-1 ps-3 pe-3" style={{ fontSize: "10px" }}>
                {/* Open Shift */}
              </span>
            </div>
          ) : (
            <div
              id={cellValue.data?.id}
              onClick={() => {
                if (cellValue.type === "invitation") {
                  dispatch(
                    handleSetInvitationModal({
                      invitation_modal_open: true,
                      selectedPosition: _location.data,
                      selectedDate: rangedate.toDate(),
                      selectedCellData: cellValue,
                    })
                  );
                } else {
                  dispatch(
                    handleSetShiftModal({
                      modal_open: true,
                      selectedPosition: _location.data,
                      selectedDate: rangedate.toDate(),
                      selectedCellData: cellValue,
                      shiftModalDisplay: "position",
                    })
                  );
                }
              }}
              className="GridItemValue"
              style={divstyle}
            >
              {!cellValue.data?.is_open_shift && getStatusIcon(cellValue)}
              {cellValue.isNurseConnect && (
                <img
                  width="20px"
                  src={"/cc_icon.png"}
                  alt=""
                  className="img-fluid mx-1"
                />
              )}
              <span className="p-1" style={{ fontSize: "10px" }}>
                {cellValue.label}{" "}
                {cellValue.count > 0 && `(${cellValue.count})`}
                <br />
                {cellValue.start_time} - {cellValue.end_time}
              </span>
            </div>
          )}
        </>
      );
    } else {
      let divstyle = JSON.parse(JSON.stringify(props.style));
      if (_location?.fieldType === "location") {
        divstyle.borderRight = "none";
        divstyle.background = _location.isOpen ? "#f8f8fb" : "#fff";
      }
      return (
        <div
          className="GridItemValue"
          style={divstyle}
          onClick={() => dispatch(handleToggleLocation(props.rowIndex))}
        >
          <span className="p-1 ps-3 pe-3" style={{ fontSize: "10px" }}>
            {/* Open Shift */}
          </span>
        </div>
      );
    }
  }
  return <React.Fragment>{getCell(props)}</React.Fragment>;
};

const PositionViewCalendar = () => {
  const ref = useRef(null);
  const { daterange, locations } = useSelector(schedulerState);
  const [rowCount, setRowCount] = useState(0);
  const [rowHeights, setRowHeights] = useState([42]);
  const [leftOffset, setLeftOffset] = useState(0);
  const [isLeftReached, setLeftReached] = useState(false);
  const handleNextPrev = (action) => {
    const { width, columnCount } = ref.current.props;
    let _totalOffset = (columnCount - 1) * 150 + 230 - width;
    let newOffset = leftOffset + ref.current.props.width;
    if (action === "next" && !isLeftReached) {
      ref.current?.scrollTo({
        scrollLeft: leftOffset + ref.current.props.width,
      });
      if (_totalOffset < newOffset) {
        setLeftReached(true);
      }
    } else if (action === "prev" && leftOffset > 0) {
      setLeftReached(false);
      ref.current?.scrollTo({
        scrollLeft: leftOffset - ref.current.props.width,
      });
    }
  };
  const handleOnScroll = ({scrollLeft, scrollTop}) => {
    setLeftOffset(scrollLeft);
    document.getElementById(
      "main_calendar-top-right"
    ).scrollLeft = scrollLeft;
    document.getElementById(
      "main_calendar-bottom-left"
    ).scrollTop = scrollTop;
  }
  useEffect(() => {
    let openIndexes = _.keys(
      _.pickBy(locations, { fieldType: "location", isOpen: true })
    );
    openIndexes = openIndexes.map((index) => parseInt(index));
    const _rowHeights = [];
    locations.map((loc, i) => {
      if (openIndexes.includes(i)) _rowHeights.push(38);
      else _rowHeights.push(55);
    });
    setRowHeights([..._rowHeights]);
    ref.current?.resetAfterRowIndex(0);
    setRowCount(locations.length);
  }, [locations]);
  return (
    <React.Fragment>
      <NextPrevButtons
        handleNextPrev={(action) => handleNextPrev(action)}
      />
      <div className="main_calendar">
        <CalendarTop
          onScroll={(leftOffset) => ref.current?.scrollTo({ scrollLeft: leftOffset })}
          calendarType="positionCalendar"
        />
        <div className="main_calendar-bottom">
          <PositionCalendarLeft
            onScroll={(topOffset) => ref.current?.scrollTo({ scrollTop: topOffset })}
          />
          <div className="main_calendar-bottom-right">
            <AutoSizer>
              {({ height, width }) => (
                <Grid
                  className="Grid"
                  columnCount={daterange.length}
                  columnWidth={(index) => 150}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={(index) => rowHeights[index]}
                  width={width}
                  key={String(daterange[0])}
                  ref={ref}
                  onScroll={handleOnScroll}
                >
                  {/* {(_props) => Cell({..._props, positions: props.positions})} */}
                  {Cell}
                </Grid>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
PositionViewCalendar.propTypes = {
  positions: PropTypes.any,
};
Cell.propTypes = {
  columnIndex: PropTypes.any,
  rowIndex: PropTypes.any,
  style: PropTypes.any,
};
export default PositionViewCalendar;
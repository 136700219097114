const nurseStatusList = [
  {
    id: 1,
    label: "Verified",
    value: "verified",
  },
  {
    id: 2,
    label: "Conditionally Verified",
    value: "conditionally_verified",
  },
  {
    id: 3,
    label: "Documents Submitted",
    value: "documents_submitted",
  },
  {
    id: 4,
    label: "Selected",
    value: "selected",
  },
  {
    id: 5,
    label: "To be Interviewed",
    value: "to_be_interviewed",
  },
  {
    id: 6,
    label: "Signed Up",
    value: "signed_up",
  },
  {
    id: 7,
    label: "Rejected",
    value: "rejected",
  },
];
export default nurseStatusList;

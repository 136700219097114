import React, { CSSProperties } from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { FiX, FiEdit } from "react-icons/fi";
import { Button } from "reactstrap";

//types
import NoteType from "./rates.types";

const wrapStyle: CSSProperties = {
  display: "block",
  width: 230,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export const FormatData = (notes: NoteType[]) => {
  const _notes = notes.map((elem: NoteType) => {
    return {
      id: elem.id,
      shift_rate: `$ ${elem.nurse_rate}`,
      overtime_rate: `$ ${elem.nurse_overtime_rate}`,
      created_at: elem.created_at,
      updated_at: elem.updated_at,
    };
  });
  return _notes;
};

export const NotesColumns = () => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "shift_rate",
    text: "Shift Rate",
    // eslint-disable-next-line react/display-name
  },
  {
    dataField: "overtime_rate",
    text: "Overtime Rate",
  },
  {
    text: "Created at",
    dataField: "created_at",
    sort: true,
    formatter: (cellContent: string, row: any) =>
      moment(cellContent).format("MM/DD/YYYY"),
  },
  {
    text: "Updated at",
    dataField: "created_at",
    sort: true,
    formatter: (cellContent: string, row: any) =>
      moment(cellContent).format("MM/DD/YYYY"),
  },
];

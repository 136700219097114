import React from "react";
import { Nav } from "rsuite";
import { useParams } from "react-router-dom";

// component
import EmployeeTable from "../../../Settings/employees/Employees";
import GigshiftTable from "../../../JobOpenings/OpeningsTable";
import PositionTable from "../../../Settings/positions/Positions";
import ShiftTable from "pages/Admin/Shifts/ShiftTable";
import RatesTable from "pages/Admin/FacilitiesProfile/rates/RatesTable";
import NotificationSubscriber from "../notification/NotificationSubscriber";
import TextNotification from "../notification/TextNotification";

const FacilityProfileTabs: React.FC<{
  // activeTab: string;
  // setActiveTab: () => void
  user: any;
}> = (
  // activeTab, setActiveTab,
  { user }
) => {
  const params: any = useParams();
  const [selectedTab, setSelectedTab] = React.useState<string>("employees");

  const tabs = [
    { title: "Employees", value: "employees" },
    { title: "Gig Shifts", value: "education" },
    { title: "Positions", value: "licenses" },
    { title: "Rates History", value: "rates" },
    { title: "Email Notification", value: "notification_subscriber" },
    { title: "Text Notification", value: "text_notification" },
  ];

  const _selectTab = (val: string) => {
    setSelectedTab(val);
  };

  return (
    <>
      <div className="MarketPlaceProfileTabs">
        {/* <Nav activeKey={activeTab} reversed onSelect={setActiveTab} appearance="subtle">
          {tabs.map((tab, i) => (
            <Nav.Item eventKey={tab.value} key={i} className="front-size-16" >
              {tab.title}
            </Nav.Item>
          ))}
        </Nav> */}
        {tabs.map((tab, i) => (
          <div
            onClick={() => {
              _selectTab(tab.value);
            }}
            key={i}
            className={
              selectedTab == tab.value
                ? "active front-size-16 MarketPlaceProfileTabs_tab"
                : "front-size-16 MarketPlaceProfileTabs_tab"
            }
          >
            {tab.title}
          </div>
        ))}
      </div>

      {selectedTab == "employees" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">Employees</h4>
          <EmployeeTable />
        </div>
      )}

      {selectedTab == "education" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">Gig Shifts</h4>
          <ShiftTable
            queryCondition={null}
            facility={params?.id}
            mapleUser={user?.is_maple_user}
          />
        </div>
      )}

      {selectedTab == "licenses" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">Positions</h4>
          <PositionTable />
        </div>
      )}
      {selectedTab == "rates" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          <h4 className="information_mainheader">Rates History</h4>
          <RatesTable queryCondition={null} user={params} />
        </div>
      )}
      {selectedTab == "notification_subscriber" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          <h4 className="information_mainheader">Email Notification</h4>
          <NotificationSubscriber user={user} />
        </div>
      )}
      {selectedTab == "text_notification" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          <h4 className="information_mainheader">Text Notification</h4>
          <TextNotification user={user} />
        </div>
      )}
    </>
  );
};

export default FacilityProfileTabs;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Row,
	Spinner,
} from "reactstrap";
import { Pagination } from 'rsuite';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { FiArrowLeft } from "react-icons/fi";


//components
import ImageViewer from "./ImageViewer";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client"
import { FetchDocuments } from "../../../gql/documents/query"

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//Functions
import { DocumentsColumns, FormatData, BuildDocumentSearchQuery, BuildDocumentFilterQuery } from "./documents.functions"

//filters
import DocumentType from "./documents.types";

const DocumentTable: React.FC<{
	queryCondition: any;
	user: any;
	onBack: () => void;
}> = props => {
	// const { auth: { user } } = useSelector(authState);
	const initialCondition = {
		practioner_id: { _eq: props.user.id },
		category: { _neq: "LICENSE" }
	}
	const [filterValue, setFilterValue] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState<any>(initialCondition)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null)

	const [getDocumentList, { loading, data, refetch, error, called }] = useLazyQuery(FetchDocuments, {
		variables: {
			where: queryCondition
		}
	})
	const defaultSorted: any = [];

	const handleRefetch = () => {
		if (called) {
			refetch()
		} else {
			getDocumentList()
		}
	}

	const handleSearch = (value: string) => {
		const conditionTemp = BuildDocumentSearchQuery(value, null)
		let _cond = {}
		if (props.queryCondition !== null) {
			_cond = props.queryCondition
		} else {
			_cond = initialCondition
		}
		setQueryCondition({ ..._cond, ...conditionTemp })
	}

	const handleSelectDocument = (_document: DocumentType) => {
		setSelectedDocument(_document)
		setShowModal(true)
	}


	useEffect(() => {
		if (searchKey.length > 0) {
			handleSearch(searchKey)
		} else {
			let _cond = {}
			if (props.queryCondition !== null) {
				_cond = props.queryCondition
			} else {
				_cond = initialCondition
			}
			setQueryCondition(_cond)
		}
	}, [searchKey])
	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildDocumentFilterQuery(filterValue, null)
		let _cond = {}
		if (props.queryCondition !== null) {
			_cond = props.queryCondition
		} else {
			_cond = initialCondition
		}
		setQueryCondition({ ..._cond, ...conditionTemp })
	}, [filterValue])

	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			setQueryCondition(initialCondition)
		}
	}, [props.queryCondition])

	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getDocumentList()
		}
	}, [queryCondition])

	const keyField = "id";
	return (
		<Row>
			<Col lg="12">
				<Card>
					<div onClick={() => props.onBack()} className="cursor-pointer">
						<FiArrowLeft size={20}/>
					</div>
					<CardBody>
						<ToolkitProvider
							keyField={keyField}
							data={FormatData(Array.isArray(data?.documents) ? data.documents : [])}
							columns={DocumentsColumns(searchKey, filterValue, history, handleSelectDocument)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									{/* <Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<FormGroup>
															<Input
																name="email"
																placeholder="Search documents"
																type="text"
																onChange={(e) => setSearchKey(e.target.value)}
																value={searchKey}
															/>
															<i className="bx bx-search-alt search-icon" />
														</FormGroup>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<JobRequestFilter
													onFilterChange={setFilterValue}
													activeFilters={["job_date", "position"]}
													filterQueryConditions={null}
												/>
											</div>
										</Col>
									</Row> */}
									{loading && (
										<Col xl="12" className="text-center">
											<Spinner color="primary" />
										</Col>
									)}
									<Row>
										<Col xl="12">
											<div className="table-responsive">
												<BootstrapTable
													key={`${searchKey}_${JSON.stringify(filterValue)}`}
													bordered={false}
													striped={false}
													defaultSorted={defaultSorted}
													// selectRow={{
													// 	mode: 'checkbox',
													// 	onSelectAll: (isSelected) => { console.log(isSelected) }
													// }}
													classes={
														"table align-middle table-nowrap table-hover"
													}
													headerWrapperClasses={"thead-light"}
													{...toolkitProps.baseProps}
													noDataIndication={() => "No requests found!"}
												/>
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-30">
										<Col className="inner-custom-pagination d-flex">
											<div className="d-inline mt-3 w-100">
												<Pagination
													total={data?.documents_aggregate?.aggregate?.count || 0}
													limit={PageData.limit}
													activePage={PageData.pageNo}
													onChangePage={(pageNo) => setPageData({ ...PageData, pageNo })}
													limitOptions={[30, 50, 100]}
													layout={['limit', 'pager']}
													onChangeLimit={(limit) => setPageData({ ...PageData, limit })}
													className="d-flex justify-content-between"
												/>
											</div>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
			<ImageViewer
				isOpen={showModal}
				toggle={() => setShowModal(false)}
				document={selectedDocument}
			/>
		</Row>
	);
};

export default DocumentTable;
import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link, useLocation } from "react-router-dom";

import { Button, Row, Col, Panel, PanelGroup, Breadcrumb } from "rsuite";
import { Form, FormGroup, Input } from "reactstrap";

import Highlighter from "react-highlight-words";

import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";

//GraphQL
import { useLazyQuery } from "@apollo/client";
import { FetchFaqs } from "gql/faq/query";

import { BuildJobRequestSearchQuery } from "../Admin/FAQs/faqs.functions";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const tabs = [
  {
    id: 1,
    text: "Nurses",
  },
  {
    id: 2,
    text: "Facilities",
  },
];
const HelpSearch: React.FC = () => {
  const search = useLocation().search;

  const initialCondition = {};
  const [activeItem, setActiveItem] = useState<any>(1);
  const [searchKey, setSearchKey] = useState("");
  const {
    auth: { token },
  } = useSelector(authState);
  const [queryCondition, setQueryCondition] = useState<any>({});
  const [getFaqs, { loading, data, refetch, error, called }] = useLazyQuery(
    FetchFaqs,
    {
      variables: {
        where: queryCondition,
      },
    }
  );

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobRequestSearchQuery(value, null);
    let _cond = {};
    if (queryCondition !== null) {
      _cond = queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };

  useEffect(() => {
    // if (Object.keys(queryCondition).length) {
    getFaqs();
    const type = new URLSearchParams(search).get("search_text");
    if (type?.length && type) {
      handleSearch(type);
      setSearchKey(type);
    }
    // }
  }, []);

  const formatHighlighter = (text: string, searchKey: string) => {
    const searchWords = [searchKey];
    return (
      <Highlighter
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={String(text)}
        highlightStyle={{ background: "#ff0" }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="HelpPage">
        <div className="HelpPage-Navbar">
          <Link to="/help">
            <img
              src={"/caregigs_logo.png"}
              alt=""
              className="HelpPage-NavbarLogo"
            />
          </Link>
          <div className="HelpPage-NavbarItems">
            <div className="HelpPage-NavbarItem1">
              <NavLink to={"/help/contact-us"}>
                <p className="HelpPage-NavbarItemText1">Contact Us</p>
              </NavLink>
            </div>
            <div>
              <NavLink to={"/login"}>
                <p className="HelpPage-NavbarItemText2">
                  {token ? "Dashboard" : "Log In"}
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="help-breadcrumb">
          <Breadcrumb separator={<AngleRightIcon />}>
            <Breadcrumb.Item>
              <Link to="/help">Help</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: -45 }}
          >
            <Col md={6}>
              <Input
                placeholder="Search here..."
                type="text"
                onChange={(e) => setSearchKey(e.target.value)}
                value={searchKey}
                style={{ borderRadius: 20 }}
              />
            </Col>
            <Col>
              <Button
                color="blue"
                appearance="subtle"
                className="ml-5 border border-primary"
                style={{ borderRadius: 20 }}
                size="sm"
                onClick={() => handleSearch(searchKey)}
                type="submit"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="HelpPage-helpSearchContainer">
          <p
            style={{ fontWeight: "600", color: "#4E4E4E" }}
            className="HelpPage-ClipboardCardSubtitle HelpPage-helpSearchTabsContainer"
          >
            Type
          </p>
          <Col xs={5} className="HelpPage-helpSearchTabsContainer">
            {tabs.map(({ id, text }) => (
              <div
                style={{
                  padding: 15,
                  paddingLeft: 15,
                  paddingRight: 10,
                  backgroundColor: id === activeItem ? "#eeeeee" : "#fff",
                  borderRadius: 10,
                  cursor: "pointer",
                  width: "100%",
                }}
                key={id}
                onClick={() => {
                  setActiveItem(id);
                  setQueryCondition(
                    id == 1
                      ? {
                          category: { _eq: "nurse" },
                        }
                      : {
                          category: { _eq: "Facility" },
                        }
                  );
                }}
              >
                {text}
              </div>
            ))}
          </Col>
          <Col md={12} className="HelpPage-helpSearchPanelContainer">
            <PanelGroup accordion bordered style={{ marginBottom: 60 }}>
              {data?.faqs?.map((event: any, i: any) => (
                <Panel
                  header={event.title}
                  key={i}
                  defaultExpanded={searchKey ? true : false}
                >
                  <p className="HelpPage-ClipboardCardDescription">
                    {formatHighlighter(event.description, searchKey)}
                  </p>
                  ),
                </Panel>
              ))}
            </PanelGroup>
          </Col>
        </Row>
        <div className="HelpPage-footer">
          <p className="HelpPage-footerText">
            © 2023 Caregigs LLC | All Rights Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(HelpSearch);

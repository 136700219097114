import React, { useState, useEffect } from "react"
import { CheckPicker, Button as RSButton } from 'rsuite'
import { PlacementType } from "rsuite/esm/toaster/toaster"
import { useQuery, useApolloClient } from "@apollo/client"

const CheckPickerPaginate: React.FC<{
	query: any;
	paginate: boolean;
	objectLabel: string;
	objectValue: string;
	filterCondition: any;
	arrKey: string;
	getFullObj: boolean;
	placeholder: string;
	value: string[];
	onChange: (value: string[]) => void;
	style: React.CSSProperties;
	placement?: PlacementType;
	menuStyle?: React.CSSProperties
}> = (props) => {
	const picker = React.useRef<any>();
	const client = useApolloClient()
	const [queryCondition, setQueryCondition] = useState({})
	const [PageData, setPageData] = useState({
		limit: 30,
		pageNo: 1
	})
	const [list, setList] = useState<any[]>([])

	const { data: items, loading, error } = useQuery(props.query, {
		variables: {
			limit: 30,
			offset: 0,
			where: queryCondition
		},
		fetchPolicy: "no-cache"
	})
	const renderPickerFooter = () => {
		if (props.paginate) {
			return (
				<div style={{
					padding: '10px 2px',
					borderTop: '1px solid #e5e5e5',
					justifyContent: "flex-end",
					display: "flex"
				}}>
					<RSButton
						style={{
							marginRight: 10,
							marginTop: 2
						}}
						appearance="primary"
						size="sm"
						onClick={loadMore}
					>
						Load More
					</RSButton>
					<RSButton
						style={{
							marginRight: 10,
							marginTop: 2
						}}
						appearance="primary"
						size="sm"
						onClick={()=>picker.current.close()}
					>
						OK
					</RSButton>
				</div>
			)
		} else {
			return null
		}
	}
	const handleSearch = (value: string) => {
		if (value.length >= 3) {
			const labelArr = props.objectLabel.split("+")
			const _conditions: any[] = []
			labelArr.map((l:string, i: number) => {
				const valueArr = value.split(" ")
				_conditions.push({[l]: { _ilike: `%${valueArr[i] || value}%` },})
			})
			setQueryCondition({
				...queryCondition,
				_or: _conditions
			})
		} else {
			let conditionTemp = {}
			if (props.filterCondition !== null) {
				conditionTemp = { ...props.filterCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}
	const loadMore = async () => {
		let conditionTemp = {}
		if (props.filterCondition !== null) {
			conditionTemp = { ...props.filterCondition }
		}
		const newPageNo = PageData.pageNo + 1
		const { data } = await client.query({
			query: props.query,
			variables: {
				limit: PageData.limit,
				offset: (newPageNo - 1) * PageData.limit,
				where: conditionTemp
			}
		})
		const temp: any[] = []
		if (Array.isArray(data?.[props.arrKey])) {
			data[props.arrKey].map((item: any) => {
				let label = ""
				const labelArr = props.objectLabel.split("+")
				labelArr.map((l: string) =>{
					label += ` ${item[l]}`
				})
				temp.push({
					label,
					value: props.getFullObj ? JSON.stringify(item) : item[props.objectValue]
				})
			})
			setList([...list, ...temp])
			setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
		}
	}
	useEffect(() => {
		const temp:any[] = []
		if (Array.isArray(items?.[props.arrKey])) {
			items[props.arrKey]?.map((item: any) => {
				let label = ""
				const labelArr = props.objectLabel.split("+")
				labelArr.map((l: string) =>{
					label += ` ${item[l]}`
				})
				temp.push({
					label,
					value: props.getFullObj ? JSON.stringify(item) : item[props.objectValue],
				})
			})
			setList(temp)
		}
	}, [items])
	useEffect(() => {
		if (props.filterCondition !== null) {
			setQueryCondition(props.filterCondition)
		}
	}, [props.filterCondition])
	return (
		<CheckPicker
			// {...props}
			menuStyle={props.menuStyle || {}}
			style={props.style}
			data={list}
			placeholder={props.placeholder}
			onSearch={handleSearch}
			value={props.value}
			onChange={props.onChange}
			renderExtraFooter={renderPickerFooter}
			placement={props.placement || "bottomEnd"}
			preventOverflow={true}
			// @ts-ignore
			ref={picker}
		/>
	)
}
export default CheckPickerPaginate
import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

//components
import OnboardingModal from "components/Onboarding/OnboardModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { authState } from "../toolkit/auth/auth.slice"
import { onboardingState, handleSetOnboardingModal } from "../toolkit/onboarding/onboarding.slice"

const Authmiddleware = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	role,
	restricted,
	...rest
}) => {
	const dispatch = useDispatch()
	const { auth: { token, user } } = useSelector(authState)
	const {showOnboardingModal} = useSelector(onboardingState)
	return (
		<Route
			{...rest}
			render={props => {
				if (isAuthProtected && !token) {
					return (
						<Redirect
							to={{ pathname: "/login", state: { from: props.location } }}
						/>
					)
				} else if (!isAuthProtected && token && restricted) {
					return (
						<Redirect
							to={{ pathname: "/schedule", state: { from: props.location } }}
						/>
					)
				}
				if((role !== "common") && (role !== user.role) && isAuthProtected){
					if(user.role === "provider"){
						return (
							<Redirect
								to={{ pathname: "/schedule", state: { from: props.location } }}
							/>
						)
					}else if(user.role === "moderator"){
						return (
							<Redirect
								to={{ pathname: "/dashboard", state: { from: props.location } }}
							/>
						)
					}
				}

				return (
					<React.Fragment>
						<Layout>
							<Component {...props} />
						</Layout>
						{token && (
							<OnboardingModal
								isOpen={showOnboardingModal}
								toggle={() => dispatch(handleSetOnboardingModal(false))}
							/>
						)}
					</React.Fragment>
				)
			}}
		/>
	)
}

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
	role: PropTypes.string,
	restricted: PropTypes.bool
}

export default Authmiddleware;

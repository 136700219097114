import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Pagination } from "rsuite";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";

//types
import timecardsType from "./timecards.types";

//components
import RatingModal from "components/timecards/RatingModal";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { FetchJobRequests } from "../../gql/jobRequests/query";
import { UpdateJobRequest } from "../../gql/jobRequests/mutation";

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";

//Functions
import {
  TimecardsColumns,
  FormatData,
  BuildJobRequestSearchQuery,
  BuildJobRequestFilterQuery,
} from "./timecards.functions";

//filters
import JobRequestFilter from "components/Filters/jobRequests/JobRequestFilter.jsx";

const TimecardTable: React.FC<{
  queryCondition: any;
}> = (props) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const initialCondition = {
    _or: [
      {
        job_opening: { createdby: { _eq: user?.id } },
      },
      {
        invitation: { createdby: { _eq: user?.id } },
      },
    ],
    active: { _eq: true },
    status: { _eq: "completed" },
  };
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>({});
  const [selectedTimecard, setSelectedTimecard] = useState<timecardsType | null>(null);
  const [getJobRequests, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchJobRequests, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    });
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);

  const defaultSorted: any = [];

  const handleRefetch = () => {
    if (called) {
      refetch();
    } else {
      getJobRequests();
    }
  };

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobRequestSearchQuery(
      value,
      props.queryCondition,
      user?.id
    );
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  };

  const handleChangeStatus = async (id: string, status: string) => {
    await UpdateJobRequestMutation({
      variables: {
        id,
        object: {
          status,
        },
      },
    });
    handleRefetch();
    Swal.fire("Status Edited Successfully!", "", "success");
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      let _cond = {};
      if (props.queryCondition !== null) {
        _cond = props.queryCondition;
      } else {
        _cond = initialCondition;
      }
      setQueryCondition(_cond);
    }
  }, [searchKey]);
  //Apply filters
  useEffect(() => {
    const conditionTemp = BuildJobRequestFilterQuery(
      filterValue,
      props.queryCondition,
      user?.id
    );
    let _cond = {};
    if (props.queryCondition !== null) {
      _cond = props.queryCondition;
    } else {
      _cond = initialCondition;
    }
    setQueryCondition({ ..._cond, ...conditionTemp });
  }, [filterValue]);

  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition);
    } else {
      setQueryCondition(initialCondition);
    }
  }, [props.queryCondition]);

  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getJobRequests();
    }
  }, [queryCondition]);

  const keyField = "id";

  const [isRatingModalOpen, setRatingModalOpen] = useState<boolean>(false);

  function toggleRatingModal(param: timecardsType) {
    setRatingModalOpen(!isRatingModalOpen);
    setSelectedTimecard(param)
  }
  return (
    <Row>
      <Col lg="12">
        <div className="Timecardpage-card">
          <div>
            <ToolkitProvider
              keyField={keyField}
              data={FormatData(
                Array.isArray(data?.job_requests) ? data.job_requests : []
              )}
              columns={TimecardsColumns(
                searchKey,
                filterValue,
                history,
                handleChangeStatus,
                toggleRatingModal
              )}
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box d-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                name="email"
                                placeholder="Search requests"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end d-flex justify-content-end">
                        {/* <ExportCSVButton
                                {...toolkitProps.csvProps}
                                style={{ border: "1px solid #74788d", marginRight: 10 }}
                              >Export</ExportCSVButton> */}
                        <JobRequestFilter
                          onFilterChange={setFilterValue}
                          activeFilters={["job_date", "position"]}
                          filterQueryConditions={null}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm="8">
                      Total Timecards:{" "}
                      {data?.job_requests_aggregate?.aggregate?.count || 0}
                    </Col>
                  </Row>
                  {loading && (
                    <Col xl="12" className="text-center">
                      <Spinner color="primary" />
                    </Col>
                  )}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={{
                            mode: "checkbox",
                            onSelectAll: (isSelected) => {
                              console.log(isSelected);
                            },
                          }}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          noDataIndication={() => "No requests found!"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline mt-3 w-100">
                        <Pagination
                          total={
                            data?.job_requests_aggregate?.aggregate?.count || 0
                          }
                          limit={PageData.limit}
                          activePage={PageData.pageNo}
                          onChangePage={(pageNo) =>
                            setPageData({ ...PageData, pageNo })
                          }
                          limitOptions={[30, 50, 100]}
                          layout={["limit", "pager"]}
                          onChangeLimit={(limit) =>
                            setPageData({ ...PageData, limit })
                          }
                          className="d-flex justify-content-between"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Col>
      <RatingModal
        modal={isRatingModalOpen}
        toggle={()=>{
          setRatingModalOpen(!isRatingModalOpen);
        }}
        timecard={selectedTimecard}
        refetch={handleRefetch}
      />
    </Row>
  );
};

export default TimecardTable;

import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import InvitationTable from "pages/Invitations/InvitationTable";
import positionType from "pages/Settings/positions/positions.types";
import moment from "moment";

//Redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const InvitationList: React.FC<{
	isOpen: boolean;
	handleClose: () => void;
	position: positionType;
	selectedDate: Date;
}> = ({ isOpen, handleClose, position, selectedDate }) => {
	const { auth: { user } } = useSelector(authState);
	return (
		<Modal isOpen={isOpen} toggle={handleClose} size="xl" backdrop="static">
			<ModalHeader toggle={handleClose} tag="h4">
				Invitations
			</ModalHeader>
			<ModalBody>
				{position?.id && (
					<InvitationTable
						queryCondition={{
							status: { _neq: "cancelled" },
							isactive: { _eq: true },
							createdby: { _eq: user.id },
							position_id: { _eq: position.id },
							job_date: { _eq: moment(selectedDate).format("YYYY-MM-DD")},
						}}
					/>
				)}
			</ModalBody>
		</Modal>
	)
}
export default InvitationList
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Pagination } from "rsuite";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";

// icons
import { FiPlus, FiDownloadCloud } from "react-icons/fi";

//import components
import CreateMapleShiftModal from "components/shifts/CreateMapleShiftModal";
import AssignNurseModal from "components/shifts/AssignNurseModal";

//GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { FetchJobOpenings } from "../../../gql/jobOpenings/query";
import { UpdateJobOpening } from "../../../gql/jobOpenings/mutation";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//Functions
import {
  JobOpeningColumns,
  FormatData,
  BuildJobOpeningSearchQuery,
  BuildJobOpeningFilterQuery,
} from "./mapleShift.functions";

//filters
import JobOpeningFilter from "components/Filters/jobOpening/JobOpeningFilter";

//helpers
import { showAlert } from "helpers/index";
import { deleteMapleShiftApi } from "toolkit/admin/admin.api";

const MapleShiftTable: React.FC<{
  queryCondition: any;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authState);
  const { ExportCSVButton } = CSVExport;
  const {
    auth: { user },
  } = useSelector(authState);
  const initialCondition = {
    isactive: { _eq: true },
    maple_shift_id: { _is_null: false },
    filled: { _eq: false },
  };
  const [filterValue, setFilterValue] = useState("");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [assignNurseModal, setAssignNurseModal] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<any | undefined>();
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>(initialCondition);
  const [selectedJobOpeningId, setSelectedJobOpeningId] = useState<string>("");

  const [getJobOpenings, { loading, data, refetch, error, called }] =
    useLazyQuery(FetchJobOpenings, {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    });
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);

  const defaultSorted: any = [];

  const handleRefetch = () => {
    if (called) {
      refetch();
    } else {
      getJobOpenings();
    }
  };

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobOpeningSearchQuery(value, null);
    setQueryCondition({ ...initialCondition, ...conditionTemp });
  };

  const onClickRequests = (id: string) => {
    setRequestModalOpen(true);
    setSelectedJobOpeningId(id);
  };

  function toggle() {
    setModal(!modal);
  }

  useEffect(() => {
    if (user?.id) {
      getJobOpenings();
    }
  }, [user]);
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey);
    } else {
      setQueryCondition(initialCondition);
    }
  }, [searchKey]);
  //Apply filters
  useEffect(() => {
    const conditionTemp = BuildJobOpeningFilterQuery(filterValue, null);
    const _cond = initialCondition;
    // if (props.queryCondition !== null) {
    // 	_cond = props.queryCondition
    // } else {
    // 	_cond = initialCondition
    // }
    setQueryCondition({ ..._cond, ...conditionTemp });
  }, [filterValue]);
  const keyField = "id";

  function toggleAssignNurseModal() {
    setAssignNurseModal(!assignNurseModal);
  }

  const onassignNurse = (_shift: any) => {
    setSelectedShift(_shift);
    toggleAssignNurseModal();
  };

  const onEditClick = (_shift: any) => {
    setSelectedShift(_shift);
    setIsEdit(true);
    toggle();
  };

  const handleDelete = (shift: any) => {
    Swal.fire({
      title: "Do you want to delete this maple shift?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = {
            mapleShiftId: shift?.maple_shift_id,
          };
          await dispatch(deleteMapleShiftApi({ formData, token }));
          await UpdateJobOpeningMutation({
            variables: {
              id: shift?.id,
              object: {
                isactive: false,
              },
            },
          });
          handleRefetch();
          Swal.fire("Deleted Successfully!", "", "success");
        } catch (err) {
          Swal.fire("Something went wrong!", "", "error");
        }
      }
    });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Row>
      <Col lg="12">
        <div className="GigShiftPage-card">
          <div>
            <ToolkitProvider
              keyField={keyField}
              data={FormatData(
                Array.isArray(data?.job_openings) ? data.job_openings : []
              )}
              columns={JobOpeningColumns(
                searchKey,
                filterValue,
                history,
                onassignNurse,
                onEditClick,
                handleDelete
              )}
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row
                    className="mb-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col sm="4">
                      <div className="search-box d-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FormGroup>
                              <Input
                                name="email"
                                placeholder="Search maple shifts"
                                type="text"
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary shadow mx-2"
                          onClick={() => setModal(true)}
                        >
                          <FiPlus /> Create Maple Shift
                        </button>
                        <ExportCSVButton
                          {...toolkitProps.csvProps}
                          style={{
                            border: "1px solid #74788d",
                            marginRight: 10,
                          }}
                        >
                          {" "}
                          <FiDownloadCloud />
                          &nbsp;&nbsp; Export
                        </ExportCSVButton>

                        <JobOpeningFilter
                          onFilterChange={setFilterValue}
                          activeFilters={["job_date", "position", "location"]}
                          filterQueryConditions={null}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm="8">
                      Total Maple Shifts:{" "}
                      {data?.job_openings_aggregate?.aggregate?.count || 0}
                    </Col>
                  </Row>
                  {loading && (
                    <Col xl="12" className="text-center">
                      <Spinner color="primary" />
                    </Col>
                  )}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={{
                            mode: "checkbox",
                            onSelectAll: (isSelected) => {
                              console.log(isSelected);
                            },
                          }}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          noDataIndication={() => "No open maple shifts found!"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline mt-3 w-100">
                        <Pagination
                          total={
                            data?.job_openings_aggregate?.aggregate?.count || 0
                          }
                          limit={PageData.limit}
                          activePage={PageData.pageNo}
                          onChangePage={(pageNo: number) =>
                            setPageData({ ...PageData, pageNo })
                          }
                          limitOptions={[30, 50, 100]}
                          layout={["limit", "pager"]}
                          onChangeLimit={(limit: number) =>
                            setPageData({ ...PageData, limit })
                          }
                          className="d-flex justify-content-between"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Col>
      <CreateMapleShiftModal
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        toggle={toggle}
        modal={modal}
        refetch={handleRefetch}
        selectedShift={selectedShift}
      />
      <AssignNurseModal
        toggle={toggleAssignNurseModal}
        modal={assignNurseModal}
        selectedShift={selectedShift}
      />
    </Row>
  );
};

export default MapleShiftTable;

import { gql } from "@apollo/client"
export const FetchPositions =
  gql`query FetchPositions (\$limit:Int,\$offset:Int,\$where:positions_bool_exp){
    positions (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      name
      active
      created_at
      organisation {
        id
        name
      }
      location {
        id
        name
      }
      area {
        id
        name
      }
      requirement
      count
      filled
      type
      abbreviation
      start_time
      end_time
      text_color
      background_color
      notes
      break_time
      contract_rate
      contract_overtime_rate
    }
    positions_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
export const FetchPositionsForFilter =
  gql`query PositionFilter(\$limit:Int,\$offset:Int,\$where:positions_bool_exp) {
  positions(limit: \$limit, offset: \$offset, where: \$where) {
    name
    id
    abbreviation
    location {
      id
      name
    }
    text_color
    background_color
    start_time
    end_time
    type
  }
}`
export const FetchPositionByName =
  gql`query FetchPositionByName(\$name:String, \$createdby: uuid) {
  positions(where: {name: {_eq: \$name}, createdby: {_eq: \$createdby}, active: {_eq: true}}, limit: 1) {
    name
    id
    abbreviation
    location {
      id
      name
    }
    text_color
    background_color
    start_time
    end_time
  }
}`
export const FetchPositionByAbbreviation =
  gql`query FetchPositionByAbbreviation(\$abbreviation:String, \$createdby: uuid) {
  positions(where: {abbreviation: {_eq: \$abbreviation}, createdby: {_eq: \$createdby}, active: {_eq: true}}, limit: 1) {
    name
    id
    abbreviation
    location {
      id
      name
    }
    text_color
    background_color
    start_time
    end_time
  }
}`
export const FetchPositionById = gql`
  query FetchPositionById($id: uuid!) {
    positions_by_pk(id: $id) {
      name
      id
      abbreviation
      location {
        id
        name
      }
      text_color
      background_color
      start_time
      end_time
      contract_rate
      contract_overtime_rate
    }
  }
`;
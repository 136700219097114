import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import MarketplaceMapView from "components/marketplace/MarketplaceMapView";
import LocationListing from "./LocationListing"

//GraphQL
import { useQuery } from "@apollo/client";
import { FetchCustomersAdmin } from "../../../gql/customers/query";
import { FetchLocations } from "../../../gql/locations/query";

//redux
import { useDispatch } from "react-redux";

const ShiftList: React.FC = () => {
  const dispatch = useDispatch();
  const initialCondition = {
    verified: { _eq: true },
    is_deleted: { _eq: false },
  };

  const [customerList, setCustomerList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [queryCondition, setQueryCondition] = useState(initialCondition);
  const [selectedUser, setSelectedUser] = useState({});
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const { loading, data, refetch, error, called } = useQuery(
    FetchCustomersAdmin,
    {
      variables: {
        where: queryCondition,
      },
    }
  );

  const { data: facilityData } = useQuery(FetchLocations);

  useEffect(() => {
    if (Array.isArray(data?.customers)) {
      setCustomerList(data?.customers);
    }
    if (Array.isArray(facilityData?.locations)) {
      setFacilityList(facilityData?.locations);
    }
  }, [data, facilityData]);

  const onClickProfile = (user: any) => {
    setSelectedUser(user);
    setProfileModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className="page-content Timecardpage AppBody">
        <MetaTags>
          <title>MAP | Caregigs</title>
        </MetaTags>
        <div className="Timecardpage-container admin">
          {/* breadcrumb */}
          <Breadcrumbs
            title="MAP"
            links={[
              { title: "Home", link: "/" },
              { title: "Map", link: "#" },
            ]}
          />
          {/* <MarketplaceMapView
            customerList={customerList}
            onClickProfile={onClickProfile}
            facilityList={facilityList}
          /> */}
          <LocationListing />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ShiftList);

import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Input,
	Form,
	Col,
	Row,
	FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";
import { useParams } from "react-router-dom";

// icons
import { FiCheck } from "react-icons/fi";
import NoteType from "pages/MarketPlaceProfile/tabs/notes/notes.types";
//graphql
import { useMutation } from "@apollo/client";
import { UpdateNote, InsertNoteOne} from "gql/notes/mutation";

const CreateNoteModal: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	note: NoteType | null;
	refetch: () => void
}> = ({ isOpen, toggle, note, refetch }) => {
	const params: {id: string} = useParams()
	const [state, setState] = useState<{
		title: string;
		note: string;
	}>({
		title: "", 
		note: ""
	})
	const [btnLoading, setBtnLoading] = useState<boolean>(false)

	const [UpdateNoteMutation] = useMutation(UpdateNote)
	const [CreateNoteMutation] = useMutation(InsertNoteOne)

	const handleClose = () => {
		toggle()
		validation.resetForm()
	}
	// validation
	const validation: any = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			note: state.note,
			title: state.title
		},
		validationSchema: Yup.object({
			note: Yup.string().required("Please Enter Notes"),
			title: Yup.string().required("Please Enter Title"),
		}),
		onSubmit: async (values) => {
			try {
				setBtnLoading(true)
				if(note?.id){
					await UpdateNoteMutation({
						variables: {
							id: note?.id,
							object: {
								title: values.title,
								note: values.note
							}
						}
					})
					showAlert({
						title: "Updated!",
						message: "Note updated successfully!",
						type: "success",
					});
				}else{
					await CreateNoteMutation({
						variables: {
							object: {
								title: values.title,
								note: values.note,
								customer_id: params.id
							}
						}
					})
					showAlert({
						title: "Created!",
						message: "Note created successfully!",
						type: "success",
					});
				}
				refetch()
				handleClose()
			} catch (err) {
				showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
			} finally {
				setBtnLoading(false)
			}
		},
	});

	useEffect(() => {
		if (note?.id) {
			setState({
				title: note.title,
				note: note.note
			})
		}
	}, [note])
	return (
		<Modal
			isOpen={isOpen}
			size="md"
			backdrop="static"
			className="customModal"
			toggle={handleClose}
		>
			<ModalHeader toggle={handleClose} tag="h4">
				{note !== null ? "Update" : "Create"} Note
			</ModalHeader>
			<ModalBody>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
				>
					<Row form>
						<Col xs={12}>
						<div className="mb-3">
								<Label className="form-label">Title</Label>
								<Input
									name="title"
									label="Title"
									type="text"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.title || ""}
									invalid={
										validation.touched.title &&
											validation.errors.title
											? true
											: false
									}
								/>
								{validation.touched.title &&
									validation.errors.title ? (
									<FormFeedback type="invalid">
										{validation.errors.title}
									</FormFeedback>
								) : null}
							</div>
							<div className="mb-3">
								<Label className="form-label">Notes</Label>
								<Input
									name="note"
									label="Notes"
									type="textarea"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.note || ""}
									invalid={
										validation.touched.note &&
											validation.errors.note
											? true
											: false
									}
								/>
								{validation.touched.note &&
									validation.errors.note ? (
									<FormFeedback type="invalid">
										{validation.errors.note}
									</FormFeedback>
								) : null}
							</div>
							<div className="text-end">
								<button
									className="btn btn-primary"
									type="submit"
									disabled={btnLoading}
								>
									<FiCheck /> &nbsp;
									Submit
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</Modal>
	)
}
export default CreateNoteModal
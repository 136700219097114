import React from "react";

const ToolTip: React.FC<{
  message: string;
  children: React.ReactNode;
}> = ({ children, message }) => {
  return (
    <div className={`customeToolTip`}>
      <div className="customeToolTip-child">{children}</div>
      <div className="customeToolTip-msg">{message}</div>
    </div>
  );
};

export default ToolTip;

import { gql } from "@apollo/client"
export const InsertPositionOne =
gql`mutation InsertPositionOne(\$object: positions_insert_input!) {
  insert_positions_one(object: \$object) {
    id
  }
}`

export const UpdatePosition =
gql`mutation UpdatePosition(\$id:uuid!, \$object: positions_set_input) {
  update_positions_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`
import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import FacilityTable from "./FacilityTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

const RequestList: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="page-content Timecardpage AppBody">
        <MetaTags>
          <title>Facilities | Caregigs</title>
        </MetaTags>
        <div className="Timecardpage-container admin">
          {/* breadcrumb */}
          <Breadcrumbs 
            title="Facilities" 
            links={[{title: "Home", link: "/"}, {title: "Facilities", link: "#"}]} 
          />
          <FacilityTable queryCondition={null} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(RequestList);

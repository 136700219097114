import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { List } from "rsuite";

const EducationTab: React.FC<{
  user: any;
}> = ({ user }) => {
  return (
    <Card>
      <CardBody>
        <p className="profileMoodalPara">
          <strong>Qualification Type </strong>{" "}&nbsp;&nbsp;
          {user.questionnaire?.qualificatioType}
        </p>
        {Array.isArray(user.education_info) && (
          <List style={{ marginTop: 20 }}>
            {user.education_info.map((edu: any, i: number) => (
              <List.Item key={i}>
                <p className="profileMoodalPara">
                  <strong>Institution </strong>{" "} &nbsp;&nbsp;
                  {edu?.institution || "N/A"}
                </p>
                <p className="profileMoodalPara">
                  <strong>Specialisation </strong>{" "} &nbsp;&nbsp;
                  {edu?.specialisation || "N/A"}
                </p>
                <p className="profileMoodalPara">
                  <strong>Completion Year </strong>{" "} &nbsp;&nbsp;
                  {edu?.completion_year || "N/A"}
                </p>
              </List.Item>
            ))}
          </List>
        )}
        {/* <p className="profileMoodalPara">
          <strong>Institution </strong>{" "} &nbsp;&nbsp;
          {user.education_info?.institution || "N/A"}
        </p>
        <p className="profileMoodalPara">
          <strong>Specialisation </strong>{" "} &nbsp;&nbsp;
          {user.education_info?.specialisation || "N/A"}
        </p>
        <p className="profileMoodalPara">
          <strong>Completion Year </strong>{" "} &nbsp;&nbsp;
          {user.education_info?.completion_year || "N/A"}
        </p> */}
      </CardBody>
    </Card>
  );
};
export default EducationTab;

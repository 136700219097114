import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import OtpInput from "react-otp-input";
import { BsArrowRightCircle } from "react-icons/bs";
import Swal from "sweetalert2";

//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { verifyEmailUpdateApi, updateEmailApi } from "toolkit/profile/profile.api";
const OtpModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  email: string;
  onVerify: () => void
}> = ({ isOpen, toggle, email, onVerify }) => {
  const dispatch = useDispatch()
  const { auth: { token } } = useSelector(authState);
  const [loading, setLoading] = useState<boolean>(false)

  const handleClose = () => {
    toggle();
  };
  const [otp, setOTP] = useState("");
  const handleResendOTP = async() => {
    try{
      await dispatch(updateEmailApi({
        formData: {new_email: email}, 
        token
      }))
      Swal.fire("OTP resent successfully!", "", "success")
    }catch(err){
      Swal.fire("Something went wrong", "", "error")
    }
  }
  const handleVerifyOTP = async() => {
    try{
      setLoading(true)
      const resp = await dispatch(verifyEmailUpdateApi({formData: {otp}, token}))
      if(resp.type === "user/verifyemailupdate/fulfilled"){
        handleClose()
        onVerify()
      }else{
        let message = "Something went wrong!";
          if (resp.payload?.error?.message) {
            message = resp.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
      }
    }catch(err){
      console.log(err)
      Swal.fire("Something went wrong", "", "error")
    }finally{
      setLoading(false)
    }
  } 
  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="sm" centered>
      <div className="modal-body">
        <div className="custom-email-otp">
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="d-flex justify-content-center align-items-center container">
            <div className="card py-5 px-3">
              <h5 className="m-0">Email verification</h5>
              <span className="mobile-text">
                Enter the code we just send on your email{" "}
                <b className="text" style={{ color: "#009ef7" }}>
                  {email}
                </b>
              </span>
              <div className="d-flex flex-row mt-5">
                <OtpInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={4}
                  separator={<span>&nbsp;</span>}
                  inputStyle={{
                    width: "100%",
                    height: "3.5rem",
                    marginRight: 10,
                    marginBottom: "34px",
                  }}
                />
              </div>
              <div className="text-center mt-1">
                <div onClick={handleVerifyOTP}>
                  <BsArrowRightCircle
                    size="25"
                    style={{ marginBottom: "15px" }}
                  />
                </div>
                <span className="d-block mobile-text">
                  Dont receive the code?
                </span>
                <div onClick={handleResendOTP}>
                  <span
                    className="font-weight-bold text cursor"
                    style={{ color: "#009ef7" }}
                  >
                    Resend
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default OtpModal;

import { gql } from "@apollo/client"
export const UpdateInvitation =
	gql`mutation UpdateInvitation(\$id:uuid!, \$object: invitations_set_input) {
    update_invitations_by_pk(pk_columns: {id: \$id}, _set: \$object) {
        id
      }
    }`

export const InsertInvitation =
	gql`mutation InsertInvitation(\$objects: [invitations_insert_input!]!) {
  insert_invitations(objects: \$objects) {
    returning {
      id
      job_date
    }
  }
}
`
import React, { useEffect, useState } from "react"
import {
	Card,
	CardBody,
	CardTitle,
} from "reactstrap"
import { Panel, Loader } from "rsuite"
//GraphQL
import { useLazyQuery } from "@apollo/client"
import { FetchDocuments } from "../../../gql/documents/query"

//helpers
import { getImage } from "helpers"

//redux
import { useSelector } from "react-redux"
import { authState } from "../../../toolkit/auth/auth.slice"
interface DocumentType {
	id: string;
	status: string;
	name: string;
	document_url: string
	category: string
}
const LicenseTab: React.FC<{
	user: any
}> = ({ user }) => {
	const {auth: {token}} = useSelector(authState)
	const [document, setDocument] = useState<DocumentType | null>(null)
	const [image, setImage] = useState<string>("")

	const [getDocumentList, { loading, data, refetch, error, called }] = useLazyQuery(FetchDocuments, {
		variables: {
			where: { practioner_id: { _eq: user.id }, category: { _eq: "LICENSE" } }
		}
	})
	const handleGetImage = async(url: string) =>{
    const _image = await getImage(url, token, "image")
    setImage(_image as string)
  }
	useEffect(() => {
		if (user?.id) {
			getDocumentList()
		}
	}, [user])
	useEffect(() => {
		if (Array.isArray(data?.documents) && data?.documents?.length) {
			setDocument(data?.documents[0])
			handleGetImage(data?.documents[0].document_url)
		}
	}, [data])
	return (
		<Card>
			{loading ? (
				<div className="d-flex justify-content-center mt-5">
				<Loader size="md"/>
				</div>
			) : (
				<>
					{document !== null ? (
						<Panel bordered header={document?.name}>
							<img src={image} height="240" />
						</Panel>
					) : (
						<CardBody>License Details to go here</CardBody>
					)}
				</>
			)}

		</Card>
	)
}
export default LicenseTab
import React from "react";

//icons
import {
	BsChevronRight,
	BsChevronDown,
} from "react-icons/bs";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  schedulerState,
  handleToggleLocation,
} from "toolkit/scheduler/scheduler.slice";

const PositionCalendarLeft: React.FC<{
	onScroll: (topOffset: number) => void
}> = ({onScroll}) => {
	const dispatch = useDispatch();
	const { locations } = useSelector(schedulerState);
	return (
		<div className="main_calendar-bottom-left">
			<div
				className="main_calendar-bottom-left-inner"
				id="main_calendar-bottom-left"
				onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
					onScroll(event.currentTarget.scrollTop)
					// ref.current?.scrollTo({ scrollTop: event.currentTarget.scrollTop });
				}}
			>
				{locations.map((_location: any, index: number) => (
					<div
						className="GridLeftHeader"
						key={`${index}`}
						style={{
							height: _location?.fieldType === "location" ? _location.isOpen !== false ? 38 : 55 : 55,
							width: 230,
							cursor: "pointer",
							backgroundColor:
								_location.data.background_color !== null
									? _location.data.background_color
									: "#f8f8fb",
							color:
								_location.data.text_color !== null
									? _location.data.text_color
									: "#0009",
						}}
						onClick={() => {
							if (_location?.fieldType === "location") {
								dispatch(handleToggleLocation(index));
							}
						}}
					>
						{_location?.fieldType === "location" && (
							<>
								{_location.isOpen !== false ? (
									<BsChevronDown />
								) : (
									<BsChevronRight />
								)}
							</>
						)}
						<span style={{ marginLeft: "2px", fontSize: 10 }}>
							{_location?.label}
						</span>
					</div>
				))}
			</div>
			<div
				style={{ width: "100%", height: 7, backgroundColor: "#f8f8fb" }}
			></div>
		</div>
	)
}
export default PositionCalendarLeft
import React, { useState } from "react";
import { Link, useRouteMatch, NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
} from "reactstrap";

const LeftBar = () => {
  const { path } = useRouteMatch();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <div className="SettingsPage-card card_sticky_top">
        <div>
          <div className="d-flex flex-column h-100">
            <div className="">
              <h4 className="SettingsPage-header">Menu</h4>

              <ul className="list-unstyled categories-list SettingsPage-menu">
                <li>
                  <NavLink
                    to="/settings/locations"
                    className="text-body d-flex align-items-center pr-0 SettingsPage-menu_item "
                  >
                    <span className="me-auto">Locations</span>
                    {/* {path === "/settings/locations" && (
                      <i className="mdi mdi-circle-medium text-danger ms-2"></i>
                    )} */}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/staff"
                    className="text-body d-flex align-items-center pr-0 SettingsPage-menu_item "
                  >
                    <span className="me-auto">Staff</span>
                    {/* {path === "/settings/staff" && (
                      <i className="mdi mdi-circle-medium text-danger ms-2"></i>
                    )} */}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/positions"
                    className="text-body d-flex align-items-center pr-0 SettingsPage-menu_item "
                  >
                    <span className="me-auto">Positions</span>
                    {/* {path === "/settings/positions" && (
                      <i className="mdi mdi-circle-medium text-danger ms-2"></i>
                    )} */}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/email-notification"
                    className="text-body d-flex align-items-center pr-0 SettingsPage-menu_item "
                  >
                    <span className="me-auto">Email Notification</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/text-notification"
                    className="text-body d-flex align-items-center pr-0 SettingsPage-menu_item "
                  >
                    <span className="me-auto">Text Notification</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeftBar;

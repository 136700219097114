import React, { useEffect, useState } from "react";

import { Label, Col } from "reactstrap";

import { InputNumber, InputGroup } from "rsuite";

const InputNumberField: React.FC<{
  fieldTitle: string;
  onChange: (experienceValue: string) => any;
}> = ({ fieldTitle, onChange }) => {
  const [experienceValue, setExperienceValue] = useState(0);
  const handleMinus = () => {
    if (experienceValue == -experienceValue) {
    } else {
      setExperienceValue(experienceValue - 1);
    }
  };
  const handlePlus = () => {
    setExperienceValue(experienceValue + 1);
  };
  useEffect(()=>{
    onChange(String(experienceValue))
  }, [experienceValue])
  return (
    <>
      <Col xs={3}>
        <div className="mb-3">
          <Label className="form-label">{fieldTitle}</Label>
          <InputGroup>
            <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
            <InputNumber
              className={"custom-input-number"}
              value={experienceValue}
            />
            <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
          </InputGroup>
        </div>
      </Col>
    </>
  );
};

export default InputNumberField;

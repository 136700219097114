import React from "react"
import {
	Card,
	CardBody,
	CardTitle,
} from "reactstrap"
const ProfessionalExpTab: React.FC<{
	user: any
}> = ({user}) => {
	return (
		<Card>
			<CardBody>
				<p className="profileMoodalPara" >
					<strong>Intend to Work </strong> &nbsp;&nbsp; {user.questionnaire?.intendToWork}
				</p>
				<p className="profileMoodalPara" >
					<strong>Interested Shifts </strong> &nbsp;&nbsp; {user.questionnaire?.intersetedShifts}
				</p>
				<p className="profileMoodalPara">
					<strong>Experience </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.totalExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Post-Acute Care (SNF, ALF, CCRC) </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.postAcuteCareExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Acute Care Inpatient Hospitals </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.acuteCareExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Ambulatory Clinics/Surgical Centers </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.clinicExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Home Health </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.homeHealthExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Hospice </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.hospieExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Urgent Care </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.urgentCareExp || 0} years
				</p>
				<p className="profileMoodalPara" >
					<strong>Other Experience Duration </strong> &nbsp;&nbsp; {user.questionnaire?.experience?.otherExp || 0} years
				</p>
			</CardBody>
		</Card>
	)
}
export default ProfessionalExpTab
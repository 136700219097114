import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getImage } from "helpers";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { profileState, resetProfile } from "toolkit/profile/profile.slice";
import { authState, loginRemoveToken } from "toolkit/auth/auth.slice";
import { handleSetOnboardingModal } from "toolkit/onboarding/onboarding.slice";
import { resetLayout } from "toolkit/layout/layout.slice";

import { withRouter, Link } from "react-router-dom";

const ProfileMenu = (props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector(profileState);
  const {
    auth: { token, user },
  } = useSelector(authState);
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [avatar, setAvatar] = useState("/avatar.jpg");

  const handleGetImage = async () => {
    let image = "";
    if (profile.organisation?.avatar) {
      image = await getImage(profile.organisation?.avatar, token, "image");
    } else {
      image = "/avatar.jpg";
    }
    setAvatar(image);
  };
  const handleLogout = () => {
    dispatch(resetProfile());
    dispatch(loginRemoveToken());
    dispatch(resetLayout());
  };
  useEffect(() => {
    if (profile?.id) {
      handleGetImage();
    }
  }, [profile]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatar}
            alt="Header Avatar"
          />
          {profile?.organisation?.name && (
            <span className="d-none d-xl-inline-block ms-2 me-1">
              {profile?.organisation?.name}
            </span>
          )}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          {user.role === "provider" ? (
            <>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => dispatch(handleSetOnboardingModal(true))}
              >
                <i className="bx bx-question-mark font-size-16 align-middle me-1 text-warning" />
                <span>How it Works </span>
              </Link>
              <Link to="/help/facilities" className="dropdown-item">
                <i className="bx bx-support font-size-16 align-middle me-1 text-primary" />
                <span>Help</span>
              </Link>
            </>
          ) : (
            <></>
          )}

          <div className="dropdown-divider" />
          <div
            className="dropdown-item"
            onClick={handleLogout}
            style={{ cursor: "pointer" }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
ProfileMenu.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ProfileMenu));

import { gql } from "@apollo/client"
export const InsertLocationOne =
gql`mutation InsertLocationOne(\$object: locations_insert_input!) {
  insert_locations_one(object: \$object) {
    id
  }
}
`
export const UpdateLocation =
gql`mutation UpdateLocation(\$id:uuid!, \$object: locations_set_input) {
  update_locations_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`

export const InsertAreaInLocation =
	gql`mutation InsertAreaInLocation(\$objects: [location_area_map_insert_input!]!) {
  insert_location_area_map(objects: \$objects) {
    affected_rows
  }
}
`

export const DeleteLocation =
gql`mutation DeleteLocation(\$where:location_area_map_bool_exp!) {
  delete_location_area_map (
    where: \$where
  ) {
    affected_rows
  }
}`

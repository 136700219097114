import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { createSlice, current } from "@reduxjs/toolkit";
import positionType from 'pages/Settings/positions/positions.types';

const moment = extendMoment(Moment);
const start = moment();
const end = moment().add(30, 'days');
const range = moment.range(start, end);
const daterange = Array.from(range.by('day'));

const formatTime = (time: string) => {
  return moment(time, "HH:mm").format("hh:mm A")
}
interface LocationType {
  id: string;
  name: string;
}

interface LocationTypeScheduler {
  id: string;
  name: string;
  isactive: boolean;
  type: string;
  positions: any;
  isOpen: boolean;
}


interface PositionType {
  id: string;
  name: string;
  abbreviation: string;
  location: LocationType
}

interface GridFilterType {
  display: string,
  date: Date,
  time: string,
  timeUnit: string
}
interface StateType {
  positions: PositionType[];
  locations: LocationTypeScheduler[];
  jobOpenings: any[];
  jobRequests: any[];
  invitations: any[];
  employees: any[];
  gridFilters: GridFilterType;
  daterange: any;
  modal_open: boolean;
  invitation_modal_open: boolean;
  selectedPosition: positionType | null;
  selectedDate: Date;
  selectedCellData: any;
  shiftModalDisplay: string;
  selectedEmployee: any;
  expandAll: boolean;
}

const initialState: StateType = {
  positions: [],
  locations: [],
  jobOpenings: [],
  jobRequests: [],
  invitations: [],
  employees: [],
  gridFilters: {
    display: "positions",
    date: new Date(),
    time: "1",
    timeUnit: "months",
  },
  daterange,
  modal_open: false,
  invitation_modal_open: false,
  selectedPosition: null,
  selectedDate: new Date(),
  selectedCellData: null,
  shiftModalDisplay: "position",
  selectedEmployee: null,
  expandAll: false
};

export const schedulerSlice: any = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    handleSetPositions(state, action) {
      return { ...state, positions: action.payload };
    },
    handleSetLocations(state, action) {
      return { ...state, locations: action.payload };
    },
    handleSetJobOpenings(state, action) {
      return { ...state, jobOpenings: action.payload };
    },
    handleSetJobRequests(state, action) {
      return { ...state, jobRequests: action.payload };
    },
    handleSetInvitations(state, action) {
      return { ...state, invitations: action.payload };
    },
    handleSetEmployees(state, action) {
      return { ...state, employees: action.payload };
    },
    handleSetGridFilters(state, action) {
      const start = moment(action.payload.date);
      const end = moment(action.payload.date).add(action.payload.time, action.payload.timeUnit);
      const range = moment.range(start, end);
      const daterange = Array.from(range.by('day'));
      return { ...state, gridFilters: action.payload, daterange: [...daterange] };
    },
    handleSetShiftModal(state, action) {
      return {
        ...state,
        modal_open: action.payload.modal_open,
        selectedPosition: action.payload.selectedPosition,
        selectedDate: action.payload.selectedDate,
        selectedCellData: action.payload.selectedCellData,
        shiftModalDisplay: action.payload.shiftModalDisplay,
        selectedEmployee: action.payload.selectedEmployee || null
      };
    },
    handleSetInvitationModal(state, action) {
      return {
        ...state,
        invitation_modal_open: action.payload.invitation_modal_open,
        selectedPosition: action.payload.selectedPosition,
        selectedDate: action.payload.selectedDate,
        selectedCellData: action.payload.selectedCellData,
      };
    },
    handleToggleLocation(state, action) {
      const locations: any[] = state.locations.map((location, i) => i === action.payload ? { ...location, isOpen: !location.isOpen } : location)
      const _location = locations[action.payload]
      const index = action.payload + 1
      if (_location.isOpen) {
        _location.data.positions.map((position: any) => {
          let positionName = ""
          if (position?.abbreviation) {
            positionName = position.abbreviation
          } else {
            positionName = position?.name
          }
          positionName += ` - ${_location.data?.name} (${formatTime(position?.start_time)} - ${formatTime(position?.end_time)})`
          locations.splice(index, 0, {
            fieldType: "position",
            isOpen: true,
            label: positionName,
            data: {
              ...position,
              location: {
                id: _location.data.id,
                name: _location.data.name
              }
            }
          });
        })
      } else {
        const endIndex = index + _location.data.positions.length
        locations.splice(index, endIndex - index);
      }
      return {
        ...state,
        locations
      }
    },
    handleToggleExpandAll(state, action) {
      const locations: any[] = state.locations.map((location, i) => { return { ...location, isOpen: !state.expandAll } }) //toggle primary isOpen state for all locations
      let newLocations: any[] = []
      locations.filter(location => location.fieldType === "location").map((_location, i) =>{ //ignore already opened locations
        if (_location.isOpen) { //open accordion
          const positions: any[] = JSON.parse(JSON.stringify((_location.data.positions || [])))
          newLocations.push(_location)
          positions.map((position: any) => {
            let positionName = ""
            if (position?.abbreviation) {
              positionName = position.abbreviation
            } else {
              positionName = position?.name
            }
            positionName += ` - ${_location.data?.name} (${formatTime(position?.start_time)} - ${formatTime(position?.end_time)})`
            newLocations.push({ //inject position fieldType for every locations
              fieldType: "position",
              isOpen: true,
              label: positionName,
              data: {
                ...position,
                location: {
                  id: _location.data.id,
                  name: _location.data.name
                }
              }
            })
          })
        } else if(_location.fieldType === "location"){ //close accordion
          newLocations = locations.filter(location => location.fieldType === "location") //remove all position fieldType
        }
      })
      return {
        ...state,
        expandAll: !state.expandAll,
        locations: newLocations
      }
    }
  }
});

export const schedulerState = (state: any) => state.scheduler;
export const { handleSetPositions, handleSetLocations, handleSetJobOpenings, handleSetJobRequests, handleSetInvitations, handleSetEmployees, handleSetGridFilters, handleSetShiftModal, handleToggleLocation, handleSetInvitationModal, handleToggleExpandAll } = schedulerSlice.actions
export default schedulerSlice.reducer;
import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";

import { FiArrowLeft } from "react-icons/fi";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "../../../../helpers/index";

//GraphQL
import {
  useQuery,
  useMutation,
  useApolloClient,
} from "@apollo/client";

import { UpdateCustomer } from "gql/profile/mutation";
import { FetchDocuments } from "gql/documents/query";
import { UpdateDocument, InsertDocumentOne } from "gql/documents/mutation";


//helpers
import { getImage } from "helpers";

//type
import DocumentType from "./documents.types";
import { customerInputType } from "pages/MarketPlaceProfile/customer.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//data
import { I9Enums } from "constants/documentCategories";


const DocumentList: React.FC<{
  queryCondition: any;
  user: any;
  onBack: () => void;
}> = (props) => {
  const { user } = props;
  const {
    auth: { token },
  } = useSelector(authState);
  const client = useApolloClient();
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [UpdateDocumentMutation] = useMutation(UpdateDocument);
  const [InsertDocumentOneMutation] = useMutation(InsertDocumentOne);
  const [images, setImages] = useState<string[]>([]);

  const initialCondition = {
    practioner_id: { _eq: props.user.id },
    category: { _in: I9Enums },
  };
  const [UpdateEmployeeMutation, { loading: loadingUpdateEmployee }] =
    useMutation(UpdateCustomer);
  const { loading, data, refetch, error, called } = useQuery(FetchDocuments, {
    variables: {
      where: initialCondition,
    },
  });

  const handleGetImages = async (documents: DocumentType[]) => {
    const promises: any[] = [];
    documents?.map((document: DocumentType) => {
      if (document.document_url?.length) {
        promises.push(getImage(document.document_url, token, "image"));
      }
    });
    const _images = await Promise.all(promises);
    setImages(_images);
  };

  useEffect(() => {
    if (data?.documents) {
      handleGetImages(data?.documents);
    }
  }, [data]);
  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (user && user.firstname) || "",
      lastname: (user && user.lastname) || "",
      doorNo: (user && user?.doorNo) || "",
      streetName: (user && user?.streetName) || "",
      cityName: (user && user?.city) || "",
      dob: (user && user.dob) || "",
      representativeFirstname:
        (user && user.representative_info.firstName) || "",
      representativeLastname: (user && user.representative_info.lastName) || "",
      representativeEmail: (user && user.representative_info.email) || "",
      representativePhone: (user && user.representative_info.phoneNumber) || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter First Name"),
      lastname: Yup.string().required("Please Enter Last Name"),
    }),
    onSubmit: async (values) => {
      console.log("working");
      await UpdateEmployeeMutation({
        variables: {
          id: user?.id,
          object: {
            firstname: values.firstname,
            lastname: values.lastname,
            address: {
              doorNo: values.doorNo,
              streetName: values.streetName,
              cityName: values.cityName,
            },
            dob: values.dob,
            representative_info: {
              firstName: values.representativeFirstname,
              lastName: values.representativeLastname,
              email: values.representativeEmail,
              phoneNumber: values.representativePhone,
            },
          },
        },
      });
      // Swal.fire("Staff edited successfully", "", "success")
      showAlert({
        title: "Updated!",
        message: "Staff edited successfully",
        type: "success",
      });
      refetch();
    },
  });
  const handleOpenFilePicker = () => {
    // setDocumentType(fileType)
    uploadRef?.current?.click();
  };

  const [file,setFile]=useState('')
    
  const handleChange=(e: any)=>{
    const data= e.target.files[0]
    setFile(data)
    console.log(data)
  }
  return (
    <Row>
      <Col lg="12">
        <Col xl="12">
          <div className="table-responsive">
            {/* <div onClick={() => props.onBack()} className="mb-3">
              <FiArrowLeft size={20} />
            </div> */}
            <div className="cursor-pointer d-flex justify-content-between mb-3">
              <div onClick={() => props.onBack()}>
                <FiArrowLeft size={20} />
              </div>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col xs={12}>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>

                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Label className="form-label mb-3 h4">Address</Label>
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Door No.</Label>
                        <Input
                          name="doorNo"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.doorNo || ""}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Street Address</Label>
                        <Input
                          name="streetName"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.streetName || ""}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">City</Label>
                        <Input
                          name="city"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cityName || ""}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Date of Birth</Label>
                        <Input
                          name="available_from"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            moment(validation.values.dob).format(
                              "YYYY-MM-DD"
                            ) || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Social Security Number
                        </Label>
                        <Input
                          name="ssn"
                          label="ssn"
                          value={user.ssn}
                          type="number"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          value={user.email}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Telephone Number</Label>
                        <Input
                          name="contactno1"
                          label="Phone"
                          type="text"
                          value={user.contactno1}
                          disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Label className="form-label mb-3 h4">
                    Representative Info
                  </Label>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Representative FirstName
                        </Label>
                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.representativeFirstname || ""
                          }
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Representative LastName
                        </Label>
                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.representativeLastname || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Representative Email
                        </Label>
                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.representativeEmail || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Representative Phone Number
                        </Label>
                        <Input
                          name="lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.representativePhone || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {/* <Label className="form-label mb-3 h4">List A</Label>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Document from List A
                        </Label>
                        <Input name="email" label="Email" type="text" />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Issuing Authority</Label>
                        <Input name="contactno1" label="Phone" type="text" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Document Number</Label>
                        <Input name="email" label="Email" type="number" />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Expiration Date</Label>
                        <DatePicker oneTap style={{ width: 500 }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <div
                          style={{
                            height: 100,
                            width: "100%",
                            borderWidth: 1,
                            borderStyle: "dashed",
                          }}
                        ></div>
                      </div>
                    </Col>
                  </Row> */}

                  <Label className="form-label mb-3 h4">List B and C</Label>
                  <Row>
                    {data?.documents.map((data: any, i: any) => (
                      <Col xs={6} key={i}>
                        <Row>
                          <div className="mb-3">
                            <Label className="form-label">
                              Document from {data.document_info?.listType}
                            </Label>
                            <Input
                              name="email"
                              label="Email"
                              type="text"
                              value={data.document_info.documentName}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Label className="form-label">
                              Issuing Authority
                            </Label>
                            <Input
                              name="contactno1"
                              label="Phone"
                              type="text"
                              value={data.document_info.issusingAuthority}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Label className="form-label">
                              Document Number
                            </Label>
                            <Input
                              name="email"
                              label="Email"
                              type="number"
                              value={data.document_info.documentNumber}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Label className="form-label">
                              Expiration Date
                            </Label>
                            <Input
                              name="available_from"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                moment(data.document_info.expireDate).format(
                                  "YYYY-MM-DD"
                                ) || ""
                              }
                              invalid={
                                validation.touched.available_from &&
                                validation.errors.available_from
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Button
                              style={{
                                height: 245,
                                width: "100%",
                                borderWidth: 1,
                                borderStyle: "dashed",
                              }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleOpenFilePicker()}
                            >
                              {images[i]?.length > 0 && (
                                <img src={images[i]} height="240" />
                              )}
                            </Button>
                          </div>
                          <input
                            type="file"
                            id="file"
                            ref={uploadRef}
                            style={{ display: "none" }}
                            onChange={handleChange}
                            accept="image/*, application/pdf"
                          />
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-1 mb-3">
                <Button
                  className="mx-2 p-2 d-flex justify-content flex-end"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Col>
    </Row>
  );
};
export default DocumentList;

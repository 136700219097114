import React, { useState } from "react";
import { Nav } from "rsuite";

// component
import Reviews from "./Reviews";
import Education from "./Education";
import Certifications from "./Certifications";
import More from "./More";
import ProfessionalExperience from "./ProfessionalExperience";
import Licenses from "./Licenses";
import NotesTable from "./notes/NotesTable";
import ReferencesTable from "./references/ReferencesTable";
import UploadLicenseModal from "components/documents/UploadLicenseModal";
import FacilityTable from "pages/Admin/Facilities/FacilityTable";
import BlockedFacilityModal from "components/blockedFacilities/BlockedFacilityModal";
import RatesTable from "./rates/RatesTable";

//graphql
import { useApolloClient } from "@apollo/client";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import { Button } from "reactstrap";

const MarketPlaceProfileTabs: React.FC<{
  // activeTab: string;
  // setActiveTab: () => void
  user: any;
}> = (
  // activeTab, setActiveTab,
  { user }
) => {
  const client = useApolloClient();
  const {
    auth: {
      user: { role },
    },
  } = useSelector(authState);
  const [selectedTab, setSelectedTab] = useState<string>("professional_exp");
  const [licenseModalOpen, setLicenseModalOpen] = useState<boolean>(false);
  const [blockedFacilityOpen, setBlockedFacilityOpen] =
    useState<boolean>(false);

  const tabs = [
    { title: "Professional Experience", value: "professional_exp" },
    { title: "Education", value: "education" },
    { title: "Licenses", value: "licenses" },
    { title: "Certifications", value: "certifications" },
    // { title: "Reviews", value: "reviews" },
    // { title: "More", value: "more" },
  ];

  const _selectTab = (val: string) => {
    setSelectedTab(val);
  };
  return (
    <>
      <div className="MarketPlaceProfileTabs">
        {/* <Nav activeKey={activeTab} reversed onSelect={setActiveTab} appearance="subtle">
          {tabs.map((tab, i) => (
            <Nav.Item eventKey={tab.value} key={i} className="front-size-16" >
              {tab.title}
            </Nav.Item>
          ))}
        </Nav> */}
        {tabs.map((tab, i) => (
          <div
            onClick={() => {
              _selectTab(tab.value);
            }}
            key={i}
            className={
              selectedTab == tab.value
                ? "active font-size-16 MarketPlaceProfileTabs_tab"
                : "font-size-16 MarketPlaceProfileTabs_tab"
            }
          >
            {tab.title}
          </div>
        ))}
        {role === "moderator" && (
          <>
            <div
              onClick={() => {
                _selectTab("notes");
              }}
              className={
                selectedTab == "notes"
                  ? "active font-size-16 MarketPlaceProfileTabs_tab"
                  : "font-size-16 MarketPlaceProfileTabs_tab"
              }
            >
              Notes
            </div>
            <div
              onClick={() => {
                _selectTab("references");
              }}
              className={
                selectedTab == "references"
                  ? "active font-size-16 MarketPlaceProfileTabs_tab"
                  : "font-size-16 MarketPlaceProfileTabs_tab"
              }
            >
              References
            </div>
            <div
              onClick={() => {
                _selectTab("blocked_facilities");
              }}
              className={
                selectedTab == "blocked_facilities"
                  ? "active font-size-16 MarketPlaceProfileTabs_tab"
                  : "font-size-16 MarketPlaceProfileTabs_tab"
              }
            >
              Blocked Facilities
            </div>
            <div
              onClick={() => {
                _selectTab("rates");
              }}
              className={
                selectedTab == "rates"
                  ? "active font-size-16 MarketPlaceProfileTabs_tab"
                  : "font-size-16 MarketPlaceProfileTabs_tab"
              }
            >
              Rates
            </div>
          </>
        )}
      </div>

      {selectedTab == "professional_exp" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">Professional Experience</h4>
          <ProfessionalExperience user={user} />
        </div>
      )}

      {selectedTab == "education" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">Education</h4>
          <Education user={user} />
        </div>
      )}

      {selectedTab == "licenses" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          {role === "moderator" ? (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                borderBottom: "1px solid #E4E6EF",
                marginBottom: 10,
              }}
            >
              <h4 className="information_mainheader" style={{ border: "none" }}>
                Licenses
              </h4>
              <Button
                type="button"
                color="primary"
                onClick={() => setLicenseModalOpen(true)}
              >
                Upload
              </Button>
            </div>
          ) : (
            <h4 className="information_mainheader">Licenses</h4>
          )}
          <Licenses user={user} />
        </div>
      )}

      {selectedTab == "certifications" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">
            Certifications{" "}
            {user?.onboarding_documents_verified && (
              <i className="bx bx-badge-check text-success"></i>
            )}
          </h4>
          <Certifications user={user} />
        </div>
      )}

      {selectedTab == "notes" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          <h4 className="information_mainheader">Notes</h4>
          <NotesTable user={user} queryCondition={null} />
        </div>
      )}

      {selectedTab == "references" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">References</h4>
          <ReferencesTable user={user} />
        </div>
      )}

      {selectedTab == "blocked_facilities" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              borderBottom: "1px solid #E4E6EF",
              marginBottom: 10,
            }}
          >
            <h4 className="information_mainheader" style={{ border: "none" }}>
              Blocked Facilities
            </h4>
            <Button
              type="button"
              color="primary"
              onClick={() => setBlockedFacilityOpen(true)}
            >
              Add New Facilities
            </Button>
          </div>
          <FacilityTable
            queryCondition={{
              id: { _in: user.blocked_facilities },
            }}
          />
        </div>
      )}

      {selectedTab == "rates" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body">
          <h4 className="information_mainheader">Rates</h4>
          <RatesTable user={user} queryCondition={null} />
        </div>
      )}

      {selectedTab == "more" && (
        <div className="MarketPlaceProfile_card MarketPlaceProfileTabs_body ">
          <h4 className="information_mainheader">More</h4>
          <More user={user} />
        </div>
      )}
      <UploadLicenseModal
        isOpen={licenseModalOpen}
        toggle={() => setLicenseModalOpen(false)}
        user={user}
      />
      <BlockedFacilityModal
        isOpen={blockedFacilityOpen}
        toggle={() => setBlockedFacilityOpen(false)}
        refetch={() => {
          client.refetchQueries({
            include: ["FetchCustomerDetailsAdmin"],
          });
        }}
        facilities={user.blocked_facilities}
      />
    </>
  );
};

export default MarketPlaceProfileTabs;

import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal, Label, Input, FormFeedback } from "reactstrap";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { SelectPicker, Nav, Checkbox } from "rsuite";

import SelectPickerPaginate from "components/Filters/SelectPickerPaginate";
import RequestTable from "pages/Requests/RequestTable";
import EmployeeDetails from "./EmployeeDetails";
import NurseDetails from "./NurseDetails";
import InvitationModal from "components/invitation/InvitationModal";
import InvitationTable from "pages/Invitations/InvitationTable";

//helpers
import {
  enumerateDaysBetweenDates,
  isSameDay,
  getShiftStatus,
} from "../../helpers/index";

//GraphQL
import {
  FetchPositionsForFilter,
  FetchPositionById,
} from "gql/positions/query";
import { FetchEmployeesForFilter } from "gql/employees/query";
import { FetchJobOpeningsForScheduler } from "gql/jobOpenings/query";
import {
  InsertJobOpeningOne,
  UpdateJobOpening,
  UpdateJobOpeningBulk,
} from "gql/jobOpenings/mutation";
import {
  InsertJobRequests,
  UpdateJobRequest,
  UpdateJobRequestByField,
} from "gql/jobRequests/mutation";
import { UpdateInvitation } from "gql/invitations/mutation";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";

//redux
import { useSelector } from "react-redux";
import { profileState } from "toolkit/profile/profile.slice";
import { authState } from "toolkit/auth/auth.slice";
import { schedulerState } from "toolkit/scheduler/scheduler.slice";

const shiftTypeData = [
  { label: "Internal Employee", value: "employee" },
  { label: "Caregigs Marketplace", value: "marketplace" },
  { label: "Open Shift", value: "open_shift" },
];
const initialState: any = {
  fee_amount: "",
  currency: "",
  fee_period: "",
  facility_details: "",
  special_instructions: "",
  shiftType: "",
  employee: "",
  position_id: "",
  available_from: new Date(),
  available_till: new Date(),
};

const CreateShift: React.FC<{
  modal_center: boolean;
  tog_center: () => void;
  position: any;
  selectedDate: Date;
  selectedCellData: any;
  display: string;
  selectedEmployee: any;
}> = ({
  modal_center,
  tog_center,
  position,
  selectedDate,
  selectedCellData,
  display,
  selectedEmployee,
}) => {
  const client = useApolloClient();
  const { profile } = useSelector(profileState);
  const {
    auth: { user },
  } = useSelector(authState);
  const { employees } = useSelector(schedulerState);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [selectedPosition, setSelectedPosition] = useState<any>();
  const [state, setState] = useState<any>(initialState);
  const [activeTab, setActiveTab] = useState("shift_detail");
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<undefined | string>();
  const [isRecurring, setRecurring] = useState<boolean>(false);
  const [CreateJobOpeningMutation, { loading, error }] =
    useMutation(InsertJobOpeningOne);
  const [CreateJobRequestMutation, { loading: loadingJobRequest }] =
    useMutation(InsertJobRequests);
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);
  const [UpdateJobOpeningBulkMutation] = useMutation(UpdateJobOpeningBulk);
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);
  const [UpdateJobRequestByFieldMutation] = useMutation(
    UpdateJobRequestByField
  );
  const [UpdateInvitationMutation] = useMutation(UpdateInvitation);

  const handleReset = () => {
    setState({
      ...state,
      shiftType: "",
      position_id: "",
      available_from: selectedDate,
      available_till: selectedDate,
      employee: "",
      special_instructions: "",
      fee_amount: "",
    });
    setSelectedPosition(undefined);
    setActiveTab("shift_detail");
  };
  const handleRefetch = () => {
    client.refetchQueries({
      include: [
        "FetchJobOpeningsForScheduler",
        "FetchJobRequestsForScheduler",
        "FetchInvitationsForScheduler",
      ],
    });
    handleReset();
  };
  const handleClose = () => {
    tog_center();
    handleReset();
  };
  const handleCreateShift = async (dates: Date[], values: any) => {
    const overlappingOpenShifts = await checkOpenShifts(dates, values);
    const objects: any[] = [];
    let location_id = "";
    const resp = await client.query({
      query: FetchPositionsForFilter,
      variables: {
        limit: 1,
        offset: 0,
        where: { id: { _eq: values.position_id } },
      },
    });
    if (Array.isArray(resp.data?.positions) && resp.data?.positions?.length) {
      location_id = resp.data?.positions[0]?.location?.id;
    }
    dates.map((date: Date) => {
      objects.push({
        name: "N/A",
        // name: values.name,
        location_id,
        isactive: values.shiftType === "marketplace" ? true : false,
        is_open_shift: values.shiftType === "open_shift" ? true : false,
        fee_amount: feeAmount || 0,
        currency: "USD",
        fee_period: "per_hour",
        job_date: moment(date).format("YYYY-MM-DD"),
        requirement: {
          facility_details: [""],
          special_instructions: [values.special_instructions],
        },
        position_id: values.position_id,
        organisation_id: profile.organisation_id,
      });
    });
    try {
      const resp = await CreateJobOpeningMutation({
        variables: {
          objects,
        },
      });
      if (overlappingOpenShifts.length) {
        //overriding overlapping open shifts
        await UpdateJobOpeningBulkMutation({
          variables: {
            ids: overlappingOpenShifts.map((ovShift: any) => ovShift.id),
            object: {
              is_open_shift: false,
            },
          },
        });
      }
      if (resp?.data && values.shiftType === "employee") {
        const jobOpeningIds = resp.data?.insert_job_openings?.returning;
        if (jobOpeningIds.length && Array.isArray(jobOpeningIds)) {
          const jobRequestObjects: any[] = [];
          jobOpeningIds.map((joID) => {
            jobRequestObjects.push({
              job_opening_id: joID.id,
              job_date: joID.job_date,
              status: "approved",
              employee_id: values.employee,
            });
          });
          await CreateJobRequestMutation({
            variables: {
              objects: jobRequestObjects,
            },
          });
          handleClose();
          handleRefetch();
          Swal.fire("Shift Created Successfully", "", "success");
        }
      } else {
        handleClose();
        handleRefetch();
        Swal.fire("Shift Created Successfully", "", "success");
      }
    } catch (err) {
      Swal.fire("Something went wrong", "", "warning");
    }
  };
  const handleUpdateShift = async (values: any) => {
    let jobOpeningObject = {};
    const jobRequestObject: any = {};
    jobRequestObject.job_date = moment(values.available_from).format(
      "YYYY-MM-DD"
    );
    if (values.shiftType === "employee") {
      //remove marketplace nurse and assign internal employee in the job
      jobRequestObject.employee_id = values.employee;
      jobRequestObject.practitioner_id = null;
    } else if (values.shiftType === "marketplace") {
      //delete existing job request if facility puts the job in marketplace again
      jobRequestObject.active = false;
    } else if (values.shiftType === "open_shift") {
      //remove marketplace nurse/internal employee from the job and remove from app listing
      jobRequestObject.employee_id = null;
      jobRequestObject.practitioner_id = null;
      jobRequestObject.active = false;
    }
    jobOpeningObject = {
      isactive: values.shiftType === "marketplace" ? true : false, //isactive true means the job will list in the app
      fee_amount: values.fee_amount,
      position_id: values.position_id,
      requirement: {
        facility_details: [""],
        special_instructions: [values.special_instructions],
      },
      job_date: moment(values.available_from).format("YYYY-MM-DD"),
      is_open_shift: values.shiftType === "open_shift" ? true : false,
    };
    try {
      const promises: any[] = [];
      promises.push(
        UpdateJobOpeningMutation({
          variables: {
            object: jobOpeningObject,
            id:
              selectedCellData.type === "jobOpening"
                ? selectedCellData.data?.id
                : selectedCellData.data?.job_opening?.id,
          },
        })
      );
      if (
        selectedCellData.type === "jobOpening" &&
        values.shiftType === "employee"
      ) {
        promises.push(
          CreateJobRequestMutation({
            variables: {
              objects: [
                {
                  job_opening_id: selectedCellData.data?.id,
                  status: "approved",
                  employee_id: values.employee,
                  job_date: moment(values.available_from).format("YYYY-MM-DD"),
                },
              ],
            },
          })
        );
      } else if (selectedCellData.type === "jobRequest") {
        promises.push(
          UpdateJobRequestMutation({
            variables: {
              object: jobRequestObject,
              id: selectedCellData.data?.id,
            },
          })
        );
      }

      await Promise.all(promises);
      handleClose();
      handleRefetch();
      Swal.fire("Shift Updated Successfully", "", "success");
    } catch (err) {
      Swal.fire("Something went wrong", "", "warning");
    }
  };
  const checkDateOverlap = async (dates: Date[], values: any) => {
    try {
      const resp = await client.query({
        query: FetchJobOpeningsForScheduler,
        variables: {
          where: {
            createdby: { _eq: user.id },
            job_date: {
              _in: dates.map((date) => moment(date).format("YYYY-MM-DD")),
            },
            position_id: { _eq: values.position_id },
            isactive: { _eq: true },
          },
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_openings || [];
    } catch (err) {
      return null;
    }
  };
  const checkOpenShifts = async (dates: Date[], values: any) => {
    try {
      const resp = await client.query({
        query: FetchJobOpeningsForScheduler,
        variables: {
          where: {
            createdby: { _eq: user.id },
            job_date: {
              _in: dates.map((date) => moment(date).format("YYYY-MM-DD")),
            },
            position_id: { _eq: values.position_id },
            isactive: { _eq: false },
            is_open_shift: { _eq: true },
          },
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_openings || [];
    } catch (err) {
      return null;
    }
  };
  const checkTimeOverlapForEmployee = async (dates: Date[], values: any) => {
    try {
      const resp = await client.query({
        query: FetchJobOpeningsForScheduler,
        variables: {
          where: {
            createdby: { _eq: user.id },
            job_date: {
              _in: dates.map((date) => moment(date).format("YYYY-MM-DD")),
            },
            job_requests: { employee_id: { _eq: values.employee } },
          },
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_openings || [];
    } catch (err) {
      return null;
    }
  };
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // fee_amount: (state && state.fee_amount) || '',
      currency: (state && state.currency) || "USD",
      // facility_details: (state && state.facility_details) || '',
      special_instructions: (state && state.special_instructions) || "",
      shiftType: (state && state.shiftType) || "",
      employee: (state && state.employee) || "",
      position_id: (state && state.position_id) || "",
      available_from: (state && state.available_from) || new Date(),
      available_till: (state && state.available_till) || new Date(),
    },
    validationSchema: Yup.object({
      // fee_amount: Yup.number().min(0).required("Please Enter Rate"),
      // fee_amount: Yup.number().min(0).when("shiftType", {
      // 	is: "marketplace",
      // 	then: Yup.number().min(0).required("Please Enter Rate")
      // }),
      // facility_details: Yup.string().required("Please Enter Facility Details"),
      // special_instructions: Yup.string().required("Please Enter Special Instructions"),
      shiftType: Yup.string().required("Please Select Shift Type"),
      employee: Yup.string().when("shiftType", {
        is: "employee",
        then: Yup.string().required("Please Select Employee"),
      }),
      position_id: Yup.string().required("Please Select Position"),
      available_from: Yup.date().min(today, "Past Date Selection Not Allowed"),
      available_till: Yup.date()
        .min(today, "Past Date Selection Not Allowed")
        .test(
          "available_till_test",
          "Shift End Date Should Not be Prior to Start Date",
          function (value) {
            const { available_from } = this.parent;
            if (isRecurring) {
              return !moment(value).isBefore(available_from);
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: async (values) => {
      if (selectedCellData !== null && selectedCellData?.type) {
        //for editing shift
        if (
          (selectedCellData.type === "jobOpening" &&
            values.shiftType === "employee") ||
          (selectedCellData.type === "jobRequest" &&
            values.shiftType === "marketplace")
        ) {
          //toggle between marketplace to internal employee or vice-versa
          Swal.fire({
            title: `Are you sure want to update this shift?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: "warning",
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (!isSameDay(selectedDate, new Date(values.available_from))) {
                //if job date is changed >> check for date overlap
                const overlappingShifts = await checkDateOverlap(
                  [new Date(values.available_from)],
                  values
                );
                if (
                  Array.isArray(overlappingShifts) &&
                  overlappingShifts?.length > 0
                ) {
                  Swal.fire(
                    "Shift Date is Overlapping",
                    "Please edit/delete existing shift to continue",
                    "warning"
                  );
                  return;
                }
              }
              handleUpdateShift(values);
            }
          });
        } else {
          //update shift without updating "Employee Type"
          if (!isSameDay(selectedDate, new Date(values.available_from))) {
            const overlappingShifts = await checkDateOverlap(
              [new Date(values.available_from)],
              values
            );
            if (
              Array.isArray(overlappingShifts) &&
              overlappingShifts?.length > 0
            ) {
              Swal.fire(
                "Shift Date is Overlapping",
                "Please edit/delete existing shift to continue",
                "warning"
              );
              return;
            }
          }
          handleUpdateShift(values);
        }
      } else {
        //for creating new shift
        const dates = enumerateDaysBetweenDates(
          values.available_from,
          isRecurring ? values.available_till : values.available_from
        );
        const overlappingShifts = await checkDateOverlap(dates, values);
        if (Array.isArray(overlappingShifts) && overlappingShifts?.length > 0) {
          //check for overlapping shifts
          Swal.fire({
            title: `Some Shift Dates are Overlapping!`,
            text: "Do you want to create other non-overlapping shifts?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: "warning",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const nonOverlappingDates: Date[] = [];
              const overlappingDates: string[] = overlappingShifts.map(
                (_shift) => _shift.job_date
              );
              dates.map((date) => {
                if (
                  !overlappingDates.includes(moment(date).format("YYYY-MM-DD"))
                ) {
                  nonOverlappingDates.push(date);
                }
              });
              handleCreateShift(nonOverlappingDates, values);
            }
          });
        } else {
          handleCreateShift(dates, values);
        }
      }
    },
  });
  const onDeleteShift = () => {
    Swal.fire({
      title: `Are you sure want to delete this shift?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(selectedCellData);
        if (selectedCellData.type === "jobOpening") {
          //delete marketplace shift
          const promises: any[] = [];
          const jobOpeningObj: {
            isactive: boolean;
            is_open_shift?: boolean;
          } = {
            isactive: false,
          };
          if (selectedCellData.data?.is_open_shift) {
            jobOpeningObj.is_open_shift = false;
          }
          promises.push(
            UpdateJobOpeningMutation({
              variables: {
                object: jobOpeningObj,
                id: selectedCellData.data?.id,
              },
            })
          );
          promises.push(
            UpdateJobRequestByFieldMutation({
              variables: {
                object: {
                  active: false,
                },
                where: {
                  job_opening_id: { _eq: selectedCellData.data?.id },
                },
              },
            })
          );
          await Promise.all(promises);
        } else if (selectedCellData.type === "jobRequest") {
          //delete internal/caregig emp shift
          await UpdateJobRequestMutation({
            variables: {
              object: {
                active: false,
              },
              id: selectedCellData.data?.id,
            },
          });
        } else if (selectedCellData.type === "invitation") {
          //delete invitation
          await UpdateInvitationMutation({
            variables: {
              object: {
                isactive: false,
              },
              id: selectedCellData.data?.id,
            },
          });
        }
        handleClose();
        handleRefetch();
        Swal.fire("Shift Deleted Successfully", "", "success");
      }
    });
  };
  const handleSelectPosition = async (newValue: string) => {
    validation.setFieldValue("position_id", newValue);
    const resp = await client.query({
      query: FetchPositionsForFilter,
      variables: {
        limit: 1,
        offset: 0,
        where: { id: { _eq: newValue } },
      },
    });
    if (Array.isArray(resp.data?.positions) && resp.data?.positions?.length) {
      setSelectedPosition(resp.data?.positions[0]);
    }
  };
  const isEditable = moment(selectedDate).isSameOrAfter(new Date(), "day");
  // useEffect(() => {
  // 	if (position) {
  // 		setPositionId(position.id)
  // 	}
  // }, [position])
  useEffect(() => {
    if (selectedCellData) {
      if (selectedCellData.type === "jobRequest" && selectedCellData.data) {
        setState({
          ...state,
          fee_amount: String(selectedCellData?.data?.job_opening?.fee_amount),
          currency: "USD",
          fee_period: "per_hour",
          facility_details:
            selectedCellData?.data?.job_opening?.requirement
              ?.facility_details[0] || "",
          special_instructions:
            selectedCellData?.data?.job_opening?.requirement
              ?.special_instructions[0] || "",
          shiftType: selectedCellData.data?.is_open_shift
            ? "open_shift"
            : selectedCellData.isNurseConnect
            ? "marketplace"
            : "employee",
          employee: selectedCellData.data?.employee?.id,
          position_id: position?.id || "",
          available_from: selectedDate,
          available_till: selectedDate,
        });
        // if (selectedCellData.data?.employee) {
        // 	setEmployee(selectedCellData.data?.employee?.id)
        // }
      } else if (
        selectedCellData.type === "jobOpening" &&
        selectedCellData.data
      ) {
        setState({
          ...state,
          fee_amount: String(selectedCellData?.data?.fee_amount),
          currency: "USD",
          fee_period: "per_hour",
          facility_details:
            selectedCellData?.data?.requirement?.facility_details[0] || "",
          special_instructions:
            selectedCellData?.data?.requirement?.special_instructions[0] || "",
          shiftType: selectedCellData.data?.is_open_shift
            ? "open_shift"
            : selectedCellData.isNurseConnect
            ? "marketplace"
            : "employee",
          position_id: position?.id || "",
          available_from: selectedDate,
          available_till: selectedDate,
        });
      } else {
        setState({
          ...state,
          position_id: position?.id || "",
          available_from: selectedDate,
          available_till: selectedDate,
        });
      }
    } else {
      setState({
        ...state,
        position_id: position?.id || "",
        available_from: selectedDate,
        available_till: selectedDate,
      });
    }
    setSelectedPosition(position);
  }, [selectedCellData, position]);
  useEffect(() => {
    if (display === "staff" && selectedEmployee) {
      setState({
        ...state,
        shiftType: "employee",
        employee: selectedEmployee?.id,
        position_id: position?.id || "",
        available_from: selectedDate,
        available_till: selectedDate,
      });
      // setEmployee(selectedEmployee?.id)
    }
  }, [display, selectedEmployee]);

  const showSidePanel =
    selectedCellData !== null && selectedCellData?.type !== "jobOpening";

  const [feeAmount, setFeeAmount] = useState<any>(null);

  useQuery(FetchPositionById, {
    variables: {
      id: validation.values.position_id,
    },
    onCompleted: (data) => {
      setFeeAmount(data?.positions_by_pk?.contract_rate);
    },
  });

  return (
    <>
      <Modal
        size={showSidePanel ? "xl" : "lg"}
        isOpen={modal_center}
        toggle={() => {
          handleClose();
        }}
        centered
        // scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {selectedCellData === null ? "Create Shift" : "Update Shift"}
          </h5>
          <button
            type="button"
            onClick={() => {
              handleClose();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {selectedCellData?.type === "jobOpening" &&
            validation.values.shiftType !== "open_shift" && (
              <Row>
                <Col
                  lg="12"
                  className="d-flex justify-content-center align-items-center mb-3"
                >
                  <Nav
                    activeKey={activeTab}
                    onSelect={setActiveTab}
                    appearance="subtle"
                  >
                    <Nav.Item eventKey="shift_detail">Shift Detail</Nav.Item>
                    <Nav.Item eventKey="gig_requests">Gig Requests</Nav.Item>
                    <Nav.Item eventKey="invitations">Invitations</Nav.Item>
                  </Nav>
                </Col>
              </Row>
            )}
          {activeTab === "shift_detail" ? (
            <Row>
              <Col md={showSidePanel ? 6 : 12}>
                {selectedCellData !== null &&
                  validation.values.shiftType === "marketplace" && (
                    <div className="mb-3">
                      <Label htmlFor="formrow-assignto-Input">
                        Shift Status
                      </Label>
                      <div>{getShiftStatus(selectedCellData)}</div>
                    </div>
                  )}
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {!showSidePanel && isEditable && (
                    <div className="mb-3">
                      <Label htmlFor="formrow-assignto-Input">
                        On-Demand Staffing Made Easy
                      </Label>
                      <div>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            handleClose();
                            setInviteModalOpen(true);
                          }}
                        >
                          Invite Qualified Professionals
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="mb-3">
                    <Label htmlFor="formrow-assignto-Input">
                      Employee Type
                    </Label>
                    <div>
                      <SelectPicker
                        data={shiftTypeData}
                        value={validation.values.shiftType}
                        onChange={(newValue) =>
                          validation.setFieldValue("shiftType", newValue)
                        }
                        menuStyle={{ zIndex: 9999 }}
                        style={{ width: "100%" }}
                        searchable={false}
                        cleanable={false}
                        disabled={!isEditable}
                        name="shiftType"
                        className={
                          validation.touched.shiftType &&
                          validation.errors.shiftType
                            ? "border-danger"
                            : ""
                        }
                      />
                      {validation.touched.shiftType &&
                      validation.errors.shiftType ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.shiftType}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {validation.values.shiftType === "employee" && (
                    <div className="mb-3">
                      <Label htmlFor="formrow-assignto-Input">Assign to</Label>
                      <SelectPickerPaginate
                        query={FetchEmployeesForFilter}
                        placeholder="Select..."
                        // value={employee}
                        // onChange={(_employee: any) => setEmployee(_employee)}
                        value={validation.values.employee}
                        onChange={(newValue) => {
                          const selectedEmp = employees.filter(
                            (_emp: any) => _emp.id === newValue
                          );
                          if (selectedEmp.length && selectedEmp[0]?.type) {
                            setSelectedType(selectedEmp[0]?.type);
                          }
                          validation.setFieldValue("employee", newValue);
                        }}
                        arrKey="employees"
                        objectLabel="firstname+lastname"
                        objectValue="id"
                        style={{ width: "100%" }}
                        paginate={true}
                        filterCondition={{
                          createdby: { _eq: user?.id },
                          isactive: { _eq: true },
                          location_id:
                            display === "staff"
                              ? undefined
                              : { _eq: selectedPosition?.location?.id },
                          // type:
                          //   display === "staff"
                          //     ? undefined
                          //     : { _eq: selectedPosition?.type },
                        }}
                        getFullObj={false}
                        searchValue={
                          selectedEmployee?.id
                            ? `${selectedEmployee?.firstname} ${selectedEmployee?.lastname}`
                            : `${selectedCellData?.data?.employee?.firstname} ${selectedCellData?.data?.employee?.lastname}`
                        }
                        menuStyle={{ zIndex: 9999 }}
                        disabled={!isEditable}
                        cleanable={false}
                        className={
                          validation.touched.employee &&
                          validation.errors.employee
                            ? "border-danger"
                            : ""
                        }
                      />
                      {validation.touched.employee &&
                      validation.errors.employee ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.employee}
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="mb-3">
                    <Label htmlFor="formrow-position-Input">Position</Label>
                    <SelectPickerPaginate
                      query={FetchPositionsForFilter}
                      placeholder="Select..."
                      value={validation.values.position_id}
                      onChange={(newValue) => {
                        handleSelectPosition(newValue);
                      }}
                      arrKey="positions"
                      objectLabel="name"
                      objectValue="id"
                      style={{ width: "100%" }}
                      paginate={true}
                      filterCondition={{
                        createdby: { _eq: user?.id },
                        active: { _eq: true },
                        type: { _eq: selectedType },
                      }}
                      getFullObj={false}
                      searchValue=""
                      menuStyle={{ zIndex: 9999 }}
                      disabled={!isEditable || selectedCellData !== null}
                      cleanable={false}
                      className={
                        validation.touched.position_id &&
                        validation.errors.position_id
                          ? "border-danger"
                          : ""
                      }
                    />
                    {validation.touched.position_id &&
                    validation.errors.position_id ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {validation.errors.position_id}
                      </div>
                    ) : null}
                  </div>

                  <Row>
                    {/* <Col md={4}>
										<div className="mb-3">
											<Label htmlFor="formrow-email-Input">Start Time</Label>
											<InputGroup>
												<Flatpickr
													className="form-control d-block"
													placeholder="Select time"
													options={{
														enableTime: true,
														noCalendar: true,
														dateFormat: "H:i"
													}}
												/>
												<div className="input-group-append">
													<span className="input-group-text">
														<i className="mdi mdi-clock-outline" />
													</span>
												</div>
											</InputGroup>
										</div>
									</Col>
									<Col md={4}>
										<div className="mb-3">
											<Label htmlFor="formrow-email-Input">End Time</Label>
											<InputGroup>
												<Flatpickr
													className="form-control d-block"
													placeholder="Select time"
													options={{
														enableTime: true,
														noCalendar: true,
														dateFormat: "H:i"
													}}
												/>
												<div className="input-group-append">
													<span className="input-group-text">
														<i className="mdi mdi-clock-outline" />
													</span>
												</div>
											</InputGroup>
										</div>
									</Col>
									<Col md={4}>
										<div className="mb-3">
											<Label htmlFor="formrow-Input">Unpaid Break</Label>
											<Input
												type="text"
												className="form-control"
												id="formrow-Input"
												placeholder="In Minutes"
											/>
										</div>
									</Col> */}
                    {/* {validation.values.shiftType !== "employee" && (
												<Col md={12}>
													<div className="mb-3">
														<Label htmlFor="formrow-Input">Rate ($/hr)</Label>
														<Input
															type="number"
															className="form-control"
															id="formrow-Input"
															name="fee_amount"
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															value={validation.values.fee_amount || ""}
															invalid={
																validation.touched.fee_amount && validation.errors.fee_amount ? true : false
															}
															disabled={!isEditable}
															min={0}
														/>
														{validation.touched.fee_amount && validation.errors.fee_amount ? (
															<FormFeedback type="invalid">{validation.errors.fee_amount}</FormFeedback>
														) : null}
													</div>
												</Col>
											)} */}
                  </Row>
                  {selectedCellData === null ? (
                    <div className="mb-3">
                      <Row>
                        <Col md={isRecurring ? 6 : 12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              {isRecurring
                                ? `Recurring Shift Start Date`
                                : `Shift Date`}
                            </Label>
                            <Input
                              name="available_from"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                moment(validation.values.available_from).format(
                                  "YYYY-MM-DD"
                                ) || ""
                              }
                              invalid={
                                validation.touched.available_from &&
                                validation.errors.available_from
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.available_from &&
                            validation.errors.available_from ? (
                              <FormFeedback type="invalid">
                                {validation.errors.available_from}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        {isRecurring && (
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">
                                Recurring Shift End Date
                              </Label>
                              <Input
                                name="available_till"
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  moment(
                                    validation.values.available_till
                                  ).format("YYYY-MM-DD") || ""
                                }
                                invalid={
                                  validation.touched.available_till &&
                                  validation.errors.available_till
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.available_till &&
                              validation.errors.available_till ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.available_till}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <div className="d-flex align-items-center">
                          <Checkbox
                            checked={isRecurring}
                            onChange={(value: any, checked: boolean) =>
                              setRecurring(checked)
                            }
                            id="recurring_checkbox"
                          />
                          <label
                            htmlFor="recurring_checkbox"
                            style={{ marginBottom: 0 }}
                          >
                            Allow Recurring Shifts
                          </label>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">Shift Date</Label>
                      <Input
                        name="available_from"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          moment(validation.values.available_from).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        invalid={
                          validation.touched.available_from &&
                          validation.errors.available_from
                            ? true
                            : false
                        }
                      />
                      {validation.touched.available_from &&
                      validation.errors.available_from ? (
                        <FormFeedback type="invalid">
                          {validation.errors.available_from}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}

                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">
                          Special Instructions
                        </Label>
                        <Input
                          type="textarea"
                          id="textarea"
                          maxLength={225}
                          rows="3"
                          name="special_instructions"
                          label="Special Instructions"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.special_instructions || ""}
                          invalid={
                            validation.touched.special_instructions &&
                            validation.errors.special_instructions
                              ? true
                              : false
                          }
                          disabled={!isEditable}
                        />
                        {validation.touched.special_instructions &&
                        validation.errors.special_instructions ? (
                          <FormFeedback type="invalid">
                            {validation.errors.special_instructions}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {/* <button type="submit" className="btn btn-primary " disabled={!isEditable}>
												Save
											</button> */}
                      <button
                        type="submit"
                        className="btn btn-primary ms-1"
                        disabled={!isEditable}
                      >
                        Save and Publish
                      </button>
                    </div>
                    <div>
                      {selectedCellData !== null && isEditable && (
                        <button
                          className="btn btn-danger ms-1"
                          type="button"
                          onClick={onDeleteShift}
                        >
                          Delete Shift
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </Col>
              {showSidePanel && (
                <Col md={6} className="border-start-1">
                  {validation.values.shiftType === "employee" &&
                  selectedCellData !== null &&
                  (selectedEmployee || selectedCellData?.data?.employee) ? (
                    <EmployeeDetails
                      employee={
                        display === "staff" && selectedEmployee
                          ? selectedEmployee
                          : selectedCellData?.data?.employee
                      }
                    />
                  ) : selectedCellData?.type === "jobRequest" &&
                    selectedCellData !== null &&
                    validation.values.shiftType === "marketplace" &&
                    selectedCellData?.data?.customer ? (
                    <NurseDetails customer={selectedCellData?.data || {}} />
                  ) : (
                    isEditable && (
                      <>
                        <h4 className="font-weight-semibold">
                          On-Demand Staffing Made Easy
                        </h4>
                        <div className="mt-4">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              handleClose();
                              setInviteModalOpen(true);
                            }}
                          >
                            Invite Qualified Providers for this shift
                          </button>
                        </div>
                      </>
                    )
                  )}
                </Col>
              )}
            </Row>
          ) : activeTab === "gig_requests" ? (
            <Row>
              <RequestTable
                queryCondition={{
                  job_opening_id: { _eq: selectedCellData?.data?.id },
                  active: { _eq: true },
                  // status: { _in: ["requested"] }
                }}
              />
            </Row>
          ) : (
            <Row>
              <InvitationTable
                queryCondition={{
                  isactive: { _eq: true },
                  createdby: { _eq: user.id },
                  position_id: { _eq: position.id },
                  job_date: { _eq: moment(selectedDate).format("YYYY-MM-DD") },
                }}
              />
            </Row>
          )}
        </div>
      </Modal>
      <InvitationModal
        isEdit={false}
        setIsEdit={() => {
          //
        }}
        toggle={() => setInviteModalOpen(false)}
        modal={inviteModalOpen}
        refetch={handleRefetch}
        customer={null}
        selectedPosition={position}
        selectedDate={selectedDate}
      />
    </>
  );
};
export default CreateShift;

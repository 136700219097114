import React, {useState} from "react";

//redux
import { useDispatch } from "react-redux";

const StaffCalendarLeft: React.FC<{
	onScroll: (topOffset: number) => void;
	employees: any[]
}> = ({ onScroll, employees }) => {
	const dispatch = useDispatch();
	const [avatar, setAvatar] = useState("/avatar.jpg")

	return (
		<div className="main_calendar-bottom-left">
			<div
				className="main_calendar-bottom-left-inner"
				id="main_calendar-bottom-left"
				onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
					onScroll(event.currentTarget.scrollTop)
					// ref.current?.scrollTo({ scrollTop: event.currentTarget.scrollTop });
				}}
			>
				{employees.map((_emp: any, index: number) => (
					<div
						className="GridLeftHeader"
						style={{
							height: 55,
							width: 230,
							cursor: "pointer",
							backgroundColor:
								_emp?.background_color !== null
									? _emp?.background_color
									: "#f8f8fb",
							color:
								_emp?.text_color !== null
									? _emp?.text_color
									: "#0009",
						}}
						key={index}
					>
						<img src={avatar} style={{ width: 30, height: 30, borderRadius: "50%", margin: 10 }} />
						<span style={{ marginLeft: '2px', fontSize: 10 }}>{`${_emp?.firstname} ${_emp?.lastname}`}</span>
					</div>
				))}
			</div>
			<div
				style={{ width: "100%", height: 7, backgroundColor: "#f8f8fb" }}
			></div>
		</div>
	)
}
export default StaffCalendarLeft
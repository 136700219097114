import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//constants
import {
    layoutTypes,
    layoutWidthTypes,
    topBarThemeTypes,
    leftBarThemeImageTypes,
    leftSidebarTypes,
    leftSideBarThemeTypes,
} from "../../constants/layout";

interface StateType {
    layoutType: string;
    layoutWidth: string;
    leftSideBarTheme: string;
    leftSideBarThemeImage: string;
    leftSideBarType: string;
    topbarTheme: string;
    isPreloader: boolean;
    showRightSidebar: boolean;
    isMobile: boolean;
    showSidebar: boolean;
    leftMenu: boolean;
}

const initialState: StateType = {
    layoutType: layoutTypes.HORIZONTAL,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    topbarTheme: topBarThemeTypes.LIGHT,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
};

function changeBodyAttribute(attribute: string, value: string) {
    if (document.body) document.body.setAttribute(attribute, value)
    return true
}

export const layoutSlice: any = createSlice({
    name: "auth",
    initialState,
    reducers: {
        changeLayout(state, action) {
            const _state = { ...state }
            if (action.payload === "horizontal") {
                _state.topbarTheme = "dark"
                document.body.removeAttribute("data-sidebar")
                document.body.removeAttribute("data-sidebar-image")
                document.body.removeAttribute("data-sidebar-size")
            } else {
                _state.topbarTheme = "light"
                // if (window.screen.width >= 998) {
                //     document.body.classList.toggle("vertical-collpsed");
                //     document.body.classList.toggle("sidebar-enable");
                // }
            }
            changeBodyAttribute("data-layout", action.payload)
            return {..._state, layoutType: action.payload}
        },
        changePreloader(state, action) {
            return { ...state, isPreloader: action.payload }
        },
        changeLayoutWidth(state, action) {
            const _state = { ...state }
            if (action.payload === "boxed") {
                _state.leftSideBarType = "icon"
                changeBodyAttribute("data-layout-size", action.payload)
                changeBodyAttribute("data-layout-scrollable", "false")
            } else if (action.payload === "scrollable") {
                _state.leftSideBarType = "default"
                changeBodyAttribute("data-layout-scrollable", "true")
            } else {
                _state.leftSideBarType = "default"
                changeBodyAttribute("data-layout-size", action.payload)
                changeBodyAttribute("data-layout-scrollable", "false")
            }
            return _state
        },
        changeSidebarTheme(state, action) {
            return {...state, leftSideBarTheme: action.payload}
        },
        changeSidebarThemeImage(state, action) {
            return {...state, leftSideBarThemeImage: action.payload}
        },
        changeSidebarType(state, action) {
            return {...state, leftSideBarType: action.payload}
        },
        changeTopbarTheme(state, action) {
            changeBodyAttribute("data-topbar", action.payload.topbarTheme)
            return {...state, topbarTheme: action.payload.topbarTheme}
        },
        showRightSidebarAction(state, action) {
            return {...state, showRightSidebar: action.payload}
        },
        showSidebar(state, action) {
            return {...state, showSidebar: action.payload}
        },
        toggleLeftmenu(state, action) {
            return {...state, leftMenu: action.payload}
        },
        resetLayout(state, action) {
            return {...state, ...initialState}
        }
    }
});

export const layoutState = (state: any) => state;
export const { changeLayout, changePreloader, changeLayoutWidth, changeSidebarTheme, changeSidebarThemeImage, changeSidebarType, changeTopbarTheme, showRightSidebarAction, showSidebar, toggleLeftmenu, resetLayout } = layoutSlice.actions;

export default layoutSlice.reducer;

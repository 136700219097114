import React, {
  useState,
  useEffect,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { SelectPicker, Button as RSButton } from "rsuite";
import { useQuery, useApolloClient } from "@apollo/client";

interface SelectPickerPaginateProps {
  query: any;
  paginate: boolean;
  objectLabel: string;
  objectValue: string;
  filterCondition: any;
  arrKey: string;
  getFullObj: boolean;
  placeholder: string;
  value: string;
  onChange: (location: any) => void;
  searchValue: string;
  style: React.CSSProperties;
  menuStyle: React.CSSProperties;
  disabled?: boolean;
  cleanable?: boolean;
  className?: string;
  formatLabel?: (item: any) => string;
  searchable?: boolean;
}
type SelectPickerPaginateHandle = {
  handleRefetch: () => void;
};
const SelectPickerPaginateRenderFn: ForwardRefRenderFunction<
  SelectPickerPaginateHandle,
  SelectPickerPaginateProps
> = (props, ref) => {
  const client = useApolloClient();
  const [queryCondition, setQueryCondition] = useState({});
  const [PageData, setPageData] = useState({
    limit: 30,
    pageNo: 1,
  });
  const [list, setList] = useState<any[]>([]);

  const {
    data: items,
    loading,
    error,
    refetch,
  } = useQuery(props.query, {
    variables: {
      limit: 30,
      offset: 0,
      where: queryCondition,
    },
    fetchPolicy: "no-cache",
  });
  const resetQueryCondition = () => {
    let conditionTemp = {};
    if (props.filterCondition !== null) {
      conditionTemp = { ...props.filterCondition };
    }
    setQueryCondition(conditionTemp);
  };
  const renderPickerFooter = () => {
    if (props.paginate) {
      return (
        <div
          style={{
            padding: "10px 2px",
            borderTop: "1px solid #e5e5e5",
          }}
        >
          <RSButton
            style={{
              float: "right",
              marginRight: 10,
              marginTop: 2,
            }}
            appearance="primary"
            size="sm"
            onClick={loadMore}
          >
            Load More
          </RSButton>
        </div>
      );
    } else {
      return null;
    }
  };
  const getDropdownLabel = (item: any) => {
    let label = "";
    if (typeof props.formatLabel === "function") {
      label = props.formatLabel(item);
    } else {
      const labelArr = props.objectLabel.split("+");
      labelArr.map((l: string) => {
        label += ` ${item[l]}`;
      });
    }
    return label;
  };
  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      // setQueryCondition({
      // 	...queryCondition,
      // 	[props.objectLabel]: { _ilike: `%${value}%` },
      // })
      const labelArr = props.objectLabel.split("+");
      const _conditions: any[] = [];
      labelArr.map((l: string, i: number) => {
        const valueArr = value.split(" ");
        _conditions.push({ [l]: { _ilike: `%${valueArr[i] || value}%` } });
      });
      setQueryCondition({
        ...queryCondition,
        _or: _conditions,
      });
    } else {
      resetQueryCondition();
    }
  };
  const loadMore = async () => {
    let conditionTemp = {};
    if (props.filterCondition !== null) {
      conditionTemp = { ...props.filterCondition };
    }
    const newPageNo = PageData.pageNo + 1;
    const { data } = await client.query({
      query: props.query,
      variables: {
        limit: PageData.limit,
        offset: (newPageNo - 1) * PageData.limit,
        where: conditionTemp,
      },
    });
    const temp: any[] = [];
    if (Array.isArray(data?.[props.arrKey])) {
      data[props.arrKey].map((item: any) => {
        const label = getDropdownLabel(item);
        temp.push({
          label,
          value: props.getFullObj
            ? JSON.stringify(item)
            : item[props.objectValue],
        });
      });
      setList([...list, ...temp]);
      setPageData({ ...PageData, pageNo: PageData.pageNo + 1 });
    }
  };
  React.useImperativeHandle(ref, () => ({
    handleRefetch() {
      refetch();
    },
  }));
  useEffect(() => {
    const temp: any[] = [];
    if (Array.isArray(items?.[props.arrKey])) {
      items[props.arrKey]?.map((item: any) => {
        const label = getDropdownLabel(item);
        temp.push({
          label,
          value: props.getFullObj
            ? JSON.stringify(item)
            : item[props.objectValue],
        });
      });
      setList(temp);
    }
  }, [items]);
  useEffect(() => {
    if (props.filterCondition !== null) {
      setQueryCondition(props.filterCondition);
    }
  }, [props.filterCondition]);
  useEffect(() => {
    if (list.length && props.value?.length) {
      const itemInList = list.filter((item) => item.value === props.value);
      if (itemInList.length === 0 && props.searchValue) {
        handleSearch(props.searchValue);
      }
    }
  }, [props.value, list]);
  return (
    <SelectPicker
      style={props.style}
      menuStyle={props.menuStyle}
      data={list}
      placeholder={props.placeholder}
      onSearch={handleSearch}
      value={props.value}
      onChange={props.onChange}
      renderExtraFooter={renderPickerFooter}
      placement="bottomStart"
      onClean={resetQueryCondition}
      disabled={props.disabled || false}
      cleanable={
        typeof props.cleanable !== "undefined" ? props.cleanable : true
      }
      preventOverflow={true}
      className={props.className ? props.className : ""}
      searchable={props.searchable ?? true}
    />
  );
};
const SelectPickerPaginate = React.forwardRef(SelectPickerPaginateRenderFn);
export default SelectPickerPaginate;

import React from "react"
import { Nav } from "rsuite"

const ProfileTabs: React.FC<{
	activeTab: string;
	setActiveTab: () => void
}> = ({ activeTab, setActiveTab }) => {
	const tabs = [{ title: "Professional Experience", value: "professional_exp" }, { title: "Education", value: "education" }, { title: "Licenses", value: "licenses" }, { title: "Certifications", value: "certifications" }, { title: "Reviews", value: "reviews" }]
	return (
		<Nav activeKey={activeTab} onSelect={setActiveTab} appearance="subtle">
			{tabs.map((tab, i) => (
				<Nav.Item eventKey={tab.value} key={i} className="front-size-16 py-3 pl-6 pr-6 " >
					{tab.title}
				</Nav.Item>
			))}
		</Nav>
	)
}
export default ProfileTabs
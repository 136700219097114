import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import OnboardingBanner from "components/Onboarding/OnboardingBanner";
import TimecardLogo from "assets/images/onboarding/Timecard.png";
import BannerContent from "constants/bannerContent.json";

import TimecardTable from "./TimecardTable";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  onboardingState,
  handleSetTimecardsBanner,
} from "toolkit/onboarding/onboarding.slice";

const RequestList: React.FC = () => {
  const dispatch = useDispatch();
  const { showTimecardsBanner } = useSelector(onboardingState);

  return (
    <React.Fragment>
      <div className="page-content Timecardpage AppBody">
        <MetaTags>
          <title>Timecards | Caregigs</title>
        </MetaTags>

        <div className="Timecardpage-container">
          {/* breadcrumb */}
          <Breadcrumbs
            title="Timecards"
            links={[
              { title: "Home", link: "/" },
              { title: "Timecards", link: "#" },
            ]}
          />

          {/* banner */}
          {showTimecardsBanner && (
            <OnboardingBanner
              media={TimecardLogo}
              title={BannerContent.timecard.title}
              subTitle={BannerContent.timecard.subtitle}
              color={"#FCFAEB"}
              handleClose={() => dispatch(handleSetTimecardsBanner(false))}
            />
          )}
          {/* Render Breadcrumbs */}
          <TimecardTable queryCondition={null} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(RequestList);

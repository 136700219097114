import React from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { Link } from "react-router-dom"
import { Dropdown } from 'rsuite';
import { GoKebabVertical } from 'react-icons/go'
import { Badge, Button } from "reactstrap";

//types
import jobOpeningType from "./openings.types"
export const FormatData = (employees: jobOpeningType[]) => {
	const _employees = employees.map((elem: jobOpeningType) => {
		return {
			id: elem.id,
			name: elem.name || "N/A",
			organization_id: elem.organization_id,
			location_name: elem.location?.name || "N/A",
			location_id: elem.location?.id,
			isactive: elem.isactive,
			shift_time: `${elem.position?.start_time} - ${elem.position?.end_time}`,
			job_date: elem.job_date,
			created_at: elem.created_at,
			position_name: elem.position?.name || "N/A",
			position_id: elem.position?.id,
			fee_amount: elem.fee_amount,
			currency: elem.currency,
			fee_period: elem.fee_period,
			filled: elem.filled,
			total_requests: elem.job_requests_aggregate?.aggregate?.count || 0,
			is_confirmed: elem.job_requests?.length > 0 ? true : false
		}
	})
	return _employees
}
const formatHighlighter = (text: string, searchKey: string, filterValue: any) => {
	let searchWords = [searchKey]
	if (Array.isArray(filterValue?.role)) {
		searchWords = [...searchWords, ...filterValue?.role]
	}
	return <Highlighter
		searchWords={searchWords}
		autoEscape={true}
		textToHighlight={String(text)}
		highlightStyle={{ background: "#ff0" }}
	/>
}
export const JobOpeningColumns = (
	searchKey: string, 
	filterValue: any, 
	history: any, 
	handleChangeStatus: (id: string, status: string) => void, 
	onDeleteClick: (id: string) => void,
	onClickRequests: (id: string) => void,
) => [
	{
		text: "id",
		dataField: "id",
		hidden: true,
	},
	// {
	// 	text: "Name",
	// 	dataField: "name",
	// 	sort: true,
	// 	formatter: (cellContent: string, user: any) => (
	// 		<span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
	// 	),
	// },
	{
		dataField: "position_name",
		text: "Position",
		sort: true,
		formatter: (cellContent: string, user: any) => (
			<span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
		),
	},
	{
		dataField: "location_name",
		text: "Location",
		sort: true,
		formatter: (cellContent: string, user: any) => (
			<span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
		),
	},
	{
		dataField: "job_date",
		text: "Job Date",
		sort: true,
		formatter: (cellContent: Date, user: any) => moment(cellContent).format("MMM DD, YYYY")
	},
	{
		dataField: "shift_time",
		text: "Shift Time",
		sort: true,
		formatter: (cellContent: string, user: any) => (
			<span>{cellContent}</span>
		),
	},
	{
		dataField: "fee_amount",
		text: "Rate",
		sort: true,
		hidden: true,
		formatter: (cellContent: string, row: any) => (
			<span>{cellContent} {row.currency}</span>
		),
	},
	{
		dataField: "filled",
		text: "Status",
		sort: true,
		formatter: (cellContent: boolean, row: any) => (
			// <span className={cellContent ? "text-warning" : "text-success"}>{cellContent ? "Open" : "Filled"}</span>
			<span><Badge
			    pill
				to="#"
				className={`badge ${cellContent ? "bg-success" : "bg-warning"} font-size-11 m-2 p-2`}
			>
				{cellContent ? row.is_confirmed ? "Confirmed" : "Approved" : "Open"}
			</Badge> </span>
		),
	},
	{
		dataField: "total_requests",
		text: "Total Requests",
		sort: true,
		align: "center",
		headerAlign: "center",
		formatter: (cellContent: string, row: any) => (
			<Link to="#" onClick={()=>onClickRequests(row.id)}>
				<span>{cellContent}</span>
			</Link>
		),
	},
	{
		dataField: "created_at",
		text: "Created On",
		sort: true,
		formatter: (cellContent: Date, row: any) => moment(cellContent).format("MMM DD, YYYY")
	},
	{
		dataField: "menu",
		isDummyField: true,
		editable: false,
		text: "Action",
		align: "center",
		headerAlign: "center",
		// eslint-disable-next-line react/display-name
		formatter: (cellContent: string, row: any) => (
			<div className="d-flex gap-3 justify-content-center">
				<Link className="text-danger" to="#">
					<i
						className="mdi mdi-delete font-size-18"
						id="deletetooltip"
						onClick={() => onDeleteClick(row.id)}
					></i>
				</Link>
			</div>
		),
	}]
export const BuildJobOpeningSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ name: { _ilike: `%${value}%` } },
				{ position: { name: { _ilike: `%${value}%` } } },
				{ location: { name: { _ilike: `%${value}%` } } },
				// { status: { _ilike: `%${value}%` } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildJobOpeningFilterQuery = (filterValue: any, queryCondition: any) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("status")) {
			if(filterValue.status === "open"){
				conditionTemp = {
					...conditionTemp,
					filled: { _eq: false }
				}
			}else if(filterValue.status === "confirmed"){
				conditionTemp = {
					...conditionTemp,
					is_confirmed: { _eq: true },
					filled: { _eq: true }
				}
			}else if(filterValue.status === "approved"){
				conditionTemp = {
					...conditionTemp,
					is_confirmed: { _eq: false },
					filled: { _eq: true }
				}
			}
		}
		if (filterValue?.hasOwnProperty("job_date")) {
			if (Array.isArray(filterValue.job_date)) {
				const job_date_from = moment(filterValue.job_date[0]).format("YYYY-MM-DD")
				const job_date_till = moment(filterValue.job_date[1]).format("YYYY-MM-DD")
				conditionTemp = {
					...conditionTemp,
					_and: [{job_date: { _gte: job_date_from }}, {job_date: { _lte: job_date_till }}]
				}
			}
		}
		if (filterValue?.hasOwnProperty("position")) {
			conditionTemp = {
				...conditionTemp,
				position_id: { _in: filterValue.position }
			}
		}
		if (filterValue?.hasOwnProperty("location")) {
			conditionTemp = {
				...conditionTemp,
				location_id: { _in: filterValue.location }
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiURL from "../../config/index";

export const fetchProfileApi: any = createAsyncThunk(
  "user/me",
  async (reqParam, thunkAPI) => {
    try {
      const response = await apiURL.get("user/me", {
        headers: {
          Authorization: reqParam
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const changePasswordApi: any = createAsyncThunk(
  "user/change-password",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/changePassword", reqParam.formData, {
        headers: {
          Authorization: reqParam.token
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const updateEmailApi: any = createAsyncThunk(
  "user/updatemail",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/updatemail", reqParam.formData, {
        headers: {
          Authorization: reqParam.token
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const verifyEmailUpdateApi: any = createAsyncThunk(
  "user/verifyemailupdate",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/verifyemailupdate", reqParam.formData, {
        headers: {
          Authorization: reqParam.token
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
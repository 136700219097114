import React, {useState} from "react";
// icons
import { FiPlus, FiPrinter } from "react-icons/fi";

//components
import CreateInterviewModal from "components/marketplace/CreateInterviewModal";

//helpers
import { printCalendar } from "helpers";

const InterviewCalendarTop:React.FC = () => {
	const [showInterviewModal, setInterviewModal] = useState<boolean>(false)
	return (
		<div className="d-flex mb-3" >
			<button
				className="btn btn-primary btn-sm "
				onClick={() => setInterviewModal(true)}
			>
				<FiPlus /> Schedule Interview
			</button>
			<button
				className="btn btn-primary btn-sm "
				onClick={() => printCalendar("calendar")}
				style={{ marginLeft: 10 }}
			>
				<FiPrinter /> Print Calendar
			</button>
			<CreateInterviewModal 
				isOpen={showInterviewModal}
				toggle={()=>setInterviewModal(false)}
				customer={null}
			/>
		</div>
	)
}
export default InterviewCalendarTop
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Steps from "./components/Steps";
import { BsArrowRight } from "react-icons/bs";
import onboardingContent from "constants/onboardingContent.json";
import { useHistory } from "react-router-dom";
import { chunk } from "lodash";

interface stepType {
  step: number;
  content: string;
  flexWay: string;
  colour: string;
  contentAlign: string;
}
const OnboardingModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
}> = ({ isOpen, toggle }) => {
  const history = useHistory();
  const [step, setStep] = useState<number>(0);
  const onboardingChunks: any[] = chunk(onboardingContent, 4);
  const handleContinue = () =>{
    setStep(0);
    toggle();
    history.push("/settings/locations");
  }
  const handleSkip = () => {
    setStep(0);
    toggle();
  }
  return (
    <Modal isOpen={isOpen} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#f6fbff", padding: 30 }}>
        <p style={{ textAlign: "center" }}>How it Works ?</p>
        <p
          style={{
            fontSize: 10,
            textAlign: "center",
            fontWeight: "500",
            paddingTop: 10,
          }}
        >
          Caregigs is a simplified platform to handle all of your staffing needs
          from organizing your internal staff shifts to hiring a nurse
          temporarily to fill a gap in your schedule. Here&apos;s how to get
          started:
        </p>
      </ModalHeader>
      <ModalBody style={{ paddingLeft: 50, paddingRight: 50 }}>
        {onboardingChunks[step].map((data: stepType, i: number) => (
          <Steps
            step={data.step}
            content={data.content}
            flexWay={data.flexWay}
            colour={data.colour}
            contentAlign={data.contentAlign}
            key={i}
          />
        ))}
      </ModalBody>
      <ModalFooter
        style={{ display: "block", paddingLeft: 50, paddingRight: 50 }}
      >
        <button
          className="btn btn-outline-primary w-100"
          style={{ borderRadius: 10, fontSize: 15 }}
          onClick={() => {
            if (step === 0) {
              setStep(1);
            } else {
              handleContinue()
            }
          }}
        >
          {`${step === 0 ? "Next" : "Continue"}`} <BsArrowRight size={16} />
        </button>
        <button
          className="btn btn-link w-100 text-muted"
          style={{ borderRadius: 10 }}
          onClick={handleSkip}
        >
          Skip
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default OnboardingModal;

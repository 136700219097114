import React from "react"
import { List } from "rsuite"

const EmployeeDetails: React.FC<{
	employee: any
}> = ({ employee }) => {
	return (
		<div>
			<h4 className="font-weight-semibold">
				Employee Details
			</h4>
			<div className="text-muted mt-3">
				<List size="md" hover>
					<List.Item>
						<strong>Name:</strong> {`${employee?.firstname} ${employee?.lastname}`}
					</List.Item>
					{/* <List.Item>
						<strong>Profession:</strong> {employee?.profession}
					</List.Item> */}
					<List.Item>
						<strong>Type:</strong> {employee?.type}
					</List.Item>
					<List.Item>
						<strong>Email:</strong> {employee?.email}
					</List.Item>
					<List.Item>
						<strong>Contact 1:</strong> {employee?.contactno1}
					</List.Item>
					<List.Item>
						<strong>Contact 2:</strong> {employee?.contactno2}
					</List.Item>
					<List.Item>
						<strong>Location:</strong> {employee?.address || "N/A"}
					</List.Item>
				</List>
			</div>
		</div>
	)
}
export default EmployeeDetails
import React from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { Link } from "react-router-dom"
import { Dropdown } from 'rsuite';
import { GoKebabVertical } from 'react-icons/go'

import employeeType from "./employees.types"
export const FormatData = (employees: employeeType[]) => {
  const _employees = employees.map((elem: employeeType) => {
    return {
      id: elem.id,
      firstname: elem.firstname || "N/A",
      lastname: elem.lastname || "N/A",
      prefix: elem.prefix || "N/A",
      profession: elem.profession || "N/A",
      active: elem.isactive,
      email: elem.email || "N/A",
      contactno1: elem.contactno1 || "",
      contactno2: elem.contactno2 || "",
      created_at: elem.created_at,
      location_id: elem?.location?.id,
      location_name: elem?.location?.name || "N/A",
      type: elem.type || "N/A",
      role: elem.is_supervisor ? "supervisor" : "employee",
      background_color: elem.background_color, 
      text_color: elem.text_color,
      is_supervisor: elem.is_supervisor,
      avatar: elem.avatar
    }
  })
  return _employees
}
const formatHighlighter = (text: string, searchKey: string, filterValue: any) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role]
  }
  return <Highlighter
    searchWords={searchWords}
    autoEscape={true}
    textToHighlight={String(text)}
    highlightStyle={{ background: "#ff0" }}
  />
}
export const EmployeeColumns = (searchKey: string, filterValue: any, history: any, handleChangeStatus: (emp: employeeType) => void, onEditClick: (emp: employeeType) => void) => [
  {
    dataField: 'id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'firstname',
    text: 'First Name',
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <div className="d-flex gap-3">
        <div style={{background: row.background_color, width: 20, border: "1px solid #002"}}/>
        <Link className="text-primary" to="#" onClick={() => onEditClick(row)}>
          {formatHighlighter(cellContent, searchKey, filterValue)}
        </Link>
      </div>
    ),
  }, {
    dataField: 'lastname',
    text: 'Last Name',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  }, 
  // {
  //   dataField: 'prefix',
  //   text: 'Prefix',
  //   sort: true,
  // }, {
  //   dataField: 'profession',
  //   text: 'Profession',
  //   sort: true,
  //   formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  // }, 
  {
    dataField: 'type',
    text: 'Type',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: 'role',
    text: 'Role',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  }, {
    dataField: 'contactno1',
    text: 'Contact 1',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  },{
    dataField: 'contactno2',
    text: 'Contact 2',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: 'location_name',
    text: 'Location',
    sort: true,
    formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey, filterValue)
  },{
    dataField: 'active',
    text: 'Status',
    sort: true,
    formatter: (cellContent: boolean, row: any) => (
      <p className={cellContent ? "text-success" : "text-danger"} style={{marginBottom: 0}}>{cellContent ? "Active" : "Inactive"}</p>
    )
  }, {
    text: "Created At",
    dataField: "created_at",
    sort: false,
    formatter: (cellContent: Date, row: any) => moment(cellContent).format("MM/DD/YYYY h:mm A")
  }, {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <Dropdown 
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        <Dropdown.Item onClick={()=>onEditClick(row)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={()=>handleChangeStatus(row)}>
          Delete
        </Dropdown.Item>
      </Dropdown>
    ),
}]
export const BuildEmployeeSearchQuery = (value: string, queryCondition: any) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { firstname: { _ilike: `%${value}%` } },
        { lastname: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { type: { _ilike: `%${value}%` } },
        { contactno1: { _ilike: `%${value}%` } },
        { contactno2: { _ilike: `%${value}%` } },
        { location: { name: {_ilike: `%${value}%` } } },
        { profession: { _ilike: `%${value}%` } },
      ]
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildEmployeeFilterQuery = (filterValue: any, queryCondition: any) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        isactive: { _eq: filterValue.status === "true" ? true : false }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        const created_at_from = moment(filterValue.created_at[0]).format("YYYY-MM-DD")
        const created_at_till = moment(filterValue.created_at[1]).format("YYYY-MM-DD")
        conditionTemp = {
          ...conditionTemp,
          _and: [{ created_at: { _gte: created_at_from } }, { created_at: { _lte: created_at_till } }]
        }
      }
    }
    if (filterValue?.hasOwnProperty("location")) {
      conditionTemp = {
        ...conditionTemp,
        location: { id: {_in: filterValue.location } }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { getImage } from "helpers";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
import ApexRevenue from "../ApexRevenue";
import { getUserProfile } from "store/actions";
import images from "assets/images";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import shiftColumns from "./projectColumns";

import ProfileTabs from "components/profile/tabs";
import ProfessionalExpTab from "components/profile/tabs/ProfessionalExpTab";
import EducationTab from "components/profile/tabs/EducationTab";
import LicenseTab from "components/profile/tabs/LicenseTab";
import CertificatesTab from "components/profile/tabs/CertificatesTab";

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

const ContactsProfile = (props) => {
  const { userProfile, user } = props;
  const {
    auth: { token },
  } = useSelector(authState);

  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: "Completed Shifts",
      iconClass: "bx-check-circle",
      text: "0",
    },
    { title: "Future Shifts", iconClass: "bx-hourglass", text: "0" },
    { title: "Total Revenue", iconClass: "bx-package", text: "$0" },
  ]);
  const [activeTab, setActiveTab] = useState("professional_exp");
  const [avatar, setAvatar] = useState("");

  const handleGetImage = async () => {
    const image = await getImage(user.profile_picture, token, "image");
    setAvatar(image);
  };
  useEffect(() => {
    let _miniCards = [...miniCards];
    if (user.completedShifts) {
      _miniCards[0].text = String(user.completedShifts);
    }
    if (user.futureShifts) {
      _miniCards[1].text = String(user.futureShifts);
    }
    if (user.profile_picture) {
      handleGetImage();
    }
    setMiniCards(_miniCards);
  }, [user]);
  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                {/* <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="12"></Col>
                  </Row>
                </div> */}
                <CardBody className="p-3 bg-">
                  <Row>
                    <Col sm="5" className="d-flex justify-content-center">
                      {!user.profile_picture || !avatar ? (
                        <span
                          className={
                            "avatar-title profileModalImg bg-soft bg-primary text-primary text-uppercase"
                          }
                        >
                          {user.firstname.charAt(0)}
                        </span>
                      ) : (
                        // <div className="avatar-md profile-user-wid mb-4">
                        <img
                          className="profileModalImg"
                          src={avatar}
                          alt=""
                          style={{ height: 150, width: 150, objectFit: 'contain'}}
                        />
                        // </div>
                      )}
                    </Col>
                    <Col sm={7}>
                      <div className="p-0">
                        <h5 className="text-primary text-capitalize">
                          {" "}
                          {user.firstname} {user.lastname}
                        </h5>
                        {userProfile.profession ? (
                          <p className="text-capitalize text-muted mt-1">
                            {userProfile.profession}
                          </p>
                        ) : (
                          <p className="text-capitalize text-muted mt-1">
                            not specified
                          </p>
                        )}
                      </div>
                      <div className="pt-2">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-14 mt-1">
                              {user.completedShifts || 0}
                            </h5>
                            <p className="text-muted font-size-11 mb-0">
                              Shifts
                            </p>
                          </Col>
                          {user.preferred_rate ? (
                            <Col xs="6">
                              <h5 className="font-size-14 mt-1">
                                ${user.preferred_rate}
                              </h5>
                              <p className="text-muted font-size-11 mb-0">
                                Rate/hr
                              </p>
                            </Col>
                          ) : (
                            <Col xs="6">
                              <h5 className="font-size-14 mt-1">N/A</h5>
                              <p className="text-muted font-size-11 mb-0">
                                Rate/hr
                              </p>
                              {/* <p className="text-muted font-size-10 mb-0">
                                No preferred rate!
                              </p> */}
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-2">Personal Information</CardTitle>
                  <p className="text-muted">{user.tags?.join(", ")}</p>
                  <p className="text-muted">
                    Covid Vaccinated: {user.questionnaire?.covidVaccinated}
                  </p>
                  <p className="text-muted">
                    Preferred Time to Call:{" "}
                    {user.questionnaire?.preferredTimeToCall}
                  </p>
                  <p className="text-muted">
                    Interested in Travel Assignments:{" "}
                    {user.questionnaire?.travelAssignments}
                  </p>
                  <p className="text-muted mb-4">
                    Negative TB test within last year:{" "}
                    {user.questionnaire?.TBNegative}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 profileModal">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>
                            {user.firstname} {user.lastname}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{user.contactno1}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{user.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{user.address || "N/A"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Row>
                <ProfileTabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Row>
              {activeTab === "professional_exp" && (
                <ProfessionalExpTab user={user} />
              )}
              {activeTab === "education" && <EducationTab user={user} />}
              {activeTab === "licenses" && <LicenseTab user={user} />}
              {activeTab === "certifications" && (
                <CertificatesTab user={user} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
  user: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: {
    id: 1,
    name: "David McHenry",
    designation: "Licensed Practical Nurse",
    color: "primary",
    email: "david@nurseconnect.com",
    projects: "125",
    rate: 30,
    tags: ["Post Acute Care", "Surgical"],
  },
});

const mapDispatchToProps = (dispatch) => ({
  // onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));

import { gql } from "@apollo/client"
export const FetchLatestOTPs =
  gql`query FetchLatestOTPs (\$limit:Int,\$offset:Int,\$where:otps_bool_exp){
    otps (limit: \$limit, offset: \$offset, where: \$where, order_by: {created_at: desc_nulls_last}){
      id
      code
      email
      phoneno
      expired
      expires_at
    }
    otps_aggregate (where: \$where) {
      aggregate {
        count
      }
    }
  }
  `
import { gql } from "@apollo/client";

export const FetchFaqs = gql`
  query FetchFaqs($limit: Int, $offset: Int, $where: faqs_bool_exp) {
    faqs(limit: $limit, offset: $offset, where: $where) {
      id
      title
      description
      category
      active
    }
    faqs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

import React, { useState } from "react";
//data
import USStates from "constants/usStates.json";

import {
	Input
} from "reactstrap";

interface StateType {
	name: string;
	abbreviation: string
}
const AutoCompleteStates: React.FC<{
	onSelect: (address: any) => void;
	value: string;
	onChange: (value: any) => void;
	onBlur: (value: any) => void;
	invalid: boolean;
	name: string;
	placeholder?: string
}> = ({ onSelect, onChange, value, onBlur, invalid, name, placeholder }) => {
	const [showList, setShowList] = useState<boolean>(false)
	const [states, setStates] = useState<StateType[]>(USStates)
	
	const renderPredictionList = () => {
		return (
			<ul style={styles.listWrapper} className="listWrapper">
				{states.map((state: StateType, i: number) => (
					<li
						key={i}
						onClick={async() => {
							onSelect(state.name)
							setShowList(false)
						}}
						style={styles.list}
					>
						{state.name}
					</li>
				))}
			</ul>
		)
	}
	return (
		<>
			<Input
				name={name}
				label="Address"
				type="text"
				onChange={(e) => {
					onChange(e)
					const _filteredStates = USStates.filter((state: StateType) => state.name.toLowerCase().includes(value.toLowerCase()))
					setStates([..._filteredStates])
					setShowList(true)
				}}
				autoComplete="caregigs"
				value={value || ""}
				onBlur={onBlur}
				invalid={invalid}
				placeholder={placeholder || ""}
			/>
			{(showList && (value.length > 0)) && renderPredictionList()}
		</>
	)
}
const styles = {
	listWrapper: {
		listStyle: "none",
		paddingLeft: 0,
		height: 150
	},
	list: {
		padding: "5px 0",
		borderBottom: "1px solid #eee",
		cursor: "pointer"
	}
}
export default AutoCompleteStates
import React, { useState, useEffect } from "react"
import {
	Card,
	CardBody,
	CardTitle,
} from "reactstrap"

//components
import DocumentTable from "../documents/DocumentTable"

const CertificatesTab: React.FC<{
	user: any
}> = ({ user }) => {
	return (
		<Card>
			{/* <CardBody> */}
				<DocumentTable 
					queryCondition={null} 
					user={user}
					onBack={()=>{
						//
					}}
				/>
			{/* </CardBody> */}
		</Card>
	)
}
export default CertificatesTab
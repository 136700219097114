import React, { useEffect, useState } from "react";
import { Col, Row, Form, FormGroup, Input } from "reactstrap";
import { Panel, Table } from "rsuite";
const { HeaderCell, Cell, Column } = Table;

// icons

//helpers

//GraphQL
import { useQuery } from "@apollo/client";
import { FetchCustomersAdmin } from "../../../gql/customers/query";
import { FetchLocations } from "../../../gql/locations/query";

//Functions

//components
import MarketplaceMapView from "components/marketplace/MarketplaceMapView";

//filters
import FacilityFilter from "components/Filters/facilities/FacilityFilter";

const createRowData = (rowIndex: any) => {
  const randomKey = Math.floor(Math.random() * 9);
  const names = [
    "Hal",
    "Bryan",
    "Linda",
    "Nancy",
    "Lloyd",
    "Alice",
    "Julia",
    "Albert",
    "Hazel",
  ];
  const citys = [
    "Beijing",
    "Shanghai",
    "New Amieshire",
    "New Gust",
    "Lefflerstad",
    "East Catalina",
    "Ritchieborough",
    "Gilberthaven",
    "Eulaliabury",
  ];
  const emails = [
    "yahoo.com",
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "live.com",
    "msn.com",
    "yandex.com",
    "mail.ru",
  ];

  return {
    id: rowIndex + 1,
    name: names[randomKey],
    city: citys[randomKey],
    email: names[randomKey].toLocaleLowerCase() + "@" + emails[randomKey],
  };
};

const dataList = Array.from({ length: 20 }).map((_, index) =>
  createRowData(index)
);

const FaqTable: React.FC = () => {
  const initialCondition = {
    verified: { _eq: true },
    is_deleted: { _eq: false },
  };

  const [customerList, setCustomerList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [queryCondition, setQueryCondition] = useState(initialCondition);
  const [selectedUser, setSelectedUser] = useState({});
  const [profileModalOpen, setProfileModalOpen] = useState(false);
    const [filterValue, setFilterValue] = useState("");


  const { loading, data, refetch, error, called } = useQuery(
    FetchCustomersAdmin,
    {
      variables: {
        where: queryCondition,
      },
    }
  );

  const { data: facilityData } = useQuery(FetchLocations);

  useEffect(() => {
    if (Array.isArray(data?.customers)) {
      setCustomerList(data?.customers);
    }
    if (Array.isArray(facilityData?.locations)) {
      setFacilityList(facilityData?.locations);
    }
  }, [data, facilityData]);

  const onClickProfile = (user: any) => {
    setSelectedUser(user);
    setProfileModalOpen(true);
  };

  return (
    <Row>
      <Col lg="12">
        <div className="GigShiftPage-card">
          {/* <Row>
            <Col sm="4">
              <div className="search-box d-block">
                <div className="position-relative">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <Input
                        name="email"
                        placeholder="Search Address"
                        type="text"
                        // onChange={(e) => setSearchKey(e.target.value)}
                        // value={searchKey}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box d-block">
                <div className="position-relative">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <Input
                        name="email"
                        placeholder="Search Address"
                        type="text"
                        // onChange={(e) => setSearchKey(e.target.value)}
                        // value={searchKey}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end d-flex justify-content-end">
                <FacilityFilter
                  onFilterChange={setFilterValue}
                  activeFilters={["status", "created_at"]}
                  filterQueryConditions={null}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="5">
              <Panel header="User List" bordered bodyFill>
                <Table height={444} data={dataList}>
                  <Column width={70} align="center" fixed>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" />
                  </Column>

                  <Column width={200} fixed>
                    <HeaderCell> Facility Name</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Address</HeaderCell>
                    <Cell dataKey="city" />
                  </Column>
                </Table>
              </Panel>
            </Col>
            <Col lg="7">
              <MarketplaceMapView
                customerList={customerList}
                onClickProfile={onClickProfile}
                facilityList={facilityList}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default FaqTable;

import { gql } from "@apollo/client"
export const UpdateProfile =
	gql`mutation UpdateProfile(\$id:uuid!, \$object: users_set_input) {
  update_users_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`
export const UpdateOrganisation =
	gql`mutation UpdateOrganisation(\$id:uuid!, \$object: organisations_set_input) {
  update_organisations_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`
export const UpdateCustomer =
	gql`mutation UpdateCustomer(\$id:uuid!, \$object: customers_set_input) {
  update_customers_by_pk(pk_columns: {id: \$id}, _set: \$object) {
      id
    }
  }`
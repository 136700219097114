import React, { useEffect, useState } from "react";
import { TagGroup, Tag, IconButton, Col, Row } from "rsuite";
import { Form, Input, FormFeedback } from "reactstrap";
import PlusIcon from "@rsuite/icons/Plus";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { showAlert } from "../../../helpers/index";

//GraphQL
import { useQuery, useMutation } from "@apollo/client";
import {
  InsertSubscriber,
  DeleteSubscriber,
} from "gql/notificationSubscribers/mutation";
import { FetchSubscriber } from "gql/notificationSubscribers/query";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const NotificationSubscriber: React.FC = () => {
  const {
    auth: { user },
  } = useSelector(authState);
  const [CreateSubscriberMutation] = useMutation(InsertSubscriber);
  const [DeleteSubscriberMutation] = useMutation(DeleteSubscriber);

  const [tags, setTags] = useState<string[]>([]);
  const [typing, setTyping] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const { loading, data, refetch, error, called } = useQuery(FetchSubscriber, {
    variables: {
      where: {
        organisation_id: { _eq: user.organisation_id },
        contact_number: { _is_null: true },
      },
    },
  });

  useEffect(() => {
    if (data) {
      setTags(data.notification_subscribers);
    }
  }, [data]);

  const removeTag = (tag: any) => {
    // const nextTags = tags.filter((item) => item !== tag);
    // setTags(nextTags);
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to delete this Email?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await DeleteSubscriberMutation({
            variables: {
              id: tag,
            },
          });
          refetch();
          showAlert({
            title: "Deleted!",
            message: "Email removed successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };

  const handleButtonClick = () => {
    setTyping(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Email"),
    }),
    onSubmit: async (values) => {
      try {
        await CreateSubscriberMutation({
          variables: {
            object: {
              email: values.email,
              organisation_id: user.organisation_id,
              created_by: user.id,
            },
          },
        });
        validation.resetForm();
        setTyping(false);
        showAlert({
          title: "Created!",
          message: "Email added successfully!",
          type: "success",
        });
        refetch();
      } catch (err) {
        console.log(err)
        showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
      }
    },
  });

  const renderInput = () => {
    if (typing) {
      return (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="d-inline-block"
          style={{ marginLeft: 5 }}
        >
          <Input
            name="email"
            value={validation.values.email}
            placeholder="Enter email address"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
            style={{
              width: 155,
            }}
            bsSize="sm"
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback
              type="invalid"
              className="d-table"
              style={{ marginLeft: 5 }}
            >
              {validation.errors.email}
            </FormFeedback>
          ) : null}
        </Form>
      );
    }

    return (
      <IconButton
        className="tag-add-btn"
        onClick={handleButtonClick}
        icon={<PlusIcon />}
        appearance="ghost"
        size="xs"
        style={{ marginLeft: 12 }}
      />
    );
  };

  return (
    <Row>
      <p
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          color: "#7E8299",
          marginBottom: 15,
        }}
      >
        Click to add email for Subscription!
      </p>
      <TagGroup>
        {tags.map((item: any, index: any) => (
          <Tag
            key={index}
            closable
            onClose={() => removeTag(item.id)}
            color="blue"
          >
            {item.email}
          </Tag>
        ))}
        {renderInput()}
      </TagGroup>
    </Row>
  );
};

export default NotificationSubscriber;

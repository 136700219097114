import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import InterviewCalendar from "components/calendar/InterviewCalendar";
import InterviewCalendarTop from "components/calendar/parts/InterviewCalendarTop";

const Calendar: React.FC = (props) => {
	return (
		<React.Fragment>
			<div className="page-content Marketplacepage AppBody">
				<MetaTags>
					<title>Calendar | Caregigs</title>
				</MetaTags>
				<div className="Marketplacepage-container admin">
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Calendar"
						links={[{ title: "Home", link: "/" }, { title: "Calendar", link: "#" }]}
					/>
					<div className="Marketplacepage-card">
						<div className="Marketplacepage-card_top" >
							<InterviewCalendarTop />
						</div>
						<div className="Marketplacepage-card_body" >
							<InterviewCalendar />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default withRouter(Calendar);

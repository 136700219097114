import { gql } from "@apollo/client"
export const UpdateArea =
	gql`mutation UpdateArea(\$id:uuid!, \$object: areas_set_input) {
    update_areas_by_pk(pk_columns: {id: \$id}, _set: \$object) {
        id
      }
    }`

export const InsertArea =
	gql`mutation InsertArea(\$objects: [areas_insert_input!]!) {
  insert_areas(objects: \$objects) {
    affected_rows
  }
}
`
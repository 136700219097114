import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";

// icons
import { FiSend } from "react-icons/fi";

//types
import { notificationFormStateType } from "./notificationModal.types";

//GraphQL
import { useMutation, useApolloClient } from "@apollo/client";
import { InsertSubscriber } from "gql/notificationSubscribers/mutation";

//function
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const TextNotificationModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  userData: any;
}> = ({ toggle, modal, userData }) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const client = useApolloClient();
  const [CreateSubscriberMutation] = useMutation(InsertSubscriber);

  const [state, setState] = useState<notificationFormStateType>({
    contact_name: "",
    contact_number: "",
  });
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contact_name: state.contact_name,
      contact_number: state.contact_number,
    },
    validationSchema: Yup.object({
      contact_name: Yup.string().required("Please Enter Name"),
      contact_number: Yup.string()
        .length(10, "Phone No. Should be 10 Digits Long")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Please Enter Phone No."),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);
        await CreateSubscriberMutation({
          variables: {
            object: {
              contact_name: values.contact_name,
              contact_number: `+1${values.contact_number}`,
              organisation_id: userData.user != null
                ? userData.user.organisation.id
                : userData.organisation_id,
              created_by: user.id,
            },
          },
        });
        showAlert({
          title: "Updated!",
          message: `Contact added successfully!`,
          type: "success",
        });
        client.refetchQueries({
          include: ["FetchSubscriber"],
        });
        handleClose();
      } catch (err) {
        showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    },
  });

  const handleClose = () => {
    toggle();
    validation.resetForm();
  };
  return (
    <Modal
      isOpen={modal}
      size="md"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Add Contact
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Contact Name</Label>
                <Input
                  name="contact_name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contact_name || ""}
                  invalid={
                    validation.touched.contact_name &&
                    validation.errors.contact_name
                      ? true
                      : false
                  }
                />
                {validation.touched.contact_name &&
                validation.errors.contact_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contact_name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Contact Number</Label>
                <Input
                  name="contact_number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contact_number || ""}
                  invalid={
                    validation.touched.contact_number &&
                    validation.errors.contact_number
                      ? true
                      : false
                  }
                />
                {validation.touched.contact_number &&
                validation.errors.contact_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contact_number}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={btnLoading}
                >
                  <FiSend /> &nbsp; Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default TextNotificationModal;

import { gql } from "@apollo/client";
export const DashboardAnalytics = gql`
  query DashboardAnalytics {
    customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    archived_nurses: customers_aggregate(
      where: { is_deleted: { _eq: false }, is_archived: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    users_aggregate(where: { role: { _eq: "provider" } }) {
      aggregate {
        count
      }
    }
    job_requests_aggregate(
      where: {
        status: { _in: ["active", "approved", "completed"] }
        active: { _eq: true }
        is_confirmed: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    pending_interviews: customers_aggregate(
      where: {
        onboarding_interview_status: { _eq: "scheduled" }
        verified: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const CustomerAggregateByNurseTypes = gql`
  query CustomerAggregateByNurseTypes {
    CNA_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "CNA" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    RN_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "RN" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    LPN_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "LPN" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const CustomerAggregateByNurseTypesForMassachusetts = gql`
  query CustomerAggregateByNurseTypesForMassachusetts {
    CNA_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "CNA" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    RN_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "RN" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    LPN_aggregate: customers_aggregate(
      where: {
        questionnaire: { _contains: { qualificatioType: "LPN" } }
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const CustomerAggregateByStatus = gql`
  query CustomerAggregateByStatus {
    verified_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        verified: { _eq: true }
        onboarding_documents_verified: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    conditionally_verified_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        verified: { _eq: true }
        _or: [
          { onboarding_documents_verified: { _eq: false } }
          { onboarding_documents_submitted: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    documents_submitted_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_documents_submitted: { _eq: true }
        verified: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    selected_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_interview_status: { _eq: "selected" }
        verified: { _eq: false }
        onboarding_documents_submitted: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    to_be_interviewed_aggregate: customers_aggregate(
      where: {
        _and: [
          { is_archived: { _eq: false } }
          { is_interested: { _eq: true } }
          { is_terminated: { _eq: false } }
          { onboarding_application_form_submitted: { _eq: true } }
          { onboarding_interview_status: { _neq: "selected" } }
          { onboarding_interview_status: { _neq: "rejected" } }
          { onboarding_documents_submitted: { _eq: false } }
          { verified: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    signed_up_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_application_form_submitted: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    rejected_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_interview_status: { _eq: "rejected" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const CustomerAggregateByStatusForMassachusetts = gql`
  query CustomerAggregateByStatusForMassachusetts {
    verified_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        verified: { _eq: true }
        onboarding_documents_verified: { _eq: true }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    conditionally_verified_aggregate: customers_aggregate(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { is_archived: { _eq: false } }
          { is_interested: { _eq: true } }
          { is_terminated: { _eq: false } }
          { verified: { _eq: true } }
          {
            _or: [
              { onboarding_documents_verified: { _eq: false } }
              { onboarding_documents_submitted: { _eq: false } }
            ]
          }
          {
            _or: [
              { address: { _contains: { state: "Massachusetts" } } }
              { address: { _contains: { state: "MA" } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    documents_submitted_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_documents_submitted: { _eq: true }
        verified: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    selected_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_interview_status: { _eq: "selected" }
        verified: { _eq: false }
        onboarding_documents_submitted: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    to_be_interviewed_aggregate: customers_aggregate(
      where: {
        _and: [
          { is_archived: { _eq: false } }
          { is_interested: { _eq: true } }
          { is_terminated: { _eq: false } }
          { onboarding_application_form_submitted: { _eq: true } }
          { onboarding_interview_status: { _neq: "selected" } }
          { onboarding_documents_submitted: { _eq: false } }
          { verified: { _eq: false } }
          {
            _or: [
              { address: { _contains: { state: "Massachusetts" } } }
              { address: { _contains: { state: "MA" } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    signed_up_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_application_form_submitted: { _eq: false }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    rejected_aggregate: customers_aggregate(
      where: {
        is_deleted: { _eq: false }
        is_archived: { _eq: false }
        is_interested: { _eq: true }
        is_terminated: { _eq: false }
        onboarding_interview_status: { _eq: "rejected" }
        _or: [
          { address: { _contains: { state: "Massachusetts" } } }
          { address: { _contains: { state: "MA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

import React from "react"
import moment from "moment"
import Highlighter from "react-highlight-words"

//types
import LatestOTPType from "./latestOtps.types"

export const FormatData = (otps: LatestOTPType[]) => {
	const _otps = otps.map((elem: LatestOTPType) => {
		return {
			id: elem.id,
			email: elem.email || "N/A",
			code: elem.code,
			phone: elem.phoneno || "N/A",
			expires_at: elem.expires_at
		}
	})
	return _otps
}
const formatHighlighter = (text: string, searchKey: string) => {
	const searchWords = [searchKey]
	return <Highlighter
		searchWords={searchWords}
		autoEscape={true}
		textToHighlight={String(text)}
		highlightStyle={{ background: "#ff0" }}
	/>
}

export const OTPColumns = (searchKey: string) => [
	{
		text: "Phone",
		dataField: "phone",
		formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey)
	},
	{
		text: "Email",
		dataField: "email",
		formatter: (cellContent: string, row: any) => formatHighlighter(cellContent, searchKey)
	},
	{
		text: "OTP",
		dataField: "code",
	},
	{
		text: "Expires at",
		dataField: "expires_at",
		formatter: (cellContent: string, row: any) => moment(cellContent).format("MM/DD/YYYY HH:mm:ss A")
	},
]
export const BuildOTPSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
	  let conditionTemp = {}
	  if (queryCondition !== null) {
		conditionTemp = { ...queryCondition }
	  }
	  conditionTemp = {
		...conditionTemp,
		_or: [
		  { phoneno: { _ilike: `%${value}%` } },
		  { email: { _ilike: `%${value}%` } },
		]
	  }
	  return conditionTemp
	} else {
	  let conditionTemp = {}
	  if (queryCondition !== null) {
		conditionTemp = { ...queryCondition }
	  }
	  return conditionTemp
	}
  }
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const OnboardingBanner: React.FC<{
  media: string;
  title: string;
  subTitle: string;
  color: string;
  handleClose: () => void;
}> = ({ media, title, subTitle, color, handleClose}) => {
  return (
    <>
      <div className={"custom-onboarding-banner"}>
        <div className="custom-onboarding-banner_container" style={{backgroundColor: color}}>
          <img src={media} className="custom-onboarding-banner_mediaImg" />
          <div className="custom-onboarding-banner_content">
            <h4 style={{ lineHeight: "2"}}>{title}</h4>
            <p style={{ fontSize: "10" }}>{subTitle}</p>
          </div>
          <div className="custom-onboarding-banner_close">
            <AiOutlineCloseCircle onClick={handleClose} />
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardingBanner;

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  FormFeedback,
} from "reactstrap";
import { Pagination, InputGroup } from "rsuite";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { showAlert } from "helpers/index";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
// icons
import { FiPlus } from "react-icons/fi";

//components
import CreateNoteModal from "components/notes/CreateNoteModal";

//GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { FetchRates } from "gql/rates/query";
import { InsertRateOne } from "gql/rates/mutation";
import { UpdateNurses } from "gql/customers/mutation";

//Functions
import { NotesColumns, FormatData } from "./rates.functions";

//type
import NoteType from "./rates.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const RatesTable: React.FC<{
  queryCondition: any;
  user: any;
}> = (props) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const [CreateRateMutation] = useMutation(InsertRateOne);
  const [UpdateCustomerRateMutation] = useMutation(UpdateNurses);

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  //   const [queryCondition, setQueryCondition] = useState<any>({});

  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });

  const { loading, data, refetch, error, called } = useQuery(FetchRates, {
    variables: {
      where: {
        customer_id: { _eq: props.user.id },
      },
    },
  });
  const defaultSorted: any = [];

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      shift_rate: props.user.nurse_rate || "",
      overtime_rate: props.user.nurse_overtime_rate || "",
    },
    validationSchema: Yup.object({
      shift_rate: Yup.number()
        .min(0, "Rate should not be in negative value")
        .required("Please Enter Shift Rate"),
      overtime_rate: Yup.number()
        .min(0, "Rate should not be in negative value")
        .required("Please Enter Overtime Rate"),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);

        await CreateRateMutation({
          variables: {
            object: {
              customer_id: props.user.id,
              nurse_rate: values.shift_rate,
              nurse_overtime_rate: values.overtime_rate,
            },
          },
        });
        await UpdateCustomerRateMutation({
          variables: {
            where: {
              id: { _eq: props.user.id },
            },
            object: {
              nurse_rate: values.shift_rate,
              nurse_overtime_rate: values.overtime_rate,
            },
          },
        });
        showAlert({
          title: "Created!",
          message: "Rate added successfully!",
          type: "success",
        });
        refetch();
      } catch (err) {
        showAlert({
          title: "Error!",
          message: "Something went wrong!",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    },
  });
  const keyField = "id";
  return (
    <Row>
      <Col lg="12">
        <ToolkitProvider
          keyField={keyField}
          data={FormatData(
            Array.isArray(data?.rate_history) ? data.rate_history : []
          )}
          columns={NotesColumns()}
          bootstrap4
          search
        >
          {(toolkitProps) => (
            <React.Fragment>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-2 justify-content-between d-flex">
                  <div>
                    <Label className="form-label">Shift Rate</Label>
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <Input
                        name="shift_rate"
                        type="number"
                        onChange={(e: any) => {
                          validation.setFieldValue(
                            "shift_rate",
                            e.target.value
                          );
                          const calc = e.target.value
                            ? e.target.value * 1.5
                            : 0;
                          validation.setFieldValue("overtime_rate", calc);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.shift_rate || ""}
                        invalid={
                          validation.touched.shift_rate &&
                          validation.errors.shift_rate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.shift_rate &&
                      validation.errors.shift_rate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.shift_rate}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  <div className="">
                    <Label className="form-label">Overtime Rate</Label>
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <Input
                        name="overtime_rate"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.overtime_rate || ""}
                        invalid={
                          validation.touched.overtime_rate &&
                          validation.errors.overtime_rate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.overtime_rate &&
                      validation.errors.overtime_rate ? (
                        <FormFeedback>
                          {validation.errors.overtime_rate}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  <Button
                    color="primary"
                    size="sm"
                    style={{ height: 35, alignSelf: "flex-end" }}
                    to="#"
                    type="submit"
                    disabled={btnLoading}
                  >
                    Update
                  </Button>
                </div>
              </Form>

              {loading && (
                <Col xl="12" className="text-center">
                  <Spinner color="primary" />
                </Col>
              )}
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      // selectRow={{
                      // 	mode: 'checkbox',
                      // 	onSelectAll: (isSelected) => { console.log(isSelected) }
                      // }}
                      classes={"table align-middle table-nowrap table-hover"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      noDataIndication={() => "No rate history found!"}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline mt-3 w-100">
                    <Pagination
                      total={data?.notes_aggregate?.aggregate?.count || 0}
                      limit={PageData.limit}
                      activePage={PageData.pageNo}
                      onChangePage={(pageNo) =>
                        setPageData({ ...PageData, pageNo })
                      }
                      limitOptions={[30, 50, 100]}
                      layout={["limit", "pager"]}
                      onChangeLimit={(limit) =>
                        setPageData({ ...PageData, limit })
                      }
                      className="d-flex justify-content-between"
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
        {/* </CardBody>
				</Card> */}
      </Col>
    </Row>
  );
};

export default RatesTable;

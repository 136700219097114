import React, { useState, useEffect } from "react";
import { CardTitle, Input, Label } from "reactstrap";
import { Loader, Panel, CheckboxGroup, Checkbox } from "rsuite";
import { v4 as uuid } from "uuid";
import { ValueType } from "rsuite/esm/Checkbox";
import { useHistory } from "react-router-dom";

// icons
import { FiRefreshCw } from "react-icons/fi";

//constants
import nurseTypes from "constants/nurseTypes";
import nurseStatusList from "constants/nurseStatus";
import usStatesList from "constants/usStates.json";
import { revokedFilters } from "constants/documentCategories";

interface filterType {
  status: ValueType[];
  speciality: ValueType[];
  licenseState: ValueType[];
  addressMA: ValueType[];
  revoked: ValueType[];
}
const NurseFilter: React.FC<{
  filterKey: string;
  setFilterKey: (uuid: string) => void;
  setFilters: (filters: filterType) => void;
  filters: filterType;
  activeTab: any;
}> = ({ filterKey, setFilterKey, setFilters, filters, activeTab }) => {
  const initialFilter: filterType = {
    status: [],
    speciality: [],
    licenseState: [],
    addressMA: [],
    revoked: [],
  };
  const history = useHistory();

  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);

  const onClickFilter = (type: any, value: ValueType[]) => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    if (value.length) {
      searchParams.set(type, value.join(","));
    } else {
      searchParams.delete(type);
    }
    history.push({ pathname: pathname, search: searchParams.toString() });
  };
  return (
    <div
      className=""
      key={filterKey}
      style={{ overflowY: "scroll", height: "100%" }}
    >
      <div className="">
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-4">Filter</CardTitle>
          <button
            className="btn btn-light"
            style={{ height: 35, marginRight: 5 }}
            onClick={() => {
              setFilters(initialFilter);
              setFilterKey(uuid());
            }}
          >
            <FiRefreshCw />
          </button>
        </div>
        <Panel header="Nurse Type" defaultExpanded collapsible>
          <div>
            <CheckboxGroup
              name="specialityList"
              value={filters.speciality}
              onChange={(value: ValueType[], e: any) => {
                setFilters({ ...filters, speciality: value });
                onClickFilter("speciality", value);
              }}
            >
              {nurseTypes.map((item) => (
                <Checkbox
                  className="font-size-13"
                  key={item.id}
                  value={item.value}
                >
                  {item.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        </Panel>
        <Panel header="Status" collapsible>
          <div>
            <CheckboxGroup
              name="nurseStatusList"
              value={filters.status}
              onChange={(value: ValueType[], e: any) => {
                setFilters({ ...filters, status: value });
                onClickFilter("type", value);
              }}
            >
              {nurseStatusList.map((item) => (
                <Checkbox
                  className="font-size-13"
                  key={item.id}
                  value={item.value}
                >
                  {item.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        </Panel>

        {activeTab == "archived" && (
          <Panel header="Revoked Nurses" collapsible>
            <div>
              <CheckboxGroup
                name="revokeList"
                value={filters.revoked}
                onChange={(value: ValueType[], e: any) => {
                  setFilters({ ...filters, revoked: value });
                  onClickFilter("revoked", value);
                }}
              >
                {revokedFilters.map((item) => (
                  <Checkbox
                    className="font-size-13"
                    key={item.id}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </div>
          </Panel>
        )}

        <Panel header="License State" collapsible>
          <div>
            <CheckboxGroup
              name="usStatesList"
              value={filters.licenseState}
              onChange={(value: ValueType[], e: any) => {
                setFilters({ ...filters, licenseState: value });
                onClickFilter("state", value);
              }}
            >
              {usStatesList.map((item) => (
                <Checkbox
                  className="font-size-13"
                  key={item.abbreviation}
                  value={item.name}
                >
                  {item.name}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        </Panel>
      </div>
    </div>
  );
};
export default NurseFilter;

import { gql } from "@apollo/client";

export const InsertFAQs = gql`
  mutation InsertFAQs($object: faqs_insert_input!) {
    insert_faqs_one(object: $object) {
      id
    }
  }
`;

export const UpdateFAQs = gql`
  mutation UpdateFAQs($id: uuid!, $object: faqs_set_input) {
    update_faqs_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const DeleteFAQ = gql`
  mutation DeleteFAQ($id: uuid!) {
    delete_faqs_by_pk(id: $id) {
      id
    }
  }
`;

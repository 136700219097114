import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Form, FormGroup, Input } from "reactstrap";
import { Pagination, Nav } from "rsuite";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDebounce } from "react-use";
import moment from "moment";
import { Link, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

// icons
import { FiDownloadCloud, FiPlus } from "react-icons/fi";

//helpers
import { showAlert } from "helpers/index";

//GraphQL
import { useLazyQuery, useMutation } from "@apollo/client";
import { FetchJobRequests } from "gql/jobRequests/query";
import { UpdateJobRequest } from "gql/jobRequests/mutation";
import { UpdateJobOpening } from "gql/jobOpenings/mutation";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
import {
  notifySubscribers,
  updateMapleShiftAssignmentApi,
  deleteMapleShiftAssignmentApi,
  deleteMapleShiftApi,
} from "toolkit/admin/admin.api";

//Functions
import {
  JobRequestColumns,
  FormatData,
  BuildJobRequestSearchQuery,
  BuildJobRequestFilterQuery,
  generateFilterQueryFromURL,
} from "./shifts.functions";

import timecardsType from "./shifts.types";

//filters
import ShiftFilter from "components/Filters/shift/ShiftFilter";

//components
import PayrollModal from "components/clock/PayrollModal";
import ClockModal from "components/clock/ClockModal";
import CreateShiftAdmin from "components/shifts/CreateShiftAdmin";
import ReassignShiftModal from "components/shifts/ReassignShift";

const ShiftTable: React.FC<{
  queryCondition: any;
  facility: string | null;
  mapleUser: any;
}> = (props) => {
  const params: any = useParams();
  const search = useLocation().search;

  const { ExportCSVButton } = CSVExport;
  const {
    auth: { user, token },
  } = useSelector(authState);
  const dispatch = useDispatch();

  const initialCondition = {
    active: { _eq: true },
    status: { _eq: "active" },
  };
  const [filterValue, setFilterValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  });
  const [queryCondition, setQueryCondition] = useState<any>({});
  const [tabCondition, setTabCondition] = useState<any>({});
  const [activeTab, setActiveTab] = useState("active_shifts");
  const [modal, setModal] = useState(false);
  const [shiftModal, setShiftModal] = useState<boolean>(false);
  const [reassignShiftModal, setReassignShiftModal] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<
    timecardsType | undefined
  >();
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);

  const tabs = [
    {
      name: "Active Shifts",
      value: "active_shifts",
    },
    {
      name: "Upcoming Confirmed Shifts",
      value: "upcoming_shifts",
    },
    {
      name: "Completed Shifts",
      value: "completed_shifts",
    },
    {
      name: "Missed Shifts",
      value: "missed_shifts",
    },
    {
      name: "Cancelled Shifts",
      value: "cancelled_shifts",
    },
  ];
  const [getShifts, { loading, data, refetch, error, called }] = useLazyQuery(
    FetchJobRequests,
    {
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
      fetchPolicy: "no-cache",
    }
  );

  const defaultSorted: any = [];

  const handleSearch = (value: string) => {
    const conditionTemp = BuildJobRequestSearchQuery(value, props.facility);
    setQueryCondition({
      _and: [tabCondition, conditionTemp],
    });
  };
  const setQueryOnTabChange = () => {
    const facilityCond = {
      _or: [
        {
          job_opening: { createdby: { _eq: props.facility } },
        },
        {
          invitation: { createdby: { _eq: props.facility } },
        },
      ],
    };
    let _condition = {};
    if (activeTab === "active_shifts") {
      _condition = initialCondition;
    } else if (activeTab === "upcoming_shifts") {
      _condition = {
        active: { _eq: true },
        status: { _eq: "approved" },
        is_confirmed: { _eq: true },
        _or: [
          {
            job_opening: { job_date: { _gte: moment().format("YYYY-MM-DD") } },
          },
          {
            invitation: { job_date: { _gte: moment().format("YYYY-MM-DD") } },
          },
        ],
      };
    } else if (activeTab === "completed_shifts") {
      _condition = {
        active: { _eq: true },
        status: { _eq: "completed" },
      };
    } else if (activeTab === "missed_shifts") {
      _condition = {
        active: { _eq: true },
        status: { _eq: "approved" },
        _or: [
          {
            job_opening: { job_date: { _lt: moment().format("YYYY-MM-DD") } },
          },
          {
            invitation: { job_date: { _lt: moment().format("YYYY-MM-DD") } },
          },
        ],
      };
    } else if (activeTab === "cancelled_shifts") {
      _condition = {
        active: { _eq: true },
        status: { _in: ["cancelled", "rejected"] },
      };
    }
    if (props.facility) {
      // setQueryCondition({ ...facilityCond, ..._condition })
      setQueryCondition({
        _and: [facilityCond, _condition],
      });
      setTabCondition({
        _and: [facilityCond, _condition],
      });
    } else {
      const obj = generateFilterQueryFromURL(search);
      const conditionTemp = BuildJobRequestFilterQuery(obj, props.facility);
      setQueryCondition({
        _and: [_condition, conditionTemp],
      });
      setTabCondition(_condition);
    }
  };
  const handleDeleteShift = (shift: any) => {
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to delete this shift?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = {
            event: "SHIFT_DELETED",
            organisation_id: shift?.fee_amount_object?.organisation.id,
            position_id: shift?.position_id,
            date: moment(shift?.job_date).format("LL"),
          };
          await dispatch(notifySubscribers({ formData, token }));
          let deleteMapleShiftObject = null;
          if (shift?.maple_shift_id) {
            deleteMapleShiftObject = {
              mapleShiftId: shift?.maple_shift_id,
            };
            await dispatch(
              deleteMapleShiftApi({ formData: deleteMapleShiftObject, token })
            );
          }

          await UpdateJobRequestMutation({
            variables: {
              id: shift.id,
              object: {
                active: false,
              },
            },
          });
          if (shift?.maple_shift_id == null && shift?.job_opening_id) {
            await UpdateJobOpeningMutation({
              variables: {
                id: shift?.job_opening_id,
                object: {
                  isactive: false,
                },
              },
            });
          }

          refetch();
          showAlert({
            title: "Deleted!",
            message: "Shift deleted successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };
  const handleCancelShift = (shift: any) => {
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to cancel this shift?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = {
            event: "FACILITY_SHIFT_CANCELLED",
            organisation_id: shift?.fee_amount_object?.organisation.id,
            nurse_id: shift?.customer_id,
            position_id: shift?.position_id,
            date: moment(shift?.job_date).format("LL"),
          };
          await dispatch(notifySubscribers({ formData, token }));

          //delete maple shift assignment when caregigs shift is cancelled
          if (shift?.maple_shift_id) {
            const deleteMapleShiftAssignmentObject = {
              mapleShiftId: shift?.maple_shift_id,
              mapleShiftAssignmentId: shift?.maple_shift_assignment_id,
            };
            await dispatch(
              deleteMapleShiftAssignmentApi({
                formData: deleteMapleShiftAssignmentObject,
                token,
              })
            );
            await UpdateJobOpeningMutation({
              variables: {
                id: shift?.job_opening_id,
                object: {
                  filled: false,
                },
              },
            });
          }

          await UpdateJobRequestMutation({
            variables: {
              id: shift.id,
              object: {
                status: "cancelled",
                cancelled_at: new Date(),
              },
            },
          });
          refetch();
          showAlert({
            title: "Cancelled!",
            message: "Shift cancelled successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };
  const handleNotifyCancelShift = (shift: any) => {
    try {
      Swal.fire({
        title: ``,
        text: `Are you sure want to notify facility about this shift?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = {
            event: "SHIFT_CANCELLED",
            organisation_id: shift.fee_amount_object.organisation.id,
            nurse_id: shift.customer_id,
            position_id: shift.position_id,
            date: moment(shift.job_date).format("LL"),
          };
          // await dispatch(notifySubscribers({ formData, token }));
          refetch();
          showAlert({
            title: "Notified!",
            message: "Facility notified successfully",
            type: "success",
          });
        }
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something Went Wrong",
        type: "danger",
      });
    }
  };
  useDebounce(
    () => {
      if (searchKey.length > 0) {
        handleSearch(searchKey);
      } else {
        setQueryOnTabChange();
      }
    },
    1000,
    [searchKey, activeTab]
  );
  //Apply filters
  useEffect(() => {
    if (search.length) {
      const obj = generateFilterQueryFromURL(search);
      const conditionTemp = BuildJobRequestFilterQuery(obj, props.facility);
      setQueryCondition({
        _and: [tabCondition, conditionTemp],
      });
    } else {
      setQueryOnTabChange();
    }
  }, [search]);

  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getShifts();
    }
  }, [queryCondition]);

  useEffect(() => {
    if (activeTab) {
      setQueryOnTabChange();
      setPageData({
        limit: 50,
        pageNo: 1,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (params?.tab) {
      setActiveTab(params.tab);
    }
  }, [params]);
  const keyField = "id";

  const onClockClick = (_shift: timecardsType) => {
    setSelectedShift(_shift);
    toggle();
  };

  function toggle() {
    setModal(!modal);
  }

  function toggleReassignShift() {
    setReassignShiftModal(!reassignShiftModal);
  }

  const onReassignShift = (_shift: timecardsType) => {
    setSelectedShift(_shift);
    toggleReassignShift();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Row>
      <Col lg="12">
        <div className="GigShiftPage-card">
          <Row>
            <Col
              lg="12"
              className="d-flex justify-content-center align-items-center mb-3"
            >
              <div className="MarketPlaceProfileTabs">
                {tabs.map((tab, i) => (
                  <React.Fragment key={i}>
                    {props.facility === null ? (
                      <Link to={`/all-shifts/${tab.value}`}>
                        <div
                          className={
                            activeTab == tab.value
                              ? "active font-size-14 MarketPlaceProfileTabs_tab"
                              : "font-size-14 MarketPlaceProfileTabs_tab"
                          }
                        >
                          {tab.name}
                        </div>
                      </Link>
                    ) : (
                      <div
                        className={
                          activeTab == tab.value
                            ? "active font-size-14 MarketPlaceProfileTabs_tab"
                            : "font-size-14 MarketPlaceProfileTabs_tab"
                        }
                        onClick={() => setActiveTab(tab.value)}
                      >
                        {tab.name}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <ToolkitProvider
            keyField={keyField}
            data={FormatData(
              Array.isArray(data?.job_requests) ? data.job_requests : []
            )}
            columns={JobRequestColumns(
              searchKey,
              filterValue,
              activeTab,
              onClockClick,
              handleCancelShift,
              handleDeleteShift,
              onReassignShift,
              handleNotifyCancelShift
            )}
            bootstrap4
            exportCSV={{ onlyExportSelection: true, exportAll: true }}
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col sm="4">
                    <div className="search-box d-block">
                      <div className="position-relative">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <FormGroup>
                            <Input
                              name="email"
                              placeholder="Search shifts"
                              type="text"
                              onChange={(e) => setSearchKey(e.target.value)}
                              value={searchKey}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col sm="8">
                    <div className="text-sm-end d-flex justify-content-end">
                      {!props.mapleUser && (
                        <button
                          type="button"
                          className="btn btn-primary shadow mx-2"
                          onClick={() => setShiftModal(true)}
                        >
                          <FiPlus /> Create Shift
                        </button>
                      )}
                      <ExportCSVButton
                        {...toolkitProps.csvProps}
                        style={{ border: "1px solid #74788d", marginRight: 10 }}
                      >
                        {" "}
                        <FiDownloadCloud />
                        &nbsp;&nbsp; Export
                      </ExportCSVButton>
                      <ShiftFilter
                        onFilterChange={setFilterValue}
                        activeFilters={
                          props.facility === null
                            ? ["job_date", "position", "location"]
                            : ["job_date"]
                        }
                        filterQueryConditions={null}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="8" style={{ textTransform: "capitalize" }}>
                    Total {activeTab.split("_")[0]} shifts:{" "}
                    {data?.job_requests_aggregate?.aggregate?.count || 0}
                  </Col>
                </Row>
                {loading && (
                  <Col xl="12" className="text-center">
                    <Spinner color="primary" />
                  </Col>
                )}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        key={`${searchKey}_${JSON.stringify(filterValue)}`}
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={{
                          mode: "checkbox",
                          onSelectAll: (isSelected) => {
                            console.log(isSelected);
                          },
                        }}
                        classes={"table align-middle table-nowrap table-hover"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        noDataIndication={() => "No shifts found!"}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline mt-3 w-100">
                      <Pagination
                        total={
                          data?.job_requests_aggregate?.aggregate?.count || 0
                        }
                        limit={PageData.limit}
                        activePage={PageData.pageNo}
                        onChangePage={(pageNo) =>
                          setPageData({ ...PageData, pageNo })
                        }
                        limitOptions={[30, 50, 100, 200, 300]}
                        layout={["limit", "pager"]}
                        onChangeLimit={(limit) =>
                          setPageData({ ...PageData, limit })
                        }
                        className="d-flex justify-content-between"
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </div>
      </Col>
      {/* {selectedShift?.invitationBy == "admin" && ( */}
      <PayrollModal
        toggle={() => {
          toggle();
          setSelectedShift(undefined);
        }}
        modal={modal}
        selectedShift={selectedShift}
        refetch={refetch}
      />
      {/* )} */}
      {/* {selectedShift?.invitationBy !== "admin" && (
        <ClockModal
          toggle={() => {
            toggle();
            setSelectedShift(undefined);
          }}
          modal={modal}
          selectedShift={selectedShift}
          refetch={refetch}
        />
      )} */}
      <CreateShiftAdmin
        modal_center={shiftModal}
        tog_center={() => setShiftModal(false)}
        selectedDate={new Date()}
      />
      <ReassignShiftModal
        toggle={toggleReassignShift}
        modal={reassignShiftModal}
        selectedShift={selectedShift}
      />
    </Row>
  );
};

export default ShiftTable;

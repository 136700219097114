import { createAsyncThunk } from "@reduxjs/toolkit";

import apiURL from "../../config/index";

export const sendNotificationApi: any = createAsyncThunk(
  "admin/send-notification",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "notification/admin/notify",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getCustomerStatsApi: any = createAsyncThunk(
  "admin/get-customer-stats",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("customer/stats", reqParam.formData, {
        headers: {
          Authorization: reqParam.token,
        },
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getLicenseStateAggregateApi: any = createAsyncThunk(
  "admin/get-license-state-aggregate",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.get("customer/license-state-aggregate", {
        headers: {
          Authorization: reqParam.token,
        },
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const createNurseApi: any = createAsyncThunk(
  "admin/create-nurse",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("customer/create", reqParam.formData, {
        headers: {
          Authorization: reqParam.token,
        },
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const notifySubscribers: any = createAsyncThunk(
  "user/email_subscribers",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "user/email_subscribers",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const notifyNurseDocSubmission: any = createAsyncThunk(
  "user/email_document_submission",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "user/email_document_submission",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const notInterestedNurseEmail: any = createAsyncThunk(
  "user/email_not_interested",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "user/email_not_interested",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createMapleShiftAssignmentApi: any = createAsyncThunk(
  "job_request/maple/create-shift-assignment",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/create-shift-assignment",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateMapleShiftAssignmentApi: any = createAsyncThunk(
  "job_request/maple/update-shift-assignment",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/update-shift-assignment",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteMapleShiftAssignmentApi: any = createAsyncThunk(
  "job_request/maple/delete-shift-assignment",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/delete-shift-assignment",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createMapleShiftApi: any = createAsyncThunk(
  "job_request/maple/create-shift",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/create-shift",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateMapleShiftApi: any = createAsyncThunk(
  "job_request/maple/update-shift",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/update-shift",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteMapleShiftApi: any = createAsyncThunk(
  "job_request/maple/delete-shift",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/delete-shift",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createMapleShiftCompletionApi: any = createAsyncThunk(
  "job_request/maple/create-shift-completion",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/create-shift-completion",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateMapleShiftCompletionApi: any = createAsyncThunk(
  "job_request/maple/update-shift-completion",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/update-shift-completion",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getMapleShiftAssignementApi: any = createAsyncThunk(
  "job_request/maple/get-shift-assignment",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post(
        "job_request/maple/get-shift-assignment",
        reqParam.formData,
        {
          headers: {
            Authorization: reqParam.token,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

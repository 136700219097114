import { gql } from "@apollo/client";
export const FetchDocuments = gql`
  query FetchDocuments($limit: Int, $offset: Int, $where: documents_bool_exp) {
    documents(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      name
      created_at
      verified_at
      document_url
      status
      category
      document_info
      enum_document_type {
        value
        comment
      }
      createdby
      customer {
        onboarding_documents_comments
      }
    }
    documents_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const FetchDocumentEnums = gql`
  query FetchDocumentEnums(
    $limit: Int
    $offset: Int
    $where: enum_document_type_bool_exp
  ) {
    enum_document_type(limit: $limit, offset: $offset, where: $where) {
      value
      comment
    }
  }
`;

export const FetchSSN = gql`
  query FetchSSN($practioner_id: uuid) {
    documents(where: {practioner_id: {_eq: $practioner_id},category: {_eq: "US_SSN_CARD"}}) {
      document_info
    }
  }
`;

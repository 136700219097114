const nurseTypes = [
  { id: 2, label: "Certified Nursing Assistant (CNA)", value: "CNA" },
  { id: 3, label: "Licensed Practical Nurse (LPN)", value: "LPN" },
  { id: 4, label: "Registered nurse (RN)", value: "RN" },
  // { id: 2, label: "Licensed Nursing Associate (LNA)", value: "LNA" },
  // { id: 4, label: "Licensed Vocational Nurse (LVN)", value: "LVN" },
  // { id: 5, label: "Nurse Practitioner (NP)", value: "NP" },
  // { id: 6, label: "Nurse Assistant (NA)", value: "NA" },
  // { id: 8, label: "Trauma Certified Registered Nurse (TCRN)", value: "TCRN" },
  // { id: 9, label: "Pediatric Nurse Practitioner (PNP)", value: "PNP" },
];
export default nurseTypes;

import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
} from "reactstrap";

import InvitationTable from "./InvitationTable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";

const RequestList: React.FC<{
  //
}> = props => {
  const {auth: { user }} = useSelector(authState);

  return (
    <React.Fragment>
      <div className="page-content AppBody">
        <MetaTags>
          <title>Invitations | Caregigs</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs 
            title="" 
            links={[{title: "Home", link: "/"}, {title: "Invitations", link: "#"}]} 
          />
          <InvitationTable 
            queryCondition={{
              createdby: {_eq: user.id},
              isactive: {_eq: true}
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(RequestList);
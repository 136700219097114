import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  forgotPasswordApi,
  verifyForgotPasswordApi,
  resetPasswordApi,
} from "../../toolkit/auth/auth.api";
import { authState } from "../../toolkit/auth/auth.slice";

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { isLoading, accessKey, accessToken },
  } = useSelector(authState);

  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(1);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      try {
        const resp = await dispatch(
          forgotPasswordApi({
            ...values,
            email: values.email?.toLowerCase()?.trim(),
            role: "merchant",
          })
        );
        if (resp.type === "auth/forgot-password/fulfilled") {
          setStep(2);
        } else {
          let message = "Something went wrong!";
          if (resp?.payload?.error?.message) {
            message = resp?.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
        }
      } catch (err) {
        console.log(err);
        Swal.fire("Something went wrong!", "", "warning");
      }
    },
  });
  const validationStep3 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confPassword: Yup.string().required("Please Enter Your Confirm Password"),
    }),
    onSubmit: async (values) => {
      try {
        const resp = await dispatch(
          resetPasswordApi({
            key: accessKey,
            password: values.password,
            accessToken,
          })
        );
        if (resp.type === "auth/reset-password/rejected") {
          let message = "Something went wrong!";
          if (resp?.payload?.error?.message) {
            message = resp?.payload?.error?.message;
          }
          Swal.fire(message, "", "warning");
        } else if (resp.type === "auth/reset-password/fulfilled") {
          Swal.fire("Password Updated Successfully", "", "success").then(() => {
            history.push("/login");
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire("Something went wrong!", "", "warning");
      }
    },
  });

  const handleSubmitOTP = async () => {
    try {
      const resp = await dispatch(
        verifyForgotPasswordApi({
          email: validation.values.email?.toLowerCase()?.trim(),
          code: otp,
        })
      );
      if (resp.type === "auth/verify-forgot-password/rejected") {
        let message = "Something went wrong!";
        if (resp?.payload?.error?.message) {
          message = resp?.payload?.error?.message;
        }
        Swal.fire(message, "", "warning");
      } else if (resp.type === "auth/verify-forgot-password/fulfilled") {
        setStep(3);
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong!", "", "warning");
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Caregigs - Partner Portal</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages authPage">
        {/* <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}> */}
        <div className="authPage_card">
          <div className="authPage_card-left forgot-pass">
            {/* <img
              src={"/caregigs_logo.png"}
              alt=""
              className="authPage_card-left-logo"
            /> */}
          </div>

          <div className="authPage_card-right">
            <div className="authPage_card-right-form">
              <div className="p-2">
                {/* <h4>Login to</h4> */}
                {/* <h1>CAREGIGS</h1> */}
                <img
                  src={"/caregigs_logo.png"}
                  alt=""
                  className="authPage_card-right-logo"
                />

                <h4 className="mb-3">Forgot Password</h4>
                {step === 1 ? (
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary btn-block cus-btn "
                          type="submit"
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                ) : step === 2 ? (
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmitOTP();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Verify OTP</Label>
                      <OtpInput
                        value={otp}
                        onChange={setOTP}
                        numInputs={4}
                        separator={<span>&nbsp;</span>}
                        inputStyle={{
                          width: "100%",
                          height: "3.5rem",
                          marginRight: 10,
                          marginBottom: "34px",
                        }}
                      />
                      {/* {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null} */}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary btn-block cus-btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationStep3.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">New Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter password"
                        type="password"
                        onChange={validationStep3.handleChange}
                        onBlur={validationStep3.handleBlur}
                        value={validationStep3.values.password || ""}
                        invalid={
                          validationStep3.touched.password &&
                          validationStep3.errors.password
                            ? true
                            : false
                        }
                      />
                      {validationStep3.touched.password &&
                      validationStep3.errors.password ? (
                        <FormFeedback type="invalid">
                          {validationStep3.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Confirm Password</Label>
                      <Input
                        name="confPassword"
                        className="form-control"
                        placeholder="Enter confirm password"
                        type="password"
                        onChange={validationStep3.handleChange}
                        onBlur={validationStep3.handleBlur}
                        value={validationStep3.values.confPassword || ""}
                        invalid={
                          validationStep3.touched.confPassword &&
                          validationStep3.errors.confPassword
                            ? true
                            : false
                        }
                      />
                      {validationStep3.touched.confPassword &&
                      validationStep3.errors.confPassword ? (
                        <FormFeedback type="invalid">
                          {validationStep3.errors.confPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary btn-block cus-btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}
                <div className="text-center mt-4">
                  <p>
                    Go back to{" "}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </Col>
          </Row>
        </Container> */}
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);

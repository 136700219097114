import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal, Label, Input, FormFeedback } from "reactstrap";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { showAlert } from "../../helpers/index";
import { Checkbox } from "rsuite";

import { SelectPicker } from "rsuite";

//GraphQL
import { useMutation, useApolloClient } from "@apollo/client";
import { InsertFAQs } from "gql/faq/mutation";
import { UpdateFAQs } from "gql/faq/mutation";

const status = ["true", "false"].map((item) => ({ label: item, value: item }));

const category = ["nurse", "Facility"].map((item) => ({
  label: item,
  value: item,
}));

const initialState: any = {
  title: "",
  description: "",
  category: "",
  active: "",
};

const CreateShiftAdmin: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  selectedFaq: any;
}> = ({ isEdit, setIsEdit, toggle, modal, selectedFaq }) => {
  const client = useApolloClient();
  const [CreateFAQMutation, { loading, error }] = useMutation(InsertFAQs);
  const [UpdateFAQMutation, { loading: loadingUpdateFAQ }] =
    useMutation(UpdateFAQs);
  const [state, setState] = useState<any>(initialState);
  const [type, setType] = useState<string>("");
  const [categoryType, setCategoryType] = useState<string>("");

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (state && state.title) || "",
      description: (state && state.description) || "",
      category: (state && state.category) || "",
      // active: (state && state.active) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        await UpdateFAQMutation({
          variables: {
            id: selectedFaq?.id,
            object: {
              title: values.title,
              description: values.description,
              // active: values.active,
              category: categoryType,
            },
          },
        });
        setIsEdit(false);
        // Swal.fire("Staff edited successfully", "", "success")
        showAlert({
          title: "Updated!",
          message: "Faq edited successfully",
          type: "success",
        });
        client.refetchQueries({
          include: ["FetchFaqs"],
        });
        handleClose();
        // refetch();
      } else {
        CreateFAQMutation({
          variables: {
            object: {
              title: values.title,
              description: values.description,
              // active: values.active,
              category: categoryType,
            },
          },
        })
          .then(() => {
            Swal.fire("Faq added successfully", "", "success");
            client.refetchQueries({
              include: ["FetchFaqs"],
            });
            handleClose();
            // refetch();
          })
          .catch((err: any) => {
            console.log(err);
            Swal.fire("Something went wrong", "", "warning");
          });
      }
    },
  });

  useEffect(() => {
    if (isEdit && selectedFaq?.id) {
      setState({
        ...state,
        title: selectedFaq.title,
        description: selectedFaq.description,
      });
      // setType(String(selectedFaq.active));
      setCategoryType(selectedFaq.category);
    }
  }, [selectedFaq, isEdit]);

  const handleClose = () => {
    toggle();
    validation.resetForm();
    setState(initialState);
    setIsEdit(false);
  };

  return (
    <>
      <Modal
        size={"md"}
        isOpen={modal}
        toggle={() => {
          handleClose();
        }}
        centered
        // scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {!!isEdit ? "Edit FAQs" : "Add FAQs"}
          </h5>
          <button
            type="button"
            onClick={() => {
              handleClose();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row form>
                  <div className="mb-3">
                    <Label className="form-label">Title</Label>
                    <Input
                      name="title"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={
                        validation.touched.title && validation.errors.title
                          ? true
                          : false
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Description</Label>
                        <Input
                          type="textarea"
                          id="textarea"
                          rows="3"
                          name="description"
                          label="Description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : false
                          }
                          disabled={false}
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    {/* <Label htmlFor="formrow-InputCity" style={{ width: "50%" }}>
                      Status
                    </Label> */}
                    <Label htmlFor="formrow-InputCity" style={{ width: "50%" }}>
                      Category
                    </Label>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center mb-4">
                    {/* <SelectPicker
                      data={status}
                      onChange={(data: string) => setType(data)}
                      value={type}
                      searchable={false}
                      style={{ width: "50%" }}
                    /> */}

                    <SelectPicker
                      data={category}
                      onChange={(value: string) => setCategoryType(value)}
                      value={categoryType}
                      searchable={false}
                      style={{ width: "50%" }}
                    />
                  </Row>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary ms-1"
                      disabled={loading || loadingUpdateFAQ}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default CreateShiftAdmin;

import React, { useEffect, useState, useCallback, useRef } from "react"
import {
	Modal,
	ModalFooter,
	Button
} from "reactstrap"
import { FiDownload } from "react-icons/fi";
import QRCode from 'qrcode'
import { toPng } from 'html-to-image';
import { Loader } from "rsuite";

//types
import locationType from "pages/Settings/locations/locations.types";

//redux
import { useSelector } from "react-redux";
import { profileState } from "toolkit/profile/profile.slice";

const QRViewer: React.FC<{
	isOpen: boolean;
	toggle: () => void;
	location: locationType | undefined
}> = ({ isOpen, toggle, location }) => {
	const ref = useRef<HTMLDivElement>(null)
	const { profile } = useSelector(profileState)
	const [qrImage, setQRImage] = useState<string>("/placeholder.jpg")
	const [loading, setLoading] = useState<boolean>(false)

	const onDownload = useCallback(() => {
		if (ref.current === null) {
			return
		}
		setLoading(true)
		toPng(ref.current, { cacheBust: true, })
			.then((dataUrl) => {
				const link = document.createElement('a')
				const filename = location?.name.replace(/\s+/g, '_').toLowerCase()
				link.download = `${filename}.png`
				link.href = dataUrl
				link.click()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [ref, location])

	useEffect(() => {
		if (location) {
			(async () => {
				const _qr = await QRCode.toDataURL(location.id)
				setQRImage(_qr)
			})()
		}
	}, [location])
	return (
		<Modal
			size="md"
			isOpen={isOpen}
			toggle={() => {
				toggle();
			}}
			centered
		>
			<div className="modal-header">
				<button
					type="button"
					onClick={() => {
						toggle();
					}}
					className="close"
					data-dismiss="modal"
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body" style={{ background: "whitesmoke" }}>
				<div className="qrviewer" ref={ref}>
					<div className="subBody">
						<img src="/caregigs_logo.png" className="logo" />
						<img src={qrImage} className="qr-logo" />
						<div className="textHeading">
							<h2>Facility</h2>
							<p>{profile?.organisation?.name}</p>
						</div>
						<div className="textHeading">
							<h2>Location</h2>
							<p>{location?.name}</p>
						</div>
					</div>
				</div>
			</div>
			<ModalFooter>
				<Button
					color="primary"
					className="m-auto w-25"
					onClick={onDownload}
					disabled={loading}
				>
					{loading
						? <Loader />
						:
						<>
							Download <FiDownload />
						</>}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
export default QRViewer
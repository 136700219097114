import React, { useState, useEffect } from "react"
import {
    Modal
} from "reactstrap"
import Zoom from 'react-img-zoom'

//Redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//types
import DocumentType from "./documents.types"
//helpers
import { getImage } from "helpers"
const ImageViewer: React.FC<{
    isOpen: boolean;
    toggle: () => void;
    document: DocumentType | null
}> = ({ isOpen, toggle, document }) => {
    const { auth: { token } } = useSelector(authState);
    const [image, setImage] = useState<string>("/placeholder.jpg")
    const handleGetImage = async (link: string) => {
        const _image = await getImage(link, token, "image")
        setImage(_image as string)
    }
    useEffect(() => {
        if (document?.document_url) {
            handleGetImage(document?.document_url)
        }
    }, [document])
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            toggle={() => {
                toggle();
            }}
            centered
        >
            <div className="modal-header">
                {/* <h5 className="modal-title mt-0">Profile</h5> */}
                <button
                    type="button"
                    onClick={() => {
                        toggle();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{display: "flex", justifyContent: "center", alignItems: "center", cursor: "zoom-in"}}>
                {/* <img src={image} style={{width: "100%"}}/> */}
                <Zoom
                    img={image}
                    zoomScale={2}
                    width={750}
                    height={600}
                    key={image}
                    style={{cursor: "pointer"}}
                />
            </div>
        </Modal>
    )
}
export default ImageViewer
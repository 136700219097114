import { combineReducers } from "redux";
import authReducer from "./auth/auth.slice";
import layoutReducer from "./layout/layout.slice";
import schedulerReducer from "./scheduler/scheduler.slice";
import profileReducer from "./profile/profile.slice";
import onboardingReducer from "./onboarding/onboarding.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  scheduler: schedulerReducer,
  profile: profileReducer,
  onboarding: onboardingReducer
});
export default rootReducer;

import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { Dropdown } from "rsuite";
import { GoKebabVertical } from "react-icons/go";

//types
import invitationType from "./invitations.types";
export const FormatData = (employees: invitationType[]) => {
  const _employees = employees.map((elem: invitationType) => {
    const customer_name = `${elem.customer?.firstname} ${elem.customer?.lastname}`;
    return {
      id: elem.id,
      status: elem.status,
      isactive: elem.isactive,
      created_at: elem.created_at,
      job_date: elem.job_date,
      position_name: elem.position?.name || "N/A",
      position_id: elem.position?.id,
      position_start_time: elem.position?.start_time,
      position_end_time: elem.position?.end_time,
      location_name: elem.location?.name || "N/A",
      location_id: elem.location?.id,
      fee_amount: `$${elem.fee_amount}`,
      currency: elem.currency,
      customer_name,
      customer_id: elem.customer?.id,
      job_requests: elem.job_requests,
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const InvitationColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  handleChangeStatus: (row: invitationType) => void,
  handleDeleteInvitation: (row: invitationType) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "img",
    text: "#",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        {!user.customer_avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.customer_name.charAt(0)}
            </span>
          </div>
        ) : (
          <div></div>
        )}
      </>
    ),
  },
  {
    text: "Name",
    dataField: "customer_name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link
            to={`/marketplace-profile/${user.customer_id}`}
            className="text-dark"
          >
            {formatHighlighter(user.customer_name, searchKey, filterValue)}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "position_name",
    text: "Position",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "job_date",
    text: "Date",
    sort: true,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "position_start_time",
    text: "Shift Start",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "position_end_time",
    text: "Shift End",
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent, "HH:mm").format("h:mm A"),
  },
  {
    dataField: "fee_amount",
    text: "Rate",
    sort: true,
    hidden: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>
        {" "}
        <Badge
          pill
          to="#"
          className={`badge ${
            cellContent === "requested"
              ? "bg-warning"
              : cellContent === "accepted"
              ? "bg-success"
              : "bg-danger"
          } font-size-11 m-2 p-2`}
        >
          {formatHighlighter(
            cellContent?.toUpperCase(),
            searchKey,
            filterValue
          )}
        </Badge>
      </span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => (
      <Dropdown
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        {(row.status === "requested" || row.status === "accepted") && (
          <Dropdown.Item onClick={() => handleChangeStatus(row)}>
            Cancel
          </Dropdown.Item>
        )}
        {row.status !== "accepted" && (
          <Dropdown.Item onClick={() => handleDeleteInvitation(row)}>
            Delete
          </Dropdown.Item>
        )}
      </Dropdown>
    ),
  },
];
export const BuildInvitationFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        status: { _eq: filterValue.status },
      };
    }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { job_date: { _gte: job_date_from } },
            { job_date: { _lte: job_date_till } },
          ],
        };
      }
    }
    if (filterValue?.hasOwnProperty("position")) {
      conditionTemp = {
        ...conditionTemp,
        position_id: { _in: filterValue.position },
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildInvitationSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        {
          customer: {
            _or: [
              { firstname: { _ilike: `%${value}%` } },
              { lastname: { _ilike: `%${value}%` } },
            ],
          },
        },
        { position: { name: { _ilike: `%${value}%` } } },
        { status: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

import { gql } from "@apollo/client";

export const InsertSubscriber = gql`
  mutation InsertSubscriber($object: notification_subscribers_insert_input!) {
    insert_notification_subscribers_one(object: $object) {
      id
    }
  }
`;

export const DeleteSubscriber = gql`
  mutation DeleteSubscriber($id: uuid!) {
    delete_notification_subscribers_by_pk(id: $id) {
      id
    }
  }
`;

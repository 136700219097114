import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Popover, Whisper } from "rsuite";

const SketchColorPicker: React.FC<{
  color: string;
  onChange: (color: string) => void;
}> = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const speaker = (
    <Popover style={{zIndex: 9999}}>
      {displayColorPicker ? (
          <div className="popover">
            <div
              className="cover"
              onClick={() => setDisplayColorPicker(false)}
            />
            <SketchPicker
              color={color}
              onChange={(color: any) => onChange(color.hex)}
            />
          </div>
        ) : null}
    </Popover>
  );

  return (
    <>
      <div className="custom-sketch">
      <Whisper
        preventOverflow
        trigger="click"
        speaker={speaker}
        placement="rightStart"
      >
        <div
          className="swatch"
          onClick={() => setDisplayColorPicker(true)}
        >
          <div className="color" style={{ backgroundColor: color }} />
        </div>
        </Whisper>
      </div>
    </>
  );
};

export default SketchColorPicker;

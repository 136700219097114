import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker, Checkbox } from 'rsuite'
import { useState } from "react"
import PropTypes from "prop-types"
import CheckPickerPaginate from "../CheckPickerPaginate"
import { Link, useParams, useHistory } from "react-router-dom";

//graphql
import { FetchLocationsForFilter } from "gql/locations/query"
import { FetchAreas } from "gql/areas/query";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";
const PositionFilter = (props) => {
	const history = useHistory();
    const params = useParams();
	const { auth: { user } } = useSelector(authState)
	const [filterData, setFilterData] = useState([])
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})

	const statusData = [{ label: "Active", value: "true" }, { label: "Inactive", value: "false" }]
	const filledData = [{ label: "Filled", value: "true" }, { label: "Not Filled", value: "false" }]

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			const filterValuesTemp = { ...filterValues }
			if (!filters.includes("status")) {
				delete filterValuesTemp["status"]
			}
			if (!filters.includes("created_at")) {
				delete filterValuesTemp["created_at"]
			}
			if (!filters.includes("location")) {
				delete filterValuesTemp["location"]
			}
			if (!filters.includes("filled")) {
				delete filterValuesTemp["filled"]
			}
			if (!filters.includes("area")) {
				delete filterValuesTemp["area"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		const filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "status") {
				filterDataTemp.push({ label: "Status", value: "status" })
			}
			if (filter === "created_at") {
				filterDataTemp.push({ label: "Created At", value: "created_at" })
			}
			if (filter === "location") {
				filterDataTemp.push({ label: "Location", value: "location" })
			}
			if (filter === "filled") {
				filterDataTemp.push({ label: "Filled", value: "filled" })
			}
			if (filter === "area") {
				filterDataTemp.push({ label: "Area", value: "area" })
			}
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "status") {
					return (
						<React.Fragment key={i}>
							<SelectPicker
								data={statusData}
								placeholder={"Select Status"}
								style={{ marginLeft: 10 }}
								onChange={(status) => setFilterValues({ ...filterValues, status })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Created At Date"
							// value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "location") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={FetchLocationsForFilter}
								placeholder="Select Location"
								value={Array.isArray(filterValues.location) ? filterValues.location : []}
								onChange={(location) => setFilterValues({ ...filterValues, location })}
								arrKey="locations"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={{
									isactive: { _eq: true },
									createdby: { _eq: user.role === "moderator" ?  params.id : user.id }
								}}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("location")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "area") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={FetchAreas}
								placeholder="Select Area"
								value={Array.isArray(filterValues.area) ? filterValues.area : []}
								onChange={(area) => setFilterValues({ ...filterValues, area })}
								arrKey="areas"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={{ user_id: { _eq: user.role === "moderator" ?  params.id : user.id } }}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("area")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "filled") {
					return (
						<React.Fragment key={i}>
							<SelectPicker
								data={filledData}
								placeholder={"Select Filled Status"}
								style={{ marginLeft: 10 }}
								onChange={(filled) => setFilterValues({ ...filterValues, filled })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("filled")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
PositionFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default PositionFilter
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Checkbox } from "rsuite";

// icons
import { FiCheck } from "react-icons/fi";

//types
import { jobOpeningInputType } from "pages/JobOpenings/openings.types";

import SelectPickerPaginate from "../Filters/SelectPickerPaginate";

//graphql
import { FetchLocationsForFilter } from "gql/locations/query";
import { FetchPositionsForFilter } from "gql/positions/query";
import { InsertJobOpeningOne } from "gql/jobOpenings/mutation";
import { useMutation, useApolloClient } from "@apollo/client";

//helpers
import { enumerateDaysBetweenDates } from "../../helpers/index";

//redux
import { useSelector } from "react-redux";
import { profileState } from "toolkit/profile/profile.slice";
import { authState } from "toolkit/auth/auth.slice";

const initialState: jobOpeningInputType = {
  name: "",
  location_id: "",
  isactive: true,
  available_from: new Date(),
  available_till: new Date(),
  fee_amount: "",
  currency: "",
  fee_period: "",
  facility_details: "",
  special_instructions: "",
  position_id: "",
  customer_id: "",
};
const JobOpeningModal: React.FC<{
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  toggle: () => void;
  modal: boolean;
  refetch: () => void;
}> = ({ isEdit, setIsEdit, toggle, modal, refetch }) => {
  const client = useApolloClient();
  const [CreateJobOpeningMutation, { loading, error }] =
    useMutation(InsertJobOpeningOne);
  const [jobOpening, setJobOpening] =
    useState<jobOpeningInputType>(initialState);
  const [isRecurring, setRecurring] = useState<boolean>(false);
  const { profile } = useSelector(profileState);
  const {
    auth: { user },
  } = useSelector(authState);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // name: (jobOpening && jobOpening.name) || '',
      // fee_amount: (jobOpening && jobOpening.fee_amount) || "",
      currency: (jobOpening && jobOpening.currency) || "USD",
      facility_details: (jobOpening && jobOpening.facility_details) || "",
      special_instructions:
        (jobOpening && jobOpening.special_instructions) || "",
      available_from: (jobOpening && jobOpening.available_from) || new Date(),
      available_till: (jobOpening && jobOpening.available_till) || new Date(),
      location_id: (jobOpening && jobOpening.location_id) || "",
      position_id: (jobOpening && jobOpening.position_id) || "",
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Please Enter Name"),
      // fee_amount: Yup.number().required("Please Enter Rate"),
      currency: Yup.string().required("Please Enter Currency"),
      // facility_details: Yup.string().required("Please Enter Facility Details"),
      // special_instructions: Yup.string().required("Please Enter Special Instructions"),
      location_id: Yup.string().required("Please Select Location"),
      position_id: Yup.string().required("Please Select Position"),
      available_from: Yup.date().min(today, "Past Date Selection Not Allowed"),
      available_till: Yup.date()
        .min(today, "Past Date Selection Not Allowed")
        .test(
          "available_till_test",
          "Shift End Date Should Not be Prior to Start Date",
          function (value) {
            const { available_from } = this.parent;
            if (isRecurring) {
              return !moment(value).isBefore(available_from);
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        // const updateUser = {
        //   id: location.id,
        //   name: values.name,
        //   designation: values.designation,
        //   tags: values.tags,
        //   email: values.email,
        //   projects: values.projects,
        // };

        // update user
        // dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
      } else {
        const objects: any[] = [];
        const dates = enumerateDaysBetweenDates(
          values.available_from,
          isRecurring ? values.available_till : values.available_from
        );
        dates.map((date: Date) => {
          objects.push({
            name: "N/A",
            // name: values.name,
            location_id: values.location_id,
            isactive: jobOpening.isactive,
            // fee_amount: values.fee_amount,
            currency: "USD",
            fee_period: "per_hour",
            job_date: moment(date).format("YYYY-MM-DD"),
            requirement: {
              facility_details: [""],
              special_instructions: [values.special_instructions],
            },
            position_id: values.position_id,
            organisation_id: profile.organisation_id,
          });
        });
        try {
          await CreateJobOpeningMutation({
            variables: {
              objects,
            },
          });
          handleClose();
          handleRefetch();
          Swal.fire("Shift Created Successfully", "", "success");
          refetch();
        } catch (err) {
          Swal.fire("Something went wrong", "", "warning");
        }
      }
    },
  });
  const handleClose = () => {
    toggle();
    validation.resetForm();
    setJobOpening(initialState);
  };
  const handleRefetch = () => {
    client.refetchQueries({
      include: [
        "FetchJobOpeningsForScheduler",
        "FetchJobRequestsForScheduler",
        "FetchInvitationsForScheduler",
      ],
    });
  };
  return (
    <Modal
      className="customModal"
      isOpen={modal}
      toggle={handleClose}
      backdrop="static"
    >
      <ModalHeader toggle={handleClose} tag="h4">
        {!!isEdit ? "Edit Gig Shift" : "Add Gig Shift"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              {/* <div className="mb-4">
					<Label className="form-label">Name</Label>
					<Input
						name="name"
						type="text"
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.name || ""}
						invalid={
							validation.touched.name && validation.errors.name ? true : false
						}
					/>
					{validation.touched.name && validation.errors.name ? (
						<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
					) : null}
				</div> */}
              <div className="mb-4">
                <Label className="form-label">Location</Label>
                <div>
                  <SelectPickerPaginate
                    query={FetchLocationsForFilter}
                    placeholder="Select Location"
                    value={validation.values.location_id}
                    onChange={(newValue) =>
                      validation.setFieldValue("location_id", newValue)
                    }
                    arrKey="locations"
                    objectLabel="name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={{
                      isactive: { _eq: true },
                      createdby: { _eq: user?.id },
                    }}
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.location_id &&
                      validation.errors.location_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.location_id &&
                  validation.errors.location_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.location_id}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <Label className="form-label">Position</Label>
                <div>
                  <SelectPickerPaginate
                    query={FetchPositionsForFilter}
                    placeholder="Select Position"
                    value={validation.values.position_id}
                    onChange={(newValue) =>
                      validation.setFieldValue("position_id", newValue)
                    }
                    arrKey="positions"
                    objectLabel="name"
                    objectValue="id"
                    style={{ width: "100%" }}
                    paginate={true}
                    filterCondition={
                      validation.values.location_id?.length > 0
                        ? {
                            location_id: { _eq: validation.values.location_id },
                            createdby: { _eq: user?.id },
                            active: { _eq: true },
                          }
                        : {
                            createdby: { _eq: user?.id },
                            active: { _eq: true },
                          }
                    }
                    getFullObj={false}
                    searchValue=""
                    menuStyle={{ zIndex: 9999 }}
                    cleanable={false}
                    className={
                      validation.touched.position_id &&
                      validation.errors.position_id
                        ? "border-danger"
                        : ""
                    }
                  />
                  {validation.touched.position_id &&
                  validation.errors.position_id ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {validation.errors.position_id}
                    </div>
                  ) : null}
                </div>
              </div>
              <Row>
                {/* <Col xs={12}>
                  <div className="mb-4">
                    <Label className="form-label">Rate ($/hr)</Label>
                    <Input
                      name="fee_amount"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fee_amount || ""}
                      invalid={
                        validation.touched.fee_amount &&
                        validation.errors.fee_amount
                          ? true
                          : false
                      }
                      min={0}
                    />
                    {validation.touched.fee_amount &&
                    validation.errors.fee_amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.fee_amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col> */}
                {/* <Col xs={6}>
					<div className="mb-4">
						<Label className="form-label">Currency</Label>
						<Input
							name="currency"
							type="text"
							disabled={true}
							onBlur={validation.handleBlur}
							value={"USD"}
						/>
					</div>
				</Col> */}
              </Row>
              <div className="mb-4">
                <Row>
                  <Col md={isRecurring ? 6 : 12}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-email-Input">
                        {isRecurring
                          ? `Recurring Shift Start Date`
                          : `Shift Date`}
                      </Label>
                      <Input
                        name="available_from"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          moment(validation.values.available_from).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        invalid={
                          validation.touched.available_from &&
                          validation.errors.available_from
                            ? true
                            : false
                        }
                      />
                      {validation.touched.available_from &&
                      validation.errors.available_from ? (
                        <FormFeedback type="invalid">
                          {validation.errors.available_from}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {isRecurring && (
                    <Col md={6}>
                      <div className="mb-2">
                        <Label htmlFor="formrow-email-Input">
                          Recurring Shift End Date
                        </Label>
                        <Input
                          name="available_till"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            moment(validation.values.available_till).format(
                              "YYYY-MM-DD"
                            ) || ""
                          }
                          invalid={
                            validation.touched.available_till &&
                            validation.errors.available_till
                              ? true
                              : false
                          }
                        />
                        {validation.touched.available_till &&
                        validation.errors.available_till ? (
                          <FormFeedback type="invalid">
                            {validation.errors.available_till}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  )}
                </Row>
                <div>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      checked={isRecurring}
                      onChange={(value, checked) => setRecurring(checked)}
                      id="recurring_checkbox"
                    />
                    <label
                      htmlFor="recurring_checkbox"
                      style={{ marginBottom: 0 }}
                    >
                      Allow Recurring Shifts
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="mb-4">
					<Label className="form-label">Facility Details</Label>
					<Input
						name="facility_details"
						label="Facility Details"
						type="textarea"
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.facility_details || ""}
						invalid={
							validation.touched.facility_details && validation.errors.facility_details ? true : false
						}
					/>
					{validation.touched.facility_details && validation.errors.facility_details ? (
						<FormFeedback type="invalid">{validation.errors.facility_details}</FormFeedback>
					) : null}
				</div> */}
              <div className="mb-4">
                <Label className="form-label">Special Instructions</Label>
                <Input
                  name="special_instructions"
                  label="Special Instructions"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.special_instructions || ""}
                  invalid={
                    validation.touched.special_instructions &&
                    validation.errors.special_instructions
                      ? true
                      : false
                  }
                />
                {validation.touched.special_instructions &&
                validation.errors.special_instructions ? (
                  <FormFeedback type="invalid">
                    {validation.errors.special_instructions}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                  disabled={loading}
                >
                  <FiCheck className="mr-4" />&nbsp;
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default JobOpeningModal;

import React, { useEffect, useState } from "react"
import {
	Col,
	Row
} from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next'
import { Pagination } from 'rsuite'
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

//Functions
import { CustomerColumns, FormatData } from "pages/Marketplace/marketplace.functions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { authState } from "toolkit/auth/auth.slice"

// icons
import {
	FiSend,
	FiRefreshCw,
	FiArrowLeft,
	FiDownloadCloud,
} from "react-icons/fi";

const MarketplaceListView: React.FC<{
	searchKey: string;
	filterValue: string;
	onClickInvite: (customer: any) => void;
	customerList: any[];
	PageData: any;
	setPageData: (params: any) => void;
	total: number,
	handleSelectRow: (data: any[]) => void
	resetSelection?: any
}> = ({
	searchKey,
	filterValue,
	onClickInvite,
	handleSelectRow,
	customerList,
	PageData,
	setPageData,
	total,
	resetSelection
}) => {
		const { ExportCSVButton } = CSVExport;
		const { auth: { user } } = useSelector(authState)
		const [selected, setSelected] = useState<any[]>([])
		const handleOnSelect = (row: any, isSelect: boolean) => {
			if (isSelect) {
				setSelected([...selected, row])
			} else {
				setSelected(selected.filter(x => x.id !== row.id))
			}
		}
		const handleOnSelectAll = (isSelect: boolean, rows: any[]) => {
			if (isSelect) {
				setSelected(rows)
			} else {
				setSelected([])
			}
		}
		useEffect(() => {
			handleSelectRow(selected)
		}, [selected])

		if(resetSelection) {
			resetSelection(() => setSelected([]))
		}

		return (
			<Row>
				<ToolkitProvider
					keyField='id'
					columns={CustomerColumns(searchKey, filterValue, onClickInvite, user.role)}
					data={FormatData(Array.isArray(customerList) ? customerList : [])}
					bootstrap4
					exportCSV={{ onlyExportSelection: true, exportAll: true }}
				>
					{toolkitProps => (
						<React.Fragment>
							<Row>
							<Col sm="12" className="p-0">
								{user.role === "moderator" && (
									<div
									className="text-sm-end d-flex justify-content-end mb-3"
								>
									<ExportCSVButton
										{...toolkitProps.csvProps}
										style={{
											border: "1px solid #74788d",
										}}
									>
										<FiDownloadCloud />
										&nbsp;&nbsp; Export
									</ExportCSVButton>
									</div>
								)}
								</Col>
								<Col xl="12">
									<div className="table-responsive">
										<BootstrapTable
											key={`${searchKey}_${JSON.stringify(filterValue)}`}
											bordered={false}
											striped={false}
											classes={
												"table align-middle table-nowrap table-check"
											}
											headerWrapperClasses={"thead-light"}
											{...toolkitProps.baseProps}
											noDataIndication={() => "No nurses found!"}
											selectRow={user.role === "moderator" ? {
												mode: 'checkbox',
												clickToSelect: true,
												selected: selected?.map(s => s?.id),
												onSelect: handleOnSelect,
												onSelectAll: handleOnSelectAll
											} : undefined}
										/>
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-30">
								<Col className="inner-custom-pagination d-flex">
									<div className="d-inline mt-3 w-100">
										<Pagination
											total={total}
											// total={data?.customers_aggregate?.aggregate?.count || 0}
											limit={PageData.limit}
											activePage={PageData.pageNo}
											onChangePage={(pageNo) => setPageData({ ...PageData, pageNo })}
											limitOptions={[30, 50, 100, 200, 300]}
											layout={['limit', 'pager']}
											onChangeLimit={(limit) => setPageData({ ...PageData, limit })}
											className="d-flex justify-content-between"
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ToolkitProvider>
			</Row>
		)
	}
export default MarketplaceListView
import { createSlice } from "@reduxjs/toolkit";

interface StateType {
  showOnboardingModal: boolean;
  showScheduleBanner: boolean;
  showMarketplaceBanner: boolean;
  showTimecardsBanner: boolean;
  showGigShiftsBanner: boolean;
  showGigRequestsBanner: boolean;
}

const initialState: StateType = {
  showOnboardingModal: true,
  showScheduleBanner: true,
  showMarketplaceBanner: true,
  showTimecardsBanner: true,
  showGigShiftsBanner: true,
  showGigRequestsBanner: true,
};

export const onboardingSlice: any = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    handleSetOnboardingModal(state, action) {
      return { ...state, showOnboardingModal: action.payload };
    },
    handleSetScheduleBanner(state, action) {
      return { ...state, showScheduleBanner: action.payload };
    },
    handleSetMarketplaceBanner(state, action) {
      return { ...state, showMarketplaceBanner: action.payload };
    },
    handleSetTimecardsBanner(state, action) {
      return { ...state, showTimecardsBanner: action.payload };
    },
    handleSetGigShiftsBanner(state, action) {
      return { ...state, showGigShiftsBanner: action.payload };
    },
    handleSetGigRequestsBanner(state, action) {
      return { ...state, showGigRequestsBanner: action.payload };
    },
  }
});
export const onboardingState = (state: any) => state.onboarding;
export const { handleSetOnboardingModal, handleSetScheduleBanner, handleSetMarketplaceBanner, handleSetTimecardsBanner, handleSetGigShiftsBanner, handleSetGigRequestsBanner } = onboardingSlice.actions
export default onboardingSlice.reducer;
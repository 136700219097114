import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Dropdown } from "rsuite";
import { GoKebabVertical } from "react-icons/go";

import positionType from "./positions.types";
export const FormatData = (locations: positionType[]) => {
  const _locations = locations.map((elem: positionType) => {
    return {
      id: elem.id,
      name: elem.name,
      abbreviation: elem.abbreviation || elem.name,
      active: elem.active,
      organisation_id: elem?.organisation?.id,
      organisation_name: elem?.organisation?.name,
      created_at: elem.created_at,
      location_id: elem?.location?.id,
      location_name: elem?.location?.name,
      requirement: elem.requirement,
      count: elem.count,
      filled: elem.filled,
      type: elem.type,
      start_time: elem.start_time,
      end_time: elem.end_time,
      text_color: elem.text_color,
      background_color: elem.background_color,
      timings: `${elem.start_time} - ${elem.end_time}`,
      notes: elem.notes,
      break_time: elem.break_time,
      area: elem.area,
      area_name: elem.area?.name || "N/A",
      contract_rate:
        elem?.contract_rate > 0 ? `$ ${elem?.contract_rate}` : "N/A",
      contract_overtime_rate:
        elem?.contract_overtime_rate > 0
          ? `$ ${elem?.contract_overtime_rate}`
          : "N/A",
    };
  });
  return _locations;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const PositionColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  handleChangeStatus: (position: positionType) => void,
  onEditClick: (position: positionType, isClone: boolean) => void
) => [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Position Name",
    sort: true,
    formatter: (cellContent: string, location: any) => (
      // <div className="d-flex gap-3">
      //   <Link className="text-primary" to="#" onClick={() => onEditClick(location)}>
      //     {formatHighlighter(cellContent, searchKey, filterValue)}
      //   </Link>
      // </div>
      <div className="d-flex gap-3">
        <div
          style={{
            background: location.background_color,
            width: 20,
            border: "1px solid #002",
          }}
        />
        <Link
          className="text-primary"
          to="#"
          onClick={() => onEditClick(location, false)}
        >
          {formatHighlighter(cellContent, searchKey, filterValue)}
        </Link>
      </div>
    ),
  },
  {
    dataField: "contract_rate",
    text: "Regular Rate",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "contract_overtime_rate",
    text: "Overtime Rate",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "abbreviation",
    text: "Abbreviation",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "type",
    text: "Type",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "location_name",
    text: "Location",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "area_name",
    text: "Area",
    sort: true,
    formatter: (cellContent: string, location: any) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "timings",
    text: "Timing",
    sort: false,
  },
  {
    dataField: "active",
    text: "Status",
    sort: true,
    formatter: (cellContent: boolean, location: any) => (
      <p
        className={cellContent ? "text-success" : "text-danger"}
        style={{ marginBottom: 0 }}
      >
        {cellContent ? "Active" : "Inactive"}
      </p>
    ),
  },
  // {
  //   dataField: 'filled',
  //   text: 'Filled',
  //   sort: true,
  //   formatter: (cellContent: boolean, location: any) => (
  //     <p className={cellContent ? "text-success" : "text-danger"}>{cellContent ? "Filled" : "Not Filled"}</p>
  //   )
  // },
  {
    text: "Created At",
    dataField: "created_at",
    sort: false,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MM/DD/YYYY h:mm A"),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: undefined, row: any) => (
      <Dropdown
        renderToggle={(props, ref) => <GoKebabVertical {...props} />}
        placement="leftStart"
      >
        <Dropdown.Item onClick={() => onEditClick(row, false)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onEditClick(row, true)}>
          Clone
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChangeStatus(row)}>
          Delete
        </Dropdown.Item>
      </Dropdown>
    ),
  },
];
export const BuildPositionSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { type: { _ilike: `%${value}%` } },
        { location: { name: { _ilike: `%${value}%` } } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildPositionFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        active: { _eq: filterValue.status === "true" ? true : false },
      };
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        const created_at_from = moment(filterValue.created_at[0]).format(
          "YYYY-MM-DD"
        );
        const created_at_till = moment(filterValue.created_at[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { created_at: { _gte: created_at_from } },
            { created_at: { _lte: created_at_till } },
          ],
        };
      }
    }
    if (
      filterValue?.hasOwnProperty("location") &&
      filterValue.location?.length
    ) {
      conditionTemp = {
        ...conditionTemp,
        location: { id: { _in: filterValue.location } },
      };
    }
    if (filterValue?.hasOwnProperty("area") && filterValue.area?.length) {
      conditionTemp = {
        ...conditionTemp,
        area_id: { _in: filterValue.area },
      };
    }
    if (filterValue?.hasOwnProperty("filled")) {
      conditionTemp = {
        ...conditionTemp,
        filled: { _eq: filterValue.filled === "true" ? true : false },
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showAlert } from "helpers/index";
import { useParams } from "react-router-dom";
import moment from "moment";

import SelectPickerPaginate from "components/Filters/SelectPickerPaginate";

// icons
import { FiSend } from "react-icons/fi";

//function
import { useDispatch, useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

//GraphQL
import { UpdateJobOpening } from "gql/jobOpenings/mutation";
import { InsertJobRequests } from "gql/jobRequests/mutation";
import { FetchJobRequests } from "../../gql/jobRequests/query";
import {
  FetchCustomersForFilter,
  FetchCustomersViewForFilter,
} from "gql/customers/query";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";

import {
  createMapleShiftAssignmentApi,
  getMapleShiftAssignementApi,
} from "toolkit/admin/admin.api";

const initialState: any = {
  customer_id: "",
};

const AssignNurseModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  selectedShift: any;
}> = ({ toggle, modal, selectedShift }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const params: any = useParams();
  const {
    auth: { token },
  } = useSelector(authState);
  const [state, setState] = useState<any>(initialState);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [CreateJobRequestMutation, { loading }] =
    useMutation(InsertJobRequests);
  const [UpdateJobOpeningMutation] = useMutation(UpdateJobOpening);

  const checkNurseAvailability = async (customer_id: string) => {
    const checkNurseAvailableCondition = {
      active: { _eq: true },
      practitioner_id: { _eq: customer_id },
      // createdby: { _eq: selectedShift?.createdBy },
      is_confirmed: { _eq: true },
      status: { _eq: "approved" },
      // job_opening: {
      //   position: {
      //     start_time: {
      //       _eq: moment(selectedShift?.position_start_time).format("HH:mm"),
      //     },
      //   },
      // },
      job_date: { _eq: moment(selectedShift?.job_date).format("YYYY-MM-DD") },
    };
    try {
      const resp = await client.query({
        query: FetchJobRequests,
        variables: {
          where: checkNurseAvailableCondition,
        },
        fetchPolicy: "no-cache",
      });
      return resp.data?.job_requests || [];
    } catch (err) {
      return null;
    }
  };

  const handleClose = () => {
    toggle();
  };

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer_id: (state && state.customer_id) || "",
    },
    validationSchema: Yup.object({
      customer_id: Yup.string().required("Please Select Nurse"),
    }),
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);
        const formData = {
          mapleShiftId: selectedShift?.maple_shift_id,
          externalUserId: validation.values.customer_id,
        };
        const overlappingOpenShifts = await checkNurseAvailability(
          validation.values.customer_id
        );
        if (overlappingOpenShifts.length) {
          showAlert({
            title: "Warning!",
            message: "Nurse not avaliable for selected date!",
            type: "Warning",
          });
        } else {
          const mapleResponse = await dispatch(
            createMapleShiftAssignmentApi({ formData, token })
          );
          if (mapleResponse?.payload?.data) {
            await UpdateJobOpeningMutation({
              variables: {
                id: selectedShift?.id,
                object: {
                  // organisation_id: "33624724-640f-4eae-986f-542ecc09bbaa",
                  filled: true,
                },
              },
            });
            await CreateJobRequestMutation({
              variables: {
                objects: {
                  job_opening_id: selectedShift?.id,
                  practitioner_id: values.customer_id,
                  createdby: selectedShift?.createdBy,
                  updatedby: selectedShift?.createdBy,
                  is_confirmed: true,
                  status: "approved",
                  job_date: selectedShift?.job_date,
                  maple_shift_assignment_id: mapleResponse?.payload?.data?.id,
                },
              },
            });

            client.refetchQueries({
              include: ["FetchJobOpenings"],
            });
            showAlert({
              title: "Updated!",
              message: "Nurse assigned successfully!",
              type: "success",
            });
          } else {
            showAlert({
              title: "Error!",
              message:
                `${
                  JSON.parse(mapleResponse?.payload?.error?.message)?.errors[0]
                    ?.title
                }` ?? "Something went wrong",
              type: "danger",
            });
          }
          validation.resetForm();
          handleClose();
        }
      } catch (err) {
        console.log(err);
        showAlert({
          title: "Error!",
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    },
  });
  
  return (
    <Modal
      isOpen={modal}
      size="md"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Assign Nurse
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label htmlFor="formrow-assignto-Input">Select Nurse</Label>
                <SelectPickerPaginate
                  query={FetchCustomersViewForFilter}
                  placeholder="Select Nurse"
                  value={validation.values.customer_id}
                  onChange={(newValue) => {
                    validation.setFieldValue("customer_id", newValue);
                  }}
                  arrKey="customers_view"
                  objectLabel="firstname"
                  formatLabel={(item) =>
                    `${item.firstname} ${item.lastname} (${item.nurse_type})`
                  }
                  objectValue="id"
                  style={{ width: "100%" }}
                  paginate={true}
                  filterCondition={{
                    verified: { _eq: true },
                    is_deleted: { _eq: false },
                    nurse_type:
                      selectedShift?.maple_worker_requirement == "CAREGIVER"
                        ? { _eq: "CNA", _is_null: false }
                        : selectedShift?.maple_worker_requirement == "NURSE"
                        ? { _in: ["LPN", "RN"], _is_null: false }
                        : selectedShift?.maple_worker_requirement == "LVN"
                        ? { _eq: "LPN", _is_null: false }
                        : {
                            _eq: selectedShift?.maple_worker_requirement,
                            _is_null: false,
                          },
                  }}
                  getFullObj={false}
                  searchValue=""
                  menuStyle={{ zIndex: 9999 }}
                  cleanable={false}
                  className={
                    validation.touched.customer_id &&
                    validation.errors.customer_id
                      ? "border-danger"
                      : ""
                  }
                />
                {validation.touched.customer_id &&
                validation.errors.customer_id ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.customer_id}
                  </div>
                ) : null}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={btnLoading}
                >
                  <FiSend /> &nbsp; Assign
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default AssignNurseModal;

import React, { useEffect, useState } from "react";
import "../scheduler/calendars/calendarView.scss";

//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import * as _Moment from "moment";
import { extendMoment } from "moment-range";
import "pages/Schedule/grid.scss";
import axios from "axios";

import {
  Calendar as ReactCalender,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

//components
import CreateInterviewModal from "components/marketplace/CreateInterviewModal";

//graphql
import { useQuery } from "@apollo/client";
import { FetchInterviews } from "gql/interviews/query";

//type
import InterviewType from "pages/Admin/calendar/interviews.types";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const moment = extendMoment(_Moment);

const InterviewCalendar: React.FC = (props) => {
  const initialCondition = {
    onboarding_interview_status: { _eq: "scheduled" },
    onboarding_interview_date: {
      _lte: moment().add(1, "M").format("YYYY-MM-DD"),
      _gte: moment().format("YYYY-MM-DD"),
    },
    verified: { _eq: false },
  };
  const {auth: {token}} = useSelector(authState);
  const [queryCondition, setQueryCondition] = useState(initialCondition);
  const [interviews, setInterviews] = useState<any[]>([]);
  const [calendlyEvents, setCalendlyEvents] = useState<any[]>([]);
  const [interviewModalOpen, setInterviewModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<InterviewType | null>(null);
  const [range, setRange] = useState({start: moment().startOf("month").toDate(), end: moment().endOf("month").toDate()})

  const { loading, data, refetch } = useQuery(FetchInterviews, {
    variables: {
      where: queryCondition,
    },
    fetchPolicy: "no-cache",
  });
  const handleOnClick = (data: InterviewType) => {
    setSelectedCustomer(data);
    setInterviewModalOpen(true);
  };

  const fetchCalendlyEvents = async(pageToken:string | null = null) => {
    try{
      let url = `${process.env.REACT_APP_API_URL}interviews?min_start_time=${range.start}&max_start_time=${range.end}`
      if(pageToken){
        url += `&page_token=${pageToken}`
      }
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if(Array.isArray(resp.data?.collection) && resp.data?.collection?.length){
        const _events = resp.data.collection.map((event: any) => {
          let title: string = event.name
          if(Array.isArray(event.invitees?.collection) && event.invitees?.collection?.length){
            title = event.invitees.collection[0].name
          }
          return {
            title: <div className="d-flex justify-content-center align-items-center">
              <span className="excerpt-text">{title}</span>
              <img src="/calendly_logo.png" style={{height: 15, width: 25}}/>
            </div>,
            allDay: false,
            start: new Date(event.start_time),
            end: new Date(event.end_time),
            id: String(Date.now()),
            data: event,
            type: "calendly"
          };
        });
        setCalendlyEvents(_events)
      }
    }catch(err){

    }
  }
  useEffect(() => {
    if (Array.isArray(data?.customers) && data?.customers?.length) {
      const _interviews = data.customers.map((interview: InterviewType) => {
        return {
          title: `${interview.firstname} ${interview.lastname}`,
          allDay: false,
          start: new Date(interview.onboarding_interview_date),
          end: new Date(interview.onboarding_interview_date),
          id: interview.id,
          data: interview,
          type: "caregigs"
        };
      });
      setInterviews(_interviews);
    }
  }, [data]);
  useEffect(()=> {
    fetchCalendlyEvents()
  }, [range])
  const localizer = momentLocalizer(moment);
  return (
    <>
      <ReactCalender
        localizer={localizer}
        events={[...interviews, ...calendlyEvents]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={{ month: true, week: true, day: true }}
        onSelectEvent={(event) => {
          if(event.type === "calendly"){
            window.open(event.data.location?.join_url, '_blank');
          }else{
            handleOnClick(event.data)
          }
        }}
        onRangeChange={(range: any)=>setRange({start: range.start, end: range.end})}
        eventPropGetter={(event) => {
          const backgroundColor = event.type === "calendly" ? '#ccc' : '#009EF7';
          const color = event.type === "calendly" ? "#000" : "#fff"
          return { style: { backgroundColor, color } }
        }}
      />
      <CreateInterviewModal
        isOpen={interviewModalOpen}
        toggle={() => setInterviewModalOpen(false)}
        customer={selectedCustomer}
        showExtraActions={true}
      />
    </>
  );
};
export default InterviewCalendar;

import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import "./calendarView.scss"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import '../../../pages/Schedule/grid.scss';

//components
import CreateShift from "components/scheduler/CreateShift";

//redux
import { useSelector } from "react-redux";
import { schedulerState } from "toolkit/scheduler/scheduler.slice";
const moment = extendMoment(Moment);

const getCellValue = (jobRequests, rangedate) => {
	let cellValue = []
	let _jobRequest = jobRequests.filter((job) => job.job_opening?.job_date === rangedate.format("YYYY-MM-DD"))
	if (_jobRequest.length) {
		_jobRequest.map((jr) => {
			let nurseName = "";
			if (jr.employee?.id) {
				nurseName = `${jr.employee?.firstname} ${jr.employee?.lastname}`
			} else {
				nurseName = `${jr.customer?.firstname} ${jr.customer?.lastname}`
			}
			let positionName = ""
			if (jr?.job_opening?.position?.abbreviation) {
				positionName = jr?.job_opening?.position.abbreviation
			} else {
				positionName = jr?.job_opening?.position?.name
			}
			positionName += ` (${formatTime(jr?.job_opening?.position?.start_time)} - ${formatTime(jr?.job_opening?.position?.end_time)})`
			// positionName += ` - ${jr?.job_opening?.location?.name} (${formatTime(jr?.job_opening?.position?.start_time)} - ${formatTime(jr?.job_opening?.position?.end_time)})`

			cellValue.push({
				nurseName,
				positionName,
				start_time: formatTime(jr?.job_opening?.position?.start_time),
				end_time: formatTime(jr?.job_opening?.position?.end_time),
				empBackground: jr.employee?.background_color,
				empColor: jr.employee?.text_color,
				positionBackground: jr?.job_opening?.position?.background_color,
				positionColor: jr?.job_opening?.position?.text_color,
				isNurseConnect: jr.employee?.id ? false : true,
				type: "jobRequest",
				data: jr
			})
		})
	}
	return cellValue
}
const formatTime = (time) => {
	return moment(time, "HH:mm").format("hh:mm A")
}
const getBlock = (jobRequests, rangedate, handleOnClick) => {
	let cellValue = getCellValue(jobRequests, rangedate);
	var divstyle = {}
	divstyle.overflowY = "auto"
	divstyle.flexDirection = "column"
	divstyle.justifyContent = "flex-start"
	divstyle.top = 42
	return (
		<div
			className="grid-item-value"
			style={divstyle} >
			{cellValue.map((cv, i) => (
				<div
					className="d-flex justify-content-between align-items-center w-100 px-3 my-2 shadow pb-2"
					key={i}
					style={{ borderBottom: "1px solid #ccc", cursor: "pointer" }}
					onClick={() => handleOnClick(rangedate, cv)}
				>
					<div
						style={{
							background: cv.empBackground,
							color: cv.empColor,
							flex: 1
						}}
						className="p-1"
					>
						{cv.isNurseConnect && (
							<img
								width="20px"
								src={"/cc_icon.png"}
								alt=""
								className="img-fluid mx-1"
							/>
						)}
						<span>{cv.nurseName}</span>
					</div>
					<div
						style={{
							background: cv.positionBackground,
							color: cv.positionColor
						}}
						className="p-1"
					>
						<span>{cv.positionName}</span>
					</div>
				</div>
			))}
			{cellValue.length === 0 && (
				<p>No Details Found!</p>
			)}
		</div>
	)
}
const CalendarView = props => {
	const { daterange, jobRequests } = useSelector(schedulerState)
	const [modal_center, setmodal_center] = useState(false);
	const [position, setPosition] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedCellData, setSelectedCellData] = useState(null)

	function tog_center(_position) {
		setmodal_center(!modal_center);
		setPosition(_position || null);
	}
	const handleOnClick = (date, data) =>{
		tog_center(data?.data?.job_opening?.position)
		setSelectedCellData(data)
		setSelectedDate(date)
	}
	return (
		<div className="calendar-view" id="calendar">
			<div className="grid-container" key={daterange.length}>
				{daterange.map((date, i) => (
					<div className="grid-item" key={i}>
						<div
							// onClick={() => tog_center()}
							className="GridTopHeader"
							style={{ height: 42, }}
						>
							<div className="d-flex">
								<div
									style={{
										background: date.format('dddd') === 'Saturday' || date.format('dddd') === 'Sunday' ? '#000000' : '#009EF7',
										color: "#FFFFFF",
										width: '30px',
										paddingTop: '5px',
										textAlign: 'center',
										verticalAlign: 'middle',
										paddingBottom: '5px'
									}}>
									<span style={{ fontSize: '15px', color: "#FFFFFF" }}> {date.format('DD')}</span>
								</div>
								<div style={{ padding: '5px', fontSize: '10px' }}>
									{date.format('MMM')}<br />
									{date.format('dddd')}
								</div>
							</div>
						</div>
						{getBlock(jobRequests, date, handleOnClick)}
					</div>
				))}
			</div>
			<CreateShift
				modal_center={modal_center}
				tog_center={tog_center}
				position={position}
				selectedDate={selectedDate}
				selectedCellData={selectedCellData}
				display="position"
			/>
		</div>
	)
}

CalendarView.propTypes = {
	positions: PropTypes.any,
	employees: PropTypes.any
}
export default CalendarView;

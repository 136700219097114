import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker } from 'rsuite'
import { useState } from "react"
import PropTypes from "prop-types"

const FacilityFilter = (props) => {
	const [filterData, setFilterData] = useState([])
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})

	const systemUserActiveData = [{ label: "Active", value: "true" }, { label: "Inactive", value: "false" }]

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			const filterValuesTemp = { ...filterValues }
			if (!filters.includes("status")) {
				delete filterValuesTemp["status"]
			}
			if (!filters.includes("created_at")) {
				delete filterValuesTemp["created_at"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		const filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "status") {
				filterDataTemp.push({ label: "Status", value: "status" })
			}
			if (filter === "created_at") {
				filterDataTemp.push({ label: "Created At", value: "created_at" })
			}
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "status") {
					return (
						<React.Fragment key={i}>
							<SelectPicker
								data={systemUserActiveData}
								placeholder={"Select Status"}
								style={{ marginLeft: 10 }}
								onChange={(status) => setFilterValues({ ...filterValues, status })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Created At Date"
							// value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
FacilityFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default FacilityFilter
export const nurseShiftList = [
  {
    id: 1,
    label: "Day Shift",
    value: "Day Shifts (7a - 3p OR 7a - 7p)",
  },
  {
    id: 2,
    label: "Evening Shift",
    value: "Evening Shifts (3p - 11p OR 11a - 7p)",
  },
  {
    id: 3,
    label: "Overnight Shift",
    value: "Overnight Shifts (7p - 7a)",
  },
  {
    id: 4,
    label: "Weekend Shift",
    value: "Weekend Shift",
  },
  {
    id: 5,
    label: "No Preference",
    value: "No Preference",
  },
];

export const nurseIntendList = [
  {
    id: 1,
    label: "6-10 Shifts",
    value: "6-10 Shifts",
  },
  {
    id: 2,
    label: "More than 10 Shifts",
    value: "More than 10 Shifts",
  },
  {
    id: 3,
    label: "1-2 Shifts",
    value: "1-2 Shifts",
  },
  {
    id: 4,
    label: "3-5 Shifts",
    value: "3-5 Shifts",
  },
  {
    id: 5,
    label: "Long-term",
    value: "Long-term",
  },
];

export const negativeTBTest = [
  {
    id: 1,
    label: "Yes, I have the documentation",
    value: "Yes, I have the documentation",
  },
  {
    id: 2,
    label: "No, I will need to get another one",
    value: "No, I will need to get another one",
  },
  {
    id: 3,
    label: "Yes, but i need to get the documentation",
    value: "Yes, but i need to get the documentation",
  },
];

export const covidTest = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    label: "No",
    value: "No",
  },
  {
    id: 3,
    label: "I am partially vaccinated",
    value: "I am partially vaccinated",
  },
];

export const genderList = [
  { id: 1, label: "Male", value: "male" },
  { id: 2, label: "Female", value: "female" },
  { id: 3, label: "Others", value: "others" },
]
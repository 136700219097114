import React from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link } from "react-router-dom";
import { Dropdown } from "rsuite";
import { FiCheck } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Badge, Button } from "reactstrap";

//types
import jobRequestType from "./requests.types";
export const FormatData = (employees: jobRequestType[]) => {
  const _employees = employees.map((elem: jobRequestType) => {
    return {
      id: elem.id,
      status: elem.status,
      rejected_reason: elem.rejected_reason || "N/A",
      cancellation_reason: elem.cancellation_reason || "N/A",
      active: elem.active,
      created_at: elem.created_at,
      job_opening_id: elem.job_opening?.id,
      job_date: elem.job_opening?.job_date,
      name:
        elem.customer?.firstname?.length && elem.customer?.lastname?.length
          ? `${elem.customer?.firstname} ${elem.customer?.lastname}`
          : "Guest User",
      customer_id: elem.customer?.id,
      position_name: elem.job_opening?.position?.name || "N/A",
      position_id: elem.job_opening?.position?.id,
      location_name: elem.job_opening?.location?.name || "N/A",
      location_id: elem.job_opening?.location?.id,
      fee_amount: elem.job_opening?.fee_amount,
      currency: elem.job_opening?.currency,
      fee_period: elem.job_opening?.fee_period,
      shift_time: `${elem.job_opening?.position?.start_time} - ${elem.job_opening?.position?.end_time}`,
      is_confirmed: elem.is_confirmed,
      filled: elem.job_opening?.filled,
    };
  });
  return _employees;
};
const formatHighlighter = (
  text: string,
  searchKey: string,
  filterValue: any
) => {
  let searchWords = [searchKey];
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role];
  }
  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  );
};
export const JobRequestColumns = (
  searchKey: string,
  filterValue: any,
  history: any,
  handleChangeStatus: (
    id: string,
    jobOpeningId: string,
    status: string
  ) => void,
  handleRevokeStatus: (id: string, jobOpeningId: string) => void,
  onDeleteClick: (id: string) => void,
  onSelectUser: (timecard: jobRequestType) => void
) => [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    dataField: "img",
    text: "#",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        {!user.customer_avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.name.charAt(0)}
            </span>
          </div>
        ) : (
          <div>
            {/* <img
                  className="rounded-circle avatar-xs"
                  src={images[user.img]}
                  alt=""
                /> */}
          </div>
        )}
      </>
    ),
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, user: any) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link 
           to={`/marketplace-profile/${user.customer_id}`} 
            className="text-dark" 
            // onClick={() => onSelectUser(user)}
          >
            {formatHighlighter(user.name, searchKey, filterValue)}
          </Link>
        </h5>
        <p className="text-muted mb-0">{user.designation}</p>
      </>
    ),
  },
  {
    dataField: "job_date",
    text: "Job Date",
    sort: true,
    formatter: (cellContent: Date, row: any) =>
      moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "position_name",
    text: "Position",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "location_name",
    text: "Location",
    sort: true,
    formatter: (cellContent: string, user: any) => (
      <span>{formatHighlighter(cellContent, searchKey, filterValue)}</span>
    ),
  },
  {
    dataField: "shift_time",
    text: "Shift Time",
    sort: true,
    formatter: (cellContent: string, user: any) => <span>{cellContent}</span>,
  },
  {
    dataField: "fee_amount",
    text: "Rate",
    sort: true,
    hidden: true,
    formatter: (cellContent: string, row: any) => (
      <span>
        {cellContent} {row.currency}
      </span>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent: string, row: any) => (
      <span>
        {" "}
        <Badge
          pill
          className={`badge ${
            cellContent === "requested"
              ? "bg-warning"
              : cellContent === "approved" || row.is_confirmed
              ? "bg-success"
              : "bg-danger"
          } font-size-11 m-2 p-2`}
        >
          {formatHighlighter(
            row.is_confirmed ? "CONFIRMED" : cellContent?.toUpperCase(),
            searchKey,
            filterValue
          )}
        </Badge>
      </span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent: string, row: any) => {
      const isBefore = moment(row.job_date, "YYYY-MM-DD").isBefore(
        moment().format("YYYY-MM-DD")
      );
      return (
        <div className="d-flex justify-content-between">
          {!isBefore && (
            <>
              {row.status === "requested" && !row.filled && (
                <>
                  <Button
                    color="success"
                    outline
                    size="sm"
                    style={{ height: 30 }}
                    to="#"
                    onClick={() =>
                      handleChangeStatus(row.id, row.job_opening_id, "approved")
                    }
                  >
                    <FiCheck size={15} />
                    Accept
                  </Button>
                  <Button
                    color="danger"
                    outline
                    size="sm"
                    style={{ height: 30, marginLeft: 5}}
                    to="#"
                    onClick={() =>
                      handleChangeStatus(row.id, row.job_opening_id, "rejected")
                    }
                  >
                    <FiX size={15} />
                    Deny
                  </Button>
                </>
              )}
              {row.status === "approved" && row.filled && (
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-restart font-size-18"
                    id="edittooltip"
                    onClick={() =>
                      handleRevokeStatus(row.id, row.job_opening_id)
                    }
                  ></i>
                </Link>
              )}
            </>
          )}
          <Link className="text-danger" to="#" style={{ marginLeft: "auto" }}>
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onDeleteClick(row.id)}
            ></i>
          </Link>
        </div>
      );
    },
  },
];
export const BuildJobRequestSearchQuery = (
  value: string,
  queryCondition: any
) => {
  if (value.length >= 3) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        {
          customer: {
            _or: [
              { firstname: { _ilike: `%${value}%` } },
              { lastname: { _ilike: `%${value}%` } },
            ],
          },
        },
        { job_opening: { position: { name: { _ilike: `%${value}%` } } } },
        { job_opening: { location: { name: { _ilike: `%${value}%` } } } },
        { status: { _ilike: `%${value}%` } },
      ],
    };
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};
export const BuildJobRequestFilterQuery = (
  filterValue: any,
  queryCondition: any
) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    if (filterValue?.hasOwnProperty("status")) {
      conditionTemp = {
        ...conditionTemp,
        status: { _eq: filterValue.status },
      };
    }
    if (filterValue?.hasOwnProperty("job_date")) {
      if (Array.isArray(filterValue.job_date)) {
        const job_date_from = moment(filterValue.job_date[0]).format(
          "YYYY-MM-DD"
        );
        const job_date_till = moment(filterValue.job_date[1]).format(
          "YYYY-MM-DD"
        );
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { job_opening: { job_date: { _gte: job_date_from } } },
            { job_opening: { job_date: { _lte: job_date_till } } },
          ],
        };
      }
    }
    if (filterValue?.hasOwnProperty("position")) {
      conditionTemp = {
        ...conditionTemp,
        job_opening: { position_id: { _in: filterValue.position } },
      };
    }
    if (filterValue?.hasOwnProperty("location")) {
      conditionTemp = {
        ...conditionTemp,
        job_opening: { location_id: { _in: filterValue.location } },
      };
    }
    return conditionTemp;
  } else {
    let conditionTemp = {};
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition };
    }
    return conditionTemp;
  }
};

import { gql } from "@apollo/client"
export const UpdateJobRequest =
    gql`mutation UpdateJobRequest(\$id:uuid!, \$object: job_requests_set_input) {
    update_job_requests_by_pk(pk_columns: {id: \$id}, _set: \$object) {
        id
      }
    }`
export const InsertJobRequests =
	gql`mutation InsertJobRequests(\$objects: [job_requests_insert_input!]!) {
  insert_job_requests(objects: \$objects) {
    affected_rows
  }
}
`
export const UpdateJobRequestByField =
    gql`mutation UpdateJobRequest(\$where:job_requests_bool_exp!, \$object: job_requests_set_input) {
    update_job_requests(where: \$where, _set: \$object) {
      affected_rows
      }
    }`
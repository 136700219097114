import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
  registerApi, 
  loginApi, 
  socialLoginApi, 
  forgotPasswordApi,
  verifyForgotPasswordApi,
  resetPasswordApi,
  verifyOTPApi,
  resendOTPApi
} from "./auth.api";

interface UserType {
  role: string;
  is_walkthrough_done: boolean;
  name: string;
  email: string;
  industry: string;
  id: string;
  is_email_verified: boolean;
  active: boolean;
  avatar: string | undefined | null;
  google_id: string | undefined | null;
  facebook_id: null;
  apple_id: string | undefined | null;
  updated_at: string;
  created_at: string;
  notification_token: string | undefined | null;
  stripe_id: string | undefined | null;
  position: string | null;
  short_name: string | null;
}

interface StateType {
  message: string;
  isLoading: boolean;
  errorMsg: string;
  user: UserType;
  token: string | null;
  accessToken: string | null;
  accessKey: string | null;
}

interface PayloadType {
  message: string;
  status: number;
}

const userDefault = {
  role: "",
  is_walkthrough_done: false,
  name: "",
  email: "",
  industry: "",
  id: "",
  is_email_verified: false,
  active: false,
  avatar: null,
  google_id: null,
  facebook_id: null,
  apple_id: null,
  updated_at: "",
  created_at: "",
  notification_token: null,
  stripe_id: null,
  position: "",
  short_name: ""
}
const initialState: StateType = {
  message: "",
  isLoading: false,
  errorMsg: "",
  token: null,
  accessToken: null,
  accessKey: null,
  user: userDefault
};

export const authSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRemoveToken(state, action){
      return { ...state, token: null, user: userDefault };
    },
    resetMessage(state){
      return { ...state, message: "", errorMsg: "" };
    }
  },
  extraReducers: {
    /** --------------Register Reducer--------------- */
    [registerApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [registerApi.fulfilled]: (state, { payload }) => {
      // localStorage.setItem("TOKEN", payload.data.token);
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [registerApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** --------------Login Reducer--------------- */
    [loginApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [loginApi.fulfilled]: (state, { payload }) => {
      if(payload?.data?.token){
        localStorage.setItem("TOKEN", payload.data.token);
        return {
          ...state,
          isLoading: false,
          user: payload.data.user_info,
          token: payload.data.token,
          errorMsg: ""
        };
      }else{
        return {
          ...state,
          isLoading: false,
          errorMsg: ""
        };
      }
    },
    [loginApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Wrong Email or Password!", message: "" };
    },

    /** --------------Social Login Reducer--------------- */
    [socialLoginApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [socialLoginApi.fulfilled]: (state, { payload }) => {
      localStorage.setItem("token", JSON.stringify(payload.data.token));
      return {
        ...state,
        isLoading: false,
        user: payload.data.user_info,
        token: payload.data.token,
        errorMsg: ""
      };
    },
    [socialLoginApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** --------------Forgot Password Reducer--------------- */
    [forgotPasswordApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [forgotPasswordApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [forgotPasswordApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** --------------Verify Forgot Password Reducer--------------- */
    [verifyForgotPasswordApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [verifyForgotPasswordApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        accessKey: payload.data.key,
        accessToken: payload.data.access_token,
        message: payload.summary,
        errorMsg: ""
      };
    },
    [verifyForgotPasswordApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** --------------Reset Password Reducer--------------- */
    [resetPasswordApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "" };
    },
    [resetPasswordApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [resetPasswordApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** --------------Verify OTP Reducer--------------- */
    [verifyOTPApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [verifyOTPApi.fulfilled]: (state, { payload }) => {
      if(payload?.data?.token){
        localStorage.setItem("TOKEN", payload.data.token);
        return {
          ...state,
          isLoading: false,
          user: payload.data.user_info,
          token: payload.data.token,
          errorMsg: ""
        };
      }else{
        return state;
      }
    },
    [verifyOTPApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },

    /** -------------- Resend OTP Reducer ------------ **/
    [resendOTPApi.pending]: (state) => {
      return { ...state, isLoading: true, message: "", errorMsg: "" };
    },
    [resendOTPApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: ""
      };
    },
    [resendOTPApi.rejected]: (state) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!", message: "" };
    },
  },
});

export const authState = (state: any) => state;
export const { loginRemoveToken, resetMessage } = authSlice.actions

export default authSlice.reducer;

import React from 'react'

const ProfessionalExperience: React.FC<{ user: any }> = ({ user }) => {

  return (
    <div>
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Intend to Work:
        </div>
        <div className="information-info" >
          {user.questionnaire?.intendToWork}
        </div>
      </div>
      {/* info */}
      {/* info */}
      {user.questionnaire?.intersetedShifts?.length > 0 && (
        <div className="information" >
          <div className="information-header" >
            Interested Shifts:
          </div>
          <div className="information-info" >
            {user.questionnaire?.intersetedShifts?.map((int_shift: string, i: number) => (
              <p key={i}>{int_shift}</p>
            ))}
          </div>
        </div>
      )}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Total Experience:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.totalExp || 0} years
        </div>
      </div>
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Post-Acute Care (SNF, ALF, CCRC):
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.postAcuteCareExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Acute Care Inpatient Hospitals:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.acuteCareExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Ambulatory Clinics/Surgical Centers:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.clinicExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Home Health:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.homeHealthExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Hospice:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.hospieExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Urgent Care:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.urgentCareExp || 0} years
        </div>
      </div>
      {/* info */}
      {/* info */}
      <div className="information" >
        <div className="information-header" >
          Other Experience Duration:
        </div>
        <div className="information-info" >
          {user.questionnaire?.experience?.otherExp || 0} years
        </div>
      </div>
      {/* info */}
    </div>
  )
}

export default ProfessionalExperience

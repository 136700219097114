import React, { useEffect } from "react";
import { DateRangePicker, CheckPicker } from "rsuite";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import CheckPickerPaginate from "../CheckPickerPaginate";
import { FetchPositionsForFilter } from "gql/positions/query";
import { FetchLocationsForFilter } from "gql/locations/query";

//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const ShiftFilter = (props) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const history = useHistory();
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  const resetFilter = (value) => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const filtersTemp = filters.filter((item) => item !== value);
    searchParams.delete(value);
    setFilters(filtersTemp);
    history.push({ pathname: pathname, search: searchParams.toString() });
  };
  useEffect(() => {
    props.onFilterChange(filterValues);
  }, [filterValues]);
  useEffect(() => {
    if (filters.length) {
      const filterValuesTemp = { ...filterValues };
      if (!filters.includes("job_date")) {
        delete filterValuesTemp["job_date"];
      }
      if (!filters.includes("position")) {
        delete filterValuesTemp["position"];
      }
      if (!filters.includes("location")) {
        delete filterValuesTemp["location"];
      }
      setFilterValues(filterValuesTemp);
    } else {
      setFilterValues({});
    }
  }, [filters]);
  useEffect(() => {
    const filterDataTemp = [];
    props.activeFilters.map((filter) => {
      if (filter === "job_date") {
        filterDataTemp.push({ label: "Job Date", value: "job_date" });
      }
      if (filter === "position") {
        filterDataTemp.push({ label: "Position", value: "position" });
      }
      if (filter === "location") {
        filterDataTemp.push({ label: "Location", value: "location" });
      }
    });
    setFilterData(filterDataTemp);
  }, [props.activeFilters]);

  const onClickFilter = (type, value) => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    if (value.length) {
      searchParams.set(type, value.join(","));
    } else {
      searchParams.delete(type);
    }
    history.push({ pathname: pathname, search: searchParams.toString() });
  };

  return (
    <div className="auto-horizontal-scroll">
      <CheckPicker
        data={filterData}
        placeholder={"Filter"}
        style={{ width: 130 }}
        value={filters}
        onChange={setFilters}
        searchable={false}
        menuStyle={{ zIndex: 9999 }}
      />
      {filters.map((filter, i) => {
        if (filter === "job_date") {
          return (
            <React.Fragment key={i}>
              <DateRangePicker
                placement="auto"
                onChange={(job_date) =>{
                  setFilterValues({ ...filterValues, job_date })
                  onClickFilter("job_date", job_date);
                }}
                style={{ marginLeft: 10 }}
                placeholder="Select Job Date"
                // value={Array.isArray(filterValues.job_date) ? filterValues.job_date : []}
              />
              <div
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => resetFilter("job_date")}
              >
                <i className="bx bx-x-circle" />
              </div>
            </React.Fragment>
          );
        }
        if (filter === "position") {
          return (
            <React.Fragment key={i}>
              <CheckPickerPaginate
                query={FetchPositionsForFilter}
                placeholder="Select Position"
                value={
                  Array.isArray(filterValues.position)
                    ? filterValues.position
                    : []
                }
                onChange={(position) => {
                  setFilterValues({ ...filterValues, position });
                  onClickFilter("position", position);
                }}
                arrKey="positions"
                objectLabel="name"
                objectValue="id"
                style={{ marginLeft: 10 }}
                paginate={true}
                filterCondition={{
                  active: { _eq: true },
                }}
                menuStyle={{ zIndex: 9999 }}
              />
              <div
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => resetFilter("position")}
              >
                <i className="bx bx-x-circle" />
              </div>
            </React.Fragment>
          );
        }
        if (filter === "location") {
          return (
            <React.Fragment key={i}>
              <CheckPickerPaginate
                query={FetchLocationsForFilter}
                placeholder="Select Location"
                value={
                  Array.isArray(filterValues.location)
                    ? filterValues.location
                    : []
                }
                onChange={(location) => {
                  setFilterValues({ ...filterValues, location });
                  onClickFilter("location", location);
                }}
                arrKey="locations"
                objectLabel="name"
                objectValue="id"
                style={{ marginLeft: 10 }}
                paginate={true}
                filterCondition={{
                  isactive: { _eq: true },
                }}
                menuStyle={{ zIndex: 9999 }}
              />
              <div
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => resetFilter("location")}
              >
                <i className="bx bx-x-circle" />
              </div>
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};
ShiftFilter.propTypes = {
  onFilterChange: PropTypes.func,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
};
export default ShiftFilter;

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Col,
  Row,
} from "reactstrap";
import { Rate } from "rsuite";
import { showAlert } from "helpers/index";

//GraphQL
import { useMutation } from "@apollo/client";
import { UpdateJobRequest } from "../../gql/jobRequests/mutation";

// icons
import { FiSend } from "react-icons/fi";

//types
import timecardsType from "pages/Timecards/timecards.types";

const RatingModal: React.FC<{
  toggle: () => void;
  modal: boolean;
  timecard: timecardsType | null;
  refetch: () => void
}> = ({ toggle, modal, timecard, refetch }) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");
  const [UpdateJobRequestMutation] = useMutation(UpdateJobRequest);

  const handleClose = () => {
    toggle();
    setRate(0);
    setFeedback("");
  };
  const handleSubmit = async(e: React.SyntheticEvent) => {
    e.preventDefault();
    try{
      setBtnLoading(true)
      await UpdateJobRequestMutation({
        variables: {
          id: timecard?.id,
          object: {
            rating: rate,
            review: feedback
          },
        },
      });
      handleClose()
      showAlert({
        title: "Success!",
        message: "Rating submitted successfully!",
        type: "success",
      })
      refetch()
    }catch(err){
      showAlert({
        title: "Error!",
        message: "Something went wrong!",
        type: "danger",
      });
    }finally{
      setBtnLoading(false)
    }
  }
  useEffect(()=> {
    if(timecard){
      setRate(timecard?.rating || 0)
      setFeedback(timecard?.review || "")
    }
  }, [timecard])
  return (
    <Modal
      isOpen={modal}
      size="md"
      backdrop="static"
      className="customModal"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose} tag="h4">
        Rate Nurse
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Rating</Label>
              </div>
              <div className="mb-3">
                <Rate 
                  defaultValue={0} 
                  allowHalf={true}
                  value={rate}
                  onChange={(value: number)=>setRate(value)}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Feedback</Label>
                <Input 
                  name="message" 
                  label="Message" 
                  type="textarea"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={btnLoading}
                >
                  <FiSend /> &nbsp; Submit
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default RatingModal;

import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link, useHistory } from "react-router-dom";

import { Button, Row, Col } from "rsuite";
import { Form, FormGroup, Input } from "reactstrap";
import { FiPhone, FiMail } from "react-icons/fi";
//redux
import { useSelector } from "react-redux";
import { authState } from "toolkit/auth/auth.slice";

const ContactUs: React.FC = () => {
  const {
    auth: { token },
  } = useSelector(authState);

  return (
    <React.Fragment>
      <div className="HelpPage">
        <div className="HelpPage-Navbar">
          <Link to="/help">
            <img
              src={"/caregigs_logo.png"}
              alt=""
              className="HelpPage-NavbarLogo"
            />
          </Link>
          <div className="HelpPage-NavbarItems">
            <div className="HelpPage-NavbarItem1">
              <p className="HelpPage-NavbarItemText1">Contact Us</p>
            </div>
            <div>
              <NavLink to={"/login"}>
                <p className="HelpPage-NavbarItemText2">
                  {token ? "Dashboard" : "Log In"}
                </p>
              </NavLink>
            </div>
          </div>
        </div>

        <Row className="HelpPage-headerContainer">
          <p className="HelpPage-contactUsHeader">Let us know your thoughts</p>
          <div className="HelpPage-subHeaderContainer">
            <p className="HelpPage-contactUsDetailHeading">
              <FiPhone size={17} className="HelpPage-detailIcon" />
              Phone number:
              <p className="HelpPage-contactUsDetailContent">
                +1 (435) 246-0763
              </p>
            </p>
            <div className="HelpPage-vl" />
            <p className="HelpPage-contactUsDetailHeading">
              <FiMail size={17} className="HelpPage-detailIcon" />
              Email:
              <p
                className="HelpPage-contactUsDetailContent"
                style={{ textDecoration: "underline" }}
              >
                support@caregigs.co
              </p>
            </p>
          </div>
        </Row>
        <div className="HelpPage-contactUsCardContainer">
          <Row className="HelpPage-contactUsCard">
            <Col className="HelpPage-contactUsFirstRow" md={12}>
              <img
                src={"/contact_us.png"}
                alt=""
                style={{ width: 369, height: "auto" }}
              />
            </Col>
            <Col
              className="HelpPage-contactUsSecondRow"
              md={12}
              // style={{ paddingInline: 70}}
            >
              <Input
                name="search"
                placeholder="Full Name"
                type="text"
                className="HelpPage-contactInputField"
              />
              <Input
                name="search"
                placeholder="Email"
                type="text"
                className="HelpPage-contactInputField"
              />
              <Input
                type="textarea"
                id="textarea"
                maxLength={500}
                rows="8"
                name="description"
                placeholder="Your questions"
                disabled={false}
                className="HelpPage-contactInputField"
              />
              <Button
                appearance="primary"
                block
                className="rounded-pill"
                style={{ backgroundColor: "#3177D8" }}
              >
                Send Message
              </Button>
            </Col>
          </Row>
        </div>
        <div className="HelpPage-footer">
          <p className="HelpPage-footerText">
            © 2023 Caregigs LLC | All Rights Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ContactUs);
